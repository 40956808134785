import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { LoanSteps } from '../types';
import { Step } from './Step';
import { StepLine } from './StepLine';
import { StepLabel } from './StepLabel';

type Props = {
  activeStep: LoanSteps;
};

export const SignContractStep = ({ activeStep }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  const isSignContract = activeStep === 'sign-contract';

  return (
    <Step>
      <StepLine
        lineColor={
          isSignContract
            ? theme.palette.B2B2023.back.controlMajor
            : theme.palette.B2B2023.front.line
        }
      />
      {!isMobile && (
        <StepLabel
          labelColor={
            isSignContract
              ? theme.palette.B2B2023.front.major
              : theme.palette.B2B2023.front.disabled
          }
        >
          {t('sign_contract')}
        </StepLabel>
      )}
    </Step>
  );
};
