import { useTranslation } from 'react-i18next';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import Campaign24 from '@tabby.ai/tabby-ui/dist/icons/core/Campaign24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { ROUTES } from '@/routes';
import { NavLink } from 'react-router-dom';

const NavMenuItemAds = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const { t } = useTranslation();

  return (
    <SideMenuItem
      icon={<Campaign24 ds={DS.B2B2023} />}
      label={t('tabby_ads')}
      to={ROUTES.ads}
      isCollapsed={isCollapsed}
      component={NavLink}
    />
  );
};

export default NavMenuItemAds;
