import { NOTIFICATION_VARIANTS, useShowNotification } from '@/components/Notifications';
import { useTranslation } from 'react-i18next';
import { isAPIError } from '@/store/rootApi';
import { useDeleteRefundPaymentMutation, useGetPaymentQuery } from './api';

export const useDeleteRefundPayment = (paymentId: string) => {
  const { t } = useTranslation();
  const [triggerDeleteRefund, { isLoading: isDeleteRefundLoading }] =
    useDeleteRefundPaymentMutation();
  const { refetch } = useGetPaymentQuery(paymentId);

  const showNotification = useShowNotification();

  const deleteRefund = async (refundId: string) => {
    try {
      await triggerDeleteRefund({ id: paymentId, refundId }).unwrap();
      showNotification({
        variant: NOTIFICATION_VARIANTS.Success,
        message: t('successfully_cancelled_pending_refund')
      });
      refetch();
    } catch (error) {
      if (isAPIError(error) && error.status === 409) {
        showNotification({
          variant: NOTIFICATION_VARIANTS.Warning,
          message: t('refund_was_already_processed')
        });
      } else {
        showNotification({
          variant: NOTIFICATION_VARIANTS.Error,
          message: t('failed_to_cancel_the_refund_please_try_again_later')
        });
      }
    }
  };

  return { deleteRefund, isDeleteRefundLoading };
};
