import { prefixLS } from '@/utils/string';

export const paymentLinksStateKey = prefixLS('pl-dict');

export const openCreatePaymentLinkParam = 'pl';

export const canCreatePaylinksRoles = [
  'workspace_admin',
  'settlements',
  'supervisor',
  'sales_manager'
] as const;
