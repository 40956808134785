import { ValueOf } from '@/types';

export const ROUTES = {
  account: '/account',
  accountPhone: '/account/phone',
  accountSessions: '/account/sessions',
  ads: '/ads',
  applicationForm: '/application-form',
  applicationFormStep: '/application-form/:stepName',
  pipedriveApplicationId: '/application/:applicationId',
  auth: '/auth',
  businessProfileStore: '/business-profile/stores/:merchantId',
  businessProfileStoreTab: '/business-profile/stores/:merchantId/:tab',
  businessProfileStores: '/business-profile/stores',
  analytics: '/analytics',
  analyticsCountryMetric: '/analytics/:country/:metric',
  disputes: '/disputes',
  disputesFaq: '/disputes-faq',
  disputesId: '/disputes/:id',
  financePayouts: '/finance/payouts',
  financeCompletedPayouts: '/finance/payouts/completed/:payoutId?',
  financeHowReportsWork: '/finance/payouts/how-reports-work',
  financeTabbyCapital: '/finance/tabby-capital',
  financeTabbyCapitalFakeDoor: '/finance/tabby-capital/fake-door',
  financeTabbyCapitalInWaitList: '/finance/tabby-capital/waitlist',
  financeTabbyCapitalLoans: '/finance/tabby-capital/loans',
  financeTabbyCapitalLoansId: '/finance/tabby-capital/loans/:id',
  financeTabbyCapitalOffersId: '/finance/tabby-capital/offers/:id',
  financeTabbyCapitalApplication: '/finance/tabby-capital/application/:id/:merchantId',
  financeTabbyCard: '/finance/tabby-card',
  financeUpcomingPayouts: '/finance/payouts/upcoming/:merchantId?',
  onboarding: '/onboarding',
  onboardingCandidateId: '/onboarding/:candidateId',
  payments: '/payments',
  paymentsId: '/payments/:id',
  root: '/',
  service: '/service',
  servicePaymentLinkStatus: '/service/pl-status/:paymentId',
  support: '/support',
  supportTicketId: '/support/:id',
  users: '/users',
  marketplace: '/marketplace',
  marketplaceProductCatalog: '/marketplace/product-catalog',
  marketplaceProductCatalogAuth: '/marketplace/product-catalog/auth',
  webviewDisputesFaq: '/webview/disputes-faq',
  logout: '/logout/:source'
} as const;

export type Routes = ValueOf<typeof ROUTES>;

export type LogoutSource = 'header' | 'account' | 'server' | 'ads';
