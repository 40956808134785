import MuiLink from '@mui/material/Link';
import { SxProps, Theme } from '@mui/material/styles';

type Props = {
  sx?: SxProps<Theme>;
  value: string;
};

export const MailToLink = ({ sx, value }: Props) => {
  return (
    <MuiLink sx={{ textDecoration: 'none', ...sx }} href={`mailto:${value}`}>
      {value}
    </MuiLink>
  );
};
