import { styled } from '@mui/material/styles';

const Root = styled('span', {
  shouldForwardProp: (p) => p !== 'color'
})<Pick<Props, 'color'>>(({ color, theme }) => {
  const colors = {
    honey: {
      color: theme.palette.B2B2023.accent.warningA,
      backgroundColor: theme.palette.B2B2023.accent.warningC
    },
    pine: {
      color: theme.palette.B2B2023.accent.positiveA,
      backgroundColor: theme.palette.B2B2023.accent.positiveC
    },
    iris: {
      color: theme.palette.B2B2023.accent.highlightA,
      backgroundColor: theme.palette.B2B2023.accent.highlightC
    },
    coral: {
      color: theme.palette.B2B2023.accent.negativeA,
      backgroundColor: theme.palette.B2B2023.accent.negativeC
    },
    grey: {
      color: theme.palette.B2B2023.front.minor,
      backgroundColor: theme.palette.B2B2023.back.minor
    }
  }[color];

  return {
    padding: '4px 8px',
    borderRadius: '6px',
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(10),
    textAlign: 'center',
    whiteSpace: 'nowrap',
    ...colors
  };
});

export type Props = {
  label: string;
  color: 'honey' | 'pine' | 'iris' | 'coral' | 'grey';
};

export const StatusBadge = ({ label, color }: Props) => <Root color={color}>{label}</Root>;
