import {
  useResendSignInCodeMutation,
  useAuthenticateVerifyMutation,
  useAuthorize
} from '@/store/auth';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { useAnalyticsTrack } from '@/store/analytics';
import { useState } from 'react';
import { VerifyEmailForm } from './components/VerifyEmailForm';
import { useHandleApiError } from './hooks/useHandleApiError';

type Props = {
  email: string;
  onEmailChange: VoidFunction;
  onChallenge: (challenge: 'setup_phone' | 'verify_phone', phonePart: string | null) => void;
};

const trackParams = { type: 'email', stage: 'login' } as const;

export const VerifySignInEmailForm = (props: Props) => {
  const { email, onEmailChange, onChallenge } = props;
  const lang = useCurrentLanguage();
  const [resend] = useResendSignInCodeMutation();
  const [verify, { isLoading }] = useAuthenticateVerifyMutation();
  const authorize = useAuthorize();
  const handleApiError = useHandleApiError('verify');
  const track = useAnalyticsTrack();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (code: string) => {
    setErrorMessage('');
    try {
      const res = await verify({ otp_code: code }).unwrap();
      track({
        event: 'OTP Entered',
        params: { ...trackParams, email, result: 'success' }
      });

      if (res.status === 'authenticated') {
        track({ event: 'Logged In', params: trackParams });
        authorize();
      } else if (res.status === 'setup_2fa' && res.factor === 'phone') {
        onChallenge('setup_phone', null);
      } else if (res.status === 'input_2fa' && res.factor === 'phone') {
        onChallenge('verify_phone', res.phone);
      }
    } catch (error) {
      track({
        event: 'OTP Entered',
        params: { ...trackParams, email, result: 'failure' }
      });

      handleApiError(error)(setErrorMessage);
    }
  };

  const handleResend = async ({ resetTimer }: { resetTimer: VoidFunction }) => {
    setErrorMessage('');
    track({ event: 'OTP Resend Code Clicked', params: trackParams });

    try {
      await resend({ lang, email }).unwrap();
      resetTimer();
    } catch (error) {
      handleApiError(error)(setErrorMessage);
    }
  };

  return (
    <VerifyEmailForm
      email={email}
      onEmailChange={onEmailChange}
      onSubmitSuccess={handleSubmit}
      onResend={handleResend}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
};
