import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { LoaderDots } from '@/components/LoaderDots';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { ROUTES } from '@/routes';
import { useGetOffersQuery } from '@/store/offers';
import { Box, Fab, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from '@tabby.ai/tabby-ui/dist/core';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import ArrowBack24 from '@tabby.ai/tabby-ui/dist/icons/core/ArrowBack24';
import ChevronForward24 from '@tabby.ai/tabby-ui/dist/icons/core/ChevronForward24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { find } from 'lodash';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as BaseLink, generatePath } from 'react-router-dom';
import { LoanWidgetNewOffer } from '../../LoansPage/components/LoanWidgetNewOffer';
import { useLoanDetailsContext } from '../hooks/useLoanDetailsContext';
import { AboutWidget } from './AboutWidget';
import { ListRepayments } from './ListRepayments';
import { LoanDetailsAlert } from './LoanDetailsAlert';
import { PaymentsWidget } from './PaymentsWidget';
import { TableRepayments } from './TableRepayments';

const Root = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  height: '100%'
}));

const Grid = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'start',
  height: '100%',

  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column'
  }
}));

const FaqRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '12px',
  padding: '12px',
  alignItems: 'start',
  justifyContent: 'center',

  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column'
  }
}));

const Panel = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  borderRadius: '16px',
  background: theme.palette.B2B2023.back.major,

  [theme.breakpoints.down('p768')]: {
    gap: '12px',
    width: '100%'
  }
}));

const RepaymentsPanel = styled(Panel)({
  padding: '24px 16px',
  height: '100%',
  overflow: 'auto'
});

const TableTitle = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._h2,
  padding: '0 8px'
}));

const TableDescription = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight500,
  color: theme.palette.B2B2023.front.minor,
  padding: '14px 8px 0 8px'
}));

const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '360px'
}));

const TableColumn = styled(Column)(() => ({
  flex: '1',
  alignSelf: 'stretch'
}));

const Row = styled(Box)(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'flex-start'
}));

const FaqButton = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: '12px 12px 12px 24px',
  borderRadius: '16px',
  background: theme.palette.B2B2023.back.major,
  alignItems: 'center',
  justifyContent: 'space-between',

  '&:hover': {
    cursor: 'pointer',
    '& .MuiButton-root': {
      background: theme.palette.B2B2023.back.minor
    }
  }
}));

const BackButton = styled(Fab)(({ theme }) => ({
  width: '48px',
  height: '48px',
  flex: 'none',
  backgroundColor: theme.palette.B2B2023.back.major,
  boxShadow: 'none',

  '&:hover': {
    mixBlendMode: 'multiply'
  }
}));

const Link = styled(BaseLink)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.highlightA
}));

export const Content = () => {
  const [isShowFaq, setIsShowFaq] = useState(false);

  const { t } = useTranslation();
  const isMobile = useIsMobilePage();
  const { loan } = useLoanDetailsContext();

  const { data: offers = [], isLoading: isLoadingOffers } = useGetOffersQuery();
  const newOffer = find(offers, (o) => Number(o.tenor) === 20);

  const today = new Date();
  const nextMonday = new Date();

  nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7 || 7));
  nextMonday.setHours(23, 59, 59, 999);

  const hasNewOfferSection = newOffer && loan.status === 'closed';

  const closeFaq = () => setIsShowFaq(false);

  if (isLoadingOffers) return <LoaderDots height="100vh" />;

  if (isShowFaq) {
    return (
      <FaqRoot>
        <Column sx={{ width: isMobile ? '100%' : '520px' }}>
          {isMobile ? (
            <Column>
              <BackButton onClick={closeFaq}>
                <ArrowBack24 ds={DS.B2B2023} />
              </BackButton>
              <Panel>
                <AboutWidget />
              </Panel>
            </Column>
          ) : (
            <Row>
              <BackButton onClick={closeFaq}>
                <ArrowBack24 ds={DS.B2B2023} />
              </BackButton>
              <Panel>
                <AboutWidget />
              </Panel>
            </Row>
          )}
        </Column>
      </FaqRoot>
    );
  }

  if (isMobile) {
    return (
      <Root>
        <LoanDetailsAlert loan={loan} />
        {hasNewOfferSection && <LoanWidgetNewOffer offer={newOffer} mobileVariant />}
        <Panel>
          <DeprecatedTypography variant="_h3">{t('repayment_history')}</DeprecatedTypography>
          <PaymentsWidget loan={loan} />
        </Panel>
        <FaqButton onClick={() => setIsShowFaq(true)}>
          <DeprecatedTypography variant="_body1Loose700">{t('faqs')}</DeprecatedTypography>
          <IconButton ds="B2B2023" variant="text">
            <ChevronForward24 ds={DS.B2B2023} />
          </IconButton>
        </FaqButton>
        <Panel>
          <ListRepayments />
        </Panel>
      </Root>
    );
  }

  return (
    <Root>
      <LoanDetailsAlert loan={loan} />
      <Grid>
        <Column>
          {hasNewOfferSection && <LoanWidgetNewOffer offer={newOffer} mobileVariant />}
          <Panel>
            <PaymentsWidget loan={loan} />
          </Panel>
          <FaqButton onClick={() => setIsShowFaq(true)}>
            <DeprecatedTypography variant="_body1Loose700">{t('faqs')}</DeprecatedTypography>
            <IconButton ds="B2B2023" variant="text">
              <ChevronForward24 ds={DS.B2B2023} />
            </IconButton>
          </FaqButton>
        </Column>
        <TableColumn>
          <RepaymentsPanel>
            <TableTitle>{t('repayment_history')}</TableTitle>
            <TableDescription>
              <Trans
                i18nKey="you_can_find_detailed_overviews_in_payouts"
                components={{
                  a: (
                    <Link
                      to={generatePath(ROUTES.financeCompletedPayouts, { payoutId: null })}
                      target="_blank"
                    />
                  )
                }}
              />
            </TableDescription>
            <TableRepayments />
          </RepaymentsPanel>
        </TableColumn>
      </Grid>
    </Root>
  );
};
