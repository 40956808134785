import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Button } from '@/ui-kit/Button';

import { useHandlePrevClick } from '../hooks/useHandlePrevClick';
import { useHandleNextClick } from '../hooks/useHandleNextClick';
import { useFormStateContext } from '../hooks/useFormStateContext';

const Root = styled('div')(() => ({
  display: 'flex',
  gap: '8px',
  justifyContent: 'flex-end'
}));

const FormControls = () => {
  const { t } = useTranslation();
  const { isFirstStep, shouldSubmitForm, isLoadingSubmit } = useFormStateContext();

  const { handlePrevClick } = useHandlePrevClick();
  const { handleNextClick } = useHandleNextClick();

  return (
    <Root>
      {!isFirstStep && (
        <Button variant="containedLight" onClick={handlePrevClick} size="large">
          {t('back')}
        </Button>
      )}
      <Button
        variant="contained"
        onClick={handleNextClick}
        isLoading={isLoadingSubmit}
        size="large"
      >
        {shouldSubmitForm ? t('submit') : t('continue')}
      </Button>
    </Root>
  );
};

export default FormControls;
