import { useCallback, useMemo, useState } from 'react';
import { prefixLS } from '@/utils/string';

const useCachedFlag = (key: string) => {
  const lsKey = prefixLS(key);
  const [flag, setFlag] = useState(() => localStorage.getItem(lsKey) !== null);

  const save = useCallback(
    (value: boolean) => {
      if (value) {
        localStorage.setItem(lsKey, '1');
      } else {
        localStorage.removeItem(lsKey);
      }

      setFlag(value);
    },
    [lsKey]
  );

  return useMemo(() => [flag, save] as const, [flag, save]);
};

export default useCachedFlag;
