import phones from '@/lib/phones';
import { getEmailSchema, stringSchema } from '@/lib/yup';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { useCRNumberSAUValidation } from './useCRNumberSAUValidation';

export const useFormSchema = (isLegalEntityCountrySAU: boolean) => {
  const { t } = useTranslation();
  const { schema: crNumberSAUSchema } = useCRNumberSAUValidation();

  const crNumberScema = isLegalEntityCountrySAU
    ? crNumberSAUSchema
    : stringSchema.required(() => '');

  const schema = useMemo(() => {
    return yup.object().shape({
      sign: yup.object().shape({
        name: stringSchema.required(() => ''),
        phone: phones.getSchema(t),
        email: getEmailSchema(t).required(() => '')
      }),
      cr_file: stringSchema.required(() => ''),
      cr_number: crNumberScema,
      company_name_or_legal_name: stringSchema.required(() => ''),
      registered_company_address: stringSchema.required(() => ''),
      is_finance: yup.boolean(),
      finance: yup.object().when('is_finance', {
        is: true,
        then: (s) =>
          s.concat(
            yup.object().shape({
              name: stringSchema.required(() => ''),
              phone: phones.getSchema(t),
              email: getEmailSchema(t).required(() => '')
            })
          )
      })
    });
  }, [crNumberScema, t]);

  return schema;
};
