import { Text } from '@/ui-kit/Text';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  error?: boolean;
};

export const FormHelperText = ({ children, error }: Props) => {
  return (
    <Text
      sx={{
        marginTop: '0px',
        color: error ? 'B2B2023.accent.negativeA' : 'B2B2023.front.minor'
      }}
      variant="caption1Tight500"
    >
      {children}
    </Text>
  );
};
