import { CapitalLoanWithLegalInfo } from '@/store/loan';
import { ResetLoanFormContent } from './ResetLoanFormContent';
import { ResetLoanFormProvider } from './ResetLoanFormProvider';
import { useResetLoanDefaultFormValues } from '../hooks/useResetLoanDefaultFormValues';

export const ResetLoanForm = ({ loan }: { loan: CapitalLoanWithLegalInfo }) => {
  const initialData = useResetLoanDefaultFormValues(loan);

  return (
    <ResetLoanFormProvider initialData={initialData} loan={loan}>
      <ResetLoanFormContent />
    </ResetLoanFormProvider>
  );
};
