import { Country, Currency, ValueOf } from '@/types';
import {
  APPLICATION_ANNUAL_VOLUME,
  BUSINESS_LICENSE,
  ECOM_PLATFORM,
  ECOM_TYPE,
  BUSINESS_CATEGORY,
  BUSINESS_SECTION,
  LOCATIONS_NUMBER
} from './constants';

export type Application = {
  id: string;
  group_id: string;
  status: 'not_submitted' | 'other' | '';
};

export type EcomType = ValueOf<typeof ECOM_TYPE>;

export type EcomPlatform = ValueOf<typeof ECOM_PLATFORM>;

export type ApplicationAnnualVolume = ValueOf<typeof APPLICATION_ANNUAL_VOLUME>;

export type BusinessLicense = ValueOf<typeof BUSINESS_LICENSE>;

export type LocationsNumber = ValueOf<typeof LOCATIONS_NUMBER>;

export type BusinessCategory = ValueOf<typeof BUSINESS_CATEGORY>;

export type BusinessCategoryItem = {
  id: string;
  name: BusinessCategory;
};

export type BusinessSection = ValueOf<typeof BUSINESS_SECTION>;

export type BusinessSectionItem = {
  id: string;
  name: BusinessSection;
};

export type LegalEntityCountry = Country | 'other';

export type LegalInfo = {
  legal_entity_country: LegalEntityCountry;
  business_license_type: BusinessLicense;
  commercial_registration_number: string;
  license_url: string;
  national_id_url: string;
  legal_address: string;
  legal_company_name: string;
  unified_number: string;
  vat_registration_number: string;
  address_in_tax_certificate: string;
  vat_certificate_url: string;
  license_expiry_date: string;
  business_type: { id: string };
  company_owner: {
    id: string;
    full_name: string;
    date_of_birth: string;
  };
};

export type BankDetails = {
  iban: string;
  bank_name: string;
  swift_code: string;
  beneficiary_name_ar: string;
  beneficiary_name_en: string;
  currency: Currency;
  branch_name: string;
  report_recipient: string;
};

export const APPLICATION_SOURCE = {
  MerchantDashboard: 'merchant_dashboard',
  Backoffice: 'backoffice'
} as const;

export type ApplicationSource = ValueOf<typeof APPLICATION_SOURCE>;

export type Location = {
  lat: string;
  lng: string;
  address_en: string;
};

export type ApplicationV3 = {
  id: string;
  account_id: string;
  status: 'draft' | 'submitted';
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  country_of_main_operations: Country;
  other_countries: Country[] | null;
  business_name: string;
  annual_volume: ApplicationAnnualVolume;
  section_id: string;
  category_id: string;
  ecommerce_types: EcomType[];
  web_url: string;
  ecommerce_platform: EcomPlatform | '';
  locations_number: LocationsNumber | null | '';
  locations: Location[] | null;
  legal_info: LegalInfo;
  bank_details: BankDetails;
  created_by_account_id: string | null;
  source: ApplicationSource;
};

export const APPLICATION_FORM_STEP_NAME = {
  BASIC_INFO: 'basic-info',
  PRODUCT_CATEGORY: 'product-category',
  STORE_TYPE: 'store-type',
  LEGAL_DETAILS: 'legal-details',
  BANK_DETAILS: 'bank-details'
} as const;

export type ApplicationFormStepName = ValueOf<typeof APPLICATION_FORM_STEP_NAME>;

export type Tier = 1 | 2 | 3 | 4;
