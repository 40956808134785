import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifyChallengeEmailForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeSetupVerifyEmail = () => {
  const { state, setStep } = useAuthPageContext();
  const { step, email } = state;

  const goBack = () => {
    setStep('challenge_setup_email');
  };

  if (step === 'challenge_setup_verify_email' && email) {
    return (
      <AuthStepWidget onGoBack={goBack}>
        <VerifyChallengeEmailForm email={email} onEmailChange={goBack} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
