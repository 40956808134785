import { LegalEntity } from '@/store/merchants';
import { LoanOffer } from '@/store/offers';
import { FormContent } from './components/FormContent';
import { FormProvider } from './components/FormProvider';
import { useDefaultFormValues } from './hooks/useDefaultFormValues';

export const ApplicationCapitalForm = ({
  legalEntity,
  offer
}: {
  legalEntity: LegalEntity;
  offer: LoanOffer;
}) => {
  const initialData = useDefaultFormValues(legalEntity);

  return (
    <FormProvider initialData={initialData} offer={offer} legalEntity={legalEntity}>
      <FormContent />
    </FormProvider>
  );
};
