import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { InputPhoneCountry, Props } from './InputPhoneCountry';

type ControlledProps<T extends FieldValues> = Omit<Props, 'value'> & {
  name: Path<T>;
  control: Control<T>;
  analytics?: { event: EventName } & Record<string, string>;
};

export const InputPhoneCountryControlled = <T extends FieldValues>({
  name,
  control,
  onChange,
  onBlur,
  analytics,
  helperText,
  ...rest
}: ControlledProps<T>) => {
  const track = useAnalyticsTrack();
  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control
  });

  const handleChange: Props['onChange'] = (value) => {
    field.onChange(value);
    onChange?.(value);
  };

  const handleBlur: Props['onBlur'] = () => {
    field.onBlur();
    onBlur?.();

    if (analytics) {
      const { event: analyticsEvent, ...analyticsParams } = analytics;

      track({
        event: analytics.event,
        params: {
          value: field.value,
          ...analyticsParams
        }
      });
    }
  };

  return (
    <InputPhoneCountry
      inputRef={field.ref}
      value={field.value as string}
      onChange={handleChange}
      onBlur={handleBlur}
      error={invalid}
      helperText={error?.message ? error.message || error.message : helperText}
      {...rest}
    />
  );
};
