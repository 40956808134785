import { rootApi } from '../rootApi/api';
import {
  Merchant,
  MerchantProduct,
  MerchantApiKeys,
  LegalEntity,
  GetMerchantsV2Response,
  GetMerchantsParams
} from './types';
import { mapApiKeys } from './utils';

const merchantsApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getMerchants: build.query<Merchant[], GetMerchantsParams | void>({
      queryFn: async (params, api, extraOptions, fetchWithBQ) => {
        const {
          scope = 'all',
          country,
          shouldGetAllMerchantsForTabbyManager = false
        } = params || {};

        if (shouldGetAllMerchantsForTabbyManager) {
          const v1 = await fetchWithBQ({
            url: '/merchant/api/v1/merchants',
            params: scope === 'payment_links' ? { payment_links: true } : undefined
          });

          if (v1.error) {
            return { error: v1.error };
          }

          const responseV1 = (v1.data as Merchant[] | null) || [];

          return { data: responseV1 };
        }

        const v2 = await fetchWithBQ('/merchant/api/v2/merchants');

        if (v2.error) {
          return { error: v2.error };
        }

        const responseV2 = v2.data as GetMerchantsV2Response;

        const resultV2 = responseV2[scope].reduce((acc, id) => {
          const m = responseV2.merchants[id];
          if (m) acc.push(m);
          return acc;
        }, [] as Merchant[]);

        if (country) {
          return { data: resultV2.filter((m) => m.country === country) };
        }

        return { data: resultV2 };
      }
    }),
    getMerchant: build.query<Merchant, string>({
      query: (merchantId) => `/merchant/api/v1/application/merchant/${merchantId}`,
      providesTags: (result, error, id) => [{ type: 'Merchant', id }]
    }),
    getMerchantProducts: build.query<MerchantProduct[], string>({
      query: (id) => `/merchant/api/v1/merchants/${id}/products`,
      transformResponse: (response: MerchantProduct[] | null) => response || [],
      providesTags: (result, error, id) => [{ type: 'MerchantProducts', id }]
    }),
    getMerchantApiKeys: build.query<ReturnType<typeof mapApiKeys>, string>({
      query: (id) => `/merchant/api/v1/application/merchant/${id}/api_keys`,
      providesTags: (result, error, id) => [{ type: 'MerchantApiKeys', id }],
      transformResponse: (result: MerchantApiKeys) => mapApiKeys(result)
    }),
    getMerchantLegalEntity: build.query<LegalEntity, string>({
      query: (merchantId) => `/merchant/api/v1/merchant/${merchantId}/legal_entity`,
      transformResponse: (response: { legal_entity: LegalEntity }) => response?.legal_entity,
      providesTags: (result, error, merchantId) => [{ type: 'LegalEntity', id: merchantId }]
    }),
    createMerchantLegalEntity: build.mutation<void, { merchantId: string } & LegalEntity>({
      query: ({ merchantId, ...body }) => ({
        url: `/merchant/api/v1/merchant/${merchantId}/legal_entity`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, args) => [
        { type: 'LegalEntity', id: args.merchantId },
        { type: 'ApplicationMerchant', id: args.merchantId },
        'Candidate'
      ]
    })
  })
});

export const {
  useCreateMerchantLegalEntityMutation,
  useGetMerchantApiKeysQuery,
  useGetMerchantLegalEntityQuery,
  useGetMerchantProductsQuery,
  useGetMerchantQuery,
  useGetMerchantsQuery,
  useLazyGetMerchantLegalEntityQuery,
  useLazyGetMerchantProductsQuery
} = merchantsApi;
