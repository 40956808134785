import { LANGUAGE, Language } from '@/types';

export const getLocale = (lang: string) => {
  return (
    {
      ar: 'ar-EG',
      en: 'en-US'
    }[lang] || 'en-US'
  );
};

export const normalizeLanguage = (value: unknown): Language => {
  return Object.values<string>(LANGUAGE).includes(value as string) ? (value as Language) : 'en';
};
