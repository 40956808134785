import { styled, useTheme } from '@mui/material/styles';
import { LOAN_STATUSES, LoanStatus } from '@/store/loan';
import { useMediaQuery } from '@mui/material';
import { MobileStepper } from './components/MobileStepper';
import { ApplicationStep } from './components/ApplicationStep';
import { ChangesRequiredStep } from './components/ChangesRequiredStep';
import { LoanSteps } from './types';
import { ReviewStep } from './components/ReviewStep';
import { SignContractStep } from './components/SignContractStep';

const getStepByStatus = (status: LoanStatus | 'new'): LoanSteps => {
  switch (true) {
    case status === LOAN_STATUSES.submitted:
      return 'in-review';
    case status === LOAN_STATUSES.approved:
      return 'sign-contract';
    case status === LOAN_STATUSES.reset:
      return 'changes-required';
    default:
      return 'application';
  }
};

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  padding: '0 12px 24px 12px',

  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column',
    padding: '12px 24px'
  }
}));

type Props = {
  status: LoanStatus | 'new';
};

export const LoanStepper = ({ status }: Props) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  const activeStep = getStepByStatus(status);

  const steps = (
    <>
      <ApplicationStep activeStep={activeStep} />
      {activeStep === 'changes-required' && <ChangesRequiredStep />}
      <ReviewStep activeStep={activeStep} />
      <SignContractStep activeStep={activeStep} />
    </>
  );

  return (
    <Root>{isMobile ? <MobileStepper activeStep={activeStep}>{steps}</MobileStepper> : steps}</Root>
  );
};
