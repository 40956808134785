import { CapitalLoanWithLegalInfo } from '@/store/loan';
import { useState } from 'react';
import countries from '@/lib/countries';
import { useResetLoanFormSubmit } from './useResetLoanFormSubmit';
import { ResetLoanFormValues } from '../types';

export const useResetLoanFormState = ({
  initialData,
  loan
}: {
  initialData: ResetLoanFormValues;
  loan: CapitalLoanWithLegalInfo;
}) => {
  const [isCRNumberSAUValid, setIsCRNumberSAUValid] = useState(false);
  const { submit, isLoading: isLoadingSubmit } = useResetLoanFormSubmit(loan);

  const legalEntityCountry = countries.getCountryByCurrency(loan.currency);
  const isLegalEntityCountrySAU = legalEntityCountry === 'SAU';

  return {
    initialData,
    loan,
    submit,
    isLoadingSubmit,
    setIsCRNumberSAUValid,
    isCRNumberSAUValid,
    isLegalEntityCountrySAU
  };
};
