import { styled } from '@mui/material/styles';
import { useState } from 'react';

import { useIsExperimentOn } from '@/store/experiments';
import { Benefits } from './Benefits';
import { FormProvider } from './FormProvider';
import { Header } from './Header';
import { Offer } from './Offer';
import { WaitingListWidget } from './WaitingListWidget';

const Root = styled('div')(({ theme }) => ({
  padding: '12px 0',
  display: 'flex',
  justifyContent: 'center',

  [theme.breakpoints.down('p768')]: {
    padding: '0'
  }
}));

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: '520px',
  borderRadius: '24px',
  overflow: 'hidden',

  [theme.breakpoints.down('p768')]: {
    borderRadius: '0'
  }
}));

const Content = styled('div')(({ theme }) => ({
  background: theme.palette.B2B2023.back.major
}));

export const FakeDoor = () => {
  const isTabbyCapitalWaitlistOn = useIsExperimentOn('md-tabby-capital-waitlist');
  const [isFormSubmitted, setIsFormSubmitted] = useState(isTabbyCapitalWaitlistOn);

  const handleSubmitForm = () => setIsFormSubmitted(true);

  if (isFormSubmitted) {
    return (
      <Root>
        <Wrapper>
          <WaitingListWidget />
        </Wrapper>
      </Root>
    );
  }

  return (
    <FormProvider onSubmit={handleSubmitForm}>
      <Root>
        <Wrapper>
          <Content>
            <Header />
            <Benefits />
            <Offer />
          </Content>
        </Wrapper>
      </Root>
    </FormProvider>
  );
};
