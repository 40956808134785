import { useAnalyticsTrack } from '@/store/analytics';

import { useShowNotification } from '@/components/Notifications';
import { useAddToWaitlistMutation } from '@/store/loan';
import { useTranslation } from 'react-i18next';
import { useFormPropsContext } from './useFormPropsContext';
import { useFormStateContext } from './useFormStateContext';

export const useFormSubmit = () => {
  const { t } = useTranslation();
  const track = useAnalyticsTrack();
  const showNotification = useShowNotification();

  const { handleSubmit } = useFormPropsContext();
  const { onSubmit } = useFormStateContext();

  const [addToWishList, { isLoading }] = useAddToWaitlistMutation();

  const submit = handleSubmit((values) => {
    addToWishList()
      .unwrap()
      .then(() => {
        track({
          event: 'Capital Fake Door Submitted',
          params: {
            ...values
          }
        });

        onSubmit?.();
      })
      .catch(() => {
        showNotification({ variant: 'error', message: t('error_while_adding_to_waitlist') });
      });
  });

  return { submit, isLoading };
};
