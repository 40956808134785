import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getEmailSchema, stringSchema } from '@/lib/yup';
import phones from '@/lib/phones';
import { useMemo } from 'react';

export const useBasicInfoSchema = () => {
  const { t } = useTranslation();
  return useMemo(
    () =>
      yup.object({
        first_name: stringSchema.required(() => ''),
        last_name: stringSchema.required(() => ''),
        email: getEmailSchema(t).required(() => ''),
        phone: phones.getSchema(t),
        country_of_main_operations: stringSchema.required(() => ''),
        is_operating_in_other_countries: yup.boolean(),
        other_countries: yup
          .array()
          .of(stringSchema.required(() => ''))
          .when('is_operating_in_other_countries', {
            is: (is_operating_in_other_countries: boolean) => is_operating_in_other_countries,
            then: (schema) => schema.min(1, ' '),
            otherwise: (schema) => schema
          }),
        business_name: stringSchema.required(() => ''),
        annual_volume: stringSchema.required(() => '')
      }),
    [t]
  );
};
