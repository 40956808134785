import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import TabbyLogoCapitalSvg from '@/assets/icons/tabby-logo-capital.svg';
import TabbyLogoCapitalSvgv2 from '@/assets/icons/tabby-logo-green.svg?inline';
import { Button as BaseButton } from '@/ui-kit/Button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addMonths } from 'date-fns';
import { InputPhoneCountryControlled } from '@/ui-kit/InputPhoneCountry';
import { Text } from '@/ui-kit/Text';
import { DatePickerControlled } from './DatePickerControlled';
import { useSchema } from '../hooks/useSchema';
import { FORMAT_TEMPLATE } from '../utils';

const Form = styled('form')`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled('div')`
  /* @noflip */
  direction: ltr;
  width: 150px;
  height: 28px;
  display: flex;
  align-items: end;
  justify-content: center;
  gap: 8px;
`;

const Indent = styled('div')`
  margin: 8px 0;
`;

const Button = styled(BaseButton)`
  width: 100%;
`;

const ControlsSection = styled('div')<{ isMobile: boolean }>`
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? 'auto' : '8px')};
`;

const Gap = styled('div')`
  visibility: hidden;
  margin-bottom: auto;
`;

type Props = {
  onSuccess: ({ phone, date }: { phone: string; date: string }) => void;
  onClose: () => void;
  isMobile: boolean;
};

export const ModalContentForm: FC<Props> = (props) => {
  const { t } = useTranslation();
  const { onSuccess, onClose, isMobile } = props;
  const minDate = new Date();
  const maxDate = addMonths(minDate, 1);
  const schema = useSchema(minDate, maxDate);

  const form = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues: {
      phone: '',
      date: ''
    }
  });

  const submit = form.handleSubmit(async ({ phone, date }) => {
    onSuccess({ phone, date });
  });
  return (
    <FormProvider {...form}>
      <Form onSubmit={submit}>
        {!isMobile ? (
          <Logo>
            <img src={TabbyLogoCapitalSvgv2} />
            <TabbyLogoCapitalSvg />
          </Logo>
        ) : (
          <Gap />
        )}
        <Text variant="h3" sx={{ marginTop: '32px' }}>
          {t('schedule_a_call')}
        </Text>
        <Indent>
          <Text
            variant="body1Tight500"
            sx={{ color: (theme) => theme.palette.B2B2023.front.minor }}
          >
            {t('a_capital_specialist_will_call_you_on_the_day_you_select')}
          </Text>
        </Indent>

        <Indent>
          <Indent>
            <InputPhoneCountryControlled name="phone" control={form.control} />
          </Indent>
          <Indent>
            <DatePickerControlled
              format={FORMAT_TEMPLATE}
              name="date"
              control={form.control}
              minDate={minDate}
              maxDate={maxDate}
            />
          </Indent>
        </Indent>
        <ControlsSection isMobile={isMobile}>
          <Button size="large" type="submit">
            {t('schedule')}
          </Button>
          <Button onClick={onClose} size="large" variant="containedLight" sx={{ marginTop: '8px' }}>
            <Text variant="body1Tight500">{t('cancel')}</Text>
          </Button>
        </ControlsSection>
      </Form>
    </FormProvider>
  );
};
