import { useCallback } from 'react';
import { useHandleCRNumberSAU } from './useHandleCRNumberSAU';
import { useFormStateContext } from './useFormStateContext';
import { useFormPropsContext } from './useFormPropsContext';

export const useHandleCRNumberInput = () => {
  const { getValues } = useFormPropsContext();
  const { isLegalEntityCountrySAU } = useFormStateContext();
  const { handleCRNumberSAU, isFetchingCRSAUValidation } = useHandleCRNumberSAU();

  const isFetchingCRValidation = isFetchingCRSAUValidation;

  const handleCRNumberInput = useCallback(async () => {
    const crNumber = getValues('cr_number');

    if (isLegalEntityCountrySAU) {
      handleCRNumberSAU(crNumber);
    }
  }, [getValues, handleCRNumberSAU, isLegalEntityCountrySAU]);

  return { handleCRNumberInput, isFetchingCRValidation };
};
