import { generatePath, useNavigate } from 'react-router-dom';
import { useAnalyticsTrack } from '@/store/analytics';
import { ROUTES } from '@/routes';

import { useFormPropsContext } from './useFormPropsContext';
import { useFormStateContext } from './useFormStateContext';

export const useFormSubmit = () => {
  const track = useAnalyticsTrack();
  const navigate = useNavigate();

  const { handleSubmit } = useFormPropsContext();
  const { legalEntities, offer, selectedOffer } = useFormStateContext();

  const submit = handleSubmit((values) => {
    const { legalEntityId } = values;
    const legalEntity = legalEntities?.find(({ id }) => id === legalEntityId);

    if (legalEntity) {
      track({
        event: 'Capital Apply Clicked',
        params: {
          merchantId: offer.merchant_id,
          capitalAmount: offer.amount,
          merchantName: legalEntity.legal_company_name,
          currency: legalEntity.currency
        }
      });

      navigate(
        generatePath(ROUTES.financeTabbyCapitalApplication, {
          id: selectedOffer ? selectedOffer.id : offer.id,
          merchantId: offer.merchant_id
        })
      );
    }
  });

  return { submit };
};
