import { Language } from '@/types/general';
// eslint-disable-next-line import/no-duplicates
import { parseISO, format as formatDateFns } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enUS, arSA } from 'date-fns/locale';
import { NotReachable } from '@/utils/notReachable';

export const FORMAT_TEMPLATE = 'dd/MM/yy';

const getLocaleFromLanguage = (language: Language) => {
  switch (language) {
    case 'en':
      return enUS;
    case 'ar':
      return arSA;
    default:
      // eslint-disable-next-line no-new
      new NotReachable(language, `Unknown language : ${language}`);
      return enUS;
  }
};

export const format = (date: string | Date, language: Language) => {
  const d = typeof date === 'string' ? parseISO(date) : date;
  return formatDateFns(new Date(d), FORMAT_TEMPLATE, { locale: getLocaleFromLanguage(language) });
};
