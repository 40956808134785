import { ExperimentName } from '../experiments/types';
import { Application } from '../application/types';
import { ComputedPermissionsApi, ComputedPermissions } from './types';

type Params = {
  applicationV2: Application | null;
  experiments: ExperimentName[];
  permissionsApi: ComputedPermissionsApi | null;
};

export const createComputedPermissions = (params: Params): ComputedPermissions => {
  const { applicationV2, permissionsApi, experiments } = params;
  const isApplicationSubmitted = !!applicationV2 && applicationV2.status !== 'not_submitted';

  const {
    is_account_edit_allowed = false,
    is_ads_allowed = false,
    is_ads_ops = false,
    is_ads_user_invite_allowed = false,
    is_analytics_allowed = false,
    is_dispute_refund_allowed = false,
    is_disputes_allowed = false,
    is_order_creation_allowed = false,
    is_order_view_allowed = false,
    is_payouts_allowed = false,
    is_tabby_manager = false,
    is_virtual_cards_enabled = false,
    is_user: has_merchants = false
  } = permissionsApi || {};

  const canViewTabbyAds =
    (is_ads_allowed && experiments.includes('md-tabby-ads')) ||
    is_ads_ops ||
    is_tabby_manager ||
    is_ads_user_invite_allowed;

  const canViewProductCatalog = experiments.includes('md-product-catalog');

  const canViewCapital =
    is_payouts_allowed &&
    (experiments.includes('md-tabby-capital') ||
      experiments.includes('md-tabby-capital-fake-door'));

  const canViewDisputes =
    (experiments.includes('tm-4654-disputes-faq') && has_merchants) || is_disputes_allowed;

  return {
    canCreatePaymentLinks: is_order_creation_allowed,
    canRefundDisputes: is_dispute_refund_allowed,
    canViewAnalytics: is_analytics_allowed,
    canViewApplication:
      !(isApplicationSubmitted || has_merchants || is_tabby_manager) && !!permissionsApi,
    canViewCapital,
    canViewDisputes,
    canFetchDisputes: is_disputes_allowed,
    canViewOnboarding: Boolean(applicationV2?.group_id),
    canViewOrders: is_order_view_allowed,
    canViewSupport: has_merchants && !is_tabby_manager,
    canViewTabbyAds,
    canViewTabbyCard: is_payouts_allowed && is_virtual_cards_enabled,
    hasApplicationV2: Boolean(applicationV2),
    isPayoutsAllowed: is_payouts_allowed,
    isTabbyManager: is_tabby_manager,
    isWorkspaceAdmin: is_account_edit_allowed,
    isApplicationSubmitted,
    canViewProductCatalog
  };
};
