import { rootApi } from '../../rootApi/api';
import {
  GetDisputesResponse,
  GetDisputesParams,
  Dispute,
  ExportDisputesCsvParams,
  ChallengeDisputeParams,
  GetDisputesResult
} from '../types';
import { prepareExportCsvDisputesParams, prepareGetDisputesParams } from '../utils';

export const disputesApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getDisputes: build.query<GetDisputesResult, GetDisputesParams>({
      query: (params) => ({
        url: '/merchant/api/v1/disputes',
        params: prepareGetDisputesParams(params)
      }),
      transformResponse: (response: GetDisputesResponse) => {
        return {
          next_page_token: response.next_page_token || '',
          items: response.disputes || []
        };
      }
    }),
    getQuantityOfActiveDisputes: build.query<{ total: number }, void>({
      keepUnusedDataFor: 60 * 5, // 5 min,
      query: () => '/merchant/api/v1/disputes/active',
      providesTags: ['DisputesActive']
    }),
    getDispute: build.query<Dispute, string>({
      query: (id) => `/merchant/api/v1/disputes/${id}`,
      providesTags: (result, error, id) => [{ type: 'Dispute', id }],
      transformResponse: (result: { dispute: Dispute }) => result.dispute
    }),
    refundDispute: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `/merchant/api/v1/disputes/${id}/approve`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Dispute', id: arg.id }, 'DisputesActive']
    }),
    challengeDispute: build.mutation<void, ChallengeDisputeParams>({
      query: ({ id, ...body }) => ({
        url: `/merchant/api/v1/disputes/${id}/challenge`,
        method: 'POST',
        body
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Dispute', id: arg.id }, 'DisputesActive']
    }),
    uploadDisputeChallengeAsset: build.mutation<{ id: string }, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('asset', file);

        return {
          url: '/merchant/api/v1/disputes/attachments/upload',
          method: 'POST',
          body: formData
        };
      }
    }),
    exportDisputesCsv: build.mutation<Blob, ExportDisputesCsvParams>({
      query: (params) => ({
        url: `/merchant/api/v1/disputes/export`,
        method: 'POST',
        params: prepareExportCsvDisputesParams(params),
        responseHandler: (response) => response.blob()
      })
    })
  })
});

export const {
  useLazyGetDisputesQuery,
  useGetQuantityOfActiveDisputesQuery,
  useGetDisputeQuery,
  useLazyGetDisputeQuery,
  useRefundDisputeMutation,
  useChallengeDisputeMutation,
  useExportDisputesCsvMutation,
  useUploadDisputeChallengeAssetMutation
} = disputesApi;
