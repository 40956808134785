import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

const Root = styled('div')({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const Title = styled('p')(({ theme }) => ({
  ...theme.typography.h4,
  [theme.breakpoints.down('p768')]: {
    ...theme.typography.body2
  }
}));

export const ErrorPage = memo(({ status }: { status: number | string }) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Title>
        {{
          403: t('403_forbidden_error'),
          404: t('404_page_not_found'),
          500: t('500_server_error')
        }[status] || t('500_server_error')}
      </Title>
    </Root>
  );
});

ErrorPage.displayName = 'ErrorPage';
