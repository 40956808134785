import {
  FormControlLabel as TuiFormControlLabel,
  type FormControlLabelProps as TuiFormControlLabelProps
} from '@tabby.ai/tabby-ui/dist/core';

export type FormControlLabelProps = Omit<TuiFormControlLabelProps, 'ds'>;

export const FormControlLabel = (props: FormControlLabelProps) => {
  return <TuiFormControlLabel ds="B2B2023" {...props} />;
};
