import { getEndDayISOString, getStartDayISOString, isValidDateString } from '@/utils/date';
import {
  GetSupportTicketsFilters,
  SupportTicketsSearchParams,
  SUPPORT_TICKET_STATUSES,
  SUPPORT_TOPICS,
  SupportTicketStatus,
  SupportTopic,
  GetSupportTicketsParams
} from '@/store/support';
import { isEnumValue } from '@/types';
import uniq from 'lodash/uniq';
import pickBy from 'lodash/pickBy';

export const supportSearchParamsToFilters = (data: SupportTicketsSearchParams) => {
  const result: GetSupportTicketsFilters = {};

  if (isValidDateString(data.startDate)) {
    result.created_at_gte = data.startDate;
  }

  if (isValidDateString(data.endDate)) {
    result.created_at_lte = data.endDate;
  }

  if (data.statuses) {
    const statuses = data.statuses
      .split(',')
      .reduce<SupportTicketStatus[]>(
        (acc, status) => (isEnumValue(SUPPORT_TICKET_STATUSES, status) ? [...acc, status] : acc),
        []
      );

    result.statuses = uniq(statuses);
  }

  if (data.topics) {
    const topics = data.topics
      .split(',')
      .reduce<SupportTopic[]>(
        (acc, status) => (isEnumValue(SUPPORT_TOPICS, status) ? [...acc, status] : acc),
        []
      );

    result.topics = topics;
  }

  return result;
};

export const prepareGetSupportTicketsParams = (
  params: GetSupportTicketsParams
): GetSupportTicketsParams => {
  const statuses = params.statuses ? [...params.statuses] : undefined;

  if (statuses && statuses.includes('opened')) {
    statuses.push('hold');
    statuses.push('escalated');
  }

  return pickBy(
    {
      ...params,
      created_at_gte: getStartDayISOString(params.created_at_gte),
      created_at_lte: getEndDayISOString(params.created_at_lte),
      statuses
    },
    Boolean
  );
};
