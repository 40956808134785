import { Text } from '@/ui-kit/Text';
import { Button } from '@/ui-kit/Button';
import { Trans, useTranslation } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import WarningImage from '@/assets/images/warning.png';
import { MailToSupport } from '@/components/MailToLink';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  height: '100vh',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const Inner = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '900px',
  gap: '50px',
  padding: '0px 50px',

  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column-reverse',
    textAlign: 'center',
    padding: '0px 24px'
  }
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const Image = styled('img')(() => ({
  width: '220px',
  height: '220px'
}));

type Props = {
  onRefresh: VoidFunction;
};

const ErrorFallback = (props: Props) => {
  const { onRefresh } = props;
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();

  return (
    <Root>
      <Inner>
        <Column sx={{ gap: '24px' }}>
          <Column sx={{ gap: '8px' }}>
            <Text variant="h2" color="B2B2023.front.major">
              {t('oops_something_went_wrong')}
            </Text>
            <Text variant="body1Loose500" color="B2B2023.front.major">
              <Trans
                i18nKey="if_the_problem_persists_please_contact_us_at_email"
                components={{
                  mailToLink: <MailToSupport />
                }}
              />
            </Text>
          </Column>
          <Button
            sx={{ marginRight: 'auto' }}
            variant="contained"
            onClick={onRefresh}
            fullWidth={isMobile}
            size={isMobile ? 'large' : 'medium'}
          >
            {t('refresh')}
          </Button>
        </Column>
        <Image src={WarningImage} alt="warning" />
      </Inner>
    </Root>
  );
};

export default ErrorFallback;
