import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import { LoanSteps } from '../types';
import { Step } from './Step';
import { StepLine } from './StepLine';
import { StepLabel } from './StepLabel';
import { PassedStepIcon } from './PassedStepIcon';

type Props = {
  activeStep: LoanSteps;
};

export const ReviewStep = ({ activeStep }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  const isSignContract = activeStep === 'sign-contract';
  const isInReview = activeStep === 'in-review';
  const inactiveInReviewStepLineColor = isSignContract
    ? theme.palette.B2B2023.accent.positiveA
    : theme.palette.B2B2023.front.line;
  const inactiveInReviewStepLabelColor = isSignContract
    ? theme.palette.B2B2023.accent.positiveA
    : theme.palette.B2B2023.front.disabled;

  return (
    <Step>
      <StepLine
        lineColor={
          isInReview ? theme.palette.B2B2023.back.controlMajor : inactiveInReviewStepLineColor
        }
      />
      {!isMobile && (
        <StepLabel
          labelColor={
            isInReview ? theme.palette.B2B2023.front.major : inactiveInReviewStepLabelColor
          }
        >
          {isSignContract && <PassedStepIcon />}
          {t('await_review')}
        </StepLabel>
      )}
    </Step>
  );
};
