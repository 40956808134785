import { generatePath, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { ROUTES } from '@/routes';
import Chart24 from '@tabby.ai/tabby-ui/dist/icons/core/Chart24';
import Bag24 from '@tabby.ai/tabby-ui/dist/icons/core/Bag24';
import Coin24 from '@tabby.ai/tabby-ui/dist/icons/core/Coin24';
import Card24 from '@tabby.ai/tabby-ui/dist/icons/core/Card24';
import Store24 from '@tabby.ai/tabby-ui/dist/icons/core/Store24';
import { useMeQuery } from '@/store/account-me';
import { SideMenu, SideMenuBlock, SideMenuItem } from '@/ui-kit/SideMenu';
import { NavMenuItemDisputes } from './NavMenuItemDisputes';
import NavMenuItemAds from './NavMenuItemAds';
import NavMenuItemSupport from './NavMenuItemSupport';
import NavMenuItemCapital from './NavMenuItemCapital';
import { NavMenuItemApplicaitonV3 } from './NavMenuItemApplicaitonV3';
import { NavMenuItemProductCatalog } from './NavMenuItemProductCatalog';
import { NavMenuItemUsers } from './NavMenuItemUsers';

type Props = {
  isCollapsed: boolean;
  onClick?: VoidFunction;
};

export const NavMenu = ({ isCollapsed, onClick }: Props) => {
  const { t } = useTranslation();
  const { data: me } = useMeQuery();
  const {
    canViewAnalytics,
    canViewCapital,
    canViewDisputes,
    canFetchDisputes,
    canViewOnboarding,
    canViewOrders,
    canViewSupport,
    canViewTabbyAds,
    canViewTabbyCard,
    isPayoutsAllowed,
    isWorkspaceAdmin,
    canViewProductCatalog
  } = me?.computedPermissions || {};

  const handleRootClick = () => {
    onClick?.();
    window.scrollTo(0, 0);
  };

  return (
    <SideMenu onClick={handleRootClick}>
      <SideMenuBlock isCollapsed={isCollapsed} label={t('general')}>
        <NavMenuItemApplicaitonV3 />
        {canViewOnboarding && (
          <SideMenuItem
            icon={<Store24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('onboarding')}
            to={ROUTES.onboarding}
            component={NavLink}
          />
        )}
        {canViewAnalytics && (
          <SideMenuItem
            icon={<Chart24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('analytics')}
            to={ROUTES.analytics}
            component={NavLink}
          />
        )}
        {canViewTabbyAds && <NavMenuItemAds isCollapsed={isCollapsed} />}
        {canViewOrders && (
          <SideMenuItem
            icon={<Bag24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('orders')}
            to={ROUTES.payments}
            component={NavLink}
          />
        )}
        {isWorkspaceAdmin && <NavMenuItemUsers />}
        {canViewDisputes && (
          <NavMenuItemDisputes isCollapsed={isCollapsed} canFetchDisputes={canFetchDisputes} />
        )}
        {canViewSupport && <NavMenuItemSupport isCollapsed={isCollapsed} />}
      </SideMenuBlock>
      {canViewProductCatalog && (
        <SideMenuBlock isCollapsed={isCollapsed} label={t('marketplace')}>
          <NavMenuItemProductCatalog isCollapsed={isCollapsed} />
        </SideMenuBlock>
      )}
      <SideMenuBlock isCollapsed={isCollapsed} label={t('business_profile')}>
        {isWorkspaceAdmin && (
          <SideMenuItem
            icon={<Store24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('stores')}
            to={ROUTES.businessProfileStores}
            component={NavLink}
          />
        )}
      </SideMenuBlock>
      <SideMenuBlock isCollapsed={isCollapsed} label={t('finance')}>
        {isPayoutsAllowed && (
          <SideMenuItem
            icon={<Coin24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('payouts')}
            to={generatePath(ROUTES.financeCompletedPayouts, { payoutId: null })}
            component={NavLink}
          />
        )}
        {canViewTabbyCard && (
          <SideMenuItem
            icon={<Card24 ds={DS.B2B2023} />}
            isCollapsed={isCollapsed}
            label={t('card')}
            to={ROUTES.financeTabbyCard}
            component={NavLink}
          />
        )}
        {canViewCapital && <NavMenuItemCapital isCollapsed={isCollapsed} />}
      </SideMenuBlock>
    </SideMenu>
  );
};
