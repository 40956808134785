import { RootState, useSelector } from '@/store';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { safeParseJson } from '@/utils/json';
import { PaymentLink, PaymentLinksState } from './types';
import { cacheState } from './utils';
import { paymentLinksStateKey } from './constants';

export const paymentLinksSlice = createSlice({
  name: 'paymentLinks',
  initialState: () => {
    return safeParseJson<PaymentLinksState>(localStorage.getItem(paymentLinksStateKey), {});
  },
  reducers: {
    save: (state, action: PayloadAction<PaymentLink>) => {
      const { payment_id, url } = action.payload;
      state[payment_id] = url;
      cacheState(state);
    },
    remove: (state, action: PayloadAction<{ paymentId: string }>) => {
      const { paymentId } = action.payload;
      delete state[paymentId];
      cacheState(state);
    }
  }
});

export const ACTIONS = {
  ...paymentLinksSlice.actions
};

export const SELECTORS = {
  selectPaymentLink: (paymentId: string) => (state: RootState) => state.paymentLinks[paymentId]
};

export const HOOKS = {
  usePaymentLink: (paymentId: string) => useSelector(SELECTORS.selectPaymentLink(paymentId))
};

export default paymentLinksSlice.reducer;
