import { SelectControlled } from '@/ui-kit/Select';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import {
  useGetBusinessSectionsQuery,
  useLazyGetBusinessCategoriesQuery
} from '@/store/application';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { TEST_IDS } from '@/lib/autotests';
import useBusinessSectionLabels from '@/hooks/i18n/useBusinessSectionLabels';
import useBusinessCategoryLabels from '@/hooks/i18n/useBusinessCategoryLabels';

import { FormSection } from './FormSection';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';

const ProductCategory = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { data: sections = [], isLoading: isSectionsLoading } = useGetBusinessSectionsQuery();
  const [getCategoriesBySectionId, { data: categories = [], isLoading: isCategoriesLoading }] =
    useLazyGetBusinessCategoriesQuery();

  const { toOptions: toSectionOptions } = useBusinessSectionLabels();
  const sectionOptions = toSectionOptions(sections);
  const { toOptions: toCategoryOptions } = useBusinessCategoryLabels();
  const categoryOptions = toCategoryOptions(categories);

  const { control, watch, setValue, getValues } = useFormPropsContext();
  const { isFormDisabled } = useFormStateContext();
  const sectionId = watch('product_category.section_id');

  useEffect(() => {
    if (sectionId) {
      getCategoriesBySectionId(sectionId, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    track({ event: Events.ProductCategoryOpened });
  }, [track]);

  return (
    <FormSection
      title={t('what_do_you_sell')}
      subtitle={t('we_ll_provide_a_tailored_experience_based_on_what_you_sell')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <SelectControlled
          control={control}
          name="product_category.section_id"
          options={sectionOptions}
          placeholder={t('industry')}
          disabled={isFormDisabled}
          size="medium"
          analytics={{ event: Events.SectionFilled }}
          testId={TEST_IDS.ApplicationTabbySectionId}
          isLoading={isSectionsLoading}
          onChange={(event) => {
            const value = event.target.value as string;

            if (value) {
              getCategoriesBySectionId(value);
            }

            if (getValues('product_category.category_id')) {
              setValue('product_category.category_id', '');
            }
          }}
        />
        <SelectControlled
          control={control}
          name="product_category.category_id"
          placeholder={t('category')}
          options={categoryOptions}
          disabled={isFormDisabled || !sectionId}
          size="medium"
          analytics={{ event: Events.CategoryFilled }}
          testId={TEST_IDS.ApplicationTabbyCategoryId}
          isLoading={isCategoriesLoading}
        />
      </Box>
    </FormSection>
  );
};

export default ProductCategory;
