import { AuthPageProvider } from './AuthPageProvider';
import { AuthPageLayout } from './components/AuthPageLayout';
import { AuthPageContent } from './components/AuthPageContent';

export const AuthPage = () => {
  return (
    <AuthPageProvider>
      <AuthPageLayout>
        <AuthPageContent />
      </AuthPageLayout>
    </AuthPageProvider>
  );
};
