import { LegalEntityWithMerchantId } from '@/store/loan';
import { useGetOffersLegalEntityQuery } from '@/store/offers';
import { uniqBy } from 'lodash';

export const useFormLegalEntities = () => {
  const { data: offersLegalEntity = [], isLoading } = useGetOffersLegalEntityQuery();

  const legalEntities: LegalEntityWithMerchantId[] = uniqBy(
    offersLegalEntity.map((o) => o.legal_entity),
    'merchant_id'
  );

  return {
    legalEntities,
    isLoading
  };
};
