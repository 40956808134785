import { useEffect, useState } from 'react';
import {
  APPLICATION_FORM_STEP_NAME,
  ApplicationFormStepName,
  isFirstOrSecondTierByVolume
} from '@/store/application';
import { useIsMounted } from '@/hooks/useIsMounted';

import { useFormStateContext } from './useFormStateContext';
import { useBasicInfoSchema } from '../schemas/useBasicInfoSchema';
import { useProductCategorySchema } from '../schemas/useProductCategorySchema';
import { useStoreTypeSchema } from '../schemas/useStoreTypeSchema';
import { useLegalInfoSchema } from '../schemas/useLegalInfoSchema';
import { useBankDetailsSchema } from '../schemas/useBankDetailsSchema';
import { useRedirectToFirstInvalidStep } from './useRedirectToFirstInvalidStep';
import { useRedirectToLastValidStep } from './useRedirectToLastValidStep';
import { useFormPropsContext } from './useFormPropsContext';

export const useStepsValidation = () => {
  const isMounted = useIsMounted();
  const { getValues } = useFormPropsContext();

  const [isLoading, setIsLoading] = useState(false);
  const { setStepsValidity } = useFormStateContext();

  const mainInfoSchema = useBasicInfoSchema();
  const productCategorySchema = useProductCategorySchema();
  const storeTypeSchema = useStoreTypeSchema();
  const { getSchema: getLegalInfoSchema } = useLegalInfoSchema();
  const { getSchema: getBankDetailsSchema } = useBankDetailsSchema();

  const { redirectToFirstInvalidStep } = useRedirectToFirstInvalidStep();
  const { redirectToLastValidStep } = useRedirectToLastValidStep();

  useEffect(() => {
    const validate = async () => {
      if (isMounted()) {
        setIsLoading(true);
      }
      const formValues = getValues();

      const [
        isMainInfoValid,
        isProductCategoryValid,
        isStoreTypeValid,
        isLegalInfoValid,
        isBankDetailsValid
      ] = await Promise.all([
        mainInfoSchema.isValid(formValues.basic_info),
        productCategorySchema.isValid(formValues.product_category),
        storeTypeSchema.isValid(formValues.store_type),
        getLegalInfoSchema(formValues.basic_info.country_of_main_operations).isValid(
          formValues.legal_info
        ),
        getBankDetailsSchema(formValues.basic_info.country_of_main_operations).isValid(
          formValues.bank_details
        )
      ]);

      const stepValidityMap: Record<ApplicationFormStepName, boolean> = {
        [APPLICATION_FORM_STEP_NAME.BASIC_INFO]: isMainInfoValid,
        [APPLICATION_FORM_STEP_NAME.PRODUCT_CATEGORY]: isProductCategoryValid,
        [APPLICATION_FORM_STEP_NAME.STORE_TYPE]: isStoreTypeValid,
        [APPLICATION_FORM_STEP_NAME.LEGAL_DETAILS]: isLegalInfoValid,
        [APPLICATION_FORM_STEP_NAME.BANK_DETAILS]: isBankDetailsValid
      };

      if (isMounted()) {
        setStepsValidity(stepValidityMap);
        setIsLoading(false);
      }

      const isValidStoreTypeWithHighTier =
        stepValidityMap['store-type'] &&
        isFirstOrSecondTierByVolume(formValues.basic_info.annual_volume);

      if (isValidStoreTypeWithHighTier) {
        redirectToLastValidStep(stepValidityMap);
      } else {
        redirectToFirstInvalidStep(stepValidityMap);
      }
    };

    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isLoading };
};
