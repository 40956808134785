import { forwardRef, ForwardedRef, ReactNode } from 'react';
import { styled } from '@mui/material/styles';

export type Props = {
  children?: ReactNode;
  className?: string;
  forceDirection?: 'ltr' | 'rtl';
  truncate?: boolean;
  textTransform?: 'uppercase' | 'lowercase' | 'capitalize' | 'none' | 'inherit';
  inline?: boolean;
};

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'truncate' && prop !== 'inline' && prop !== 'forceDirection'
})<Pick<Props, 'truncate' | 'inline' | 'forceDirection'>>(
  ({ truncate, inline, forceDirection }) => ({
    ...(truncate && {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }),
    ...(forceDirection && {
      direction: `${forceDirection} /* @noflip */` as typeof forceDirection
    }),
    ...(inline && { display: 'inline-flex' })
  })
);

export const TextBox = forwardRef(
  (
    {
      children,
      className,
      forceDirection,
      truncate = true,
      textTransform = 'inherit',
      inline
    }: Props,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <Root
        ref={ref}
        className={className}
        truncate={truncate}
        forceDirection={forceDirection}
        inline={inline}
      >
        <span style={{ textTransform }}>{children}</span>
      </Root>
    );
  }
);

TextBox.displayName = 'TextBox';

export default TextBox;
