import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%'
}));

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

export type SideMenuProps = {
  children?: ReactNode;
  onClick: VoidFunction;
};

export const SideMenu = ({ children, onClick }: SideMenuProps) => {
  return (
    <Root onClick={onClick}>
      <Content>{children}</Content>
    </Root>
  );
};
