import { useShowNotification, NOTIFICATION_VARIANTS } from '@/components/Notifications';
import {
  useSubmitApplicationV3Mutation,
  useUpdateApplicationV3Mutation
} from '@/store/application';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { useTranslation } from 'react-i18next';
import { useFormPropsContext } from './useFormPropsContext';
import { toApplication } from '../utils';

export const useFormSubmit = () => {
  const { t } = useTranslation();
  const track = useAnalyticsTrack();
  const showNotification = useShowNotification();
  const { handleSubmit } = useFormPropsContext();

  const [update, { isLoading: isLoadingUpdate }] = useUpdateApplicationV3Mutation();
  const [submit, { isLoading: isLoadingSubmit }] = useSubmitApplicationV3Mutation();

  const submitApplication = handleSubmit(async (values) => {
    const application = toApplication(values);
    const trackParams = {
      firstName: application.first_name,
      lastName: application.last_name,
      brandName: application.business_name,
      annualVolume: application.annual_volume,
      email: application.email,
      phone: application.phone
    };

    update(application)
      .unwrap()
      .then(() => {
        track({
          event: Events.ApplicationUpdated,
          params: { result: 'success', ...trackParams }
        });
        submit(application)
          .unwrap()
          .then(() => {
            track({
              event: Events.ApplicationSubmitted,
              params: { result: 'success', ...trackParams }
            });
          })
          .catch(() => {
            track({
              event: Events.ApplicationSubmitted,
              params: { result: 'failure', ...trackParams }
            });
            showNotification({
              variant: NOTIFICATION_VARIANTS.Error,
              message: t('failed_to_submit_application_please_try_later')
            });
          });
      })
      .catch(() => {
        track({
          event: Events.ApplicationUpdated,
          params: { result: 'failure', ...trackParams }
        });
        showNotification({
          variant: NOTIFICATION_VARIANTS.Error,
          message: t('failed_to_submit_application_please_try_later')
        });
      });
  });

  return { submitApplication, isLoading: isLoadingUpdate || isLoadingSubmit };
};
