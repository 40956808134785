import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { Button } from '@/ui-kit/Button';
import { ROUTES } from '@/routes';
import { useNavigate } from 'react-router-dom';
import Close24 from '@tabby.ai/tabby-ui/dist/icons/core/Close24';
import { Text } from '@/ui-kit/Text';
import Box from '@mui/material/Box';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '4px'
}));

type Props = {
  onClose: VoidFunction;
  onBack: VoidFunction;
};

export const WhoCanManageDisputesContent = ({ onClose, onBack }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleAdd = () => {
    navigate(ROUTES.users);
    onClose();
  };

  return (
    <Root>
      <Box display="flex" gap="4px">
        <Box display="flex" flexDirection="column" gap="8px">
          <Text variant="h4">{t('how_do_disputes_work')}</Text>
          <Text variant="body2Loose500">
            {t(
              'add_more_users_to_help_you_manage_incoming_disputes_your_disputes_can_be_managed_by_admins_supervisors'
            )}
          </Text>
        </Box>
        <div>
          <IconButton ds={DS.B2B2023} size="small" onClick={onClose} variant="text">
            <Close24 color="B2B2023.front.invertMajor" />
          </IconButton>
        </div>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text variant="body2Loose500" color="front.invertMajor">
          {t('step_of_steps', { step: 2, steps: 2 })}
        </Text>
        <Box display="flex" gap="8px" justifyContent="flex-end">
          <Button variant="contained" size="small" color="primary" onClick={onBack}>
            {t('back')}
          </Button>
          <Button variant="containedLight" size="small" color="primary" onClick={handleAdd}>
            {t('add')}
          </Button>
        </Box>
      </Box>
    </Root>
  );
};
