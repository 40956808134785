import { Language } from '@/types';
import memoize from 'lodash/memoize';

export type FormatTimestampResult = {
  ddmm: string;
  ddmmyyyy: string;
  ddmmyyyytime: string;
  time: string;
};

type DateTimeFormatPartsDict = {
  [k in keyof Intl.DateTimeFormatPartTypesRegistry]: string;
};

const LOCALES_DICT = {
  ar: 'ar-EG-u-nu-latn',
  en: 'en-US'
} as const;

const options: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
  month: 'short',
  year: 'numeric'
};

export const formatTimestamp = memoize(
  (
    timestamp: string,
    lang: Language,
    customOptions?: Intl.DateTimeFormatOptions
  ): FormatTimestampResult | null => {
    const date = new Date(timestamp);

    if (date.toString() === 'Invalid Date') {
      return null;
    }

    const { day, month, year, hour, minute, dayPeriod } = new Intl.DateTimeFormat(
      LOCALES_DICT[lang],
      {
        ...options,
        ...customOptions
      }
    )
      .formatToParts(date)
      .reduce((acc, p) => {
        acc[p.type] = p.value;
        return acc;
      }, {} as DateTimeFormatPartsDict);

    return {
      ddmm: `${day} ${month}`,
      ddmmyyyy: `${day} ${month}, ${year}`,
      ddmmyyyytime: `${day} ${month}, ${year} ${hour}:${minute} ${dayPeriod.toLowerCase()}`,
      time: `${hour}:${minute} ${dayPeriod.toLowerCase()}`
    };
  },
  (timestamp, lang, customOptions) => `${timestamp}${lang}${customOptions}`
);
