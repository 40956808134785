import { Breadcrumbs, PageLayout } from '@/components/PageLayout';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { Loans } from './components/Loans';

const PageContent = styled(PageLayout.Content)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.down('p768')]: {
    padding: '0px'
  }
}));

export const LoansPage = () => {
  const { t } = useTranslation();

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'tabby_capital',
        href: '',
        label: t('tabby_capital')
      }
    ],
    [t]
  );

  return (
    <>
      <PageLayout.Header customBreadcrumbs={<Breadcrumbs crumbs={breadcrumbs} />} />
      <PageContent>
        <Loans />
      </PageContent>
    </>
  );
};
