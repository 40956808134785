import { useEffect } from 'react';
import { useMeQuery } from '../api';

const ACCOUNT_ID_TO_EXCLUDE = '1a067512-ea50-43ae-ac69-a619f96dc557';

// TODO: Remove on June 1, 2025
export const useExcludeAccount = () => {
  const { data: me } = useMeQuery();

  useEffect(() => {
    if (me?.account?.id === ACCOUNT_ID_TO_EXCLUDE) {
      throw new Error();
    }
  }, [me?.account?.id]);

  return null;
};
