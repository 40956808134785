import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifySignInPhoneForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepSignInVerifyPhone = () => {
  const { state, setStep, goToSignIn, setEmailPart } = useAuthPageContext();
  const { step, phone } = state;

  const handleChallenge = (challenge: 'setup_email' | 'verify_email', emailPart: string | null) => {
    setEmailPart(emailPart);
    setStep(`challenge_${challenge}`);
  };

  if (step === 'sign_in_verify_phone' && phone) {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <VerifySignInPhoneForm
          phone={phone}
          onPhoneChange={goToSignIn}
          onChallenge={handleChallenge}
        />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
