import { CapitalLoanWithLegalInfo } from '@/store/loan';
import { ReactNode, createContext } from 'react';
import { useResetLoanFormState } from '../hooks/useResetLoanFormState';
import { ResetLoanFormState, ResetLoanFormValues } from '../types';

export const FormStateContext = createContext({} as ResetLoanFormState);

type Props = {
  children: ReactNode;
  initialData: ResetLoanFormValues;
  loan: CapitalLoanWithLegalInfo;
};

export const ResetLoanFormStateProvider = ({ children, ...rest }: Props) => {
  const state = useResetLoanFormState(rest);
  return <FormStateContext.Provider value={state}>{children}</FormStateContext.Provider>;
};
