import cn from 'classnames';
import MuiChip, { ChipProps } from '@mui/material/Chip';
import { styled } from '@mui/material/styles';

const Root = styled(MuiChip, { shouldForwardProp: (prop) => prop !== 'chipColor' })<{
  chipColor: 'grey' | 'red';
}>(({ theme, chipColor }) => ({
  backgroundColor:
    chipColor === 'red' ? theme.palette.B2B2023.special.notification : theme.palette.grey[50],
  color: chipColor === 'red' ? theme.palette.B2B2023.front.invertMajor : theme.palette.grey[600]
}));

type Props = Omit<ChipProps, 'color'> & { color?: 'grey' | 'red' };

const Chip = ({ className, color = 'grey', ...props }: Props) => {
  return <Root className={cn(className)} chipColor={color} {...props} />;
};

export default Chip;
