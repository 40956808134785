import { Trans, useTranslation } from 'react-i18next';
import ReactQRCode from 'react-qr-code';
import { Text } from '@/ui-kit/Text';
import downloadOnAppStoreSvgUrl from '@/assets/images/download-app-store.svg?url';
import getItOnGooglePlayImageUrl from '@/assets/images/download-google-play.png';
import exploreItOnAppGalleryEnImageUrl from '@/assets/images/download-app-gallery-en.png';
import exploreItOnAppGalleryArImageUrl from '@/assets/images/download-app-gallery-ar.png';
import { styled } from '@mui/material/styles';
import { LANGUAGE } from '@/types';
import { MIN_WIDTH_PX, QR_CODE_SIZE_PX } from '../constants';
import { useDownloadAppLinks } from '../hooks/useDownloadAppLinks';

const Root = styled('div')(({ theme }) => ({
  padding: '64px 24px 40px 24px',
  backgroundColor: theme.palette.B2B2023.back.minor,
  borderRadius: '16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '24px',
  width: `${MIN_WIDTH_PX}px`,
  [theme.breakpoints.down('p768')]: {
    paddingTop: '40px'
  }
}));

const QRCodeWrap = styled('div')(({ theme }) => ({
  borderRadius: '16px',
  padding: '12px',
  backgroundColor: theme.palette.B2B2023.back.major,
  overflow: 'hidden',
  display: 'flex',
  [theme.breakpoints.down('p768')]: {
    display: 'none'
  }
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',
  textAlign: 'center'
}));

const DownloadImage = styled('div')(() => ({
  width: '134px',
  height: '40px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat'
}));

export const DownloadApp = () => {
  const { appStoreUrl, googlePlayUrl, appGalleryUrl, qrCodeUrl } = useDownloadAppLinks();
  const { i18n } = useTranslation();

  return (
    <Root>
      <QRCodeWrap>
        <ReactQRCode size={QR_CODE_SIZE_PX} value={qrCodeUrl} />
      </QRCodeWrap>
      <Column>
        <Text variant="h3">
          <Trans i18nKey="download_the_tabby_business_app" components={{ br: <br /> }} />
        </Text>
        <Text variant="body1Loose500" color="B2B2023.front.minor">
          <Trans
            i18nKey="manage_orders_track_sales_and_get_customer_support"
            components={{ br: <br /> }}
          />
        </Text>
      </Column>
      <Column>
        <a href={appStoreUrl} target="_blank" rel="noreferrer">
          <DownloadImage sx={{ backgroundImage: `url(${downloadOnAppStoreSvgUrl})` }} />
        </a>
        <a href={googlePlayUrl} target="_blank" rel="noreferrer">
          <DownloadImage sx={{ backgroundImage: `url(${getItOnGooglePlayImageUrl})` }} />
        </a>
        <a href={appGalleryUrl} target="_blank" rel="noreferrer">
          <DownloadImage
            sx={{
              backgroundImage: `url(${
                i18n.language === LANGUAGE.ar
                  ? exploreItOnAppGalleryArImageUrl
                  : exploreItOnAppGalleryEnImageUrl
              })`
            }}
          />
        </a>
      </Column>
    </Root>
  );
};
