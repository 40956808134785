import { useCallback, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { convertSearchQueryToObject, convertObjectToSearchQuery } from '@/utils/url';
import omit from 'lodash/omit';

export type SearchParams = {
  data: Record<string, string>;
  update: (obj: Partial<Record<string, string>>) => void;
  add: (obj: Partial<Record<string, string>>) => void;
  remove: (keys: string[]) => void;
  clear: () => void;
};

const useSearchParams = (defaultProps = {}): SearchParams => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialSearch = convertObjectToSearchQuery(defaultProps);

  const searchParams = useMemo(() => {
    return [initialSearch, location.search].reduce(
      (acc, query) => ({ ...acc, ...convertSearchQueryToObject(query) }),
      {}
    );
  }, [initialSearch, location.search]);

  const update = useCallback(
    (obj = {}) => {
      const search = convertObjectToSearchQuery(obj);

      if (location.search !== search) {
        navigate({ pathname: location.pathname, search }, { replace: true });
      }
    },
    [navigate, location.pathname, location.search]
  );

  const add = useCallback(
    (obj = {}) => update({ ...searchParams, ...obj }),
    [searchParams, update]
  );

  const remove = useCallback(
    (keys: string[]) => {
      update(omit(searchParams, keys));
    },
    [searchParams, update]
  );

  const clear = useCallback(() => {
    navigate({ pathname: location.pathname, search: '' }, { replace: true });
  }, [navigate, location.pathname]);

  return { data: searchParams, update, add, remove, clear };
};

export default useSearchParams;
