import { FC, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Button as BaseButton } from '@/ui-kit/Button';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { styled } from '@mui/material/styles';
import Check48 from '@tabby.ai/tabby-ui/dist/icons/core/Check48';
import { formatPhoneNumber } from '@/utils/phone';
import NoBr from '@/components/NoBr/NoBr';
import { Text } from '@/ui-kit/Text';
import { TextBox } from '@/components/TextBox';
import { format } from '../utils';

const Root = styled('div')`
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Button = styled(BaseButton)`
  width: 100%;
  margin-top: 24px;
`;

const Indent = styled('div')`
  margin-top: 16px;
`;

const CheckMarkCircle = styled('div')`
  background-color: ${({ theme }) => theme.palette.B2B2023.accent.positiveC};
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  onClose: () => void;
  date: string;
  phone: string;
  offerId: string;
};

export const ModalContentSuccess: FC<Props> = (props) => {
  const { onClose, phone, date, offerId } = props;
  const { t } = useTranslation();
  const track = useAnalyticsTrack();

  useEffect(() => {
    track({
      event: Events.ScheduledCall,
      params: {
        offer_id: offerId,
        date: format(date, 'en'),
        phoneNumber: phone
      }
    });
  }, [date, offerId, phone, track]);

  return (
    <Root>
      <CheckMarkCircle>
        <Check48 color="B2B2023.accent.positiveA" />
      </CheckMarkCircle>
      <Indent>
        <Text variant="h3">{t('scheduled')}</Text>
      </Indent>
      <Indent>
        <Text variant="body1Loose500" sx={{ color: (theme) => theme.palette.B2B2023.front.minor }}>
          <Trans
            shouldUnescape
            i18nKey="a_capital_specialist_will_call_you_on_{{date}}_at_{{phoneNumber}}"
            components={{
              nobr: <NoBr />,
              ltr: <TextBox forceDirection="ltr" inline />
            }}
            values={{
              date: format(date, 'en'),
              phoneNumber: formatPhoneNumber(phone)
            }}
          />
        </Text>
      </Indent>
      <Button onClick={onClose} size="large" variant="containedLight">
        <Text
          variant="body1Tight500"
          sx={{ color: (theme) => theme.palette.B2B2023.front.neutral }}
        >
          {t('got_it')}
        </Text>
      </Button>
    </Root>
  );
};
