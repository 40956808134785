import Portal from '@mui/material/Portal';
import { styled } from '@mui/material/styles';
import { useNotificationsContext } from '../NotificationsProvider';
import { NotificationItem } from './NotificationItem';
import { useCloseNotification } from '../hooks/useCloseNotification';

const Root = styled('div')(() => ({
  width: '100%',
  position: 'fixed',
  top: 0,
  zIndex: 9999
}));

export const NotificationsList = () => {
  const { notifications } = useNotificationsContext();
  const closeNotification = useCloseNotification();

  return (
    <Portal container={document.body}>
      <Root>
        {notifications.map((item) => (
          <NotificationItem key={item.id} notification={item} onClose={closeNotification} />
        ))}
      </Root>
    </Portal>
  );
};
