import TabbyLogoGreenSvg from '@/assets/icons/tabby-logo-green.svg';
import TabbyLogoBusiness from '@/assets/icons/tabby-logo-business.svg';
import { styled } from '@mui/material/styles';

const Root = styled('div')`
  /* @noflip */
  direction: ltr;
  width: 158px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TabbyBusinessLogo = () => {
  return (
    <Root>
      <TabbyLogoGreenSvg />
      <TabbyLogoBusiness />
    </Root>
  );
};
