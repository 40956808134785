import { ValueOf } from '@/types';

export const DIAL_CODES = {
  ARE: '+971',
  BHR: '+973',
  EGY: '+20',
  KWT: '+965',
  QAT: '+974',
  SAU: '+966',
  JOR: '+962'
} as const;

export type DialCode = ValueOf<typeof DIAL_CODES>;

export type PhoneConfig = {
  dialCode: DialCode;
  format: string;
  inputMask: string;
  inputPlaceholder: string;
  mask: string;
  maxLength: number;
  regExp: RegExp;
  separator: string;
};
