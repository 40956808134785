import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Link from '@mui/material/Link';
import PlusIcon from '@/assets/icons/plus.svg?icon';

import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { useFaqItems } from '@/pages/FinancePages/hooks/useFaqItems';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const List = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '16px'
}));

const Accordion = styled(MuiAccordion)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.B2B2023.front.line}`,
  padding: '0',
  '.MuiButtonBase-root': {
    padding: '5px 0'
  },
  '&:before': {
    display: 'none'
  },
  '&:last-of-type': {
    borderBottom: 0
  }
}));

const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(45deg)'
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: '0 0 12px'
}));

export const AboutWidget = () => {
  const { t } = useTranslation();
  const faqItems = useFaqItems();
  const [expanded, setExpanded] = useState<number>(0);

  const handleChange = (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : 0);
  };

  return (
    <Root>
      <Header>
        <DeprecatedTypography variant="_h1">{t('tabby_capital_faqs')}</DeprecatedTypography>
        <DeprecatedTypography variant="_body1Loose500">
          <Trans
            i18nKey="more_questions_reach_out_to"
            components={{
              mailToLink: (
                <Link
                  sx={{ textDecoration: 'none' }}
                  href="mailto:partner@tabby.ai"
                  target="_blank"
                />
              )
            }}
          />
        </DeprecatedTypography>
      </Header>

      <List>
        {faqItems.map(({ key, title, content }) => (
          <Accordion
            key={key}
            disableGutters
            elevation={0}
            square
            expanded={expanded === key}
            onChange={handleChange(key)}
          >
            <AccordionSummary expandIcon={<PlusIcon />}>
              <DeprecatedTypography variant="_h3">{title}</DeprecatedTypography>
            </AccordionSummary>
            <AccordionDetails>
              <DeprecatedTypography variant="_body1Loose500">{content}</DeprecatedTypography>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Root>
  );
};
