import {
  IconButton as TuiIconButton,
  IconButtonProps as TuiIconButtonProps
} from '@tabby.ai/tabby-ui/dist/core/IconButton';

export type IconButtonProps = Omit<TuiIconButtonProps, 'ds'>;

export const IconButton = ({ children, ...rest }: IconButtonProps) => (
  <TuiIconButton ds="B2B2023" {...rest}>
    {children}
  </TuiIconButton>
);
