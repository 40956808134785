import { Control, useController, type FieldValues, type Path } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { TextField, type TextFieldProps } from './TextField';

type Props<T extends FieldValues> = Omit<TextFieldProps, 'name' | 'value'> & {
  control: Control<T>;
  name: Path<T>;
  analytics?: { event: EventName } & Record<string, string | boolean>;
  transform?: (value: string) => string | number | boolean | null;
};

export const TextFieldControlled = <T extends FieldValues>({
  control,
  name,
  helperText,
  onChange,
  onBlur,
  analytics,
  transform = (value) => value,
  ...rest
}: Props<T>) => {
  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control
  });
  const track = useAnalyticsTrack();

  const handleFieldChange: TextFieldProps['onChange'] = (event) => {
    const newValue = transform(event.target.value);

    field.onChange(newValue);
    onChange?.(event);
  };

  const handleFieldBlur: TextFieldProps['onBlur'] = (event) => {
    field.onBlur();
    onBlur?.(event);

    if (analytics) {
      const { event: analyticsEvent, ...analyticsParams } = analytics;
      track({ event: analyticsEvent, params: { value: field.value, ...analyticsParams } });
    }
  };

  return (
    <TextField
      inputRef={field.ref}
      value={field.value}
      onChange={handleFieldChange}
      onBlur={handleFieldBlur}
      error={invalid}
      helperText={error?.message ? error.message || error.message : helperText}
      name={name}
      {...rest}
    />
  );
};
