declare global {
  interface Window {
    _refinerQueue: any[];
    _refiner?: (
      event: string,
      options?:
        | Record<string, string | number>
        | ((formId: string, formData: Record<string, string | number>) => void)
        | string,
      forceEvent?: boolean
    ) => void;
  }
}

const refinerId = 'refiner-snippet';

const scriptAlreadyExists = () => {
  return document.querySelector(`script#${refinerId}`) !== null;
};

type InitRefinerParams = {
  onLoad: () => void;
  onError: () => void;
  skip?: boolean;
};

export const initRefiner = ({ onLoad, onError, skip }: InitRefinerParams) => {
  if (skip || scriptAlreadyExists()) return;

  const script = document.createElement('script');

  script.defer = true;
  script.id = refinerId;
  script.src = `https://js.refiner.io/v001/client.js`;

  window._refinerQueue = [];
  window._refiner = (...args) => {
    window._refinerQueue.push(args);
  };

  script.addEventListener('load', onLoad);
  script.addEventListener('error', onError);

  document.body.appendChild(script);
};
