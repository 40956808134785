import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { Checkbox, CheckboxProps } from './Checkbox';

type Props<T extends FieldValues> = Omit<CheckboxProps, 'name' | 'value' | 'inputRef'> & {
  name: Path<T>;
  control: Control<T>;
  analytics?: { event: EventName } & Record<string, string>;
};

export const CheckboxControlled = <T extends FieldValues>({
  control,
  name,
  onChange,
  onBlur,
  analytics,
  helperText,
  ...rest
}: Props<T>) => {
  const track = useAnalyticsTrack();

  const {
    field,
    fieldState: { error, invalid }
  } = useController({
    name,
    control
  });

  const handleChange: CheckboxProps['onChange'] = (e, checked) => {
    field.onChange(checked);
    onChange?.(e, checked);
    if (analytics) {
      const { event, ...analyticsParams } = analytics;

      track({
        event,
        params: {
          value: checked,
          ...analyticsParams
        }
      });
    }
  };

  const handleBlur: CheckboxProps['onBlur'] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <Checkbox
      {...rest}
      checked={field.value}
      inputRef={field.ref}
      onChange={handleChange}
      onBlur={handleBlur}
      error={invalid}
      helperText={error ? error.message : helperText}
    />
  );
};
