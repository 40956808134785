import { safeParseJson } from '@/utils/json';
import { AUTHORIZED_KEY, DEPRECATED_AUTH_TOKEN_KEY, SESSION_ID_KEY } from './constants';

export const getSessionId = () => {
  return sessionStorage.getItem(SESSION_ID_KEY);
};

export const setSessionId = (id: string) => {
  sessionStorage.setItem(SESSION_ID_KEY, id);
};

export const removeSessionId = () => {
  sessionStorage.removeItem(SESSION_ID_KEY);
};

export const getDeprecatedAuthToken = () => {
  return localStorage.getItem(DEPRECATED_AUTH_TOKEN_KEY);
};

export const getIsAuthorized = (): boolean => {
  // TODO: Set default to "false" on 1st September 2024
  const defaultValue = Boolean(getDeprecatedAuthToken());

  return safeParseJson(localStorage.getItem(AUTHORIZED_KEY), defaultValue);
};

export const setIsAuthorized = (isAuthorized: boolean) => {
  if (isAuthorized) {
    localStorage.setItem(AUTHORIZED_KEY, JSON.stringify(true));
  } else {
    localStorage.removeItem(AUTHORIZED_KEY);
  }
};
