import { ReactNode, useState } from 'react';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import IconButtonBase from '@mui/material/IconButton';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { NavSidebar } from '@/components/NavSidebar';
import { PageAction } from '@/components/PageLayout/types';
import { styled } from '@mui/material';
import { WhoCanManageDisputesTooltip } from '@/pages/DisputesPage';
import { HeaderActions } from './HeaderActions';
import { BORDER_RADIUS_PX } from '../constants';

const Root = styled('div')(({ theme }) => ({
  height: '64px',
  flexShrink: 0,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 24px',
  backgroundColor: theme.palette.common.white,
  borderRadius: `0px 0px ${BORDER_RADIUS_PX}px ${BORDER_RADIUS_PX}px`,
  borderRight: `2px solid ${theme.palette.B2B2023.back.minor}`,
  borderBottom: `2px solid ${theme.palette.B2B2023.back.minor}`,
  borderLeft: `2px solid ${theme.palette.B2B2023.back.minor}`,
  margin: '0 10px',

  [theme.breakpoints.down('p768')]: {
    margin: '0',
    borderRadius: 0,
    borderBottom: `1px solid ${theme.palette.B2B2023.front.line}`,
    padding: '0 16px',

    '& .MuiIconButton-root': {
      padding: 0
    }
  }
}));

const Title = styled('div')(({ theme }) => ({
  width: '100%',
  color: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: theme.spacing(4),
  overflow: 'hidden'
}));

const IconButton = styled(IconButtonBase)(({ theme }) => ({
  color: theme.palette.common.black
}));

type Props = {
  pageActions?: PageAction[];
  title?: ReactNode;
  isMobileMenuHidden?: boolean;
  customActions?: ReactNode;
  customBreadcrumbs: ReactNode;
};

export const Header = (props: Props) => {
  const { pageActions = [], title, isMobileMenuHidden, customActions, customBreadcrumbs } = props;

  const isMobile = useIsMobilePage();
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSideBar = () => {
    setShowSidebar((prev) => !prev);
  };

  return (
    <Root>
      <Title>
        {isMobile && !isMobileMenuHidden && (
          <>
            <WhoCanManageDisputesTooltip placement="bottom">
              {(hideTooltip) => (
                <IconButton
                  onClick={() => {
                    toggleSideBar();
                    hideTooltip();
                  }}
                  size="large"
                >
                  <MenuRoundedIcon />
                </IconButton>
              )}
            </WhoCanManageDisputesTooltip>
            <NavSidebar
              variant="temporary"
              open={showSidebar}
              onClose={toggleSideBar}
              onNavMenuClick={toggleSideBar}
            />
          </>
        )}
        {title || customBreadcrumbs}
      </Title>
      {customActions || <HeaderActions pageActions={pageActions} />}
    </Root>
  );
};
