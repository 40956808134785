import { useMemo } from 'react';
import { FormProps } from '../types';

type Params = Pick<FormProps, 'legalEntities'>;

export const useDefaultFormValues = ({ legalEntities }: Params) => {
  const defaultItem = legalEntities && legalEntities?.length > 0 ? legalEntities[0].id : '';

  return useMemo(
    () => ({
      legalEntityId: defaultItem,
      offerId: ''
    }),
    [defaultItem]
  );
};
