import { LANGUAGE, Language, Option } from '@/types';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  getLabel: (language: Language) => string;
  labels: Record<Language, string>;
  options: Option[];
  toOptions: (countries: Language[]) => Option[];
};

export const useLanguageLabels = (): Props => {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      en: t('english'),
      ar: t('arabic')
    }),
    [t]
  );

  const getLabel = (language: Language) => labels[language];

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: labels[value] })),
    [labels]
  );

  const options: Props['options'] = useMemo(
    () => toOptions([LANGUAGE.ar, LANGUAGE.en]),
    [toOptions]
  );

  return { getLabel, toOptions, options, labels };
};
