import { TFunction, useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Text } from '@/ui-kit/Text';
import { useAuthenticateMutation } from '@/store/auth';
import { InputPhoneCountryControlled } from '@/ui-kit/InputPhoneCountry';
import { Button } from '@/ui-kit/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEmailSchema } from '@/lib/yup';
import * as yup from 'yup';
import phones from '@/lib/phones';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { useAnalyticsTrack } from '@/store/analytics';
import { useHandleApiError } from './hooks/useHandleApiError';
import { EmailPhoneToggle } from './components/EmailPhoneToggle';
import { EmailFieldControlled } from './components/EmailFieldControlled';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

type Method = 'email' | 'phone';

type Props = {
  method: Method;
  initialEmail: string | null;
  initialPhone: string | null;
  onSuccess: (method: Method, values: { email: string; phone: string }) => void;
  onMethodChange: (method: Method) => void;
};

const getSchema = (method: Method, t: TFunction) => {
  return {
    email: yup.object({
      email: getEmailSchema(t).required(() => '')
    }),
    phone: yup.object({
      phone: phones.getSchema(t)
    })
  }[method];
};

export const SignInForm = (props: Props) => {
  const { method, initialEmail, initialPhone, onMethodChange, onSuccess } = props;
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const [authenticate, { isLoading }] = useAuthenticateMutation();
  const handleApiError = useHandleApiError('signin');
  const { control, handleSubmit, setError } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(getSchema(method, t)),
    defaultValues: {
      email: initialEmail || '',
      phone: initialPhone || ''
    }
  });
  const track = useAnalyticsTrack();

  const submit = handleSubmit(async ({ email, phone }) => {
    try {
      const params = { email: { email, lang }, phone: { phone, lang } }[method];
      await authenticate(params).unwrap();

      track({
        event: 'Merchant Signed Up',
        params: { type: method, email, phone }
      });

      onSuccess(method, { email, phone });
    } catch (error) {
      handleApiError(error)((message) => setError(method, { message }));
    }
  });

  return (
    <Root>
      <Text variant="h3" sx={{ textAlign: 'center' }}>
        {t('sign_up_or_log_in')}
      </Text>
      <Column>
        <EmailPhoneToggle value={method} onChange={onMethodChange} />
        <Form onSubmit={submit} noValidate>
          {method === 'email' && (
            <EmailFieldControlled
              control={control}
              name="email"
              placeholder={t('email')}
              InputProps={{ size: 'medium' }}
              inputProps={{ type: 'email' }}
              disabled={isLoading}
              autoFocus
            />
          )}
          {method === 'phone' && (
            <InputPhoneCountryControlled
              control={control}
              name="phone"
              size="medium"
              disabled={isLoading}
              autoFocus
              allowOnlyDialCode
            />
          )}
          <Button type="submit" isLoading={isLoading} variant="contained" size="large" fullWidth>
            {t('continue')}
          </Button>
        </Form>
      </Column>
    </Root>
  );
};
