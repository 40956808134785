import { ValueOf } from '@/types';

export const ROLES = {
  Advertiser: 'advertiser',
  DisputeManager: 'dispute_manager',
  RefundsManager: 'refunds_manager',
  SalesManager: 'sales_manager',
  Settlements: 'settlements',
  SettlementsViewOnly: 'settlements_view_only',
  Supervisor: 'supervisor',
  TabbyCallCenterOperator: 'call_center_operator',
  TabbyCustomerCare: 'tabby_customer_care',
  TabbySalesManager: 'tabby_sales_manager',
  ViewOnly: 'view_only',
  WorkspaceAdmin: 'workspace_admin'
} as const;

export type Role = ValueOf<typeof ROLES>;

export type Permission =
  | 'DISPUTES_VIEW_AND_CHALLENGE'
  | 'USERS_EDIT'
  | 'PAYMENTS_CAPTURE'
  | 'PAYMENTS_CREATE'
  | 'PAYMENTS_REFUND_AND_CANCEL'
  | 'PAYMENTS_VIEW'
  | 'SETTLEMENTS_VIEW';

export type AccountPermission = {
  merchant_id: string;
  merchant_name: string;
  role: Role;
};

export type Account = {
  created_at: string;
  email: string | null;
  first_name: string;
  id: string;
  is_active: boolean;
  is_email_confirmed?: boolean;
  is_phone_confirmed?: boolean;
  last_name: string;
  permissions: AccountPermission[];
  phone: string | null;
};

export type AccountSession = {
  device_name: string | null;
  id: string;
  is_current: boolean;
  last_online: string | null;
  location: string | null;
  type: 'mobile' | 'web' | 'other';
  user_agent: string | null;
};

export const ACCOUNT_ERRORS = {
  TooManyAttemptsToSendCode: 'too many attempts to send code',
  EmailIsNotConfirmed: 'email is not confirmed',
  PhoneNumberNotUnique: 'phone number not unique',
  CouldNotValidatePhone: 'could not validate phone',
  InvalidOtpCode: 'invalid otp code'
} as const;
