import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LegendVariant } from '@/store/loan';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { useLegendLabels } from '../hooks/useLegendLabels';

const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}));

const Label = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight500,
  color: theme.palette.B2B2023.front.minor
}));

const Dot = styled('span', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant?: LegendVariant }>(({ theme, variant }) => {
  const color =
    variant &&
    {
      default: {
        bgColor: theme.palette.B2B2023.front.line,
        borderColor: theme.palette.B2B2023.back.minor
      },
      paid: {
        bgColor: theme.palette.B2B2023.accent.positiveB,
        borderColor: theme.palette.B2B2023.accent.positiveB
      },
      schedule: {
        bgColor: theme.palette.B2B2023.accent.highlightB,
        borderColor: theme.palette.B2B2023.accent.highlightB
      },
      overdue: {
        bgColor: theme.palette.B2B2023.accent.negativeB,
        borderColor: theme.palette.B2B2023.accent.negativeB
      },
      earlyPayOff: {
        bgColor: theme.palette.B2B2023.accent.highlightB,
        borderColor: theme.palette.B2B2023.accent.highlightB
      }
    }[variant];

  return {
    display: 'inline-block',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    width: '12px',
    height: '12px',
    backgroundColor: color?.bgColor,
    borderColor: color?.borderColor
  };
});

type Props = {
  variant: LegendVariant;
};

export const WidgetLegend = ({ variant = 'default' }: Props) => {
  const { getLabel } = useLegendLabels();

  return (
    <Root>
      <Dot variant={variant} />
      <Label>{getLabel(variant)}</Label>
    </Root>
  );
};
