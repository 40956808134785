import { useShowNotification } from '@/components/Notifications';
import { ROUTES } from '@/routes';
import { CapitalLoanWithLegalInfo, useUpdateLoanMutation } from '@/store/loan';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import pickBy from 'lodash/pickBy';
import { useResetLoanFormPropsContext } from './useResetLoanFormPropsContext';
import { useLoanUpdateErrorsLabels } from './useLoanUpdateErrorsLabels';

export const useResetLoanFormSubmit = (loan: CapitalLoanWithLegalInfo) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const showNotification = useShowNotification();

  const { handleSubmit } = useResetLoanFormPropsContext();
  const [updateLoan, { isLoading }] = useUpdateLoanMutation();
  const { getLabel } = useLoanUpdateErrorsLabels();

  const submit = handleSubmit((values) => {
    const formValues = {
      cr_file: values.cr_file,
      cr_number: values.cr_number,
      registered_company_address: values.registered_company_address,
      company_name_or_legal_name: values.company_name_or_legal_name
    };

    const payload = {
      id: loan.id,
      ...pickBy(formValues, Boolean)
    };

    updateLoan(payload)
      .unwrap()
      .then(() => {
        showNotification({
          variant: 'success',
          message: t('changes_saved')
        });

        navigate(generatePath(ROUTES.financeTabbyCapitalLoansId, { id: loan.id }));
      })
      .catch((err) => {
        const errorMessage = err.data?.details?.reason
          ? getLabel(err.data?.details?.reason)
          : t('something_went_wrong_please_try_again_later');

        return showNotification({
          variant: 'error',
          message: errorMessage
        });
      });
  });

  return { submit, isLoading };
};
