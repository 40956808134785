import { BrowserRouter } from 'react-router-dom';
import { Provider as StoreProvider } from 'react-redux';
import store from '@/store';
import { BaseI18nextProvider } from '@tabby.ai/tabby-ui/dist/core';
import { AppRoutes } from '@/components/AppRoutes';
import { NotificationsProvider, NotificationsList } from '@/components/Notifications';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { GetStreamProvider } from '@/components/GetStreamProvider';
import { AnalyticsProvider } from '@/store/analytics';
import { Suspense } from 'react';
import { LoaderDots } from '@/components/LoaderDots';
import { ThemeProvider } from '@/components/ThemeProvider';

export const App = () => {
  return (
    <BaseI18nextProvider config={{ fallbackLng: 'en' }}>
      <ThemeProvider>
        <ErrorBoundary>
          <BrowserRouter>
            <AnalyticsProvider>
              <NotificationsProvider>
                <StoreProvider store={store}>
                  <GetStreamProvider>
                    <Suspense fallback={<LoaderDots height="100vh" />}>
                      <AppRoutes />
                    </Suspense>
                    <NotificationsList />
                  </GetStreamProvider>
                </StoreProvider>
              </NotificationsProvider>
            </AnalyticsProvider>
          </BrowserRouter>
        </ErrorBoundary>
      </ThemeProvider>
    </BaseI18nextProvider>
  );
};
