import { CleanerPatternName } from './types';

const arabicAll = '\u0600-\u06FF\uFE70-\uFEFF';
const brackets = `{}<>()[\\]`;
const dividers = `\\\\|/`;
const latinAll = 'a-zA-Z0-9!?_;:\'",.\\- ';
const latinLetters = 'a-zA-Z';
const numbers = '0-9';
const other = '=~@#$%^&*+';
const punctuation = `!?_;:'",.\\- `;
const unsafeAll = `\\\\|/{}<>()[\\]=~@#^*;:'".`;

export const patterns: Record<CleanerPatternName, string> = {
  arabic_all: arabicAll,
  brackets,
  dividers,
  latin_all: latinAll,
  latin_letters: latinLetters,
  numbers,
  other,
  punctuation,
  unsafe_all: unsafeAll
};
