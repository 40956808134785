import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { LoanSteps } from '../types';

type Props = {
  activeStep: LoanSteps;
  children: ReactNode;
};

const MobileStepLines = styled('div')(() => ({
  display: 'flex',
  gap: '8px'
}));

const MobileStepInfo = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const MobileStepLabel = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight700,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: theme.palette.B2B2023.front.major
}));

const MobileStepCount = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._caption1Tight500,
  color: theme.palette.B2B2023.front.minor
}));

export const MobileStepper = ({ activeStep, children }: Props) => {
  const { t } = useTranslation();

  const getActiveStepIndex = () => {
    switch (true) {
      case activeStep === 'application':
        return 1;
      case activeStep === 'changes-required':
      case activeStep === 'in-review':
        return 2;
      default:
        return 3;
    }
  };

  const getActiveStepLabel = () => {
    switch (true) {
      case activeStep === 'application':
        return t('apply');
      case activeStep === 'changes-required':
        return t('make_changes');
      case activeStep === 'in-review':
        return t('await_review');
      default:
        return t('sign_contract');
    }
  };

  return (
    <>
      <MobileStepLines>{children}</MobileStepLines>
      <MobileStepInfo>
        <MobileStepLabel>{getActiveStepLabel()}</MobileStepLabel>
        <MobileStepCount>
          {getActiveStepIndex()}/{activeStep === 'changes-required' ? 4 : 3}
        </MobileStepCount>
      </MobileStepInfo>
    </>
  );
};
