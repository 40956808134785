import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { useBenefits } from '../hooks/useBenefits';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  padding: '16px 24px'
}));

const Item = styled('div')(({ theme }) => ({
  color: theme.palette.B2B2023.front.major,
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gap: '16px'
}));

const Icon = styled('div')(({ theme }) => ({
  background: theme.palette.B2B2023.back.minor,
  width: '40px',
  height: '40px',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const TextGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

const Title = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._h2,
  display: 'block'
}));

const Text = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Loose500,
  color: theme.palette.B2B2023.front.minor,
  display: 'block'
}));

export const Benefits = () => {
  const benefits = useBenefits();

  return (
    <Root>
      {benefits.map(({ key, icon, title, text }) => (
        <Item key={key}>
          <Icon>{icon}</Icon>
          <TextGroup>
            <Title>{title}</Title>
            <Text>{text}</Text>
          </TextGroup>
        </Item>
      ))}
    </Root>
  );
};
