import { useController, Control, FieldValues, Path } from 'react-hook-form';
import Switch, { Props as SwitchProps } from './Switch';

export type Props<T extends FieldValues> = Omit<
  SwitchProps,
  'name' | 'value' | 'inputRef' | 'onChange' | 'onBlur' | 'error' | 'helperText'
> & {
  name: Path<T>;
  control: Control<T>;
};

const SwitchControlled = <T extends FieldValues>({ control, name, ...rest }: Props<T>) => {
  const {
    field: { onChange, onBlur, value }
  } = useController({
    name,
    control
  });

  return <Switch {...rest} checked={value} onChange={onChange} onBlur={onBlur} />;
};

export default SwitchControlled;
