import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { StatusBadge } from '@/components/StatusBadge';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

const Root = styled('div')(({ theme }) => ({
  color: theme.palette.B2B2023.front.major
}));

const Container = styled('div')(() => ({
  padding: '24px 24px 12px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  alignItems: 'flex-start'
}));

const Title = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._hxl,
  fontSize: theme.typography.pxToRem(44),
  lineHeight: theme.typography.pxToRem(46)
}));

const Badge = styled(StatusBadge)(() => ({
  marginBottom: '16px'
}));

const HeaderText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px'
}));

export const Header = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <Container>
        <Badge label={t('coming_soon')} color="iris" />
        <HeaderText>
          <Title>
            <Trans i18nKey="grow_your_business_with_tabby_capital" components={{ br: <br /> }} />
          </Title>
          <DeprecatedTypography variant="_body1Loose500">
            {t('receive_advance_to_stock_up_on_inventory__description')}
          </DeprecatedTypography>
        </HeaderText>
      </Container>
    </Root>
  );
};
