import { pxToRem } from '@tabby.ai/tabby-ui/dist/theme';

export const typography = {
  h1: {
    fontWeight: 700,
    fontSize: pxToRem(48),
    lineHeight: 1.292
  },
  h2: {
    fontWeight: 700,
    fontSize: pxToRem(32),
    lineHeight: 1.313
  },
  h3: {
    fontWeight: 700,
    fontSize: pxToRem(24),
    lineHeight: 1.333
  },
  h4: {
    fontWeight: 700,
    fontSize: pxToRem(18),
    lineHeight: 1.333
  },
  h5: {
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: 1.5
  },
  h6: {
    fontWeight: 700,
    fontSize: pxToRem(14),
    lineHeight: 1.5
  },
  body1: {
    fontWeight: 400,
    fontSize: pxToRem(16),
    lineHeight: 1.5
  },
  body2: {
    fontWeight: 400,
    fontSize: pxToRem(14),
    lineHeight: 1.5
  }
};
