import { LegalEntity } from '@/store/merchants';
import { LoanOffer } from '@/store/offers';
import { COUNTRIES } from '@/types';
import { useState } from 'react';
import { FormValues } from '../types';
import { useFormSubmit } from './useFormSubmit';

export const useFormState = ({
  initialData,
  legalEntity,
  offer
}: {
  initialData: FormValues;
  legalEntity: LegalEntity;
  offer: LoanOffer;
}) => {
  const [isCRNumberSAUValid, setIsCRNumberSAUValid] = useState(false);
  const { submit, isLoading: isLoadingSubmit } = useFormSubmit(offer);

  const legalEntityCountry = legalEntity.legal_entity_country;
  const isLegalEntityCountrySAU = legalEntityCountry === COUNTRIES.SAU;

  return {
    initialData,
    hasAuthority: true,
    offer,
    submit,
    isLoadingSubmit,
    setIsCRNumberSAUValid,
    isCRNumberSAUValid,
    isLegalEntityCountrySAU,
    legalEntity: {
      iban: legalEntity.iban,
      name: legalEntity.legal_company_name
    }
  };
};
