import { useTranslation } from 'react-i18next';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import UsersIcon from '@/assets/icons/users.svg?icon';
import { ROUTES } from '@/routes';
import { NavLink } from 'react-router-dom';
import { WhoCanManageDisputesTooltip } from '@/pages/DisputesPage';

export const NavMenuItemUsers = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const { t } = useTranslation();

  return (
    <WhoCanManageDisputesTooltip placement="right">
      {(hideTooltip) => (
        <SideMenuItem
          icon={<UsersIcon />}
          isCollapsed={isCollapsed}
          label={t('users')}
          to={ROUTES.users}
          component={NavLink}
          onClick={hideTooltip}
        />
      )}
    </WhoCanManageDisputesTooltip>
  );
};
