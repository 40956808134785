import { Currency, TabbyProduct, ValueOf } from '@/types';
import { Merchant } from '@/store/merchants';

export const PAYMENT_STATUSES = {
  Cancelled: 'cancelled',
  Captured: 'captured',
  Disputed: 'disputed',
  Expired: 'expired',
  Expiring: 'expiring',
  New: 'new',
  None: '',
  PartiallyRefunded: 'partially_refunded',
  PendingRefund: 'pending_refund',
  Refunded: 'refunded',
  Rejected: 'rejected'
} as const;

export type PaymentStatus = ValueOf<typeof PAYMENT_STATUSES>;

export interface PaymentProduct {
  // period in ISO-8601 format, e.g. "P3Y6M4W1D" (3 years, 6 months, 4 weeks and 1 day.)
  installment_period: string;
  installments_count: number;
  type: TabbyProduct;
}

export interface PaymentBuyer {
  email: string;
  name: string;
  phone: string;
}

export enum PaymentRefundAckType {
  Instant = 'instant',
  Async = 'async',
  Disputed = 'disputed'
}

export interface PaymentRefund {
  ack_type: PaymentRefundAckType;
  acknowledged: boolean;
  amount: string;
  capture_id?: string;
  id: string;
  reason: string;
}

export interface Payment {
  amount: string;
  buyer: PaymentBuyer;
  cancelable: boolean;
  captured_amount: string;
  created_at: string | null;
  currency: Currency;
  expires_at: string | null;
  id: string;
  is_test: boolean;
  merchant_id: string;
  merchant_name: string;
  order_number: string;
  product: PaymentProduct;
  refunded_amount: string;
  refunds: PaymentRefund[];
  status: PaymentStatus;
  transaction_auth_code: string;
  transaction_ref_no: string;
  transaction_rrn: string;
  dispute_id?: string;
}

export type PaymentOrderItem = {
  reference_id: string;
  title: string;
  unit_price: string;
  quantity: number;
};

export interface PaymentOrder {
  reference_id: string;
  discount_amount: string;
  items: PaymentOrderItem[] | null;
}

export type PaymentHistoryEntry = {
  amount: string | null;
  author: 'system' | 'payment_api' | string;
  payment_id: string | null;
  reference_id: string | null;
  timestamp: string;
  type:
    | 'authorized'
    | 'cancelled'
    | 'captured'
    | 'closed'
    | 'expired'
    | 'payment_link_created'
    | 'refund_cancelled'
    | 'refund_disputed'
    | 'refunded'
    | 'updated';
};

export interface PaymentShippingAddress {
  city: string;
  address: string;
  zip: string;
}

export type PaymentPermission =
  | 'cancel'
  | 'capture'
  | 'close'
  | 'create'
  | 'edit'
  | 'export'
  | 'refund'
  | 'view';

export interface PaymentDetailed extends Omit<Payment, 'merchant_name'> {
  captured_at: string | null;
  closed_at: string | null;
  not_captured_amount: string;
  billed_amount: string;
  merchant: Merchant & { mid: string };
  shipping_address: PaymentShippingAddress;
  order: PaymentOrder;
  permissions: PaymentPermission[];
  history: PaymentHistoryEntry[];
}

export type PaymentAmountName =
  | 'total_amount'
  | 'billed_amount'
  | 'captured_amount'
  | 'not_captured_amount'
  | 'refunded_amount'
  | 'pending_refund_amount'
  | 'refund_limit_amount';

export type PaymentAmounts = Record<PaymentAmountName, number>;

export type PaymentCaptureParams = {
  id: string;
  amount: string;
};

export type PaymentEditParams = {
  id: string;
  payment: {
    order: {
      reference_id: string;
    };
  };
};

export type PaymentRefundParams = {
  id: string;
  amount: string;
};

export type PaymentDeleteRefundParams = {
  id: string;
  refundId: string;
};
