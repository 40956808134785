import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import FeedbackAlertIcon from '@/assets/icons/feedback-alert.svg?icon';
import { Box } from '@mui/material';
import { AlertVariant } from '../types';

export type Props = {
  title: ReactNode;
  text?: ReactNode;
  variant: AlertVariant;
  icon?: ReactNode;
};

const AlertWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '16px',
  borderRadius: '16px',
  backgroundColor: theme.palette.B2B2023.back.major
}));

const IconWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant?: Props['variant'] }>(({ theme, variant }) => {
  const color =
    variant &&
    {
      error: theme.palette.B2B2023.accent.negativeA,
      warning: theme.palette.B2B2023.accent.warningA,
      success: theme.palette.B2B2023.accent.positiveA
    }[variant];

  const background =
    variant &&
    {
      error: theme.palette.B2B2023.accent.negativeC,
      warning: theme.palette.B2B2023.accent.warningC,
      success: theme.palette.B2B2023.accent.positiveC
    }[variant];

  return {
    color,
    background,
    padding: '12px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
});

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant'
})<{ variant?: Props['variant'] }>(({ theme, variant }) => {
  const color =
    variant &&
    {
      error: theme.palette.B2B2023.accent.negativeA,
      warning: theme.palette.B2B2023.accent.warningA,
      success: theme.palette.B2B2023.front.major
    }[variant];

  return {
    color,
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    padding: '6px 0'
  };
});

const Text = styled(DeprecatedTypography)(({ theme }) => ({
  color: theme.palette.B2B2023.front.minor
}));

export const Alert = ({ title, text, variant = 'warning', icon }: Props) => {
  return (
    <AlertWrapper>
      <IconWrapper variant={variant}>{icon || <FeedbackAlertIcon />}</IconWrapper>
      <Content variant={variant}>
        <DeprecatedTypography variant="_body1Tight700">{title}</DeprecatedTypography>
        {text && <Text variant="_caption1Loose500">{text}</Text>}
      </Content>
    </AlertWrapper>
  );
};
