import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

export const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: 24,
  marginTop: '24px'
}));

export const Stepper = styled(Skeleton)(({ theme }) => ({
  height: 44,
  width: 355,
  borderRadius: '66px',
  [theme.breakpoints.down('p768')]: {
    width: 310
  }
}));

export const Form = styled(Skeleton)(({ theme }) => ({
  height: 520,
  width: 520,
  borderRadius: '24px',
  [theme.breakpoints.down('p768')]: {
    width: 310
  }
}));

export const FormContentSkeleton = () => {
  return (
    <Root>
      <Stepper variant="rectangular" animation="wave" />
      <Form variant="rectangular" animation="wave" />
    </Root>
  );
};
