import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '@/components/Select/Select';
import useSearchParams from '@/hooks/useSearchParams';
import { getTruthyKeys } from '@/utils/object';
import { formatYYYYMMDD } from '@/utils/date';
import { Merchant } from '@/store/merchants';
import { useDispatch } from 'react-redux';

import { ACTIONS as disputesActions, HOOKS as disputesHooks } from '../state';
import { DisputeStatuses } from '../types';
import { getDisputesSearchParams } from '../utils';

interface AllFilters {
  from: Date | null;
  to: Date | null;
  statuses: SelectOption[];
  merchants: Merchant[];
  query?: string;
}

export const useDisputesFilters = () => {
  const searchParams = useSearchParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectedMerchants = disputesHooks.useSelectedMerchants();

  const optionAll = useMemo(() => ({ value: 'all', label: t('all') }), [t]);

  const statusLabels = {
    approved: t('dispute_status__approved'),
    arbitration: t('dispute_status__arbitration'),
    cancelled: t('dispute_status__cancelled'),
    declined: t('dispute_status__declined'),
    pending: t('dispute_status__pending')
  };

  const data = useMemo(
    () => ({
      ...getDisputesSearchParams(searchParams.data),
      merchantsIds: selectedMerchants.map((m) => m.id)
    }),
    [selectedMerchants, searchParams.data]
  );

  const { createdFrom, createdTo, statuses = [] } = data;

  const statusOptions = Object.values(DisputeStatuses).map((status) => ({
    label: statusLabels[status],
    value: status
  }));

  const statusFilters = statuses.map((status) => ({
    label: statusLabels[status],
    value: status
  }));

  const handleChangeDates = (from: Date, to: Date) => {
    searchParams.add({
      createdFrom: formatYYYYMMDD(from),
      createdTo: formatYYYYMMDD(to)
    });
  };

  const handleChangeStatuses = (options: SelectOption[]) => {
    searchParams.add({
      statuses: options
        .map((item) => item.value)
        .filter((v) => v !== optionAll.value)
        .join(',')
    });
  };

  const handleChangeMerchants = (merchants: Merchant[]) => {
    dispatch(disputesActions.setSelectedMerchants(merchants));
  };

  const changeAllFilters = (allFilters: AllFilters) => {
    dispatch(disputesActions.setSelectedMerchants(allFilters.merchants));
    searchParams.add({
      createdFrom: formatYYYYMMDD(allFilters.from),
      createdTo: formatYYYYMMDD(allFilters.to),
      statuses: allFilters.statuses.map((item) => item.value).join(','),
      query: allFilters.query
    });
  };

  const filtersCount = getTruthyKeys({
    statuses: data.statuses?.length,
    createdFrom: data.createdFrom,
    merchantsIds: selectedMerchants.length
  }).length;

  return {
    data,
    initialCreatedFrom: createdFrom ? new Date(createdFrom) : null,
    initialCreatedTo: createdTo ? new Date(createdTo) : null,
    statusOptions,
    statusFilters,
    handleChangeDates,
    handleChangeStatuses,
    handleChangeMerchants,
    filtersCount,
    changeAllFilters,
    optionAll,
    selectedMerchants
  };
};
