import { LegalEntity } from '@/store/merchants';
import { Currency, ValueOf } from '@/types';

export const LOAN_STATUSES = {
  submitted: 'submitted',
  approved: 'approved',
  closed: 'closed',
  paying: 'paying',
  overdue: 'overdue',
  rejected: 'rejected',
  waiting: 'waiting',
  reset: 'reset'
} as const;

export type LoanStatus = ValueOf<typeof LOAN_STATUSES>;

export type LoanAuthority = {
  name: string;
  phone: string;
  email: string;
};

export type LoanLegalInfo = {
  cr_file: string;
  cr_number: string;
  legal_address: string;
  legal_name: string;
};

export type Repayment = {
  id: string;
  date: string;
  amount: string;
  payment_amount: string;
  repayment_type: 'general' | 'early_pay_off';
};

export type EarlyPayOff = {
  repayment_amount: string;
  capital_amount: string;
};

export type CapitalLoan = {
  id: string;
  merchant_id: string;
  amount: string;
  paid_amount: string;
  amount_vat: string;
  interest: string;
  currency: Currency;
  status: LoanStatus;
  repayments: Repayment[] | null;
  authority: {
    finance?: LoanAuthority;
    sign?: LoanAuthority;
  };
  applicant?: {
    account_id: string;
    email: string;
  };
  fee_amount: string;
  minimum_repayment_amount: string;
  applied_at?: string;
  completed_at?: string;
  repaid_percent?: string;
  next_settlement_date?: string;
  early_pay_off?: EarlyPayOff | null;
  fee_and_vat_amount: string;
  reset_reason?: ResetReason;
  reject_reason?: RejectionReason;
  tenor: string;
  repayment_rate: string;
  partner_name?: string;
};

export type CapitalLoanState = CapitalLoan & {
  fee: number;
  total: number;
  minimumRepayment: number;
  legalEntity: LegalEntity;
};

export type ApplyLoanParams = {
  cr_file: string;
  cr_number: string;
  sign: LoanAuthority;
  finance?: LoanAuthority;
  merchant_id: string;
  offer_id: string;
};

export type UpdateLoanParams = {
  id: string;
  cr_file?: string;
  cr_number?: string;
  registered_company_address?: string;
  company_name_or_legal_name?: string;
};

export type LegalEntityWithMerchantId = LegalEntity & {
  merchant_id: string;
};

export type CapitalLoanWithLegalEntity = CapitalLoan & {
  legal_entity: LegalEntityWithMerchantId;
};

export type CapitalLoanWithLegalInfo = CapitalLoan & {
  legal_info: LoanLegalInfo;
};

export const REPAYMENT_INFO_STATUSES = {
  paying: 'paying',
  paid: 'paid',
  overdueCritical: 'overdueCritical',
  overdueWarning: 'overdueWarning',
  earlyPayOff: 'earlyPayOff'
} as const;

export type RepaymentInfoStatus = ValueOf<typeof REPAYMENT_INFO_STATUSES>;

export type RepaymentInfo = {
  id: string;
  date: string;
  deducted: number;
  settlementPayout: number;
  repaymentAmount: number;
  resultingPayout: number;
  currency: Currency;
  status: RepaymentInfoStatus;
  repaymentType: 'early_pay_off' | 'general';
};

export const LEGEND_VARIANTS = {
  default: 'default',
  paid: 'paid',
  overdue: 'overdue',
  earlyPayOff: 'earlyPayOff'
} as const;

export type LegendVariant = ValueOf<typeof LEGEND_VARIANTS>;

export const ALERT_VARIANTS = {
  error: 'error',
  warning: 'warning'
};

export type AlertVariant = ValueOf<typeof ALERT_VARIANTS>;

export const REPAYMENTS_SORT_BY = {
  date: 'date',
  settlementPayout: 'settlementPayout',
  repaymentAmount: 'repaymentAmount',
  resultingPayout: 'resultingPayout'
} as const;

export type RepaymentsSortBy = ValueOf<typeof REPAYMENTS_SORT_BY>;

export const RESET_REASON = {
  beneficiary_name_mismatch: 'beneficiary_name_mismatch',
  company_name_mismatch: 'company_name_mismatch',
  irrelevant_license: 'irrelevant_license',
  business_license_expires_soon: 'business_license_expires_soon',
  business_license_expired: 'business_license_expired'
} as const;

export type ResetReason = ValueOf<typeof RESET_REASON>;

export const REJECTION_REASON = {
  general_reject: 'general_reject',
  no_valid_license: 'no_valid_license',
  high_exposure: 'high_exposure'
} as const;

export type RejectionReason = ValueOf<typeof REJECTION_REASON>;
