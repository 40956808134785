import { getEndDayISOString, getStartDayISOString, isValidDateString } from '@/utils/date';
import { filterFalsyValues } from '@/utils/object';
import {
  DisputeStatuses,
  ExportDisputesCsvParams,
  ExportDisputesCsvParamsApi,
  GetDisputesParams,
  GetDisputesParamsApi
} from './types';

export const prepareGetDisputesParams = (params: GetDisputesParams): GetDisputesParamsApi => {
  return filterFalsyValues({
    page_size: params.limit || 20,
    page_token: params.nextPageToken,
    created_at_gte: getStartDayISOString(params.createdFrom),
    created_at_lte: getEndDayISOString(params.createdTo),
    statuses: params.statuses,
    merchant_ids: params.merchantsIds,
    query: params.query
  });
};

export const prepareExportCsvDisputesParams = (
  params: ExportDisputesCsvParams
): ExportDisputesCsvParamsApi => {
  return filterFalsyValues({
    created_at_gte: params.createdFrom && new Date(params.createdFrom).toISOString(),
    created_at_lte:
      params.createdTo &&
      new Date(params.createdTo).toISOString().replace('T00:00:00.000Z', 'T23:59:59.999Z'),
    statuses: params.statuses,
    merchant_ids: params.merchantsIds,
    query: params.query
  });
};

export const getDisputesSearchParams = (data: Record<string, string | undefined>) => {
  const { createdFrom, createdTo, statuses: initialStatuses = '', query } = data;
  const statuses = initialStatuses
    .split(',')
    .filter((status): status is DisputeStatuses =>
      Object.values<string>(DisputeStatuses).includes(status)
    );

  const result: Pick<GetDisputesParams, 'statuses' | 'createdFrom' | 'createdTo' | 'query'> = {
    statuses,
    query
  };

  if ([createdFrom, createdTo].every(isValidDateString)) {
    result.createdFrom = createdFrom;
    result.createdTo = createdTo;
  }

  return result;
};
