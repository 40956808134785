import { TextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import LinearProgressBase, { linearProgressClasses } from '@mui/material/LinearProgress';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { LoanStatus, RepaymentInfo, RepaymentInfoStatus } from '@/store/loan';
import orderBy from 'lodash/orderBy';
import { Currency } from '@/types';
import { Box } from '@mui/material';
import { WidgetTooltip } from '@/pages/FinancePages/components/WidgetTooltip';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const LinearProgress = styled(LinearProgressBase)(({ theme }) => ({
  height: 48,
  borderRadius: 10,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.B2B2023.back.minor
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.B2B2023.accent.positiveB
  }
}));

const VisualSlots = styled('div')(() => ({
  display: 'flex',
  gap: '2px'
}));

const VisualSlot = styled('div', {
  shouldForwardProp: (props) => props !== 'status'
})<{ status?: RepaymentInfoStatus }>(({ theme, status }) => {
  const background =
    status &&
    {
      earlyPayOff: theme.palette.B2B2023.accent.highlightB,
      paying: theme.palette.B2B2023.back.minor,
      paid: theme.palette.B2B2023.accent.positiveB,
      overdueWarning: theme.palette.B2B2023.accent.positiveB,
      overdueCritical: theme.palette.B2B2023.accent.negativeB
    }[status];

  return {
    background,
    flex: '1',
    height: '24px',
    borderRadius: '5px'
  };
});

const VisualFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: theme.palette.B2B2023.front.minor
}));

const FooterText = styled('span')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  color: theme.palette.B2B2023.front.minor
}));

const CapitalizedText = styled('span')({
  textTransform: 'capitalize'
});

const StylesTextCurrency = styled(TextCurrency, {
  shouldForwardProp: (props) => props !== 'status'
})<{ status: LoanStatus }>(({ theme, status }) => ({
  color: status === 'overdue' ? theme.palette.B2B2023.accent.negativeA : 'inherit'
}));

type Props = {
  repayments: RepaymentInfo[];
  totalCount: number;
  currentCount: number;
  progress?: number;
  minimumRepayment?: string;
  currency?: Currency;
  merchantName?: string;
  hasProgress?: boolean;
  hasRepaymentTooltip?: boolean;
  loanStatus: LoanStatus;
};

export const VisualDataWidget = ({
  repayments = [],
  totalCount,
  currentCount,
  progress,
  minimumRepayment,
  currency: loanCurrency,
  merchantName,
  hasProgress,
  hasRepaymentTooltip = false,
  loanStatus
}: Props) => {
  const { t } = useTranslation();
  const sortedByDateRepayments = orderBy(repayments, 'date');

  return (
    <Root>
      {hasProgress && <LinearProgress variant="determinate" value={progress} />}
      {merchantName && <FooterText>{merchantName}</FooterText>}
      <VisualSlots>
        {sortedByDateRepayments.map((repayment, idx) => {
          if (!repayment) {
            // eslint-disable-next-line react/no-array-index-key
            return <VisualSlot status="paying" key={`${idx}`} />;
          }

          const {
            date,
            status,
            settlementPayout,
            repaymentAmount,
            resultingPayout,
            currency,
            repaymentType
          } = repayment;

          if (!hasRepaymentTooltip) {
            return <VisualSlot status={status} key={`${date + idx}`} />;
          }

          const tooltipInfo =
            repaymentType === 'early_pay_off'
              ? [
                  {
                    label: t('early_payoff'),
                    money: <TextCurrency amount={resultingPayout} currency={currency} />
                  },
                  {
                    label: t('repayment'),
                    money: <TextCurrency amount={repaymentAmount} currency={currency} />
                  }
                ]
              : [
                  {
                    label: t('sales'),
                    money: <TextCurrency amount={settlementPayout} currency={currency} />
                  },
                  {
                    label: t('repayment'),
                    money: <TextCurrency amount={repaymentAmount} currency={currency} />
                  },
                  {
                    label: t('payout'),
                    money: <TextCurrency amount={resultingPayout} currency={currency} />
                  }
                ];

          return (
            <WidgetTooltip
              key={`${date + idx}`}
              title={<TextDate format="ddmmyyyy" timestamp={date} />}
              info={tooltipInfo}
              placement="bottom"
            >
              <VisualSlot status={status} />
            </WidgetTooltip>
          );
        })}
      </VisualSlots>
      <VisualFooter>
        {minimumRepayment && loanCurrency ? (
          <>
            <Box sx={{ display: 'flex', gap: '10px' }}>
              <FooterText>{t('weekly_min')}: </FooterText>
              <DeprecatedTypography variant="_body2Loose700">
                <StylesTextCurrency
                  amount={minimumRepayment}
                  currency={loanCurrency}
                  status={loanStatus}
                />
              </DeprecatedTypography>
            </Box>
            <FooterText>
              <CapitalizedText>{`${t('week')}: ${currentCount}/${totalCount}`}</CapitalizedText>
            </FooterText>
          </>
        ) : (
          <>
            <DeprecatedTypography variant="_body2Tight500">
              <CapitalizedText>{t('week')}</CapitalizedText>
            </DeprecatedTypography>
            <DeprecatedTypography variant="_body2Tight500">
              {currentCount}/{totalCount}
            </DeprecatedTypography>
          </>
        )}
      </VisualFooter>
    </Root>
  );
};
