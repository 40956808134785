import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { RadioGroupProps } from './types';
import { RadioGroup } from './RadioGroup';

type Props<T extends FieldValues> = Omit<RadioGroupProps, 'onChange' | 'onBlur' | 'value'> & {
  control: Control<T>;
  name: Path<T>;
};

export const RadioGroupControlled = <T extends FieldValues>({
  control,
  name,
  options,
  helperText,
  ...rest
}: Props<T>) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { invalid, error }
  } = useController({ control, name });

  return (
    <RadioGroup
      value={value}
      options={options}
      onChange={onChange}
      onBlur={onBlur}
      invalid={invalid}
      helperText={error?.message ? error.message : helperText}
      {...rest}
    />
  );
};
