import { SelectProps } from '@/ui-kit/Select';
import { COUNTRIES } from '@/types';
import { useFormPropsContext } from './useFormPropsContext';
import { mapApplication } from '../utils';

export const useHandleCountryOfMainOperationChange = () => {
  const { setValue, reset, getValues } = useFormPropsContext();

  const handleCountryOfMainOperationChange: SelectProps['onChange'] = (event) => {
    const { value } = event.target;

    reset({
      ...getValues(),
      legal_info: { ...mapApplication().legal_info },
      bank_details: { ...mapApplication().bank_details }
    });

    if (value === COUNTRIES.SAU) {
      setValue('legal_info.business_license_type', 'commercial_registration');
    }

    setValue('basic_info.other_countries', []);
  };

  return { handleCountryOfMainOperationChange };
};
