import Typography from '@mui/material/Typography';
import { Text } from '@/ui-kit/Text';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

export const BORDER_RADIUS_PX = 24;

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: `${BORDER_RADIUS_PX}px`,
  background: theme.palette.common.white,
  overflow: 'hidden'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '24px 24px 16px 24px'
}));

type ContentProps = {
  padding: 8 | 24;
  column: boolean;
  gap?: number;
};

const Content = styled('div', {
  shouldForwardProp: (p) => !['padding', 'column', 'gap'].includes(p as string)
})<ContentProps>((props) => {
  const { padding, column, gap } = props;

  const paddingValue = {
    8: '0px 8px 8px 8px',
    24: '0px 24px 24px 24px'
  }[padding];

  return {
    padding: paddingValue,
    ...(column && {
      display: 'flex',
      flexDirection: 'column',
      gap: gap && `${gap}px`
    })
  };
});

export type Props = {
  title: string;
  subtitle?: ReactNode;
  children: ReactNode;
} & Partial<ContentProps>;

export const WidgetBase = (props: Props) => {
  const { title, subtitle, children, padding = 8, column = false, gap } = props;

  return (
    <Root>
      <Header>
        <Text variant="h3">{title}</Text>
        {subtitle && typeof subtitle === 'string' ? (
          <Typography variant="body1">{subtitle}</Typography>
        ) : (
          subtitle
        )}
      </Header>
      <Content padding={padding} column={column} gap={gap}>
        {children}
      </Content>
    </Root>
  );
};

export default WidgetBase;
