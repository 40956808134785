import { ROUTES } from '@/routes';
import { useGetPaymentsQuery } from '@/store/payments';
import { Navigate } from 'react-router-dom';

export const RedirectToOnboarding = () => {
  const { data: { items: payments } = {}, isError } = useGetPaymentsQuery({ limit: 1 });

  if (isError) {
    return <Navigate to={ROUTES.onboarding} replace />;
  }

  if (!payments) {
    return null;
  }

  if (payments.length > 0) {
    return <Navigate to={ROUTES.payments} replace />;
  }

  return <Navigate to={ROUTES.onboarding} replace />;
};
