import { useCallback, useMemo } from 'react';
import { CURRENCY, Currency, Option } from '@/types';

type Props = {
  getLabel: (currency: Currency) => string;
  labels: Record<Currency, string>;
  toOptions: (currencies: Currency[]) => Option[];
  options: Option[];
};

export const useCurrencyLabels = (): Props => {
  const labels: Props['labels'] = useMemo(
    () => ({
      [CURRENCY.AED]: 'AED',
      [CURRENCY.BHD]: 'BHD',
      [CURRENCY.CNH]: 'CNH',
      [CURRENCY.EGP]: 'EGP',
      [CURRENCY.EUR]: 'EUR',
      [CURRENCY.GBP]: 'GBP',
      [CURRENCY.INR]: 'INR',
      [CURRENCY.KRW]: 'KRW',
      [CURRENCY.KWD]: 'KWD',
      [CURRENCY.OMR]: 'OMR',
      [CURRENCY.QAR]: 'QAR',
      [CURRENCY.SAR]: 'SAR',
      [CURRENCY.TRY]: 'TRY',
      [CURRENCY.USD]: 'USD'
    }),
    []
  );

  const getLabel: Props['getLabel'] = useCallback(
    (currency) => {
      return labels[currency] || currency;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const options: Props['options'] = useMemo(
    () => toOptions(Object.values(CURRENCY)).sort((a, b) => a.label.localeCompare(b.label)),
    [toOptions]
  );

  return { labels, getLabel, options, toOptions };
};
