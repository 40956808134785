import scrollIntoView from 'scroll-into-view-if-needed';

export const scrollToClosestElement = ({
  target,
  closest
}: {
  target: string;
  closest: string;
}) => {
  setTimeout(() => {
    const el = document.querySelectorAll(target)?.[0]?.closest(closest);

    if (el) {
      scrollIntoView(el, { scrollMode: 'if-needed', block: 'nearest', inline: 'nearest' });
    }
  }, 0);
};

export const virtualDownloadClick = (downloadUrl: string, target?: '_blank') => {
  const el = document.createElement('a');

  el.setAttribute('href', downloadUrl);
  el.setAttribute('download', 'true');

  if (target) {
    el.setAttribute('target', target);
  }

  el.click();
  el.remove();
};

export const getCookies = (cookie = document.cookie) => {
  try {
    return cookie.split(';').reduce<Record<string, string>>((acc, pair) => {
      const [key, ...arr] = pair.trim().split('=');
      const value = decodeURIComponent(arr.join('='));

      if (key && value) {
        acc[key] = value;
      }

      return acc;
    }, {});
  } catch {
    return {
      error: 'failed to parse cookies'
    };
  }
};
