import { styled } from '@mui/material/styles';
import Edit24 from '@tabby.ai/tabby-ui/dist/icons/core/Edit24';
import { Text } from '@/ui-kit/Text';
import { useTranslation } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { LoanStatusBadge } from './LoanStatusBadge';

const ApprovedSection = styled('div')({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '16px',
  background: '#EDFAF1'
});

const ApprovedHint = styled(Text)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.positiveA
}));

export const ApprovedStatus = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();

  return (
    <ApprovedSection>
      {isMobile ? (
        <Edit24 color="B2B2023.accent.positiveA" />
      ) : (
        <LoanStatusBadge status="approved" />
      )}
      <ApprovedHint variant="body2Tight500">
        {t('sign_the_contract_we_sent_to_your_email')}
      </ApprovedHint>
    </ApprovedSection>
  );
};
