import { useMeQuery } from '@/store/account-me';
import { ROUTES } from '@/routes';
import { Navigate } from 'react-router-dom';
import { useIsAuthorized } from '@/store/auth';
import { LoaderDots } from '@/components/LoaderDots';

import { RedirectToOnboarding } from './RedirectToOnboarding';

export const RedirectByPermissions = () => {
  const isAuth = useIsAuthorized();
  const { data: me } = useMeQuery();

  if (!isAuth) {
    return <Navigate to={ROUTES.auth} replace />;
  }

  if (!me) {
    return <LoaderDots height="100vh" />;
  }

  const { canViewApplication, canViewOnboarding, canViewOrders } = me.computedPermissions;

  if (canViewApplication) {
    return <Navigate to={ROUTES.applicationForm} replace />;
  }

  if (canViewOnboarding) {
    return <RedirectToOnboarding />;
  }

  if (canViewOrders) {
    return <Navigate to={ROUTES.payments} replace />;
  }

  return <Navigate to={ROUTES.account} replace />;
};
