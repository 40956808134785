import { useTranslation } from 'react-i18next';
import { NOTIFICATION_VARIANTS, useShowNotification } from '@/components/Notifications';
import { delay } from '@/utils/promise';
import { formatCurrencyAmount } from '@/utils/currency';
import { useCapturePaymentMutation, useClosePaymentMutation, useGetPaymentQuery } from './api';
import { INVALIDATE_TIMEOUT } from '../constants';

export const useCaptureAndClosePayment = (paymentId: string) => {
  const { t } = useTranslation();
  const { data: payment, refetch } = useGetPaymentQuery(paymentId);
  const [triggerCapturePayment, { isLoading: isCaptureLoading }] = useCapturePaymentMutation({
    fixedCacheKey: paymentId
  });
  const [triggerClosePayment, { isLoading: isCloseLoading }] = useClosePaymentMutation();
  const showNotification = useShowNotification();

  const captureAndClosePayment = async (amount: string, onSuccessCb: () => void) => {
    try {
      await triggerCapturePayment({ id: paymentId, amount }).unwrap();
      showNotification({
        variant: NOTIFICATION_VARIANTS.Success,
        message: t('successfully_captured_amount', {
          amount: formatCurrencyAmount(amount, payment?.currency)
        })
      });
      await triggerClosePayment(paymentId).unwrap();
      showNotification({
        variant: NOTIFICATION_VARIANTS.Success,
        message: t('successfully_closed_payment')
      });
      onSuccessCb?.();
      await delay(INVALIDATE_TIMEOUT);
      refetch();
    } catch {
      showNotification({
        variant: NOTIFICATION_VARIANTS.Error,
        message: t('something_went_wrong_please_try_again_later')
      });
    }
  };

  return { captureAndClosePayment, isCaptureAndCloseLoading: isCaptureLoading || isCloseLoading };
};
