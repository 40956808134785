import {
  FormLabel as TuiFormLabel,
  type FormLabelProps as TuiFormLabelProps,
  TuiFormLabelClasses
} from '@tabby.ai/tabby-ui/dist/core';
import { formLabelClasses } from '@mui/material';

type Props = Omit<TuiFormLabelProps, 'ds'>;

export const FormLabel = ({ sx, children, ...rest }: Props) => {
  return (
    <TuiFormLabel
      ds="B2B2023"
      sx={{
        [`&.${formLabelClasses.root}.${TuiFormLabelClasses.forTextField}`]: {
          ...sx,
          marginBottom: '0px'
        }
      }}
      {...rest}
    >
      {children}
    </TuiFormLabel>
  );
};
