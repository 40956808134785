import { styled } from '@mui/material/styles';

import { LoanOffer } from '@/store/offers';
import { AboutWidget } from './AboutWidget';
import { FormProvider } from './FormProvider';
import { Header } from './Header';
import { Offer } from './Offer';
import { useFormLegalEntities } from '../hooks/useFormLegalEntities';

const Root = styled('div')(({ theme }) => ({
  padding: '12px 0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.down('p768')]: {
    padding: '0',
    width: '100%'
  }
}));

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: '520px',
  borderRadius: '24px',
  overflow: 'hidden',
  backgroundColor: theme.palette.common.white,

  [theme.breakpoints.down('p768')]: {
    width: '100%'
  }
}));

const Panel = styled('div')(({ theme }) => ({
  maxWidth: '520px',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  borderRadius: '24px',
  background: theme.palette.B2B2023.back.major,

  [theme.breakpoints.down('p768')]: {
    gap: '12px',
    width: '100%'
  }
}));

export const WelcomeScreen = ({ offer }: { offer: LoanOffer }) => {
  const { legalEntities: legalEntitiesData = [], isLoading } = useFormLegalEntities();

  if (legalEntitiesData?.length === 0) {
    return null;
  }

  return (
    <FormProvider legalEntities={legalEntitiesData} offer={offer} isLoading={isLoading}>
      <Root>
        <Wrapper>
          <Header />
          <Offer />
        </Wrapper>
        <Panel>
          <AboutWidget />
        </Panel>
      </Root>
    </FormProvider>
  );
};
