import { rootApi } from '../rootApi/api';
import {
  AuthenticateParams,
  AuthenticateResponse,
  ChallengeVerifyResponse,
  AuthenticateVerifyResponse,
  OTPCodeParams,
  EmailOrPhoneParams
} from './types';
import { getSessionId, removeSessionId, setSessionId } from './utils';

const authApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    logout: build.mutation<void, void>({
      query: () => ({
        url: '/merchant/api/v1/auth/logout',
        method: 'POST',
        keepalive: true
      })
    }),
    authenticate: build.mutation<AuthenticateResponse, AuthenticateParams>({
      query: (body) => ({
        url: '/merchant/api/v2/auth/authenticate',
        method: 'POST',
        body
      }),
      onCacheEntryAdded: async (args, api) => {
        try {
          const res = await api.cacheDataLoaded;
          setSessionId(res.data.session_id);
        } catch (error) {
          // skip
        }
      }
    }),
    resendSignInCode: build.mutation<AuthenticateResponse, AuthenticateParams>({
      query: (params) => ({
        url: '/merchant/api/v2/auth/authenticate',
        method: 'POST',
        body: {
          session_id: getSessionId(),
          ...params
        }
      }),
      onCacheEntryAdded: async (args, api) => {
        try {
          const res = await api.cacheDataLoaded;
          setSessionId(res.data.session_id);
        } catch (error) {
          // skip
        }
      }
    }),
    authenticateVerify: build.mutation<AuthenticateVerifyResponse, OTPCodeParams>({
      query: (params) => ({
        url: '/merchant/api/v2/auth/verify',
        method: 'POST',
        body: {
          session_id: getSessionId(),
          ...params
        }
      }),
      onCacheEntryAdded: async (args, api) => {
        const res = await api.cacheDataLoaded;
        if (res.data.status === 'authenticated') {
          removeSessionId();
        }
      }
    }),
    resendChallengeOTP: build.mutation<void, void>({
      query: () => ({
        url: '/merchant/api/v2/auth/challenge',
        method: 'POST',
        body: {
          session_id: getSessionId()
        }
      })
    }),
    challengeSetup: build.mutation<void, EmailOrPhoneParams>({
      query: (params) => ({
        url: '/merchant/api/v2/auth/challenge/setup',
        method: 'POST',
        body: {
          session_id: getSessionId(),
          ...params
        }
      })
    }),
    challengeVerify: build.mutation<ChallengeVerifyResponse, OTPCodeParams>({
      query: (params) => ({
        url: '/merchant/api/v2/auth/challenge/verify',
        method: 'POST',
        body: {
          session_id: getSessionId(),
          ...params
        }
      }),
      onCacheEntryAdded: async (args, api) => {
        try {
          await api.cacheDataLoaded;
          removeSessionId();
        } catch (error) {
          // skip
        }
      }
    })
  })
});

export const {
  useAuthenticateMutation,
  useAuthenticateVerifyMutation,
  useChallengeSetupMutation,
  useChallengeVerifyMutation,
  useLogoutMutation,
  useResendChallengeOTPMutation,
  useResendSignInCodeMutation
} = authApi;
