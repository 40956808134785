import { DIAL_CODES, DialCode } from '@/lib/phones';
import { Text } from '@/ui-kit/Text';
import { SxProps, styled, Theme } from '@mui/material/styles';
import circleFlagAreImageUrl from '@/assets/icons/circle-flag-are.svg?url';
import circleFlagBhrImageUrl from '@/assets/icons/circle-flag-bhr.svg?url';
import circleFlagEgyImageUrl from '@/assets/icons/circle-flag-egy.svg?url';
import circleFlagKwtImageUrl from '@/assets/icons/circle-flag-kwt.svg?url';
import circleFlagQatImageUrl from '@/assets/icons/circle-flag-qat.svg?url';
import circleFlagSauImageUrl from '@/assets/icons/circle-flag-sau.svg?url';
import circleFlagJorImageUrl from '@/assets/icons/circle-flag-jor.svg?url';

const Root = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
}));

const CountryIcon = styled('div', { shouldForwardProp: (p) => p !== 'dialCode' })<{
  dialCode: DialCode;
}>(({ theme, dialCode }) => {
  const backgroundUrl: string = {
    [DIAL_CODES.ARE]: circleFlagAreImageUrl,
    [DIAL_CODES.BHR]: circleFlagBhrImageUrl,
    [DIAL_CODES.EGY]: circleFlagEgyImageUrl,
    [DIAL_CODES.KWT]: circleFlagKwtImageUrl,
    [DIAL_CODES.QAT]: circleFlagQatImageUrl,
    [DIAL_CODES.SAU]: circleFlagSauImageUrl,
    [DIAL_CODES.JOR]: circleFlagJorImageUrl
  }[dialCode];

  return {
    height: '28px',
    width: '28px',
    borderRadius: '50%',
    overflow: 'hidden',
    padding: '2px',
    border: `1px solid ${theme.palette.B2B2023.front.line}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: `url(${backgroundUrl})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  };
});

const TextCode = styled(Text)`
  /* @noflip */
  direction: ltr;
`;

type Props = {
  sx?: SxProps<Theme>;
  value: DialCode;
};

export const IconDialCode = ({ sx, value }: Props) => {
  return (
    <Root sx={sx}>
      <CountryIcon className="CountryIcon" dialCode={value} />
      <TextCode variant="body2Tight500">{value}</TextCode>
    </Root>
  );
};
