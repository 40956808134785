import { useLazyValidateURLQuery } from '@/store/validators';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { stringSchema } from '@/lib/yup';
import { Events, useAnalyticsTrack } from '@/store/analytics';

export const useURLValidation = () => {
  const { t } = useTranslation();
  const track = useAnalyticsTrack();
  const [getValidation, { isFetching }] = useLazyValidateURLQuery();
  const baseSchema = useMemo(
    () => stringSchema.url(t('please_enter_a_valid_website_link_starting_with_https')),
    [t]
  );

  const schema = useCallback(() => {
    return baseSchema.test({
      name: 'is-url-exists',
      test: async (value) => {
        if (!value || !baseSchema.isValidSync(value)) {
          return true;
        }

        try {
          const result = await getValidation(value).unwrap();
          const { is_valid } = result;

          if (!is_valid) {
            track({ event: Events.WebsiteURLCheckFailed, params: { url: value, is_valid } });
          }

          return true;
        } catch (err) {
          return true;
        }
      }
    });
  }, [baseSchema, getValidation, track]);

  return { schema, isFetching };
};
