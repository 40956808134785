import {
  ApplicationFormStepName,
  ApplicationV3,
  useGetApplicationV3Query
} from '@/store/application';
import { LoaderDots } from '@/components/LoaderDots';
import { useMeQuery } from '@/store/account-me';
import { useMemo } from 'react';
import { FormStateProvider } from './FormStateProvider';
import { FormProvider } from './FormProvider';
import { FormContent } from './components/FormContent';

export const ApplicationForm = ({ stepName }: { stepName: ApplicationFormStepName }) => {
  const { data: me, isLoading: isMeLoading } = useMeQuery();
  const { data: applicationV3, isLoading: isApplicationV3Loading } = useGetApplicationV3Query();

  const initialData: Partial<ApplicationV3> = useMemo(
    () => ({
      ...applicationV3,
      email: applicationV3?.email || me?.account?.email || '',
      phone: applicationV3?.phone || me?.account?.phone || ''
    }),
    [applicationV3, me?.account?.email, me?.account?.phone]
  );

  if (isApplicationV3Loading || isMeLoading) return <LoaderDots />;

  return (
    <FormProvider initialData={initialData}>
      <FormStateProvider initialData={initialData} currentStepName={stepName}>
        <FormContent />
      </FormStateProvider>
    </FormProvider>
  );
};
