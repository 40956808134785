import { styled } from '@mui/material';

export const StepLine = styled('div', {
  shouldForwardProp: (prop) => prop !== 'lineColor'
})<{
  lineColor: string;
}>(({ lineColor }) => {
  return {
    width: '100%',
    height: '2px',
    borderRadius: '1px',
    background: lineColor,
    marginBottom: '8px'
  };
});
