import { LoanOffer } from '@/store/offers';
import { ReactNode, createContext } from 'react';
import { useFormState } from '../hooks/useFormState';
import { FormState } from '../types';

export const FormStateContext = createContext({} as FormState);

type Props = {
  children: ReactNode;
  offer: LoanOffer;
};

export const FormStateProvider = ({ children, ...rest }: Props) => {
  const state = useFormState(rest);
  return <FormStateContext.Provider value={state}>{children}</FormStateContext.Provider>;
};
