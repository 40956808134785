import { ErrorPage } from '@/components/ErrorPage';
import { LoaderDots } from '@/components/LoaderDots';
import { Breadcrumbs, PageLayout } from '@/components/PageLayout';
import { ROUTES } from '@/routes';
import { useGetOffersQuery } from '@/store/offers';
import { styled } from '@mui/material/styles';
import { find } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { WelcomeScreen } from './components/WelcomeScreen';

const PageContent = styled(PageLayout.Content)(({ theme }) => ({
  padding: '12px',
  alignItems: 'center',

  [theme.breakpoints.down('p768')]: {
    padding: '0px'
  }
}));

export const OfferPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();

  const { data: offers = [], isLoading: isLoadingOffers } = useGetOffersQuery();

  const defaultOffer = find(offers, (l) => l.id === id);

  const breadcrumbs = useMemo(() => {
    return [
      {
        id: 'tabby_capital',
        href: generatePath(ROUTES.financeTabbyCapital),
        label: t('tabby_capital')
      },
      {
        id: 'application',
        href: '',
        label: t('new_offer')
      }
    ];
  }, [t]);

  if (isLoadingOffers) return <LoaderDots height="100vh" />;

  if (!defaultOffer) {
    return <ErrorPage status={404} />;
  }

  return (
    <>
      <PageLayout.Header customBreadcrumbs={<Breadcrumbs crumbs={breadcrumbs} />} />
      <PageContent>
        <WelcomeScreen offer={defaultOffer} />
      </PageContent>
    </>
  );
};
