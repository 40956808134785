import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LegalEntityWithMerchantId } from '@/store/loan';
import { uniqBy } from 'lodash';
import { useFormStateContext } from './useFormStateContext';

export const useLegalEntityOptions = () => {
  const { t } = useTranslation();
  const { legalEntities } = useFormStateContext();

  const getSelectOption = useCallback(
    ({
      id,
      legal_company_name,
      iban,
      commercial_registration_number,
      legal_address
    }: LegalEntityWithMerchantId) => ({
      value: id,
      label: legal_company_name || `${t('iban')} - ${iban}`,
      iban: legal_company_name ? `${t('iban')} - ${iban}` : '',
      licenseNumber: commercial_registration_number
        ? `${t('license_number')} - ${commercial_registration_number}`
        : '',
      address: legal_address ? `${t('address')} - ${legal_address}` : ''
    }),
    [t]
  );

  return useMemo(() => {
    const uniqLegalEntities = uniqBy(legalEntities, (it) => it);
    return uniqLegalEntities?.map((legalEntity) => getSelectOption({ ...legalEntity }));
  }, [getSelectOption, legalEntities]);
};
