import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { MIN_WIDTH_PX, TABBY_MARKETPLACE_URL_AR, TABBY_MARKETPLACE_URL_EN } from '../constants';
import { LogoHeader } from './LogoHeader';

const Root = styled('div')(({ theme }) => ({
  width: '348px',
  [theme.breakpoints.down('p768')]: {
    width: `${MIN_WIDTH_PX}px`
  }
}));

const FooterText = styled('div')(({ theme }) => ({
  marginTop: '16px',
  textAlign: 'center',
  ...deprecatedTypographyStyles[theme.direction]._body2Loose500,
  color: theme.palette.B2B2023.front.minor
}));

const Link = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.B2B2023.accent.highlightA,
  fontWeight: 500
}));

type Props = {
  children: JSX.Element;
};

export const SignIn = ({ children }: Props) => {
  const { t } = useTranslation();
  const lang = useCurrentLanguage();
  const url = {
    en: TABBY_MARKETPLACE_URL_EN,
    ar: TABBY_MARKETPLACE_URL_AR
  }[lang];

  return (
    <Root>
      <LogoHeader sx={{ padding: '28px 16px 16px', marginBottom: '16px' }} />
      {children}
      <FooterText>
        {t('looking_to_use_tabby_as_a_shopper')}
        <br />
        <Trans
          i18nKey="visit_our_marketplace"
          components={{ a: <Link href={url} target="_blank" rel="no-refferer" /> }}
        />
      </FooterText>
    </Root>
  );
};
