import { ReactNode } from 'react';
import { paletteJun24 } from '@tabby.ai/tabby-ui/dist/deprecated/theme/palette/paletteJun24';
import { breakpointsJune24 } from '@tabby.ai/tabby-ui/dist/deprecated/theme/breakpoints/breakpoints';
import { BaseThemeProvider, DsProvider } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { themeOptions } from '@/theme/themeOptions';
import { useTranslation } from 'react-i18next';
import { LANGUAGE } from '@/types';

interface Props {
  children: ReactNode;
}

export const ThemeProvider = ({ children }: Props) => {
  const { i18n } = useTranslation();

  return (
    <BaseThemeProvider
      themeOptions={{
        ...themeOptions,
        palette: paletteJun24,
        breakpoints: breakpointsJune24
      }}
      dir={i18n.language === LANGUAGE.ar ? 'rtl' : 'ltr'}
    >
      <DsProvider ds={DS.B2B2022}>{children}</DsProvider>
    </BaseThemeProvider>
  );
};
