export const downloadBlobAsFile = (blob: Blob, filename: string) => {
  const urlObject = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', urlObject);
  link.setAttribute('download', filename);
  link.click();

  link.remove();
  window.URL.revokeObjectURL(urlObject);
};

export const convertBase64StringIntoFile = (
  params: { data: string; mime_type: string; name: string } | void
) => {
  if (!params) return null;

  const { data, mime_type, name } = params;

  try {
    const byteString = window.atob(data);
    const buffer = new ArrayBuffer(byteString.length);
    const arr = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i += 1) {
      arr[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: mime_type });
    const file = new File([blob], name, { type: mime_type, lastModified: Date.now() });

    return file;
  } catch (error) {
    return null;
  }
};

export const isFileTypeValid = (file: File, mimeTypes: string | string[]) => {
  return typeof mimeTypes === 'string' ? file.type === mimeTypes : mimeTypes.includes(file.type);
};

export const downloadByUrl = (url: string, filename: string) => {
  const link = document.createElement('a');

  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.click();

  link.remove();
};
