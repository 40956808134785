import { TabbyBusinessLogo } from '@/ui-kit/TabbyBusinessLogo';
import { SxProps, Theme, styled } from '@mui/material/styles';
import ArrowBack24 from '@tabby.ai/tabby-ui/dist/icons/core/ArrowBack24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';

const Root = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  alignItems: 'center',
  gap: '8px'
}));

const Arrow = styled('div')(() => ({
  cursor: 'pointer',
  marginRight: 'auto'
}));

type Props = {
  sx?: SxProps<Theme>;
  onGoBack?: VoidFunction;
};

export const LogoHeader = ({ sx, onGoBack }: Props) => {
  return (
    <Root sx={sx}>
      <Arrow sx={{ visibility: onGoBack ? 'visible' : 'hidden' }} onClick={onGoBack}>
        <ArrowBack24 ds={DS.B2B2023} color="B2B2023.front.minor" />
      </Arrow>
      <TabbyBusinessLogo />
    </Root>
  );
};
