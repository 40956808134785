import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalyticsTrack } from '@/store/analytics';
import { PageLayout, Breadcrumbs } from '@/components/PageLayout';
import { FakeDoor } from './components/FakeDoor';

export const FakeDoorPage = () => {
  const { t } = useTranslation();
  const track = useAnalyticsTrack();

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'tabby_capital',
        href: '',
        label: t('tabby_capital')
      }
    ],
    [t]
  );

  useEffect(() => {
    track({
      event: 'Capital Fake Door Opened'
    });
  }, [track]);

  return (
    <>
      <PageLayout.Header customBreadcrumbs={<Breadcrumbs crumbs={breadcrumbs} />} />
      <PageLayout.Content>
        <FakeDoor />
      </PageLayout.Content>
    </>
  );
};
