import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

export const useSearchParamsLanguage = () => {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const lang = searchParams.get('lang');
    let timer: NodeJS.Timeout;

    if (lang === 'ar' || lang === 'en') {
      timer = setTimeout(() => {
        i18n.changeLanguage(lang);
        // using window.location.pathname so that during other redirects, we can get the actual value
        navigate(window.location.pathname, { replace: true });
      }, 0);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [i18n, navigate, searchParams, setSearchParams]);
};
