import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import phones, { DialCode, sortedDialCodes } from '@/lib/phones';
import { TestId } from '@/lib/autotests';
import { Select, SelectProps } from '@/ui-kit/Select';
import { TextField, TextFieldProps } from '@/ui-kit/TextField';
import { FormLabel } from '@/ui-kit/FormLabel';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import cleaner from '@/lib/cleaner';
import { IconDialCode } from './components/IconDialCode';

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const Inputs = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '8px'
}));

export type Props = Pick<TextFieldProps, 'inputRef' | 'helperText'> & {
  disabled?: boolean;
  error?: boolean;
  label?: string;
  onBlur?: VoidFunction;
  onChange?: (value: string) => void;
  testId?: TestId;
  value: string;
  size?: 'small' | 'medium';
  autoFocus?: boolean;
  allowOnlyDialCode?: boolean;
};

export const InputPhoneCountry = ({
  disabled,
  error,
  helperText,
  inputRef,
  label,
  onBlur,
  onChange,
  testId,
  value,
  size = 'medium',
  autoFocus,
  allowOnlyDialCode
}: Props) => {
  const { t, i18n } = useTranslation();
  const cfg = phones.getConfigByPhone(value);
  const [dialCode, setDialCode] = useState<DialCode>(cfg.dialCode);
  const [phone, setPhone] = useState<string>(phones.getPhoneWithoutDialCode(value));

  useEffect(() => {
    if (allowOnlyDialCode) {
      onChange?.(`${dialCode}${phone}`);
    } else {
      onChange?.(phone ? `${dialCode}${phone}` : '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialCode, phone]);

  const handleChangeDialCode: SelectProps['onChange'] = (event) => {
    setDialCode(event.target.value as DialCode);
  };

  const handlePhoneChange: TextFieldProps['onChange'] = (event) => {
    setPhone(cleaner.parse(event.target.value, { keep: ['numbers'] }));
  };

  const inputsJsx = (
    <Inputs>
      <Box sx={{ width: '116px' }}>
        <Select
          name="code"
          placeholder={t('country_region')}
          value={dialCode}
          onChange={handleChangeDialCode}
          disabled={disabled}
          testId={testId}
          required
          size={size}
          renderValue={(option) => {
            if (!option) return null;
            return (
              <IconDialCode
                sx={{
                  height: '100%',
                  '& > .CountryIcon': {
                    border: 'none'
                  }
                }}
                value={option as DialCode}
              />
            );
          }}
          options={sortedDialCodes.map((code) => ({
            label: code,
            value: code,
            customHtml: <IconDialCode value={code} />
          }))}
        />
      </Box>
      <Box sx={{ flex: 1 }}>
        <TextField
          sx={{
            '& input': {
              direction: 'ltr /* @noflip */',
              textAlign: i18n.dir() === 'ltr' ? 'start' : 'end'
            }
          }}
          inputRef={inputRef}
          fullWidth
          name="phone"
          type="tel"
          placeholder={t('phone')}
          value={phone}
          onChange={handlePhoneChange}
          error={error}
          helperText={error ? helperText : undefined}
          onBlur={onBlur}
          size={size}
          inputProps={{
            maxLength: cfg.maxLength,
            type: 'tel',
            autoComplete: 'off',
            'data-1p-ignore': true
          }}
          disabled={disabled}
          testId={testId}
          autoFocus={autoFocus}
        />
      </Box>
    </Inputs>
  );

  return label ? (
    <Column>
      <FormLabel variant="TextField" color="primary">
        {label}
      </FormLabel>
      {inputsJsx}
    </Column>
  ) : (
    inputsJsx
  );
};
