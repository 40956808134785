import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import { v4 as uuidv4 } from 'uuid';

export const uuid = () => uuidv4();

export const prefixLS = (str: string) => `tby:md:${str}`;

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const upperCamelCase = (str: string) => capitalize(camelCase(str));

export const kebabToSnakeCase = (str: string) => kebabCase(str).replace(/-+/g, '_');

export const normalize = (str: string) => {
  return str.trim().toLowerCase().replace(/ +/g, '');
};

export const partialMatch = (a: string, b: string, format = normalize) => {
  return format(a).includes(format(b));
};

export const snakeCaseToText = (str: string, format = capitalize) =>
  format(str).replace(/_+/g, ' ');

export const containsArabic = (text: string) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(text);
};

export const toPercentage = (value: string) => {
  const valueToNumber = Number(value);
  if (Number.isNaN(valueToNumber)) {
    return '';
  }

  return `${parseFloat((valueToNumber * 100).toFixed(2))}%`;
};
