import { rootApi } from '../rootApi/api';
import { CRSAUValidationResponse, IBANValidationResponse, URLValidationResponse } from './types';

export const validatorsApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    validateCRNumberSAU: build.query<CRSAUValidationResponse, string>({
      query: (value) => `/partners/application/api/v1/commercial-registration/${value}`
    }),
    validateIBAN: build.query<IBANValidationResponse, string>({
      query: (value) => `/partners/application/api/v1/iban/${value}`
    }),
    validateURL: build.query<URLValidationResponse, string>({
      query: (url) => `/partners/application/api/v1/url/response/${encodeURIComponent(url)}`
    })
  })
});

export const {
  useLazyValidateCRNumberSAUQuery,
  useLazyValidateIBANQuery,
  useLazyValidateURLQuery
} = validatorsApi;
