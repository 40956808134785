import { LoanOffer, useGetOffersQuery } from '@/store/offers';
import { find, sortBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { ROUTES } from '@/routes';
import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { useFormLegalEntities } from './useFormLegalEntities';
import { useFormSubmit } from './useFormSubmit';
import { useFormPropsContext } from './useFormPropsContext';

export const useFormState = ({ offer }: { offer: LoanOffer }) => {
  const [selectedOffer, setSelectedOffer] = useState<LoanOffer | null>();
  const { id } = useParams<{ id?: string }>();

  const navigate = useNavigate();

  const { submit } = useFormSubmit();
  const { watch } = useFormPropsContext();

  const { data: offers = [], isLoading: isLoadingOffers } = useGetOffersQuery();
  const { legalEntities: legalEntitiesData = [], isLoading: isLoadingLegalEntity } =
    useFormLegalEntities();

  const selectedLegalEntity = watch('legalEntityId');

  useEffect(() => {
    setSelectedOffer(null);
  }, [selectedLegalEntity]);

  const filteredOffersByMerchantId = useMemo(() => {
    const selectedMerchant = find(legalEntitiesData, (l) => l.id === selectedLegalEntity);
    const filterdOffers = offers.filter((o) =>
      selectedMerchant ? o.merchant_id === selectedMerchant.merchant_id : true
    );

    return sortBy(filterdOffers, (o) => Number(o.tenor));
  }, [selectedLegalEntity, legalEntitiesData, offers]);

  useEffect(() => {
    if (filteredOffersByMerchantId.length > 0) {
      const defaultOffer = find(filteredOffersByMerchantId, (o) => Number(o.tenor) === 20);
      const currentOffer = find(filteredOffersByMerchantId, (o) => o.id === id);

      if (currentOffer && currentOffer.id !== defaultOffer?.id) {
        setSelectedOffer(currentOffer);
      } else if (defaultOffer && !selectedOffer) {
        setSelectedOffer(defaultOffer);
      }
    }
  }, [filteredOffersByMerchantId, selectedOffer, id]);

  const handleSelectOffer = (item: LoanOffer) => {
    setSelectedOffer(item);

    navigate(
      generatePath(ROUTES.financeTabbyCapitalOffersId, {
        id: item.id
      }),
      { replace: true }
    );
  };

  return {
    isLoading: isLoadingOffers || isLoadingLegalEntity,
    legalEntities: legalEntitiesData,
    offer,
    offers: filteredOffersByMerchantId,
    selectedOffer,
    submit,
    onSelectOffer: handleSelectOffer
  };
};
