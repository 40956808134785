import { NOTIFICATION_VARIANTS, useShowNotification } from '@/components/Notifications';
import { useTranslation } from 'react-i18next';
import { delay } from '@/utils/promise';
import { PaymentEditParams } from '../types';
import { useEditPaymentMutation, useGetPaymentQuery } from './api';
import { INVALIDATE_TIMEOUT } from '../constants';

export const useEditPayment = (paymentId: string) => {
  const { t } = useTranslation();
  const [triggerEditPayment, { status }] = useEditPaymentMutation();
  const { refetch } = useGetPaymentQuery(paymentId);
  const showNotification = useShowNotification();

  const editPayment = async (payment: PaymentEditParams['payment']) => {
    try {
      await triggerEditPayment({ id: paymentId, payment }).unwrap();
      await delay(INVALIDATE_TIMEOUT);
      refetch();
    } catch {
      showNotification({
        variant: NOTIFICATION_VARIANTS.Error,
        message: t('failed_to_edit_payment_please_try_again_later')
      });
    }
  };

  return { editPayment, status };
};
