import { useCallback } from 'react';
import { uuid } from '@/utils/string';
import { useNotificationsContext } from '../NotificationsProvider';
import { Notification } from '../types';

export const useShowNotification = () => {
  const { setNotifications } = useNotificationsContext();

  return useCallback(
    (notification: Omit<Notification, 'id'>) => {
      setNotifications((prev) =>
        prev.concat([
          {
            id: uuid(),
            autoHide: true,
            ...notification
          }
        ])
      );
    },
    [setNotifications]
  );
};
