import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap as GoogleMapBase, useJsApiLoader, Marker } from '@react-google-maps/api';
import { styled } from '@mui/material/styles';
import PinSvg from '@/assets/icons/pin.svg';
import { Coordinates } from './types';
import { DEFAULT_LOCATION_DUBAI } from './constants';
import { GoogleMapSearchSkeleton } from './GoogleMapSearchSkeleton';

const PinSvgColored = styled(PinSvg)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.negativeA
}));

const Root = styled('div')({
  height: '100%',
  width: '100%'
});

type GoogleMapProps = {
  lat: number;
  lng: number;
  updateMarkerPosition: (latLng: Coordinates) => void;
  setIsMapLoaded: VoidFunction;
  disabled?: boolean;
};

export const GoogleMap = ({
  lat,
  lng,
  updateMarkerPosition,
  setIsMapLoaded,
  disabled
}: GoogleMapProps) => {
  const [mapCenter, setMapCenter] = useState<Coordinates>({ lat, lng });
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.GOOGLE_MAPS_API_KEY,
    libraries: ['places']
  });

  const onLoad = useCallback(
    (newMap: google.maps.Map) => {
      setMap(newMap);
      setIsMapLoaded();
    },
    [setIsMapLoaded]
  );

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleOnClick = (e: google.maps.MapMouseEvent) => {
    if (!disabled) {
      const latLng = {
        lat: e.latLng?.lat() || DEFAULT_LOCATION_DUBAI.lat,
        lng: e.latLng?.lng() || DEFAULT_LOCATION_DUBAI.lng
      };
      setMapCenter(latLng);
      updateMarkerPosition(latLng);
    }
  };

  useEffect(() => {
    if (map) {
      map.setCenter({ lat, lng });
      setMapCenter({ lat, lng });
    }
  }, [lat, lng, map]);

  if (!isLoaded) {
    return <GoogleMapSearchSkeleton />;
  }

  return (
    <Root>
      <GoogleMapBase
        center={mapCenter}
        zoom={15}
        options={{
          fullscreenControl: false,
          mapTypeControl: false,
          streetViewControl: false
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={{ width: '100%', height: '100%', borderRadius: '12px' }}
        onClick={handleOnClick}
      >
        <Marker
          position={mapCenter}
          icon={{
            url: PinSvgColored as unknown as string,
            scaledSize: new google.maps.Size(40, 40)
          }}
        />
      </GoogleMapBase>
    </Root>
  );
};
