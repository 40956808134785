export const MIN_WIDTH_PX = 336;

export const QR_CODE_SIZE_PX = 162;

export const TABBY_BUSINESS_QR_CODE_URL = 'https://tabby-merchant.onelink.me/0rVZ/open';

export const TABBY_BUSINESS_APP_STORE_URL = 'https://apple.co/3AjmwMN';

export const TABBY_BUSINESS_GOOGLE_PLAY_URL =
  'https://play.google.com/store/apps/details?id=app.tabby.cashier';

export const TABBY_BUSINESS_APP_GALLERY_URL = 'https://url.cloud.huawei.com/rdHEPnAttm';

export const TABBY_MARKETPLACE_URL_EN = 'https://tabby.ai/en-SA';

export const TABBY_MARKETPLACE_URL_AR = 'https://tabby.ai/ar-SA';

export const TERMS_URL_EN = 'https://tabby.ai/en-SA/merchant-toc';

export const TERMS_URL_AR = 'https://tabby.ai/ar-SA/merchant-toc';
