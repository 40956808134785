import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@/types';
import { APPLICATION_FORM_STEP_NAME, ApplicationFormStepName } from '@/store/application';

type Props = {
  getLabel: (stepName: ApplicationFormStepName) => string;
  labels: Record<ApplicationFormStepName, string>;
  toOptions: (stepNames: ApplicationFormStepName[]) => Option[];
  options: Option[];
};

export const useApplicationFormStepLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Record<ApplicationFormStepName, string> = useMemo(
    () => ({
      [APPLICATION_FORM_STEP_NAME.BASIC_INFO]: t('basic_info'),
      [APPLICATION_FORM_STEP_NAME.PRODUCT_CATEGORY]: t('product_category'),
      [APPLICATION_FORM_STEP_NAME.STORE_TYPE]: t('store_type'),
      [APPLICATION_FORM_STEP_NAME.LEGAL_DETAILS]: t('legal_details'),
      [APPLICATION_FORM_STEP_NAME.BANK_DETAILS]: t('bank_details')
    }),
    [t]
  );

  const getLabel = useCallback(
    (stepName: ApplicationFormStepName) => {
      return labels[stepName] || stepName;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const options: Props['options'] = useMemo(
    () => toOptions(Object.values(APPLICATION_FORM_STEP_NAME).sort((a, b) => a.localeCompare(b))),
    [toOptions]
  );

  return { labels, getLabel, toOptions, options };
};
