import { useWindowListeners } from '@/hooks/useWindowListeners';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { useUpdateApplicationV3Mutation } from '@/store/application';
import { useCallback } from 'react';

import { useFormPropsContext } from './useFormPropsContext';
import { toApplication } from '../utils';

export const useFormUpdateOnFocusLost = () => {
  const track = useAnalyticsTrack();
  const { getValues } = useFormPropsContext();
  const [update, { isLoading: isLoadingUpdate }] = useUpdateApplicationV3Mutation({});

  const updateApplication = useCallback(async () => {
    const values = getValues();
    const application = toApplication(values);

    const trackParams = {
      firstName: application.first_name,
      lastName: application.last_name,
      brandName: application.business_name,
      annualVolume: application.annual_volume,
      email: application.email,
      phone: application.phone
    };

    try {
      await update(application).unwrap();
      track({
        event: Events.ApplicationUpdated,
        params: { result: 'success', ...trackParams }
      });
    } catch (e) {
      track({
        event: Events.ApplicationUpdated,
        params: { result: 'failure', ...trackParams }
      });
    }
  }, [getValues, track, update]);

  useWindowListeners({
    onFocusLost: updateApplication,
    onBeforeunload: updateApplication
  });

  return { isLoading: isLoadingUpdate };
};
