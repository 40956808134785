import { FormControl as MuiFormControl, FormGroup } from '@mui/material';
import { CheckboxBase, CheckboxBaseProps } from '@/ui-kit/Checkbox';
import { HelperText } from '@/ui-kit/HelperText';
import { Text } from '@/ui-kit/Text';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';

export const FormControl = styled(MuiFormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const FormControlLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px',
  borderRadius: '16px',
  border: '1px solid',
  borderColor: theme.palette.B2B2023.front.line,
  backgroundColor: theme.palette.B2B2023.back.major,
  cursor: 'pointer',
  gap: '20px'
}));

const LabelRoot = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',
  width: '100%',
  alignItems: 'center'
}));

const TextWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  borderRadius: '50px',
  backgroundColor: theme.palette.B2B2023.back.minor
}));

export type EcomTypeCheckboxGroupProps = {
  value: string[];
  options: { value: string; title: string; subtitle: string; icon: ReactNode }[];
  onChange?: (value: string[]) => void;
  onBlur?: () => void;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
};

export const EcomTypeCheckboxGroup = ({
  value,
  options,
  onChange,
  onBlur,
  error,
  helperText,
  disabled
}: EcomTypeCheckboxGroupProps) => {
  const handleChange: CheckboxBaseProps['onChange'] = (event, selected) => {
    const selectedValue = event.target.value;

    if (selected) {
      onChange?.([...value, selectedValue]);
    } else {
      onChange?.(value.filter((v) => v !== selectedValue));
    }
  };

  return (
    <FormControl disabled={disabled}>
      <FormGroup sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {options.map((option) => {
          return (
            <FormControlLabel key={option.value}>
              <LabelRoot>
                <div>
                  <IconWrapper>{option.icon}</IconWrapper>
                </div>
                <TextWrapper>
                  <Text variant="h4" color="B2B2023.front.major">
                    {option.title}
                  </Text>
                  <Text variant="body1Loose500" color="B2B2023.front.minor">
                    {option.subtitle}
                  </Text>
                </TextWrapper>
              </LabelRoot>
              <CheckboxBase
                sx={{ '.MuiCheckbox-root': { padding: 0 } }}
                checked={value.includes(option.value)}
                onChange={handleChange}
                onBlur={onBlur}
                value={option.value}
              />
            </FormControlLabel>
          );
        })}
      </FormGroup>
      {helperText && <HelperText error={error && !disabled}>{helperText}</HelperText>}
    </FormControl>
  );
};
