import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TabbyProduct } from '@/types';

type Props = {
  labels: Record<TabbyProduct, string>;
  getLabel: (value: string) => string;
};

export const useTabbyProductLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Props['labels'] = useMemo(
    () => ({
      credit_card_installments: t('credit_card_installments'),
      installments: t('installments'),
      monthly_billing: t('monthly_billing'),
      pay_later: t('pay_later'),
      virtual_card_installments: t('tabby_card'),
      pay_in_full: t('pay_in_full')
    }),
    [t]
  );

  const getLabel = useCallback((value: string) => labels[value as TabbyProduct] || '', [labels]);

  return { labels, getLabel };
};
