import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { ECOM_TYPE, ecomPlatformOptions } from '@/store/application';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { TEST_IDS } from '@/lib/autotests';
import { useEffect } from 'react';
import Globe24 from '@tabby.ai/tabby-ui/dist/icons/core/Globe24';
import Store24 from '@tabby.ai/tabby-ui/dist/icons/core/Store24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { SelectControlled } from '@/ui-kit/Select';
import { LocationFields } from './LocationsFields';
import { EcomTypeCheckboxGroupControlled } from './EcomTypeCheckboxGroup';
import { FormSection } from './FormSection';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';

const StoreType = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormPropsContext();
  const { isFormDisabled } = useFormStateContext();
  const isOnlineEcomTypeIncluded = watch('store_type.ecommerce_types')?.includes(ECOM_TYPE.ONLINE);
  const isOfflineEcomTypeIncluded = watch('store_type.ecommerce_types')?.includes(
    ECOM_TYPE.OFFLINE
  );

  useEffect(() => {
    track({ event: Events.StoreTypeOpened });
  }, [track]);

  return (
    <FormSection
      title={t('where_do_you_sell')}
      subtitle={t('this_helps_us_determine_the_right_solutions_for_your_needs')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <EcomTypeCheckboxGroupControlled
          control={control}
          name="store_type.ecommerce_types"
          disabled={isFormDisabled}
          options={[
            {
              value: ECOM_TYPE.ONLINE,
              title: t('online'),
              subtitle: t('website_blog_social_media'),
              icon: <Globe24 dir="ltr" ds={DS.B2B2023} color="B2B2023.front.major" />
            },
            {
              value: ECOM_TYPE.OFFLINE,
              title: t('in_store'),
              subtitle: t('retail_stores_or_other_physical_locations'),
              icon: <Store24 dir="ltr" ds={DS.B2B2023} color="B2B2023.front.major" />
            }
          ]}
          analytics={{ event: Events.MerchantTypeFilled }}
          onChange={(values) => {
            if (!values.includes('offline')) {
              setValue('store_type.locations_number', null);
              setValue('store_type.locations', null);
            }
            if (!values.includes('online')) {
              setValue('store_type.ecommerce_platform', '');
            }
          }}
        />
        {isOnlineEcomTypeIncluded && (
          <SelectControlled
            control={control}
            name="store_type.ecommerce_platform"
            placeholder={t('ecommerce_platform')}
            analytics={{ event: Events.EcommercePlatformFilled }}
            testId={TEST_IDS.ApplicationEcommercePlatform}
            disabled={isFormDisabled}
            options={ecomPlatformOptions}
            size="medium"
          />
        )}
        <LocationFields />
        {(isOfflineEcomTypeIncluded || isOnlineEcomTypeIncluded) && (
          <TextFieldControlled
            control={control}
            name="store_type.web_url"
            placeholder={
              isOnlineEcomTypeIncluded ? t('website_or_social_media') : t('social_media')
            }
            helperText={
              isOfflineEcomTypeIncluded &&
              !isOnlineEcomTypeIncluded &&
              t('this_is_a_link_to_your_official_social_media')
            }
            analytics={{ event: Events.WebsiteURLFilled }}
            testId={TEST_IDS.ApplicationWebUrl}
            disabled={isFormDisabled}
            size="medium"
          />
        )}
      </Box>
    </FormSection>
  );
};

export default StoreType;
