export const numberRegExp = /\d/;

export const lowerCaseLetterRegexp = /[a-z]/;

export const upperCaseLetterRegexp = /[A-Z]/;

export const specialCharactersRegexp = /\W|_/;

export const emailEndRegex = /@.+(\..+)+$/;

export const urlWithTabbyDeepLinkRegexp =
  /(?:https?|tabbyai):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
