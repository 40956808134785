import { ReactNode } from 'react';
import { Text } from '@/ui-kit/Text';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Button } from '@/ui-kit/Button';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const SubtitleWrap = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Loose500
}));

type Props = {
  title: string;
  subtitle: ReactNode;
  changeButtonText?: string;
  onChange?: () => void;
};

export const Header = ({ title, subtitle, onChange, changeButtonText }: Props) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Text variant="h2">{title}</Text>
      <SubtitleWrap>
        <div>{subtitle}</div>
        {onChange && (
          <div>
            <Button onClick={onChange} variant="text" size="extraSmall" sx={{ padding: 0 }}>
              {changeButtonText || t('change')}
            </Button>
          </div>
        )}
      </SubtitleWrap>
    </Root>
  );
};
