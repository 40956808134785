import { AllColors } from '@tabby.ai/tabby-ui/dist/deprecated/types/allColors';
import { compose } from 'lodash/fp';
import { StepperItemVariant } from './StepperItem';

export const getVariantByFlags = (isActive: boolean, isValid: boolean): StepperItemVariant => {
  if (isActive) return 'active';
  if (isValid) return 'valid';
  return 'default';
};

export const getIconColorByVariant = (variant: StepperItemVariant): AllColors => {
  const variantToIconColor: Record<StepperItemVariant, AllColors> = {
    active: 'B2B2023.front.major',
    valid: 'B2B2023.accent.positiveA',
    default: 'B2B2023.front.disabled'
  };

  return variantToIconColor[variant];
};

export const getIconColorByFlags = compose<[boolean, boolean], StepperItemVariant, AllColors>(
  getIconColorByVariant,
  getVariantByFlags
);
