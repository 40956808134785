import { isFileMimeTypeValid } from '@/utils/file/isFileMimeTypeValid';
import { useTranslation } from 'react-i18next';
import { isFileSizeValid } from '@/utils/file';
import { useCallback } from 'react';
import { ALLOWED_FILE_EXTENTIONS, ALLOWED_MIME_TYPES, MAX_FILE_SIZE_MB } from '../constants';

export const useValidateSupportAsset = () => {
  const { t } = useTranslation();

  return useCallback(
    async (file: File) => {
      const isMimeTypeValid = await isFileMimeTypeValid(file, ALLOWED_MIME_TYPES);
      if (!isMimeTypeValid) {
        return t('must_be_a_file_of_fileExtentions', {
          fileExtentions: ALLOWED_FILE_EXTENTIONS.join(', ')
        });
      }

      const isSizeValid = isFileSizeValid(file, { max: MAX_FILE_SIZE_MB, unit: 'MB' });
      if (!isSizeValid) {
        return t('file_is_larger_than_x_mb', { maxSize: MAX_FILE_SIZE_MB });
      }

      return null;
    },
    [t]
  );
};
