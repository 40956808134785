import { CapitalLoanWithLegalInfo } from '@/store/loan';

export const useResetLoanDefaultFormValues = (loan: CapitalLoanWithLegalInfo) => {
  return {
    cr_file: loan.legal_info?.cr_file || '',
    cr_number: loan.legal_info?.cr_number || '',
    company_name_or_legal_name: loan.legal_info?.legal_name || '',
    registered_company_address: loan.legal_info?.legal_address || ''
  };
};
