import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { Button } from '@/ui-kit/Button';
import { styled, useTheme } from '@mui/material/styles';

import { ROUTES } from '@/routes';

import { Text } from '@/ui-kit/Text';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { LoanOffer } from '@/store/offers';
import { formatCurrencyAmount } from '@/utils/currency';

const Root = styled('div', {
  shouldForwardProp: (props) => props !== 'mobileVariant'
})<{ mobileVariant: boolean }>(({ theme, mobileVariant }) => ({
  width: mobileVariant ? '100%' : '680px',
  position: 'relative',
  borderRadius: '24px',

  [theme.breakpoints.down('p768')]: {
    width: '100%',
    borderRadius: '16px'
  }
}));

const ContentWrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'mobileVariant'
})<{ mobileVariant: boolean }>(({ mobileVariant }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '16px',
  padding: mobileVariant ? '12px 16px 12px 24px' : '20px 24px'
}));

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});

type Props = {
  offer: LoanOffer;
  mobileVariant?: boolean;
};

export const LoanWidgetNewOffer = ({ offer, mobileVariant = false }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();
  const isMobileVariant = mobileVariant || isMobile;
  const { currency, amount = 0 } = offer;

  const offerAmount = formatCurrencyAmount(amount, currency, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });

  return (
    <Root
      mobileVariant={isMobileVariant}
      style={{
        background: `linear-gradient(to ${
          theme.direction === 'rtl' ? 'left' : 'right'
        }, #D421E8 0%, #F552AF 50%, #FD877E 100%)`
      }}
    >
      <ContentWrapper mobileVariant={isMobileVariant}>
        <Content>
          <Text variant="body1Tight700" color="B2B2023.front.invertMajor">
            {t('new_tabby_capital')}
          </Text>
          {isMobileVariant ? (
            <Text variant="h3" color="B2B2023.front.invertMajor">
              {offerAmount}
            </Text>
          ) : (
            <Text variant="h2" color="B2B2023.front.invertMajor">
              {t('up_to_amount', { amount: offerAmount })}
            </Text>
          )}
        </Content>
        <Button
          variant="contained"
          size={isMobileVariant ? 'medium' : 'large'}
          href={generatePath(ROUTES.financeTabbyCapitalOffersId, {
            id: offer.id
          })}
        >
          {t('apply_offer')}
        </Button>
      </ContentWrapper>
    </Root>
  );
};
