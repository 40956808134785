import { ReactNode, Suspense } from 'react';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { NavSidebar } from '@/components/NavSidebar';
import { LoaderDots } from '@/components/LoaderDots';
import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from './components/Header';
import { Content } from './components/Content';
import { DeprecatedWrapper } from './components/DeprecatedWrapper';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  backgroundColor: theme.palette.colors.chrome[50]
}));

const LayoutContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  flexGrow: 1,
  minWidth: 0,

  [theme.breakpoints.down('p768')]: {
    gap: '0px'
  }
}));

type Props = {
  children?: ReactNode;
  sidebar?: boolean;
};

export const PageLayout = ({ children, sidebar = false }: Props) => {
  const isMobile = useIsMobilePage();

  return (
    <Root>
      {!isMobile && sidebar && <NavSidebar />}
      <LayoutContent>
        <Suspense fallback={<LoaderDots />}>{children || <Outlet />}</Suspense>
      </LayoutContent>
    </Root>
  );
};

PageLayout.Header = Header;
PageLayout.Content = Content;
PageLayout.DeprecatedWrapper = DeprecatedWrapper;
