import { Currency } from '@/types';
import { getLocale } from './i18n';
import { getCurrencyDecimalCount } from './currency';

export const amountToFixed = (amount: number, currency: Currency) =>
  parseFloat(amount.toFixed(getCurrencyDecimalCount(currency)));

export const truncateNumber = (x: number, limit = 99, sign = '+') => {
  return `${Math.min(x, limit)}${x > limit ? sign : ''}`;
};

export const formatBigNumbers = (value: number, lang: string) => {
  const formattedValue = new Intl.NumberFormat(getLocale(lang), {
    notation: 'compact',
    compactDisplay: 'short'
  }).format(value);

  return formattedValue.toLocaleString();
};

export const calcPercent = (partialValue: number, totalValue: number) => {
  const ratio = partialValue / totalValue;

  if (!Number.isFinite(partialValue) || !Number.isFinite(totalValue)) {
    return 0;
  }

  return Math.min(Math.abs(ratio * 100), 100);
};

export const formatNumber = (value: number | string, lang: string) => {
  if (Number.isFinite(Number(value))) {
    const formattedValue = new Intl.NumberFormat(getLocale(lang)).format(Number(value));
    return formattedValue.toLocaleString();
  }

  return '';
};
