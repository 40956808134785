import { Skeleton } from '@/ui-kit/Skeleton';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px'
}));

export const FileUploadSkeleton = () => {
  return (
    <Root>
      <Skeleton height="18px" />
      <Skeleton height="100px" />
    </Root>
  );
};
