import { Language } from '@/types';
import { formatTimestamp, FormatTimestampResult } from '@/utils/date';
import { normalizeLanguage } from '@/utils/i18n';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const Root = styled('span')({
  wordBreak: 'break-word'
});

type Props = {
  className?: string;
  sx?: SxProps<Theme>;
  timestamp: string;
  format: keyof FormatTimestampResult;
  lang?: Language;
  customOptions?: Intl.DateTimeFormatOptions;
};

export const TextDate = (props: Props) => {
  const { className, sx, timestamp, format, lang, customOptions } = props;
  const { i18n } = useTranslation();
  const language = normalizeLanguage(lang || i18n.language);

  const result = useMemo(
    () => (timestamp ? formatTimestamp(timestamp, language, customOptions) : null),
    [language, timestamp, customOptions]
  );

  if (!result) {
    return null;
  }

  return (
    <Root className={className} sx={sx}>
      {format === 'ddmmyyyytime' ? (
        <>
          <span>{result.ddmmyyyy}</span> <span>{result.time}</span>
        </>
      ) : (
        result[format]
      )}
    </Root>
  );
};

export default memo(TextDate);
