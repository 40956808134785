import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useGoalItems = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        value: 'invest_in_marketing',
        label: t('invest_in_marketing')
      },
      {
        value: 'manage_cash_flows',
        label: t('manage_cash_flows')
      },
      {
        value: 'buy_more_inventory',
        label: t('buy_more_inventory')
      },
      {
        value: 'other',
        label: t('other')
      }
    ],
    [t]
  );
};
