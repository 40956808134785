import * as yup from 'yup';
import { isFirstOrSecondTierByVolume } from '@/store/application';
import { useBasicInfoSchema } from './useBasicInfoSchema';
import { useProductCategorySchema } from './useProductCategorySchema';
import { useStoreTypeSchema } from './useStoreTypeSchema';
import { useLegalInfoSchema } from './useLegalInfoSchema';
import { useBankDetailsSchema } from './useBankDetailsSchema';
import { ApplicationFormValues } from '../types';

export const useSchema = () => {
  const basicInfoSchema = useBasicInfoSchema();
  const productCategorySchema = useProductCategorySchema();
  const storeTypeSchema = useStoreTypeSchema();
  const { getSchema: getLegalInfoSchema } = useLegalInfoSchema();
  const { getSchema: getBankDetailsSchema } = useBankDetailsSchema();

  return yup.lazy(
    ({ basic_info: { country_of_main_operations, annual_volume } }: ApplicationFormValues) => {
      if (isFirstOrSecondTierByVolume(annual_volume)) {
        return yup.object({
          basic_info: basicInfoSchema,
          product_category: productCategorySchema,
          store_type: storeTypeSchema
        });
      }

      return yup.object({
        basic_info: basicInfoSchema,
        product_category: productCategorySchema,
        store_type: storeTypeSchema,
        legal_info: getLegalInfoSchema(country_of_main_operations),
        bank_details: getBankDetailsSchema(country_of_main_operations)
      });
    }
  );
};
