import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { EcomTypeCheckboxGroup, EcomTypeCheckboxGroupProps } from './EcomTypeCheckboxGroup';

type Props<T extends FieldValues> = Omit<EcomTypeCheckboxGroupProps, 'value'> & {
  control: Control<T>;
  name: Path<T>;
  analytics?: { event: EventName } & Record<string, string>;
};

export const EcomTypeCheckboxGroupControlled = <T extends FieldValues>({
  name,
  control,
  analytics,
  helperText,
  onChange,
  onBlur,
  ...rest
}: Props<T>) => {
  const {
    field,
    fieldState: { invalid, error }
  } = useController({ name, control });
  const track = useAnalyticsTrack();

  const handleChange: EcomTypeCheckboxGroupProps['onChange'] = (options) => {
    field.onChange(options);
    onChange?.(options);

    if (analytics) {
      const { event, ...analyticsParams } = analytics;

      track({
        event,
        params: {
          value: options,
          ...analyticsParams
        }
      });
    }
  };

  const handleBlur: EcomTypeCheckboxGroupProps['onBlur'] = () => {
    field.onBlur();
    onBlur?.();
  };

  return (
    <EcomTypeCheckboxGroup
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={invalid}
      helperText={error?.message ? error.message : helperText}
      {...rest}
    />
  );
};
