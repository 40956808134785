import { PaymentStatus } from '@/store/payment';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const usePaymentStatusLabels = () => {
  const { t } = useTranslation();

  const labels: Record<PaymentStatus, string | undefined> = useMemo(
    () => ({
      '': t('none'),
      cancelled: t('cancelled'),
      captured: t('captured'),
      disputed: t('disputed'),
      expired: t('expired'),
      expiring: t('expiring'),
      new: t('new'),
      partially_refunded: t('partially_refunded'),
      pending_refund: t('pending_refund'),
      refunded: t('refunded'),
      rejected: t('rejected')
    }),
    [t]
  );

  const getLabel = useCallback(
    (status: PaymentStatus): string => {
      return labels[status] || t('none');
    },
    [labels, t]
  );

  return { getLabel };
};
