import { NOTIFICATION_VARIANTS, useShowNotification } from '@/components/Notifications';
import { useTranslation } from 'react-i18next';
import { delay } from '@/utils/promise';
import { useCapturePaymentMutation, useClosePaymentMutation, useGetPaymentQuery } from './api';
import { INVALIDATE_TIMEOUT } from '../constants';

export const useClosePayment = (paymentId: string) => {
  const { t } = useTranslation();
  const { refetch } = useGetPaymentQuery(paymentId);
  const [triggerClosePayment, { isLoading: isCloseLoading }] = useClosePaymentMutation();
  const [, { isLoading: isCaptureLoading }] = useCapturePaymentMutation({
    fixedCacheKey: paymentId
  });
  const showNotification = useShowNotification();

  const closePayment = async () => {
    try {
      await triggerClosePayment(paymentId).unwrap();
      showNotification({
        variant: NOTIFICATION_VARIANTS.Success,
        message: t('successfully_closed_payment')
      });
      await delay(INVALIDATE_TIMEOUT);
      refetch();
    } catch {
      showNotification({
        variant: NOTIFICATION_VARIANTS.Error,
        message: t('failed_to_close_payment_please_try_again_later')
      });
    }
  };

  return { closePayment, isCloseLoading, isCaptureLoading };
};
