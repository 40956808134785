import { useMemo } from 'react';

export const useDefaultFormValues = () => {
  return useMemo(
    () => ({
      stores: [],
      capitalUse: '',
      otherUse: ''
    }),
    []
  );
};
