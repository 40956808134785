import { Country, COUNTRIES } from '@/types';
import { DIAL_CODES, DialCode, PhoneConfig } from './types';
import * as configs from './configs';

export const configByCountry: Record<Country, PhoneConfig> = {
  [COUNTRIES.ARE]: configs.ARE,
  [COUNTRIES.BHR]: configs.BHR,
  [COUNTRIES.EGY]: configs.EGY,
  [COUNTRIES.KWT]: configs.KWT,
  [COUNTRIES.QAT]: configs.QAT,
  [COUNTRIES.SAU]: configs.SAU
};

export const configByDialCode: Record<DialCode, PhoneConfig> = {
  [DIAL_CODES.ARE]: configs.ARE,
  [DIAL_CODES.BHR]: configs.BHR,
  [DIAL_CODES.EGY]: configs.EGY,
  [DIAL_CODES.KWT]: configs.KWT,
  [DIAL_CODES.QAT]: configs.QAT,
  [DIAL_CODES.SAU]: configs.SAU,
  [DIAL_CODES.JOR]: configs.JOR
};

const dialCodesSorting: Record<DialCode, number> = {
  [DIAL_CODES.SAU]: 1,
  [DIAL_CODES.ARE]: 2,
  [DIAL_CODES.QAT]: 3,
  [DIAL_CODES.BHR]: 4,
  [DIAL_CODES.KWT]: 5,
  [DIAL_CODES.EGY]: 6,
  [DIAL_CODES.JOR]: 7
};

export const sortedDialCodes: DialCode[] = Object.entries(dialCodesSorting)
  .sort((a, b) => a[1] - b[1])
  .map((e) => e[0] as DialCode)
  .filter((code) => code !== DIAL_CODES.JOR); // TODO: delete when backend is ready

export const DEFAULT_COUNTRY = COUNTRIES.SAU;
