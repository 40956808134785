import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import CircleFlagAreIcon from '@/assets/icons/circle-flag-are.svg?icon';
import { ROUTES } from '@/routes';
import { Link as BaseLink } from 'react-router-dom';
import PayoutBankAccountIcon from '@/assets/icons/bank-account.svg?icon';
import { useMediaQuery, useTheme } from '@mui/material';
import Bag24 from '@tabby.ai/tabby-ui/dist/icons/core/Bag24';
import Bank24 from '@tabby.ai/tabby-ui/dist/icons/core/Bank24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';

const SectionWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const ExampleTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center'
}));

const FlagIcon = styled(CircleFlagAreIcon)(() => ({
  marginRight: '8px'
}));

const LightText = styled(Typography)(({ theme }) => ({
  display: 'inline',
  color: theme.palette.B2B2023.front.minor
}));

const ExampleSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: '16px 12px'
}));

const ExampleRow = styled('div')(() => ({
  display: 'flex',
  gap: '23px'
}));

const IconsRow = styled('div')(() => ({
  display: 'flex'
}));

const ExampleIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '134px'
}));

const MobileExampleSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: '16px 12px'
}));

const MobileExampleItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center'
}));

const ExampleIcon = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  backgroundColor: '#F2E8FF',
  color: '#5D21DE'
}));

const ExampleDatePeriod = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._caption2Tight500,
  color: theme.palette.B2B2023.front.minor,
  textAlign: 'center',
  width: '134px',

  [theme.breakpoints.down('p768')]: {
    width: 'auto',
    textAlign: 'left'
  }
}));

const ExampleLabel = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._caption2Tight500,
  textAlign: 'center',
  width: '134px',

  [theme.breakpoints.down('p768')]: {
    width: 'auto',
    textAlign: 'left'
  }
}));

const ExampleDividerItem = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '48px',
  alignItems: 'center',

  [theme.breakpoints.down('p768')]: {
    height: '23px',
    marginLeft: '24px'
  }
}));

const Divider = styled('div')(({ theme }) => ({
  width: '23px',
  height: '2px',
  borderRadius: '2px',
  backgroundColor: '#5D21DE',

  [theme.breakpoints.down('p768')]: {
    width: '2px',
    height: '23px'
  }
}));

const Link = styled(BaseLink)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.highlightA
}));

const List = styled('ul')(() => ({
  paddingInlineStart: '20px',
  marginBlockStart: '4px',
  marginBlockEnd: '0',
  whiteSpace: 'pre-line'
}));

const StyledText = styled('span')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  padding: '2px 4px',
  borderRadius: '4px',
  background: theme.palette.B2B2023.back.controlMinor
}));

export const PayoutCycleInfo = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  return (
    <SectionWrapper>
      <DeprecatedTypography variant="_body1Loose500">
        {t(
          'the_schedule_by_which_tabby_pays_out_the_settlements_for_the_purchases_made_by_your_shoppers_through_tabby'
        )}
      </DeprecatedTypography>
      <ExampleTitle>
        <FlagIcon />
        <DeprecatedTypography variant="_body1Loose500">
          <Trans
            i18nKey="uae_settlements_example"
            components={{
              light: <LightText itemType="span" />
            }}
          />
        </DeprecatedTypography>
      </ExampleTitle>
      {isMobile ? (
        <MobileExampleSection>
          <MobileExampleItem>
            <ExampleIcon>
              <Bag24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
            </ExampleIcon>
            <div>
              <ExampleDatePeriod>{t('mon_sun_4_10_march')}</ExampleDatePeriod>
              <ExampleLabel>{t('processing_orders')}</ExampleLabel>
            </div>
          </MobileExampleItem>
          <ExampleDividerItem>
            <Divider />
          </ExampleDividerItem>
          <MobileExampleItem>
            <ExampleIcon>
              <Bank24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
            </ExampleIcon>
            <div>
              <ExampleDatePeriod>{t('mon_11_march')}</ExampleDatePeriod>
              <ExampleLabel>{t('payout_initiated')}</ExampleLabel>
            </div>
          </MobileExampleItem>
          <ExampleDividerItem>
            <Divider />
          </ExampleDividerItem>
          <MobileExampleItem>
            <ExampleIcon>
              <PayoutBankAccountIcon />
            </ExampleIcon>
            <div>
              <ExampleDatePeriod>{t('mon_tue_11_12_march')}</ExampleDatePeriod>
              <ExampleLabel>{t('settlement_reaches_bank_account')}</ExampleLabel>
            </div>
          </MobileExampleItem>
        </MobileExampleSection>
      ) : (
        <ExampleSection>
          <ExampleRow>
            <ExampleDatePeriod>{t('mon_sun_4_10_march')}</ExampleDatePeriod>
            <ExampleDatePeriod>{t('mon_11_march')}</ExampleDatePeriod>
            <ExampleDatePeriod>{t('mon_tue_11_12_march')}</ExampleDatePeriod>
          </ExampleRow>
          <IconsRow>
            <ExampleIconWrapper>
              <ExampleIcon>
                <Bag24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
              </ExampleIcon>
            </ExampleIconWrapper>
            <ExampleDividerItem>
              <Divider />
            </ExampleDividerItem>
            <ExampleIconWrapper>
              <ExampleIcon>
                <Bank24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
              </ExampleIcon>
            </ExampleIconWrapper>
            <ExampleDividerItem>
              <Divider />
            </ExampleDividerItem>
            <ExampleIconWrapper>
              <ExampleIcon>
                <PayoutBankAccountIcon />
              </ExampleIcon>
            </ExampleIconWrapper>
          </IconsRow>
          <ExampleRow>
            <ExampleLabel>{t('processing_orders')}</ExampleLabel>
            <ExampleLabel>{t('payout_initiated')}</ExampleLabel>
            <ExampleLabel>{t('settlement_reaches_bank_account')}</ExampleLabel>
          </ExampleRow>
        </ExampleSection>
      )}
      <DeprecatedTypography variant="_body1Loose500">
        <Trans
          i18nKey="tabby_offers_a_choice_between_two_payout_cycles_you_can_see_and_change_your_cycle_on_the_payout_tab_of_the_stores_page"
          components={{
            a: <Link to={ROUTES.businessProfileStores} target="_blank" />
          }}
        />
      </DeprecatedTypography>
      <DeprecatedTypography variant="_body1Loose500" display="block">
        {t('flexible')}
        <List>
          <li>
            <Trans
              i18nKey="settlements_are_kept_on_hold_until_the_total_amount_reaches__description"
              components={{
                highlighted: <StyledText />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="the_bank_charges_a_fee_of_sar_aed_6_per_settlement"
              components={{
                highlighted: <StyledText />
              }}
            />
          </li>
        </List>
      </DeprecatedTypography>
      <DeprecatedTypography variant="_body1Loose500" display="block">
        {t('fixed_weekly')}
        <List>
          <li>
            <Trans i18nKey="settlements_are_transferred_weekly_every_working_monday_regardless_of_the_amount" />
          </li>
          <li>
            <Trans
              i18nKey="the_bank_charges_a_fee_of_sar_aed_6_per_settlement"
              components={{
                highlighted: <StyledText />
              }}
            />
          </li>
          <li>
            <Trans
              i18nKey="settlements_of_less_than_sar_aed_2500_are_subject_to_a_fee_of_sar_aed_25_and_tabby_takes_on_the_bank_fee_of_sar_aed_5"
              components={{
                highlighted: <StyledText />
              }}
            />
          </li>
        </List>
      </DeprecatedTypography>
    </SectionWrapper>
  );
};
