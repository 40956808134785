import { useEffect, MouseEvent } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import CloseIcon from '@/assets/icons/close.svg?icon';
import { getTestProps } from '@/lib/autotests';
import Typography from '@mui/material/Typography';
import { SxProps, styled, Theme } from '@mui/material/styles';
import { NotificationItemIcon } from './NotificationItemIcon';
import { NotificationVariant, Notification } from '../types';
import { AUTO_HIDE_DURATION_MS } from '../constants';

export const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inline'
})<{
  inline: boolean;
}>(({ theme, inline }) => ({
  width: '100%',
  zIndex: 999,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  color: theme.palette.grey[600],
  padding: inline ? '0px' : '68px 16px 0px'
}));

export const Inner = styled('div', {
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'inline'
})<{ variant: NotificationVariant; inline: boolean }>(({ theme, variant, inline }) => {
  const colorTheme = {
    success: {
      backgroundColor: theme.palette.__green[5],
      color: theme.palette.__green[60]
    },
    warning: {
      backgroundColor: theme.palette.__honey[5],
      color: theme.palette.__honey[60]
    },
    error: {
      backgroundColor: theme.palette.__coral[5],
      color: theme.palette.__coral[60]
    },
    info: {
      backgroundColor: theme.palette.bg.grey,
      color: theme.palette.__grey[90]
    },
    iris: {
      backgroundColor: theme.palette.__iris[5],
      color: theme.palette.__iris[70]
    }
  }[variant];

  return {
    width: inline ? '100%' : 'auto',
    position: 'relative',
    borderRadius: '8px',
    boxShadow: '0px 8px 16px rgba(200, 200, 200, 0.15)',
    display: 'flex',
    gap: '12px',
    padding: '12px 16px',
    alignItems: 'center',
    ...colorTheme,
    [theme.breakpoints.down('p768')]: {
      width: '100%'
    }
  };
});

export const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'inline'
})<{ inline: boolean }>(({ theme, inline }) => ({
  maxWidth: inline ? 'max-content' : '350px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('p768')]: {
    minWidth: 'auto'
  }
}));

export const Control = styled('div')(({ theme }) => ({
  marginLeft: 'auto',
  color: theme.palette._text.default
}));

type Props = {
  sx?: SxProps<Theme>;
  notification: Notification;
  onClose?: (id: string) => void;
  inline?: boolean;
};

export const NotificationItem = ({ notification, onClose, inline, sx }: Props) => {
  const { id, autoHide, variant, title, message } = notification;

  const handleClickAway = () => {
    onClose?.(id);
  };

  const handleCloseClick = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    onClose?.(id);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (autoHide) onClose?.(id);
    }, AUTO_HIDE_DURATION_MS);

    return () => {
      clearTimeout(timer);
    };
  }, [autoHide, id, onClose]);

  return (
    <Root sx={sx} inline={!!inline}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Inner variant={variant} inline={!!inline} {...getTestProps('notification', variant)}>
          <NotificationItemIcon variant={variant} />
          <Content inline={!!inline}>
            {title && <Typography variant="h6">{title}</Typography>}
            <Typography variant="body2">{message}</Typography>
          </Content>
          {!inline && !autoHide && (
            <Control>
              <CloseIcon
                style={{ fontSize: '16px', cursor: 'pointer' }}
                onClick={handleCloseClick}
              />
            </Control>
          )}
        </Inner>
      </ClickAwayListener>
    </Root>
  );
};
