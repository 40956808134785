import { useMatch } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import BasicInfo from './BasicInfo';
import ProductCategory from './ProductCategory';
import StoreType from './StoreType';
import LegalDetails from './LegalDetails';
import BankDetails from './BankDetails';
import FormControls from './FormControls';
import { Stepper } from './Stepper';
import { FormContentSkeleton } from './FormContentSkeleton';
import { useStepsValidation } from '../hooks/useStepsValidation';
import { generateStepPath } from '../utils';
import { useOpenFormTrackingEvents } from '../hooks/useOpenFormTrackingEvents';

const Root = styled('div')(() => ({}));

const Content = styled('div')(({ theme }) => ({
  maxWidth: '540px',
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  gap: '40px',
  backgroundColor: theme.palette.B2B2023.back.major,
  margin: '0 auto',
  borderRadius: '24px'
}));

export const FormContent = () => {
  const basicInfoPage = useMatch(generateStepPath('basic-info'));
  const productCategoryPage = useMatch(generateStepPath('product-category'));
  const storeTypePage = useMatch(generateStepPath('store-type'));
  const legalDetailsPage = useMatch(generateStepPath('legal-details'));
  const bankDetailsPage = useMatch(generateStepPath('bank-details'));

  const { isLoading: isLoadingStepsValidation } = useStepsValidation();
  useOpenFormTrackingEvents();

  if (isLoadingStepsValidation) {
    return <FormContentSkeleton />;
  }

  return (
    <Root>
      <Box sx={{ marginBottom: '36px' }}>
        <Stepper />
      </Box>
      <Content>
        {basicInfoPage && <BasicInfo />}
        {productCategoryPage && <ProductCategory />}
        {storeTypePage && <StoreType />}
        {legalDetailsPage && <LegalDetails />}
        {bankDetailsPage && <BankDetails />}
        <FormControls />
      </Content>
    </Root>
  );
};
