import {
  Dropdown as TuiDropdown,
  type DropdownProps as TuiDropdownProps
} from '@tabby.ai/tabby-ui/dist/core';

type DropdownProps = Omit<TuiDropdownProps, 'ds'>;

export const Dropdown = (props: DropdownProps) => {
  return <TuiDropdown ds="B2B2023" {...props} />;
};
