import { ROUTES } from '@/routes';
import { useTranslation } from 'react-i18next';
import { useGetStreamContext } from '@/components/GetStreamProvider';
import Agent24 from '@tabby.ai/tabby-ui/dist/icons/core/Agent24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import { NavLink } from 'react-router-dom';

type Props = {
  isCollapsed?: boolean;
};

const NavMenuItemSupport = ({ isCollapsed }: Props) => {
  const { t } = useTranslation();
  const { hasUnreadMessages } = useGetStreamContext();

  return (
    <SideMenuItem
      isCollapsed={isCollapsed}
      icon={<Agent24 ds={DS.B2B2023} />}
      label={t('support')}
      to={ROUTES.support}
      notificationBadgeText={hasUnreadMessages ? '' : undefined}
      component={NavLink}
    />
  );
};

export default NavMenuItemSupport;
