import { Component, ErrorInfo, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import ErrorFallback from './components/ErrorFallback';

type Props = { children: ReactNode; fallback?: ReactNode };
type State = { hasError: boolean };

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    datadogRum.addAction('ErrorBoundary', { error, errorInfo });
    this.setState({ hasError: true });
  }

  handleRefresh = () => {
    this.setState({ hasError: false });
    window.location.reload();
  };

  public render() {
    const { children, fallback } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return fallback || <ErrorFallback onRefresh={this.handleRefresh} />;
    }

    return children;
  }
}
