import { Control, useController, type FieldValues, type Path } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { FileUpload, type FileUploadProps } from './FileUpload';

type Props<T extends FieldValues> = Omit<FileUploadProps, 'value' | 'inputRef'> & {
  control: Control<T>;
  name: Path<T>;
  analytics?: { event: EventName } & Record<string, string>;
};

export const FileUploadControlled = <T extends FieldValues>({
  control,
  name,
  helperText,
  onUpload,
  onBlur,
  analytics,
  ...rest
}: Props<T>) => {
  const track = useAnalyticsTrack();
  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control
  });

  const handleFieldChange: FileUploadProps['onUpload'] = (value) => {
    field.onChange(value || '');
    onUpload?.(value);
    if (analytics) {
      const { event: analyticsEvent, ...analyticsParams } = analytics;

      track({
        event: analyticsEvent,
        params: {
          value,
          ...analyticsParams
        }
      });
    }
  };

  const handleFieldBlur: FileUploadProps['onBlur'] = () => {
    field.onBlur();
    onBlur?.();
  };

  return (
    <FileUpload
      {...rest}
      value={field.value}
      onUpload={handleFieldChange}
      onBlur={handleFieldBlur}
      error={invalid}
      helperText={error?.message ? error.message : helperText}
      name={name}
    />
  );
};
