import { BORDER_RADIUS_PX } from '@/ui-kit/WidgetBase';
import { styled } from '@mui/material/styles';
import { LogoHeader } from './components/LogoHeader';
import { Terms } from './components/Terms';
import { MIN_WIDTH_PX } from './constants';

const Root = styled('div')(({ theme }) => ({
  borderRadius: `${BORDER_RADIUS_PX}px`,
  backgroundColor: theme.palette.B2B2023.back.major,
  overflow: 'hidden',
  padding: '24px 24px 32px 24px',
  width: '360px',
  [theme.breakpoints.down('p768')]: {
    width: `${MIN_WIDTH_PX}px`
  }
}));

type Props = {
  children: JSX.Element;
  onGoBack: VoidFunction;
};

export const AuthStepWidget = ({ children, onGoBack }: Props) => {
  return (
    <Root>
      <LogoHeader sx={{ padding: '16px 8px', marginBottom: '24px' }} onGoBack={onGoBack} />
      <div>
        {children}
        <Terms sx={{ marginTop: '16px', textAlign: 'center' }} />
      </div>
    </Root>
  );
};
