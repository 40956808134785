import { styled } from '@mui/material';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { OfferCard } from './OfferCard';
import { OffersItem } from './OffersItem';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-start',
  width: '100%'
}));

const OffersList = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'flex-start',
  overflowX: 'auto',
  paddingTop: '24px',
  paddingBottom: '4px',
  width: '100%'
}));

export const Offers = () => {
  const { onSelectOffer, selectedOffer, offers } = useFormStateContext();

  return (
    <Root>
      <OffersList>
        {offers.map((offer) => (
          <OffersItem
            key={offer.id}
            offer={offer}
            isActive={selectedOffer?.id === offer.id}
            onSelect={() => onSelectOffer(offer)}
            isPopular={Number(offer.tenor) === 20}
          />
        ))}
      </OffersList>

      {selectedOffer && <OfferCard offer={selectedOffer} />}
    </Root>
  );
};
