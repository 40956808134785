import { CapitalLoan } from '@/store/loan';
import { Content } from './Content';
import { LoanDetailsProvider } from './LoanDetailsProvider';

export const LoanDetails = ({ loan, loans }: { loan: CapitalLoan; loans: CapitalLoan[] }) => {
  return (
    <LoanDetailsProvider loans={loans} loan={loan}>
      <Content />
    </LoanDetailsProvider>
  );
};
