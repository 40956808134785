import { useCallback, useMemo } from 'react';
import { BusinessCategory, BUSINESS_CATEGORY, BusinessCategoryItem } from '@/store/application';
import { useTranslation } from 'react-i18next';
import { Option } from '@/types';

type Props = {
  getLabel: (section: BusinessCategory) => string;
  labels: Record<BusinessCategory, string>;
  toOptions: (sections: BusinessCategoryItem[]) => Option[];
};

const useBusinessCategoryLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Record<BusinessCategory, string> = useMemo(
    () => ({
      [BUSINESS_CATEGORY.Activewear]: t('tabby_category_activewear'),
      [BUSINESS_CATEGORY.AnimeAndFigurines]: t('tabby_category_anime_figurines'),
      [BUSINESS_CATEGORY.Automotive]: t('tabby_category_automotive'),
      [BUSINESS_CATEGORY.BedAndBath]: t('tabby_category_bed_bath'),
      [BUSINESS_CATEGORY.BooksAndStationery]: t('tabby_category_books_stationery'),
      [BUSINESS_CATEGORY.Clinics]: t('tabby_category_clinics'),
      [BUSINESS_CATEGORY.Clothing]: t('tabby_category_clothing'),
      [BUSINESS_CATEGORY.Education]: t('tabby_category_education'),
      [BUSINESS_CATEGORY.Electronics]: t('tabby_category_electronics'),
      [BUSINESS_CATEGORY.Entertainment]: t('tabby_category_entertainment'),
      [BUSINESS_CATEGORY.Eyewear]: t('tabby_category_eyewear'),
      [BUSINESS_CATEGORY.FoodAndPantry]: t('tabby_category_food_pantry'),
      [BUSINESS_CATEGORY.Gadgets]: t('tabby_category_gadgets'),
      [BUSINESS_CATEGORY.Gaming]: t('tabby_category_gaming'),
      [BUSINESS_CATEGORY.GiftCards]: t('tabby_category_gift_cards'),
      [BUSINESS_CATEGORY.Gifts]: t('tabby_category_gifts'),
      [BUSINESS_CATEGORY.Gyms]: t('tabby_category_gyms'),
      [BUSINESS_CATEGORY.HomeAppliances]: t('tabby_category_home_appliances'),
      [BUSINESS_CATEGORY.HomeDecor]: t('tabby_category_home_decor'),
      [BUSINESS_CATEGORY.Insurance]: t('tabby_category_insurance'),
      [BUSINESS_CATEGORY.JewelleryAndAccessories]: t('tabby_category_jewellery_accessories'),
      [BUSINESS_CATEGORY.KidsClothing]: t('tabby_category_kids_clothing'),
      [BUSINESS_CATEGORY.Luxury]: t('tabby_category_luxury'),
      [BUSINESS_CATEGORY.Marketplace]: t('tabby_category_marketplace'),
      [BUSINESS_CATEGORY.MaternityAndBabyGear]: t('tabby_category_maternity_baby_gear'),
      [BUSINESS_CATEGORY.MedicalAndPharmacy]: t('tabby_category_medical_pharmacy'),
      [BUSINESS_CATEGORY.Outdoor]: t('tabby_category_outdoor'),
      [BUSINESS_CATEGORY.PerfumesAndOud]: t('tabby_category_perfumes_oud'),
      [BUSINESS_CATEGORY.Pets]: t('tabby_category_pets'),
      [BUSINESS_CATEGORY.SelfCare]: t('tabby_category_self_care'),
      [BUSINESS_CATEGORY.Services]: t('tabby_category_services'),
      [BUSINESS_CATEGORY.Shoes]: t('tabby_category_shoes'),
      [BUSINESS_CATEGORY.SkincareHairAndMakeup]: t('tabby_category_skincare_hair_makeup'),
      [BUSINESS_CATEGORY.SportsAccessories]: t('tabby_category_sports_accessories'),
      [BUSINESS_CATEGORY.SportsAndGym]: t('tabby_category_sports_gym'),
      [BUSINESS_CATEGORY.SupplementsAndNutrition]: t('tabby_category_supplements_nutrition'),
      [BUSINESS_CATEGORY.Taxi]: t('tabby_category_taxi'),
      [BUSINESS_CATEGORY.Toys]: t('tabby_category_toys'),
      [BUSINESS_CATEGORY.TravelAndTransport]: t('tabby_category_travel_transport'),
      [BUSINESS_CATEGORY.Watches]: t('tabby_category_watches')
    }),
    [t]
  );

  const getLabel: Props['getLabel'] = useCallback(
    (section: BusinessCategory) => {
      return labels[section] || section;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map(({ id, name }) => ({ value: id, label: getLabel(name) })),
    [getLabel]
  );

  return { labels, getLabel, toOptions };
};

export default useBusinessCategoryLabels;
