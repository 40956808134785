import { Currency } from '@/types';
import { formatCurrencyAmount } from '@/utils/currency';

export type Props = {
  currency: Currency;
  amount: number | string;
  className?: string;
};

export const TextCurrency = ({ currency, amount, className }: Props) => {
  return <span className={className}>{formatCurrencyAmount(amount, currency)}</span>;
};
