import useIsMobilePage from '@/hooks/useIsMobilePage';
import { Box, Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  width: '680px',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  borderRadius: '24px',
  background: theme.palette.B2B2023.back.major,

  [theme.breakpoints.down('p768')]: {
    gap: '16px',
    width: '360px'
  }
}));

const HeadInfo = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

export const LoanWidgetSkeleton = () => {
  const isMobile = useIsMobilePage();

  if (isMobile) {
    return (
      <Root>
        <HeadInfo>
          <Box
            sx={{
              display: 'flex',
              gap: '2px',
              alignItems: 'flex-start',
              flexDirection: 'column'
            }}
          >
            <Skeleton variant="text" height={24} width={95} />
            <Skeleton variant="text" height={35} width={235} />
            <Skeleton variant="text" height={35} width={155} />
          </Box>
          <Skeleton variant="text" height={24} width={95} />
        </HeadInfo>
      </Root>
    );
  }

  return (
    <Root>
      <HeadInfo>
        <Skeleton variant="text" height={33} width={145} />
        <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
          <Skeleton variant="text" height={22} width={118} />
          <Skeleton variant="circular" height={32} width={32} />
        </Box>
      </HeadInfo>
      <Column>
        <Skeleton variant="text" height={55} width={isMobile ? 200 : 550} />
      </Column>
    </Root>
  );
};
