import { Language } from '@/types';
import { useCallback } from 'react';
import { useAnalytics } from '../AnalyticsProvider';

type Params = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  language: Language;
};

export const useAnalyticsIdentify = () => {
  const analytics = useAnalytics();

  const identify = useCallback(
    ({ id, ...rest }: Params) => analytics.identify(id, { id, ...rest }),
    [analytics]
  );

  return identify;
};
