import { useGetLoansQuery } from '@/store/loan';
import { styled } from '@mui/material/styles';

import { find } from 'lodash';
import { useGetOffersQuery } from '@/store/offers';
import { Text } from '@/ui-kit/Text';
import { useTranslation } from 'react-i18next';
import { LoanWidget } from './LoanWidget';
import { LoanWidgetSkeleton } from './LoanWidgetSkeleton';
import { LoanWidgetNewOffer } from './LoanWidgetNewOffer';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%'
});

const ContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',

  [theme.breakpoints.down('p768')]: {
    gap: '8px',
    width: '100%'
  }
}));

const SectionLabel = styled(Text)({
  padding: '12px 0px 2px 24px',
  textTransform: 'uppercase'
});

export const Loans = () => {
  const { t } = useTranslation();
  const { data: loans = [], isLoading: isLoadingLoans } = useGetLoansQuery();
  const { data: offers = [], isLoading: isLoadingOffers } = useGetOffersQuery();

  const activeLoans = loans.filter((loan) =>
    ['submitted', 'approved', 'paying', 'overdue', 'reset', 'waiting'].includes(loan.status)
  );
  const inactiveLoans = loans.filter((loan) => ['closed', 'rejected'].includes(loan.status));
  const newOffer = find(offers, (l) => Number(l.tenor) === 20);

  if (isLoadingLoans || isLoadingOffers) {
    return (
      <Root>
        <ContentWrapper>
          <LoanWidgetSkeleton />
          <LoanWidgetSkeleton />
          <LoanWidgetSkeleton />
        </ContentWrapper>
      </Root>
    );
  }

  return (
    <Root>
      <ContentWrapper>
        {newOffer && <LoanWidgetNewOffer offer={newOffer} />}
        {activeLoans.length > 0 && (
          <SectionLabel variant="caption1Tight700" color="B2B2023.front.minor">
            {t('active')}
          </SectionLabel>
        )}
        {activeLoans?.map((loanItem) => {
          return <LoanWidget key={loanItem.id} loan={loanItem} />;
        })}
        {inactiveLoans.length > 0 && (
          <SectionLabel variant="caption1Tight700" color="B2B2023.front.minor">
            {t('inactive')}
          </SectionLabel>
        )}
        {inactiveLoans?.map((loanItem) => {
          return <LoanWidget key={loanItem.id} loan={loanItem} />;
        })}
      </ContentWrapper>
    </Root>
  );
};
