import { Trans } from 'react-i18next';
import { SxProps, Theme, styled } from '@mui/material/styles';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { TERMS_URL_AR, TERMS_URL_EN } from '../constants';

const Root = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Loose500,
  color: theme.palette.B2B2023.front.minor
}));

const Link = styled('a')(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.palette.B2B2023.front.minor
}));

type Props = {
  sx?: SxProps<Theme>;
};

export const Terms = ({ sx }: Props) => {
  const lang = useCurrentLanguage();
  const url = {
    en: TERMS_URL_EN,
    ar: TERMS_URL_AR
  }[lang];

  return (
    <Root sx={sx}>
      <Trans
        i18nKey="by_pressing_continue_you_agree_to_terms_of_service_and_privacy_policy"
        components={{ a: <Link href={url} target="_blank" rel="no-refferer" /> }}
      />
    </Root>
  );
};
