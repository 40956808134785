import { Option } from '@/types';
import { ApplicationAnnualVolume, Tier } from './types';

export const ECOM_TYPE = {
  ONLINE: 'online',
  OFFLINE: 'offline'
};

export const ECOM_PLATFORM = {
  CSCart: 'CS Cart',
  Custom: 'Custom',
  DashU: 'DashU',
  ExpandCart: 'ExpandCart',
  Glary: 'Glary',
  GuideMeDoc: 'GuideMeDoc',
  Ignite: 'Ignite',
  Innoshop: 'Innoshop',
  Lana: 'Lana',
  Magento1: 'Magento 1',
  Magento2: 'Magento 2',
  MakanE: 'MakanE',
  Manafeth: 'Manafeth',
  Matjrah: 'Matjrah',
  OpenCart: 'OpenCart',
  Osarh: 'Osarh',
  Prestashop: 'Prestashop',
  Salesforce: 'Salesforce',
  Salla: 'Salla',
  Shopify: 'Shopify',
  Wix: 'Wix',
  WooCommerce: 'WooCommerce',
  Zammit: 'Zammit',
  Zid: 'Zid'
} as const;

export const APPLICATION_ANNUAL_VOLUME = {
  LESS_THAN_1M: 'less_than_1m',
  FROM_1M_TO_5M: 'from_1m_to_5m',
  FROM_5M_TO_15M: 'from_5m_to_15m',
  ABOVE_15M: 'above_15m'
} as const;

export const BUSINESS_LICENSE = {
  Commercial: 'commercial_registration',
  Freelance: 'freelance_license',
  Trade: 'trade_license'
} as const;

export const LOCATIONS_NUMBER = {
  1: '1',
  2: '2',
  3: '3',
  MoreThan3: 'MoreThan3'
} as const;

export const BUSINESS_CATEGORY = {
  Activewear: 'Activewear',
  AnimeAndFigurines: 'Anime & figurines',
  Automotive: 'Automotive',
  BedAndBath: 'Bed & Bath',
  BooksAndStationery: 'Books & Stationery',
  Clinics: 'Clinics',
  Clothing: 'Clothing',
  Education: 'Education',
  Electronics: 'Electronics',
  Entertainment: 'Entertainment',
  Eyewear: 'Eyewear',
  FoodAndPantry: 'Food & Pantry',
  Gadgets: 'Gadgets',
  Gaming: 'Gaming',
  GiftCards: 'Gift cards',
  Gifts: 'Gifts',
  Gyms: 'Gyms',
  HomeAppliances: 'Home appliances',
  HomeDecor: 'Home Decor',
  Insurance: 'Insurance',
  JewelleryAndAccessories: 'Jewellery & Accessories',
  KidsClothing: 'Kids Clothing',
  Luxury: 'Luxury',
  Marketplace: 'Marketplace',
  MaternityAndBabyGear: 'Maternity & Baby Gear',
  MedicalAndPharmacy: 'Medical & Pharmacy',
  Outdoor: 'Outdoor',
  PerfumesAndOud: 'Perfumes & Oud',
  Pets: 'Pets',
  SelfCare: 'Self-Care',
  Services: 'Services',
  Shoes: 'Shoes',
  SkincareHairAndMakeup: 'Skincare, Hair & Makeup',
  SportsAccessories: 'Sports Accessories',
  SportsAndGym: 'Sports & Gym',
  SupplementsAndNutrition: 'Supplements & Nutrition',
  Taxi: 'Taxi',
  Toys: 'Toys',
  TravelAndTransport: 'Travel & Transport',
  Watches: 'Watches'
} as const;

export const BUSINESS_SECTION = {
  Accessories: 'Accessories',
  BabyAndToys: 'Baby & Toys',
  Beauty: 'Beauty',
  Fashion: 'Fashion',
  Gifts: 'Gifts',
  Home: 'Home',
  Kids: 'Kids',
  Other: 'Other',
  PopularStores: 'Popular stores',
  Sports: 'Sports',
  SportsAndGaming: 'Sports & Gaming'
} as const;

export const annualVolumeToTier: Record<ApplicationAnnualVolume, Tier> = {
  [APPLICATION_ANNUAL_VOLUME.ABOVE_15M]: 1,
  [APPLICATION_ANNUAL_VOLUME.FROM_5M_TO_15M]: 2,
  [APPLICATION_ANNUAL_VOLUME.FROM_1M_TO_5M]: 3,
  [APPLICATION_ANNUAL_VOLUME.LESS_THAN_1M]: 4
};

export const ecomPlatformOptions: Option[] = Object.values(ECOM_PLATFORM)
  .map((value) => ({ value, label: value }))
  .sort((a, b) => a.label.localeCompare(b.label));
