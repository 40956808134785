import { useAnalyticsTrack } from '@/store/analytics';
import { useEffect } from 'react';
import { usePrevious } from '@/hooks/usePrevious';
import { useAuthPageContext } from '../AuthPageProvider';

export const useTrackAuthStepOpened = () => {
  const { state } = useAuthPageContext();
  const { step, email, phone, emailPart, phonePart } = state || {};
  const track = useAnalyticsTrack();
  const prevStep = usePrevious(step);

  useEffect(() => {
    if (step === prevStep) return;

    switch (step) {
      case 'sign_in':
        track({ event: 'Merchant Sign Up Opened' });
        break;
      case 'sign_in_verify_email':
        track({ event: 'OTP Opened', params: { stage: 'login', type: 'email', email } });
        break;
      case 'sign_in_verify_phone':
        track({ event: 'OTP Opened', params: { stage: 'login', type: 'phone', phone } });
        break;
      case 'challenge_verify_email':
        track({
          event: 'OTP Opened',
          params: { stage: '2fa', type: 'email', email: emailPart }
        });
        break;
      case 'challenge_verify_phone':
        track({
          event: 'OTP Opened',
          params: { stage: '2fa', type: 'phone', phone: phonePart }
        });
        break;
      case 'challenge_setup_verify_email':
        track({
          event: 'OTP Opened',
          params: { stage: '2fa', type: 'email', email }
        });
        break;
      case 'challenge_setup_verify_phone':
        track({
          event: 'OTP Opened',
          params: { stage: '2fa', type: 'phone', phone }
        });
        break;
      case 'challenge_setup_email':
        track({
          event: 'Link 2FA Opened',
          params: { type: 'email' }
        });
        break;
      case 'challenge_setup_phone':
        track({
          event: 'Link 2FA Opened',
          params: { type: 'phone' }
        });
        break;
      // no default
    }
  }, [email, emailPart, phone, phonePart, prevStep, step, track]);
};
