import { LoaderDots } from '@/components/LoaderDots';
import { getAuthReferrer, getRelativeUrlWithAuthReferrer, ROUTES } from '@/routes';
import { useIsAuthorized } from '@/store/auth';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  children?: JSX.Element | null;
};

export const LogoutRoute = ({ children = <Outlet /> }: Props): JSX.Element | null => {
  const isAuth = useIsAuthorized();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      const redirectUrl = getRelativeUrlWithAuthReferrer(
        ROUTES.auth,
        getAuthReferrer(location.search)
      );

      navigate(redirectUrl, { replace: true });
    }
  }, [isAuth, location.search, navigate]);

  return isAuth ? children : <LoaderDots height="100vh" />;
};
