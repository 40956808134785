import { rootApi } from '../rootApi/api';
import { useMeQuery } from '../account-me/api';
import { ExperimentName, GetExperimentsParams, GetExperimentsResponse } from './types';

const experimentsApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getExperiments: build.query<ExperimentName[], GetExperimentsParams>({
      query: (params) => ({
        url: '/merchant/api/v1/experiments',
        params
      }),
      transformResponse: (res: GetExperimentsResponse) => {
        return res.experiments.filter((e) => e.value === 'enabled').map((e) => e.name);
      }
    })
  })
});

export const { useLazyGetExperimentsQuery } = experimentsApi;

export const useIsExperimentOn = (name: ExperimentName): boolean => {
  const { data } = useMeQuery();
  return data?.experiments.includes(name) || false;
};
