import { useEffect } from 'react';
import { useAuthPageContext } from '../AuthPageProvider';

export const GoToSignIn = () => {
  const {
    state: { step },
    goToSignIn
  } = useAuthPageContext();

  useEffect(() => {
    if (step !== 'sign_in') {
      goToSignIn();
    }
  }, [goToSignIn, step]);

  return null;
};
