import { StreamChat, EventHandler } from 'stream-chat';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import uniq from 'lodash/uniq';
import { rootApi } from '../rootApi/api';
import { getUnreadChannels } from './utils';

export const client = StreamChat.getInstance(process.env.GET_STREAM_KEY);

type StartChatParams = { user_id: string; name: string };

type StartChatResult = {
  has_unread_messages: boolean;
  unread_channel_ids: string[];
};

const supportChatApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    startSupportChat: build.query<StartChatResult, StartChatParams>({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        const { user_id, name } = args;

        const result = await baseQuery('/merchant/api/v1/support/chat-token');
        if (result.error) {
          return { error: result.error as FetchBaseQueryError };
        }

        const { token } = result.data as { token: string };

        try {
          await client.connectUser({ id: user_id, name }, token);
          const unreadChannels = await getUnreadChannels(client, user_id);

          return {
            data: {
              unread_channel_ids: unreadChannels.ids,
              has_unread_messages: unreadChannels.count > 0
            }
          };
        } catch (error) {
          return { error: { status: 500 } as FetchBaseQueryError };
        }
      },
      onCacheEntryAdded: async (
        { user_id },
        { cacheDataLoaded, updateCachedData, cacheEntryRemoved }
      ) => {
        const handleTotalUnread: EventHandler = (event) => {
          const { unread_channels } = event;

          if (unread_channels) {
            updateCachedData((draft) => {
              draft.has_unread_messages = unread_channels > 0;
            });
          }
        };

        const handleNewMessages: EventHandler = (event) => {
          const { channel_id, message } = event;

          if (channel_id && message?.user && message.user.id !== user_id) {
            updateCachedData((draft) => {
              draft.unread_channel_ids = uniq([...draft.unread_channel_ids, channel_id]);
            });
          }
        };

        const handleReadMessages: EventHandler = (event) => {
          const { channel_id } = event;

          if (channel_id) {
            updateCachedData((draft) => {
              const arr = draft.unread_channel_ids.filter((id) => id !== channel_id);
              draft.unread_channel_ids = arr;
              draft.has_unread_messages = arr.length > 0;
            });
          }
        };

        try {
          await cacheDataLoaded;
          client.on(handleTotalUnread);
          client.on('notification.message_new', handleNewMessages);
          client.on('message.new', handleNewMessages);
          client.on('notification.mark_read', handleReadMessages);
          client.on('message.read', handleReadMessages);
        } catch (error) {
          // no error
        }

        await cacheEntryRemoved;
        client.off(handleTotalUnread);
        client.off('notification.message_new', handleNewMessages);
        client.off('message.new', handleNewMessages);
        client.off('notification.mark_read', handleReadMessages);
        client.off('message.read', handleReadMessages);

        client.disconnectUser();
      }
    })
  })
});

export const { useStartSupportChatQuery } = supportChatApi;
