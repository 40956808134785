import { LOAN_STATUSES, LoanStatus } from '@/store/loan';
import { TextBadge } from '@tabby.ai/tabby-ui/dist/core';
import { useMemo } from 'react';
import { SxProps, Theme } from '@mui/material';
import { useStatusLabels } from '../hooks/useStatusLabels';

type Props = {
  status: LoanStatus;
  sx?: SxProps<Theme>;
};

export const LoanStatusBadge = ({ status, sx }: Props) => {
  const { getLabel } = useStatusLabels();

  const badgeType = useMemo(() => {
    switch (status) {
      case LOAN_STATUSES.submitted:
      case LOAN_STATUSES.reset:
        return 'warning';
      case LOAN_STATUSES.overdue:
      case LOAN_STATUSES.rejected:
        return 'negative';
      case LOAN_STATUSES.closed:
        return 'default';
      case LOAN_STATUSES.approved:
      case LOAN_STATUSES.waiting:
        return 'positive';
      case LOAN_STATUSES.paying:
        return 'accent';
      default:
        return 'default';
    }
  }, [status]);

  return (
    <TextBadge ds="B2B2023" type={badgeType} size="large" width="fit-content" sx={sx}>
      {getLabel(status)}
    </TextBadge>
  );
};
