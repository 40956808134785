import { createContext, ReactNode } from 'react';
import { useForm, FormProvider as FormPropsProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useFormSchema } from '../hooks/useFormSchema';
import { useDefaultFormValues } from '../hooks/useDefaultFormValues';
import { FormProps, FormValues } from '../types';

type Props = FormProps & {
  children: ReactNode;
};

export const FormStateContext = createContext({} as FormProps);

const FormStateProvider = ({ children, ...rest }: Props) => {
  return <FormStateContext.Provider value={rest}>{children}</FormStateContext.Provider>;
};

export const FormProvider = ({ children, ...rest }: Props) => {
  const schema = useFormSchema();
  const defaultValues = useDefaultFormValues();

  const formProps = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues
  });

  return (
    <FormPropsProvider {...formProps}>
      <FormStateProvider {...rest}>{children}</FormStateProvider>
    </FormPropsProvider>
  );
};
