import { isValidDateString } from '@/utils/date';
import { SORT_DIRECTION, TabbyProduct, TABBY_PRODUCTS, isEnumValue } from '@/types';
import { filterFalsyValues } from '@/utils/object';
import uniq from 'lodash/uniq';
import pickBy from 'lodash/pickBy';
import {
  GetPaymentsFilters,
  PaymentsSearchParams,
  PaymentsSortBy,
  PaymentsIsTestFilter,
  GetPaymentsParams
} from './types';
import { allowedStatusesForFilter, initialSearchParams } from './constants';
import { PAYMENT_STATUSES, PaymentStatus } from '../payment';

export const prepareGetPaymentsParams = (params?: GetPaymentsParams) => {
  return pickBy(params, Boolean);
};

export const paymentsParamsToFilters = (data: PaymentsSearchParams): GetPaymentsFilters => {
  const result: GetPaymentsFilters = {};

  if (isValidDateString(data.createdFrom)) {
    result.created_at__gte = data.createdFrom;
  }

  if (isValidDateString(data.createdTo)) {
    result.created_at__lte = data.createdTo;
  }

  result.is_test = isEnumValue(PaymentsIsTestFilter, data.test) ? data.test : undefined;
  result.query = data.query;
  result.sort_by = isEnumValue(PaymentsSortBy, data.sortBy) ? data.sortBy : undefined;
  result.sort_direction = isEnumValue(SORT_DIRECTION, data.sortBy) ? data.sortBy : undefined;

  if (data.status) {
    const statuses = data.status
      .split(',')
      .reduce<PaymentStatus[]>(
        (acc, status) => (isEnumValue(PAYMENT_STATUSES, status) ? [...acc, status] : acc),
        []
      )
      .filter((status) => allowedStatusesForFilter.includes(status));

    result.status = uniq(statuses);
  }

  if (data.products) {
    const products = data.products
      .split(',')
      .reduce<TabbyProduct[]>(
        (acc, product) => (isEnumValue(TABBY_PRODUCTS, product) ? [...acc, product] : acc),
        []
      );

    result.product_type = uniq(products);
  }

  return {
    ...initialSearchParams,
    ...filterFalsyValues(result)
  };
};
