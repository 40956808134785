import { createApi } from '@reduxjs/toolkit/query/react';
import { DownloadFileResponse, DownloadFileTransformedResponse } from './types';
import { toBase64Asset } from './utils';
import { axiosBaseQueryWithAuth } from './axiosBaseQuery';

export const axiosFileUploadApi = createApi({
  reducerPath: 'axiosApi',
  baseQuery: axiosBaseQueryWithAuth,
  tagTypes: [],
  endpoints: (build) => ({
    uploadFile: build.mutation<
      string,
      { file: File; signal: AbortSignal; onUploadProgress: (event: number) => void }
    >({
      query: ({ file, onUploadProgress, signal }) => {
        const data = new FormData();
        data.append('file', file);
        return {
          url: '/merchant/api/v2/upload',
          method: 'POST',
          onUploadProgress: (progressEvent) => {
            const progress = Math.round((progressEvent.loaded / (progressEvent.total || 0)) * 100);
            onUploadProgress(progress);
          },
          data,
          signal
        };
      }
    }),
    downloadFile: build.query<DownloadFileTransformedResponse, string>({
      query: (url) => ({
        url: `/merchant/api/v2/download?url=${encodeURIComponent(url)}`,
        method: 'GET'
      }),
      transformResponse: (response: DownloadFileResponse) => {
        return toBase64Asset(response);
      }
    })
  })
});

export const { useUploadFileMutation, useLazyDownloadFileQuery } = axiosFileUploadApi;
