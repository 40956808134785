import { uuid } from '@/utils/string';
import { rootApi } from '@/store/rootApi';
import {
  Payment,
  PaymentCaptureParams,
  PaymentDeleteRefundParams,
  PaymentDetailed,
  PaymentEditParams,
  PaymentRefundParams
} from '../types';

const paymentApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getPayment: build.query<PaymentDetailed, string>({
      query: (id) => ({
        url: `/merchant/api/v1/payments/${id}`,
        method: 'GET'
      }),
      providesTags: (result, error, id) => [{ type: 'Payment', id }]
    }),
    capturePayment: build.mutation<Payment, PaymentCaptureParams>({
      query: ({ id, amount }) => ({
        url: `/merchant/api/v1/payments/${id}/captures`,
        body: { amount },
        method: 'POST'
      })
    }),
    closePayment: build.mutation<Payment, string>({
      query: (id) => ({
        url: `/merchant/api/v1/payments/${id}/close`,
        method: 'POST'
      })
    }),
    editPayment: build.mutation<Payment, PaymentEditParams>({
      query: ({ id, payment }) => ({
        url: `/merchant/api/v1/payments/${id}`,
        body: payment,
        method: 'PUT'
      })
    }),
    refundPayment: build.mutation<Payment, PaymentRefundParams>({
      query: ({ id, amount }) => ({
        url: `/merchant/api/v1/payments/${id}/refunds`,
        method: 'POST',
        headers: {
          'Idempotency-Key': uuid()
        },
        body: { amount }
      })
    }),
    deleteRefundPayment: build.mutation<Payment, PaymentDeleteRefundParams>({
      query: ({ id, refundId }) => ({
        url: `/merchant/api/v1/payments/${id}/refunds/${refundId}`,
        method: 'DELETE'
      })
    })
  })
});

export const {
  useGetPaymentQuery,
  useLazyGetPaymentQuery,
  useCapturePaymentMutation,
  useClosePaymentMutation,
  useEditPaymentMutation,
  useRefundPaymentMutation,
  useDeleteRefundPaymentMutation
} = paymentApi;
