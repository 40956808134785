import { rootApi } from '../rootApi/api';
import { BusinessSectionItem, BusinessCategoryItem, Application, ApplicationV3 } from './types';

const applicationApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getBusinessSections: build.query<BusinessSectionItem[], void>({
      query: () => '/merchant/api/v1/application/sections',
      providesTags: ['BusinessSections']
    }),
    getBusinessCategories: build.query<BusinessCategoryItem[], string>({
      query: (sectionId) => `/merchant/api/v1/application/sections/${sectionId}/categories`,
      providesTags: (result, error, id) => [{ type: 'BusinessCategories', id }]
    }),
    getApplication: build.query<Application, void>({
      query: () => '/merchant/api/v2/application',
      providesTags: () => ['Application']
    }),
    getApplicationV3: build.query<ApplicationV3, void>({
      query: () => '/partners/application/api/v1/application',
      providesTags: () => ['ApplicationV3']
    }),
    updateApplicationV3: build.mutation<void, ApplicationV3>({
      query: (application) => ({
        url: `/partners/application/api/v1/application/${application.id}`,
        method: 'PUT',
        body: application,
        keepalive: true
      })
    }),
    submitApplicationV3: build.mutation<void, ApplicationV3>({
      query: (application) => ({
        url: `/partners/application/api/v1/application/${application.id}/submit`,
        method: 'POST',
        body: application
      }),
      invalidatesTags: ['ApplicationV3', 'Me']
    })
  })
});

export const {
  useGetBusinessSectionsQuery,
  useLazyGetBusinessCategoriesQuery,
  useGetApplicationQuery,
  useGetApplicationV3Query,
  useSubmitApplicationV3Mutation,
  useUpdateApplicationV3Mutation
} = applicationApi;
