import { v4 as uuidv4 } from 'uuid';
import { Falsy } from '@/types';

export const filterFalsyValues = <T extends Record<string, any>, K extends keyof T>(
  object = {} as T,
  allowedFalsyValues = [] as Falsy[]
) =>
  Object.entries(object).reduce((acc, [key, value]) => {
    if (Boolean(value) || allowedFalsyValues.includes(value)) {
      acc[key as K] = value;
    }
    return acc;
  }, {} as T);

export const getTruthyKeys = (object = {} as Record<string, any>) =>
  Object.keys(object).filter((key) => Boolean(object[key]));

export const removeKeys = <T extends Record<string, any>, K extends keyof T>(
  object = {} as T,
  keys = [] as K[]
) =>
  Object.keys(object).reduce((acc, key) => {
    if (!keys.includes(key as K)) {
      acc[key as K] = object[key];
    }
    return acc;
  }, {} as T);

export const arrayToFlatObject = (array: string[], value: any) =>
  array.reduce((acc, str) => ({ ...acc, [str]: value }), {});

export const withId = <T>(params: T): T & { id: string } => {
  return { id: uuidv4(), ...params };
};
