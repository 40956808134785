import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import memoize from 'lodash/memoize';

export const formatPhoneNumber = memoize((phone: string): string => {
  try {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedPhoneNumber = phoneUtil.parse(phone);
    const isValidNumber = phoneUtil.isValidNumber(parsedPhoneNumber);

    if (!isValidNumber) {
      throw new Error('invalid_phone_number_please_check_your_input');
    }

    const formattedPhoneNumber = phoneUtil.format(
      parsedPhoneNumber,
      PhoneNumberFormat.INTERNATIONAL
    );

    return formattedPhoneNumber;
  } catch (error) {
    return phone;
  }
});
