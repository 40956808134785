import { compose } from 'lodash/fp';
import { annualVolumeToTier } from './constants';
import { ApplicationAnnualVolume, Tier } from './types';

export const getTierByAnnualVolume = (volume: string) =>
  annualVolumeToTier[volume as ApplicationAnnualVolume];

export const isFirstOrSecondTier = (tier: Tier) => [1, 2].includes(tier);

export const isFirstOrSecondTierByVolume = compose(isFirstOrSecondTier, getTierByAnnualVolume);
