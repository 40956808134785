import useMediaQuery from '@mui/material/useMediaQuery';

const useIsMobilePage = () => {
  const isMobile = useMediaQuery('(max-width:768px)', {
    noSsr: true
  });

  return isMobile;
};

export default useIsMobilePage;
