import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useStartSupportChatQuery, client } from '@/store/support-chat';
import { useGetHasActiveTicketsQuery } from '@/store/support';
import { skipToken } from '@reduxjs/toolkit/query';
import { Account } from '@/store/account';
import { useMeQuery } from '@/store/account-me';
import { ROUTES } from '@/routes';
import { useMatch } from 'react-router-dom';

type GetStreamContextProps = {
  client: typeof client | null;
  hasUnreadMessages: boolean;
  initGetStream: (account: Account, shouldSkipActiveTicketsCheck: boolean) => void;
  isError: boolean;
  unreadChannelIds: string[];
};

const GetStreamContext = createContext<GetStreamContextProps | null>(null);

export const GetStreamProvider = ({ children }: { children: ReactNode }) => {
  const [account, setAccount] = useState<Account | null>(null);

  const [skipActiveTicketsCheck, setSkipActiveTicketsCheck] = useState(false);
  const { data: hasActiveTickets = false } = useGetHasActiveTicketsQuery(undefined, {
    skip: !account || skipActiveTicketsCheck
  });

  const { data, isSuccess, isError } = useStartSupportChatQuery(
    account && (hasActiveTickets || skipActiveTicketsCheck)
      ? { user_id: account.id, name: account.email || account.phone || '' }
      : skipToken
  );

  const initGetStream = useCallback(
    (acc: Account, shouldSkipActiveTicketsCheck: boolean) => {
      setSkipActiveTicketsCheck(shouldSkipActiveTicketsCheck);
      if (account?.id !== acc.id) setAccount(acc);
    },
    [account]
  );

  const value = useMemo(
    (): GetStreamContextProps => ({
      client: isSuccess ? client : null,
      hasUnreadMessages: data?.has_unread_messages || false,
      initGetStream,
      isError,
      unreadChannelIds: data?.unread_channel_ids || []
    }),
    [data?.has_unread_messages, data?.unread_channel_ids, initGetStream, isError, isSuccess]
  );

  return <GetStreamContext.Provider value={value}>{children}</GetStreamContext.Provider>;
};

export const useGetStreamContext = () => {
  const context = useContext(GetStreamContext);

  if (context === null) {
    throw new Error('Must be used within GetStreamProvider');
  }

  return context;
};

export const useInitGetStream = () => {
  const { data: me } = useMeQuery();
  const isTicketPage = !!useMatch(ROUTES.supportTicketId)?.params.id;
  const { initGetStream } = useGetStreamContext();

  useEffect(() => {
    if (me?.account && me.computedPermissions.canViewSupport) {
      initGetStream(me.account, isTicketPage);
    }
  }, [isTicketPage, initGetStream, me]);
};
