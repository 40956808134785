import { Trans, useTranslation } from 'react-i18next';

import { TextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import { CapitalLoan } from '@/store/loan';
import useRepaymentData from '@/store/loan/hooks/useRepaymentData';
import { maxBy } from 'lodash';
import { useMemo } from 'react';
import { Alert } from './Alert';
import { ContractSignedAlert } from './ContractSignedAlert';
import { LoanClosedAlert } from './LoanClosedAlert';

export const LoanDetailsAlert = ({ loan }: { loan: CapitalLoan }) => {
  const { t } = useTranslation();
  const { overdueData, alertStatus, data: repayments = [] } = useRepaymentData(loan);
  const { minimum_repayment_amount, currency, repayment_rate, next_settlement_date, status } = loan;

  const lastRepayment = maxBy(repayments, 'date');
  const nextWeekAmount = lastRepayment
    ? Number(minimum_repayment_amount) - lastRepayment.repaymentAmount
    : 0;

  const title = useMemo(() => {
    switch (alertStatus) {
      case 'payingWarning':
        return t('repayment_rate_increased_this_week');
      case 'overdueCritical':
        return t('this_week_payout_was_amount', { amount: `${currency} 0` });
      case 'overdueWeeklyMin':
        return t('this_weeks_sales_didnt_meet_the_minimum_repayment');
      default:
        return '';
    }
  }, [alertStatus, t, currency]);

  const text = useMemo(() => {
    switch (alertStatus) {
      case 'payingWarning':
        return (
          <Trans
            i18nKey="to_meet_your_minimum_repayment_we_deducted_more_than_percent_from_your_date_settlement"
            components={{ date: <TextDate format="ddmm" timestamp={overdueData?.date || ''} /> }}
            values={{ percent: repayment_rate }}
          />
        );
      case 'overdueCritical':
        return t('we_will_deduct_the_weekly_minimum_repayment_from_your_sales_next_week');
      case 'overdueWeeklyMin':
        return (
          <Trans
            i18nKey="well_deduct_the_remainig_amount_from_your_sales_next_week"
            components={{
              amount: <TextCurrency amount={nextWeekAmount} currency={currency} />
            }}
          />
        );

      default:
        return '';
    }
  }, [alertStatus, currency, nextWeekAmount, overdueData?.date, repayment_rate, t]);

  if (status === 'waiting' && next_settlement_date) {
    return <ContractSignedAlert settlementDate={next_settlement_date} />;
  }

  if (status === 'closed') {
    return <LoanClosedAlert />;
  }

  const alertVariant = alertStatus === 'payingWarning' ? 'warning' : 'error';

  if (!title) return null;

  return <Alert variant={alertVariant} title={title} text={text} />;
};
