import { useEffect } from 'react';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { ApplicationFormValues } from '../types';
import { useFormPropsContext } from './useFormPropsContext';

const checkIsEmptyBasicInfo = ({ basic_info }: Pick<ApplicationFormValues, 'basic_info'>) => {
  const { email, phone, ...rest } = basic_info;
  return Object.values(rest).every((value) => value === '');
};

export const useOpenFormTrackingEvents = () => {
  const track = useAnalyticsTrack();

  const { getValues } = useFormPropsContext();
  const isBasicInfoEmpty = checkIsEmptyBasicInfo(getValues());

  useEffect(() => {
    if (isBasicInfoEmpty) {
      track({ event: Events.ApplicationFirstTimeOpened });
    } else {
      track({ event: Events.ApplicationFormOpened });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [track]);
};
