import { styled } from '@mui/material/styles';
import { Skeleton } from '@/ui-kit/Skeleton';
import { SelectProps } from './Select';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
}));

export const SelectSkeleton = ({
  hasLabel,
  size = 'small'
}: Pick<SelectProps, 'size'> & { hasLabel?: boolean }) => {
  return (
    <Root>
      {hasLabel && <Skeleton height="18px" width="50%" />}
      {size === 'small' && <Skeleton height="40px" sx={{ borderRadius: '16px' }} />}
      {size === 'medium' && <Skeleton height="56px" sx={{ borderRadius: '16px' }} />}
    </Root>
  );
};
