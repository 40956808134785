import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useShowNotification, NOTIFICATION_VARIANTS } from '@/components/Notifications';

export const useConnectionStatus = () => {
  const showNotification = useShowNotification();
  const { t } = useTranslation();

  useEffect(() => {
    const handleOnline = () => {
      showNotification({
        variant: NOTIFICATION_VARIANTS.Success,
        message: t('you_are_back_online')
      });
    };

    const handleOffline = () => {
      showNotification({
        variant: NOTIFICATION_VARIANTS.Error,
        message: t('you_are_currently_offline_please_check_your_network_connection')
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [showNotification, t]);
};
