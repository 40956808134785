import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query/react';
import { FetchAxiosBaseQueryError } from './types';
import { authSlice } from '../auth/state';
import { getDeprecatedAuthToken } from '../auth/utils';

const $api = axios.create({
  baseURL: process.env.API_HOST
});

$api.interceptors.request.use((config) => {
  const token = getDeprecatedAuthToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const axiosBaseQuery: BaseQueryFn<AxiosRequestConfig, unknown, FetchAxiosBaseQueryError> = async ({
  url,
  ...config
}: AxiosRequestConfig) => {
  try {
    const result = await $api({
      url: process.env.API_HOST + url,
      withCredentials: true,
      ...config
    });
    return { data: result.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    return {
      error: {
        message: err.message,
        name: err.name,
        code: err.code,
        status: err.status,
        stack: err.stack
      }
    };
  }
};

export const axiosBaseQueryWithAuth: BaseQueryFn<
  AxiosRequestConfig,
  unknown,
  FetchAxiosBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await axiosBaseQuery(args, api, extraOptions);
  const status = result?.error?.status || null;
  api.dispatch(authSlice.actions.set401Error(status === 401));
  return result;
};
