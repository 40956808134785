import { ChangeEvent } from 'react';
import MuiRadioGroup from '@mui/material/RadioGroup';
import BaseFormControlLabel from '@mui/material/FormControlLabel';
import { Skeleton, FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { RadioBase } from '@/ui-kit/RadioBase';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { RadioGroupProps } from './types';

const FormControlLabel = styled(BaseFormControlLabel)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight500,
  display: 'flex',
  alignItems: 'flex-start',
  color: theme.palette.B2B2023.front.major,

  '& .MuiFormControlLabel-label': {
    alignSelf: 'center'
  }
}));

const Description = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  marginBottom: '12px',
  color: theme.palette.grey[400]
}));

const RadioControl = styled(RadioBase)(() => ({
  padding: '0px 9px',
  height: '100%'
}));

const RadioSkeleton = () => <Skeleton animation="wave" width={200} height={34} />;

export const RadioGroup = ({
  onChange,
  onBlur,
  options,
  value,
  loading,
  helperText,
  invalid,
  sx,
  disabled: groupDisabled
}: RadioGroupProps) => {
  const handleRadioClick = (event: ChangeEvent<HTMLInputElement>, selected: string) => {
    if (selected !== value) {
      onChange?.(selected);
    }
  };

  return (
    <>
      <MuiRadioGroup
        onBlur={onBlur}
        aria-label="behaviour"
        name="behaviour"
        value={value}
        onChange={handleRadioClick}
        sx={sx}
      >
        {loading && (
          <div>
            <RadioSkeleton />
            <RadioSkeleton />
            <RadioSkeleton />
          </div>
        )}
        {options.map(({ value: optionValue, label, description, disabled }) => (
          <FormControlLabel
            sx={{ alignItems: 'center' }}
            key={optionValue}
            value={optionValue}
            disabled={disabled || groupDisabled}
            label={
              <>
                {label}
                {description && <Description>{description}</Description>}
              </>
            }
            control={<RadioControl disabled={disabled || groupDisabled} />}
          />
        ))}
      </MuiRadioGroup>
      {Boolean(helperText) && <FormHelperText error={invalid}>{helperText}</FormHelperText>}
    </>
  );
};
