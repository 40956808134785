import { Events } from '@/store/analytics';
import { Button as BaseButton } from '@/ui-kit/Button';
import { SelectControlled } from '@/ui-kit/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { useGetMerchantsQuery } from '@/store/merchants';
import { RadioGroupControlled } from '@/ui-kit/RadioGroup';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useGoalItems } from '../hooks/useGoalItems';

const Root = styled('div')(() => ({
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

const RadioButtons = styled('div')(() => ({
  padding: '0px 16px'
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const ButtonWrapper = styled('div')(() => ({
  textAlign: 'center'
}));

const Button = styled(BaseButton)(() => ({
  width: '100%'
}));

const Text = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  color: theme.palette.B2B2023.front.minor
}));

const FooterText = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Loose500,
  color: theme.palette.B2B2023.front.minor,
  margin: '10px 24px 0px',

  [theme.breakpoints.down('p768')]: {
    margin: '10px 0px 0px'
  }
}));

const MenuItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  padding: '11px 0px'
}));

const MenuItemLabel = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight500,
  color: theme.palette.B2B2023.front.major
}));

export const Offer = () => {
  const { t } = useTranslation();
  const { isLoading } = useFormStateContext();
  const { control, watch } = useFormPropsContext();
  const { submit } = useFormSubmit();

  const goalOptions = useGoalItems();

  const { data: merchants, isFetching: isFetchingMerchants } = useGetMerchantsQuery({
    scope: 'all'
  });

  const isOtherGoalSelected = watch('capitalUse') === 'other';
  const isFetching = isLoading || isFetchingMerchants;

  return (
    <Root>
      <Column>
        <Text>{t('choose_the_store_you_would_want_to_use_capital_for')}</Text>
        {isFetching ? (
          <Skeleton height={40} />
        ) : (
          <SelectControlled
            control={control}
            name="stores"
            placeholder={t('select_one_or_more_stores')}
            options={
              merchants
                ? merchants.map(({ id, name }) => {
                    return {
                      value: id,
                      label: name,
                      customHtml: (
                        <MenuItem key={id}>
                          <MenuItemLabel>{name}</MenuItemLabel>
                        </MenuItem>
                      )
                    };
                  })
                : []
            }
            analytics={{
              event: Events.CapitalStoreSelected
            }}
            required
            multiple
            outsideChips
          />
        )}
      </Column>

      <div>
        <Column>
          <Text>{t('what_would_be_the_main_use_for_your_advance_payout')}</Text>
          <RadioButtons>
            <RadioGroupControlled control={control} name="capitalUse" options={goalOptions} />
          </RadioButtons>
        </Column>
        {isOtherGoalSelected && (
          <TextFieldControlled
            control={control}
            name="otherUse"
            placeholder={t('let_us_know_what_you_use_capital_for')}
            multiline
            rows={3}
          />
        )}
      </div>

      <ButtonWrapper>
        <Button size="large" onClick={submit} disabled={isLoading}>
          {t('join_the_waitlist')}
        </Button>
        <FooterText>{t('register_your_interest_for_tabby_capital__description')}</FooterText>
      </ButtonWrapper>
    </Root>
  );
};
