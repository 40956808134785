import {
  createApi,
  fetchBaseQuery,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError
} from '@reduxjs/toolkit/query/react';
import { serializeGetParams } from '@/utils/url';
import { getDeprecatedAuthToken } from '../auth/utils';
import { TAG_TYPES } from './constants';
import { addCacheBusterToFetchArgs } from './utils';
import { authSlice } from '../auth/state';

// auth token is in http-only cookie
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.API_HOST,
  // TODO: Remove authorization bearer header on 1st September 2024
  prepareHeaders: (headers) => {
    const token = getDeprecatedAuthToken();
    if (token) headers.set('authorization', `Bearer ${token}`);
    return headers;
  },
  paramsSerializer: serializeGetParams,
  credentials: 'include',
  mode: 'cors'
});

const baseQueryWithAuth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(addCacheBusterToFetchArgs(args), api, extraOptions);
  const status = result.error?.status || result.meta?.response?.status || null;
  api.dispatch(authSlice.actions.set401Error(status === 401));
  return result;
};

export const rootApi = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: TAG_TYPES,
  endpoints: () => ({})
});
