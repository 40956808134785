import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { generateStepPath } from '../utils';
import { useFormStateContext } from './useFormStateContext';
import { useHandleValidateCurrentStep } from './useHandleValidateCurrentStep';

export const useHandleNextClick = () => {
  const navigate = useNavigate();
  const { currentStepName, currentIndex, steps, limit, shouldSubmitForm, submitApplication } =
    useFormStateContext();

  const { validateCurrentStep } = useHandleValidateCurrentStep();

  const next = useCallback(() => {
    const newIndex = Math.min(limit, currentIndex + 1);
    navigate(generateStepPath(steps[newIndex].name));
  }, [currentIndex, navigate, limit, steps]);

  const handleNextClick = useCallback(async () => {
    if (shouldSubmitForm) {
      submitApplication();
      return;
    }

    const isStepValid = await validateCurrentStep(currentStepName);

    if (!isStepValid) {
      return;
    }

    next();
  }, [currentStepName, next, shouldSubmitForm, submitApplication, validateCurrentStep]);

  return { handleNextClick };
};
