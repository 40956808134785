import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import ArrowChevronRightIcon from '@/assets/icons/arrow-chevron-right.svg?icon';
import ArrowChevronLeftIcon from '@/assets/icons/arrow-chevron-left.svg?icon';
import { Theme, styled } from '@mui/material/styles';
import { TextBox, Props as TextBoxProps } from '@/components/TextBox/TextBox';
import { useTranslation } from 'react-i18next';

const Root = styled('div')(({ theme }: { theme: Theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '70vw',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  ...theme.typography.h4
}));

const LinkStyled = styled(Link, { shouldForwardProp: (prop) => prop !== 'isLast' })<{
  isLast?: boolean;
}>(({ theme, isLast }) => ({
  textTransform: 'capitalize',
  color: theme.palette._text.default,
  overflow: 'hidden',
  ...(isLast && {
    color: theme.palette.common.black,
    pointerEvents: 'none'
  }),
  '&:first-of-type': {
    overflow: 'unset'
  }
}));

const LinkStyledNoHref = styled('div', { shouldForwardProp: (prop) => prop !== 'isLast' })<{
  isLast?: boolean;
}>(({ theme, isLast }) => ({
  textTransform: 'capitalize',
  color: theme.palette._text.default,
  overflow: 'hidden',
  ...(isLast && {
    color: theme.palette.common.black,
    pointerEvents: 'none'
  }),
  '&:first-of-type': {
    overflow: 'unset'
  }
}));

const Separator = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0px 5px'
}));

type Props = {
  crumbs: {
    id: string;
    href: string;
    label: string;
    textBoxProps?: Omit<TextBoxProps, 'children'>;
  }[];
};

export const Breadcrumbs = ({ crumbs }: Props) => {
  const { i18n } = useTranslation();

  return (
    <Root>
      {crumbs.map((crumb, index) => {
        const isLast = crumbs.length - 1 === index;

        return (
          <Fragment key={crumb.id}>
            {crumb.href ? (
              <LinkStyled isLast={isLast} to={crumb.href}>
                <TextBox {...crumb.textBoxProps}>{crumb.label}</TextBox>
              </LinkStyled>
            ) : (
              <LinkStyledNoHref isLast={isLast}>
                <TextBox {...crumb.textBoxProps}>{crumb.label}</TextBox>
              </LinkStyledNoHref>
            )}
            {!isLast && (
              <Separator>
                {i18n.dir() === 'ltr' ? <ArrowChevronRightIcon /> : <ArrowChevronLeftIcon />}
              </Separator>
            )}
          </Fragment>
        );
      })}
    </Root>
  );
};
