import { TextCurrency as BaseTextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import Divider from '@mui/material/Divider';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import useRepaymentData from '@/store/loan/hooks/useRepaymentData';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { REPAYMENT_INFO_STATUSES, RepaymentInfoStatus } from '../types';
import { EmptyState } from './EmptyState';
import { useLoanDetailsContext } from '../hooks/useLoanDetailsContext';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  [theme.breakpoints.down('p768')]: {
    width: '100%'
  }
}));

const Item = styled('div')(({ theme }) => ({
  borderRadius: '16px',
  background: theme.palette.B2B2023.back.major
}));

const ItemTitle = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._h3,
  padding: '18px 24px'
}));

const ItemDeducted = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end'
}));

const TextByStatus = styled(DeprecatedTypography, {
  shouldForwardProp: (props) => props !== 'status'
})<{ status?: RepaymentInfoStatus }>(({ theme, status }) => {
  const color =
    status &&
    {
      [REPAYMENT_INFO_STATUSES.earlyPayOff]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.paying]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.paid]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.overdueCritical]: theme.palette.B2B2023.accent.negativeA,
      [REPAYMENT_INFO_STATUSES.overdueWarning]: theme.palette.B2B2023.accent.warningA
    }[status];

  return {
    color,
    display: 'block'
  };
});

const ItemData = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const ItemDataRow = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Loose500,
  color: theme.palette.B2B2023.front.minor,
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 0'
}));

const TextCurrency = styled(BaseTextCurrency)(({ theme }) => ({
  color: theme.palette.B2B2023.front.major
}));

const EmptyStateWrapper = styled('div')(({ theme }) => ({
  borderRadius: '16px',
  background: theme.palette.B2B2023.back.major,
  padding: '16px',

  [theme.breakpoints.down('p768')]: {
    with: '100%'
  }
}));

export const ListRepayments = () => {
  const { loan } = useLoanDetailsContext();

  const { t } = useTranslation();
  const { data: repayments } = useRepaymentData(loan);

  if (repayments.length === 0) {
    return (
      <EmptyStateWrapper>
        <EmptyState />
      </EmptyStateWrapper>
    );
  }

  return (
    <Root>
      {repayments.map((repayment) => {
        const {
          date,
          deducted,
          settlementPayout,
          repaymentAmount,
          resultingPayout,
          currency,
          status,
          repaymentType
        } = repayment;

        const showDeducted = !!(settlementPayout && repaymentAmount && deducted);
        const isEarlyPayoffPayment = repaymentType === 'early_pay_off';

        return (
          <Item key={settlementPayout}>
            <ItemTitle>
              <TextDate format="ddmmyyyy" timestamp={date} />
            </ItemTitle>
            <Divider />
            <ItemData>
              <ItemDataRow>
                <span>{t('repayment')}</span>
                <ItemDeducted>
                  <TextCurrency currency={currency} amount={repaymentAmount} />
                  {isEarlyPayoffPayment && (
                    <TextByStatus variant="_caption2Tight500" status={status}>
                      {t('early_payoff')}
                    </TextByStatus>
                  )}
                  {showDeducted && (
                    <TextByStatus variant="_caption2Tight500" status={status}>
                      {t('percent_deducted', { amount: deducted })}
                    </TextByStatus>
                  )}
                </ItemDeducted>
              </ItemDataRow>
              <ItemDataRow>
                <span>{t('sales')}</span>
                {isEarlyPayoffPayment ? (
                  '-'
                ) : (
                  <TextCurrency currency={currency} amount={settlementPayout} />
                )}
              </ItemDataRow>
              <ItemDataRow>
                <span>{t('final_payout')}</span>
                {isEarlyPayoffPayment ? (
                  '-'
                ) : (
                  <TextCurrency currency={currency} amount={resultingPayout} />
                )}
              </ItemDataRow>
            </ItemData>
          </Item>
        );
      })}
    </Root>
  );
};
