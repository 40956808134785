import { Control, useController, type FieldValues, type Path } from 'react-hook-form';
import { EventName, useAnalyticsTrack } from '@/store/analytics';
import { Select, type SelectProps } from './Select';

type Props<T extends FieldValues> = Omit<SelectProps, 'value'> & {
  control: Control<T>;
  name: Path<T>;
  analytics?: { event: EventName } & Record<string, string>;
};

export const SelectControlled = <T extends FieldValues>({
  name,
  control,
  analytics,
  onChange,
  onBlur,
  helperText,
  ...rest
}: Props<T>) => {
  const {
    field,
    fieldState: { invalid, error }
  } = useController({
    name,
    control
  });
  const track = useAnalyticsTrack();

  const handleChange: SelectProps['onChange'] = (event) => {
    const value = event.target.value as string;

    field.onChange(value);
    onChange?.(event);

    if (analytics) {
      const { event: analyticsEvent, ...analyticsParams } = analytics;

      track({
        event: analyticsEvent,
        params: {
          value,
          ...analyticsParams
        }
      });
    }
  };

  const handleBlur: SelectProps['onBlur'] = (event) => {
    field.onBlur();
    onBlur?.(event);
  };

  return (
    <Select
      inputRef={field.ref}
      value={field.value}
      onChange={handleChange}
      onBlur={handleBlur}
      error={invalid}
      helperText={error?.message ? error.message || error.message : helperText}
      {...rest}
    />
  );
};
