import { useEffect } from 'react';
import { useLocation, useMatch } from 'react-router-dom';
import { LogoutSource, ROUTES } from '@/routes';
import { useAnalyticsPage } from './useAnalyticsPage';

export const useReportPageView = () => {
  const location = useLocation();
  const logoutMatch = useMatch(ROUTES.logout);
  const source = logoutMatch?.params.source as LogoutSource | undefined;
  const page = useAnalyticsPage({ skipMeQuery: source === 'server' });

  useEffect(() => {
    page();
  }, [location.pathname, page]);
};
