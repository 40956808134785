import { datadogRum } from '@datadog/browser-rum';

const EXCLUDED_ERROR_MESSAGES = [
  // These "ResizeObserver" errors occur due to the usage of react-virtuoso.
  // For more information, please refer to: https://virtuoso.dev/troubleshooting/
  'ResizeObserver loop completed with undelivered notifications',
  'ResizeObserver loop limit exceeded'
];

export const initDataDog = () => {
  if (process.env.NODE_ENV === 'development') {
    return;
  }

  datadogRum.init({
    applicationId: '1813d05a-5672-41c2-be11-bc3bf165bec3',
    clientToken: 'pub289d86b00983c610a91b7c220f5e8429',
    site: 'datadoghq.eu',
    service: 'merchant-dashboard.web-app',
    sampleRate: 100,
    trackInteractions: true,
    version: process.env.TAG.split('-')[0],
    env: process.env.ENVIRONMENT_NAME,
    allowedTracingOrigins: ['https://api.tabby.ai', /https:\/\/.*\.tabby\.dev/],
    defaultPrivacyLevel: 'allow',
    beforeSend: (event) => {
      if (
        event.type === 'error' &&
        EXCLUDED_ERROR_MESSAGES.some((m) => event.error.message.includes(m))
      ) {
        return false;
      }

      return true;
    }
  });

  datadogRum.startSessionReplayRecording();
};
