import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export const useFormSchema = () => {
  const { t } = useTranslation();

  return yup.object().shape({
    capitalUse: yup
      .string()
      .trim()
      .required(() => t('choose_option')),
    otherUse: yup.string().trim(),
    stores: yup
      .array()
      .min(1, () => '')
      .of(yup.string())
      .required(() => '')
  });
};
