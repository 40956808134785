import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { SetupChallengeEmailForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeSetupEmail = () => {
  const { state, goToSignIn, setStep, setEmail } = useAuthPageContext();
  const { step, email: initialEmail } = state;

  const handleSuccess = (email: string) => {
    setEmail(email);
    setStep('challenge_setup_verify_email');
  };

  if (step === 'challenge_setup_email') {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <SetupChallengeEmailForm initialEmail={initialEmail} onSuccess={handleSuccess} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
