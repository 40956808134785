import { matchPath } from 'react-router-dom';
import { ROUTES } from '@/routes';

export const getAnalyticsPageName = (pathname = window.location.pathname) => {
  const onboardingMatch = matchPath(ROUTES.onboarding, pathname);
  const storesMatch = matchPath(ROUTES.businessProfileStores, pathname);

  switch (true) {
    case Boolean(onboardingMatch):
      return 'onboarding';
    case Boolean(storesMatch):
      return 'store';
    default:
      return '';
  }
};
