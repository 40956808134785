import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Role, Permission } from '@/store/account';

type Dict<T extends string> = Record<T, string>;

export const useAccountRoleLabels = () => {
  const { t } = useTranslation();

  const roles: Dict<Role> = useMemo(
    () => ({
      advertiser: t('advertiser'),
      call_center_operator: t('none'),
      refunds_manager: t('refunds_manager'),
      sales_manager: t('sales_manager'),
      settlements: t('admin'),
      settlements_view_only: t('finance_manager'),
      supervisor: t('supervisor'),
      tabby_customer_care: t('none'),
      tabby_sales_manager: t('none'),
      view_only: t('customer_care_agent'),
      workspace_admin: t('workspace_admin'),
      dispute_manager: t('dispute_manager')
    }),
    [t]
  );

  const descriptions: Dict<Role> = useMemo(
    () => ({
      advertiser: t('advertiser_description'),
      call_center_operator: t('none'),
      refunds_manager: t('refunds_manager_description'),
      sales_manager: t('sales_manager_description'),
      settlements: t('admin_description'),
      settlements_view_only: t('finance_manager_description'),
      supervisor: t('supervisor_description'),
      tabby_customer_care: t('none'),
      tabby_sales_manager: t('none'),
      view_only: t('customer_care_agent_description'),
      workspace_admin: t('workspace_admin_description'),
      dispute_manager: t('dispute_manager_description')
    }),
    [t]
  );

  const permissions: Dict<Permission> = useMemo(
    () => ({
      DISPUTES_VIEW_AND_CHALLENGE: t('view_and_challenge_disputes'),
      USERS_EDIT: t('add_and_manage_users'),
      PAYMENTS_CAPTURE: t('capture_orders'),
      PAYMENTS_CREATE: t('create_orders_and_payment_links'),
      PAYMENTS_REFUND_AND_CANCEL: t('refund_and_cancel_orders'),
      PAYMENTS_VIEW: t('view_and_export_orders'),
      SETTLEMENTS_VIEW: t('view_and_export_settlements')
    }),
    [t]
  );

  const toOptions = useCallback(
    (arr: Role[]) => arr.map((value) => ({ value, label: roles[value] })),
    [roles]
  );

  return {
    labels: {
      roles,
      descriptions,
      permissions
    },
    toOptions
  };
};
