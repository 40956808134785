import { ErrorPage } from '@/components/ErrorPage';
import { ROUTES } from '@/routes';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { useIsExperimentOn } from '@/store/experiments';
import { useGetLoansWithLegalEntityQuery } from '@/store/loan';
import { useGetOffersQuery } from '@/store/offers';
import { find } from 'lodash';
import { useEffect } from 'react';
import { Navigate, generatePath } from 'react-router-dom';

export const RedirectByStatus = () => {
  const track = useAnalyticsTrack();
  const isTabbyCapitalFakeDoorOn = useIsExperimentOn('md-tabby-capital-fake-door');

  const {
    data: loans = [],
    isError: isErrorLoans,
    isLoading: isLoadingLoans
  } = useGetLoansWithLegalEntityQuery();
  const {
    data: offers = [],
    isLoading: isLoadingOffers,
    isError: isErrorOffers
  } = useGetOffersQuery();

  useEffect(() => {
    if (!isErrorLoans && !isErrorOffers) {
      track({ event: Events.CapitalPageOpened });
    }
  }, [isErrorLoans, isErrorOffers, track]);

  if (isTabbyCapitalFakeDoorOn) {
    return <Navigate to={generatePath(ROUTES.financeTabbyCapitalFakeDoor)} replace />;
  }

  if (isErrorLoans && isErrorOffers) {
    return <ErrorPage status={404} />;
  }

  if (isLoadingOffers || isLoadingLoans) return null;

  if (loans.length === 0 && offers.length === 0) {
    return (
      <Navigate to={generatePath(ROUTES.financeCompletedPayouts, { payoutId: null })} replace />
    );
  }

  if (loans.length === 1 && offers.length === 0) {
    const { id } = loans[0];
    return <Navigate to={generatePath(ROUTES.financeTabbyCapitalLoansId, { id })} replace />;
  }

  if (loans.length === 0 && offers.length > 0) {
    const defaultOffer = find(offers, (o) => Number(o.tenor) === 20);

    if (defaultOffer) {
      return (
        <Navigate
          to={generatePath(ROUTES.financeTabbyCapitalOffersId, { id: defaultOffer.id })}
          replace
        />
      );
    }

    return (
      <Navigate to={generatePath(ROUTES.financeCompletedPayouts, { payoutId: null })} replace />
    );
  }

  if (loans.length > 1 || offers.length > 0) {
    return <Navigate to={ROUTES.financeTabbyCapitalLoans} replace />;
  }

  return null;
};
