import { BaseProviderThemeOptions } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { typography } from './typography';

export const themeOptions: BaseProviderThemeOptions = {
  ds: DS.B2B2022,
  spacing: 4,
  typography,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: 'none'
        },
        body: {
          minHeight: '100vh',
          fontSize: '0.875rem',
          backgroundColor: '#F2F5F7'
        }
      }
    }
  }
};
