type SizeUnit = 'MB' | 'KB';

export const UNIT_SIZES_IN_BYTES: Record<SizeUnit, number> = {
  MB: 1_000_000,
  KB: 1024
};

export type isFileSizeValidOptions = { min?: number; max?: number; unit: SizeUnit };

export const isFileSizeValid = (
  { size }: File,
  { min = 0, max = 0, unit }: isFileSizeValidOptions
): boolean => {
  const unitSizeInBytes = UNIT_SIZES_IN_BYTES[unit];
  const minLimit = min * unitSizeInBytes;
  const maxLimit = max * unitSizeInBytes;

  switch (true) {
    case min > 0 && max > 0:
      return size >= minLimit && size <= maxLimit;
    case min > 0:
      return size >= minLimit;
    case max > 0:
      return size <= maxLimit;
    default:
      return false;
  }
};
