import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { CodeForm } from './CodeForm';
import { Header } from './Header';
import { EMAIL_OTP_RESEND_INTERVAL_MS, EMAIL_OTP_CODE_LENGTH } from '../constants';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Subtitle = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '4px'
}));

const Truncate = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  email: string;
  isLoading: boolean;
  onSubmitSuccess: (value: string) => void;
  onResend: ({ resetTimer }: { resetTimer: VoidFunction }) => void;
  onEmailChange?: VoidFunction;
  errorMessage?: string;
};

export const VerifyEmailForm = (props: Props) => {
  const { onSubmitSuccess, email, onEmailChange, isLoading, onResend, errorMessage } = props;
  const { t } = useTranslation();

  return (
    <Root>
      <Header
        title={t('verify_your_identity')}
        subtitle={
          <Subtitle>
            <div>{t('we_sent_a_6_digit_code_to')}</div>
            <Truncate>
              <b>{email}</b>.
            </Truncate>
            <div>{t('this_code_will_be_valid_for_2_minutes')}</div>
          </Subtitle>
        }
        onChange={onEmailChange}
        changeButtonText={t('change_email_address')}
      />
      <CodeForm
        codeLength={EMAIL_OTP_CODE_LENGTH}
        isLoading={isLoading}
        onResend={onResend}
        onSubmitSuccess={onSubmitSuccess}
        resendIntervalMs={EMAIL_OTP_RESEND_INTERVAL_MS}
        errorMessage={errorMessage}
      />
    </Root>
  );
};
