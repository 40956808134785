import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifyChallengePhoneForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeSetupVerifyPhone = () => {
  const { state, setStep } = useAuthPageContext();
  const { step, phone } = state;

  const goBack = () => {
    setStep('challenge_setup_phone');
  };

  if (step === 'challenge_setup_verify_phone' && phone) {
    return (
      <AuthStepWidget onGoBack={goBack}>
        <VerifyChallengePhoneForm phone={phone} onPhoneChange={goBack} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
