import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@mui/material';
import Bag24 from '@tabby.ai/tabby-ui/dist/icons/core/Bag24';
import Bank24 from '@tabby.ai/tabby-ui/dist/icons/core/Bank24';
import Refresh24 from '@tabby.ai/tabby-ui/dist/icons/core/Refresh24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

const ExampleSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '16px',
  padding: '16px 12px',
  marginTop: '16px',
  background: theme.palette.B2B2023.back.minor
}));

const ExampleRow = styled('div')(() => ({
  display: 'flex',
  gap: '23px',
  justifyContent: 'space-between'
}));

const IconsRow = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}));

const ExampleIconWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '130px'
}));

const MobileExampleSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  borderRadius: '16px',
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: '16px 12px',
  marginTop: '16px',
  background: theme.palette.B2B2023.back.minor
}));

const MobileExampleItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  alignItems: 'center'
}));

const ExampleIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '50%',
  width: '48px',
  height: '48px',
  background: theme.palette.B2B2023.back.major,
  color: '#5D21DE'
}));

const ExampleLabel = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  textAlign: 'center',
  width: '130px',

  [theme.breakpoints.down('p768')]: {
    width: 'auto',
    textAlign: 'left'
  }
}));

const ExampleDividerItem = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '48px',
  alignItems: 'center',

  [theme.breakpoints.down('p768')]: {
    height: '16px',
    marginLeft: '24px'
  }
}));

const Divider = styled('div')(({ theme }) => ({
  width: '16px',
  height: '2px',
  borderRadius: '2px',
  backgroundColor: '#5D21DE',

  [theme.breakpoints.down('p768')]: {
    width: '2px',
    height: '16px'
  }
}));

export const Diagram = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  return isMobile ? (
    <MobileExampleSection>
      <MobileExampleItem>
        <ExampleIcon>
          <Bag24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
        </ExampleIcon>
        <ExampleLabel>{t('processing_orders')}</ExampleLabel>
      </MobileExampleItem>
      <ExampleDividerItem>
        <Divider />
      </ExampleDividerItem>
      <MobileExampleItem>
        <ExampleIcon>
          <Bank24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
        </ExampleIcon>
        <ExampleLabel>{t('weekly_settlement')}</ExampleLabel>
      </MobileExampleItem>
      <ExampleDividerItem>
        <Divider />
      </ExampleDividerItem>
      <MobileExampleItem>
        <ExampleIcon>
          <Refresh24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
        </ExampleIcon>
        <ExampleLabel>{t('repay_automatically')}</ExampleLabel>
      </MobileExampleItem>
    </MobileExampleSection>
  ) : (
    <ExampleSection>
      <IconsRow>
        <ExampleIconWrapper>
          <ExampleIcon>
            <Bag24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
          </ExampleIcon>
        </ExampleIconWrapper>
        <ExampleDividerItem>
          <Divider />
        </ExampleDividerItem>
        <ExampleIconWrapper>
          <ExampleIcon>
            <Bank24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
          </ExampleIcon>
        </ExampleIconWrapper>
        <ExampleDividerItem>
          <Divider />
        </ExampleDividerItem>
        <ExampleIconWrapper>
          <ExampleIcon>
            <Refresh24 ds={DS.B2B2023} color="B2B2023.accent.highlightA" />
          </ExampleIcon>
        </ExampleIconWrapper>
      </IconsRow>
      <ExampleRow>
        <ExampleLabel>{t('processing_orders')}</ExampleLabel>
        <ExampleLabel>{t('weekly_settlement')}</ExampleLabel>
        <ExampleLabel>{t('repay_automatically')}</ExampleLabel>
      </ExampleRow>
    </ExampleSection>
  );
};
