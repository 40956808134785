import { styled } from '@mui/material/styles';

import { useGetLoanQuery } from '@/store/loan';
import { useResetStatusLabels } from '@/store/loan/hooks/useResetStatusLabels';
import { skipToken } from '@reduxjs/toolkit/query';
import { ResetLoanHeader } from './ResetLoanHeader';
import { ResetLoanForm } from './ResetLoanForm';
import { ResetLoanWidgetSkeleton } from './ResetLoanWidgetSkeleton';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '540px',
  padding: '16px',

  [theme.breakpoints.down('p768')]: {
    width: '100%',
    padding: '0'
  }
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.B2B2023.back.major,
  borderRadius: '16px',
  padding: '24px',
  gap: '24px'
}));

export const ResetLoanWidget = ({ loanId }: { loanId?: string }) => {
  const { getLabel, getTitle } = useResetStatusLabels();

  const { data: loan, isLoading } = useGetLoanQuery(loanId || skipToken);

  if (!loanId || !loan?.reset_reason) return null;

  if (isLoading) {
    return (
      <Root>
        <Column>
          <ResetLoanWidgetSkeleton />
        </Column>
      </Root>
    );
  }

  return (
    <Root>
      <Column>
        <ResetLoanHeader
          title={getTitle(loan.reset_reason)}
          subtitle={getLabel(loan.reset_reason)}
        />
        <Content>
          <ResetLoanForm loan={loan} />
        </Content>
      </Column>
    </Root>
  );
};
