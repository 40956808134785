import { ErrorPage } from '@/components/ErrorPage';
import { LoaderDots } from '@/components/LoaderDots';
import { Breadcrumbs, PageLayout } from '@/components/PageLayout';
import { ROUTES } from '@/routes';
import { useAnalyticsTrack } from '@/store/analytics';
import { useGetMerchantLegalEntityQuery } from '@/store/merchants';
import { useGetOffersQuery } from '@/store/offers';
import { styled } from '@mui/material/styles';
import { find } from 'lodash';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { ApplicationCapitalForm } from '../../components/ApplicationCapitalForm';
import { LoanStepper } from '../../components/LoanStepper/LoanStepper';

const PageContent = styled(PageLayout.Content)(({ theme }) => ({
  background: theme.palette.B2B2023.back.minor,
  padding: '12px 0'
}));

const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: '520px',
  margin: '0 auto',
  borderRadius: '24px',
  backgroundColor: theme.palette.common.white,
  width: '100%'
}));

export const LoanApplicationPage = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { id, merchantId } = useParams<{ id?: string; merchantId?: string }>();

  const { data: offers = [], isLoading: isLoadingOffers } = useGetOffersQuery();
  const { data: merchantLegalEntity, isLoading: isLoadingLegalEntity } =
    useGetMerchantLegalEntityQuery(merchantId || skipToken, { skip: !merchantId });

  const offer = find(offers, (o) => o.id === id);

  useEffect(() => {
    if (merchantLegalEntity) {
      track({
        event: 'Capital Application Opened',
        params: {
          merchantId,
          legalCompanyName: merchantLegalEntity?.legal_company_name
        }
      });
    }
  }, [merchantLegalEntity, merchantId, track]);

  const breadcrumbs = useMemo(
    () => [
      {
        id: 'tabby_capital',
        href: generatePath(ROUTES.financeTabbyCapital),
        label: t('tabby_capital')
      },
      { id: 'application', href: '', label: t('application') }
    ],
    [t]
  );

  if (isLoadingOffers || isLoadingLegalEntity) return <LoaderDots height="100vh" />;

  if (!merchantLegalEntity) {
    return <ErrorPage status={404} />;
  }

  if (!offer) return null;

  return (
    <>
      <PageLayout.Header customBreadcrumbs={<Breadcrumbs crumbs={breadcrumbs} />} />
      <PageContent>
        <LoanStepper status="new" />
        <Wrapper>
          <ApplicationCapitalForm legalEntity={merchantLegalEntity} offer={offer} />
        </Wrapper>
      </PageContent>
    </>
  );
};
