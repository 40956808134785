import { upperCamelCase } from '@/utils/string';
import { TestId, TestProps } from './types';

export const getTestProps = (id: TestId | void, suffix = ''): TestProps => {
  if (!id) {
    return {};
  }

  const value = id + upperCamelCase(suffix);

  return { 'data-testid': value as TestId };
};
