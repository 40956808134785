import MuiPopper from '@mui/material/Popper';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Props as PopperProps } from './Popper';

export const popperClassNames = {
  inner: 'MuiPopper-inner',
  arrow: 'MuiPopper-arrow'
} as const;

const popperBorderRadius = '16px';

export const StyledPopper = styled(MuiPopper, {
  shouldForwardProp: (prop) => {
    const disallowedProps: PropertyKey[] = ['arrow', 'updateDelay', 'disableRootScroll'];
    return !disallowedProps.includes(prop);
  }
})<PopperProps>(({ theme, arrow }) => {
  const arrowColor = theme.palette.common.white;
  const innerMargin = `${arrow ? 2 : 0}px`;

  return `
    z-index: ${theme.zIndex.tooltip};

    .${popperClassNames.inner} {
      position: relative;
      box-shadow: ${theme.shadows[11]};
      border-radius: ${popperBorderRadius};
    }

    &[data-popper-placement*="right"] {
      & .${popperClassNames.inner} {
        /* @noflip */
        margin-left: ${innerMargin};
      }
      & .${popperClassNames.arrow} {
        /* @noflip */
        left: 0;
        /* @noflip */
        margin-left: -0.9em;
        height: 3em;
        width: 1em;

        &:before {
          /* @noflip */
          border-width: 1em 1em 1em 0;
          /* @noflip */
          border-color: transparent ${arrowColor} transparent transparent;
        }
      }
    }

    &[data-popper-placement*="left"] {
      & .${popperClassNames.inner} {
        /* @noflip */
        margin-right: ${innerMargin};
      }
      & .${popperClassNames.arrow} {
        /* @noflip */
        right: 0;
        /* @noflip */
        margin-right: -0.9em;
        height: 3em;
        width: 1em;

        &:before {
          /* @noflip */
          border-width: 1em 0 1em 1em;
          /* @noflip */
          border-color: transparent transparent transparent ${arrowColor};
        }
      }
    }

    &[data-popper-placement*="bottom"] {
      & .${popperClassNames.inner} {
        margin-top: ${innerMargin};
      }
      & .${popperClassNames.arrow} {
        top: 0;
        left: 0;
        margin-top: -0.9em;
        width: 3em;
        height: 1em;

        &:before {
          border-width: 0 1em 1em 1em;
          border-color: transparent transparent ${arrowColor} transparent;
        }
      }
    }

    &[data-popper-placement*="top"] {
      & .${popperClassNames.inner} {
        margin-bottom: ${innerMargin};
      }
      & .${popperClassNames.arrow} {
        bottom: 0;
        left: 0;
        margin-bottom: -0.9em;
        width: 3em;
        height: 1em;

        &:before {
          border-width: 1em 1em 0 1em;
          border-color: ${arrowColor} transparent transparent transparent;
        }
      }
    }
  `;
});

export const PopperArrow = styled('div')({
  position: 'absolute',
  fontSize: '10px',
  width: '3em',
  height: '3em',
  '&::before': {
    content: '""',
    margin: 'auto',
    display: 'block',
    width: 0,
    height: 0,
    borderStyle: 'solid'
  }
});

export const PopperContent = styled('div')(({ theme }) => ({
  overflow: 'hidden',
  borderRadius: popperBorderRadius,
  backgroundColor: theme.palette.common.white
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.__grey[90],
  position: 'absolute',
  right: '7px',
  top: '7px',
  fontSize: '16px'
}));
