import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Agent24 from '@tabby.ai/tabby-ui/dist/icons/core/Agent24';
import { Text } from '@/ui-kit/Text';
import { FC, useState } from 'react';
import ButtonBase from '@/components/Button/Button';
import { Modal } from './components/Modal';

const Root = styled('div')`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Button = styled(ButtonBase)`
  & .MuiButton-sizeLarge {
    border-radius: 14px !important;
  }

  & .MuiButton-root:hover {
    background-color: ${({ theme }) => theme.palette.B2B2023.back.minor};
`;

type Props = {
  offerId: string;
};

export const ArrangeCallback: FC<Props> = (props) => {
  const { offerId } = props;
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <Root>
      <Button
        startIcon={<Agent24 color="B2B2023.front.major" />}
        onClick={() => setIsModalOpen(true)}
        size="large"
        variant="text"
        fullWidth
      >
        <Text
          variant="body1Tight500"
          sx={{ color: (theme) => theme.palette.B2B2023.front.neutral }}
        >
          {t('questions_arrange_a_callback')}
        </Text>
      </Button>
      <Modal offerId={offerId} open={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </Root>
  );
};
