import { useCallback } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useDispatch, useSelector } from '../store';
import { getIsAuthorized, setIsAuthorized } from './utils';
import { rootApi } from '../rootApi';

export const authSlice = createSlice({
  name: 'auth',
  initialState: () => ({
    isAuthorized: getIsAuthorized(),
    is401Error: false
  }),
  reducers: {
    authorized: (state) => {
      state.isAuthorized = true;
      setIsAuthorized(state.isAuthorized);
    },
    deauthorized: (state) => {
      state.isAuthorized = false;
      setIsAuthorized(state.isAuthorized);
    },
    set401Error: (state, action: PayloadAction<boolean>) => {
      state.is401Error = action.payload;
    }
  }
});

export const useAuthorize = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(authSlice.actions.authorized());
    dispatch(rootApi.util.invalidateTags(['Me']));
  }, [dispatch]);
};

export const useIsAuthorized = () => {
  return useSelector((state: RootState) => state.auth.isAuthorized);
};

export const useIs401Error = () => {
  return useSelector((state: RootState) => state.auth.is401Error);
};
