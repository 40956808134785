import { SortDirection, TabbyProduct } from '@/types';
import { Payment, PaymentStatus } from '@/store/payment';

export type PaymentsHeading =
  | 'paymentId'
  | 'creationDate'
  | 'type'
  | 'status'
  | 'orderNumber'
  | 'store'
  | 'customer'
  | 'amount'
  | 'refunded';

export type GetPaymentsPagination = {
  limit?: number;
  offset?: number;
};

export type GetPaymentsFilters = {
  created_at__gte?: string;
  created_at__lte?: string;
  is_test?: PaymentsIsTestFilter;
  query?: string;
  sort_by?: PaymentsSortBy;
  sort_direction?: SortDirection;
  status?: PaymentStatus[];
  product_type?: TabbyProduct[];
  merchant_id?: string[];
};

export type GetPaymentsParams = GetPaymentsPagination & GetPaymentsFilters;

export type GetPaymentsResponse = {
  payments: Payment[] | null;
  total_count: number;
  limit: number;
  offset: number;
};

export type GetPaymentsResult = {
  items: Payment[];
  total_count: number;
  limit: number;
  offset: number;
};

export enum PaymentsSortBy {
  createdAt = 'createdAt',
  amount = 'amount'
}

export enum PaymentsIsTestFilter {
  true = 'true',
  false = 'false'
}

export type PaymentsSearchParams = {
  sortBy?: string;
  sortDirection?: string;
  createdFrom?: string;
  createdTo?: string;
  query?: string;
  status?: string;
  test?: string;
  products?: string;
};
