import mime from 'mime-types';
import uniq from 'lodash/uniq';
import { SUPPORT_TICKET_STATUSES, SupportTicketStatus } from './types';

export const ALLOWED_MIME_TYPES = [mime.types.jpeg, mime.types.jpg, mime.types.png];

export const ALLOWED_FILE_EXTENTIONS = uniq(ALLOWED_MIME_TYPES.map((t) => mime.extension(t)));

export const MAX_FILE_SIZE_MB = 5;

export const CHANNEL_TYPE = 'messaging' as const;

export const INACTIVE_TICKET_STATUSES: SupportTicketStatus[] = ['closed'];

export const ACTIVE_TICKET_STATUSES = Object.values(SUPPORT_TICKET_STATUSES).filter(
  (s) => !INACTIVE_TICKET_STATUSES.includes(s)
);
