import { Country } from '@/types';
import { TFunction } from 'react-i18next';
import { stringSchema } from '@/lib/yup';
import { DEFAULT_COUNTRY, configByCountry, configByDialCode } from './constants';
import { DIAL_CODES, DialCode, PhoneConfig } from './types';

export const phones = {
  getConfigByCountryCode: (country: Country): PhoneConfig => {
    return configByCountry[country] || configByCountry[DEFAULT_COUNTRY];
  },
  parseDialCode: (value: string): DialCode | null => {
    const dialCode = Object.values(DIAL_CODES).find((c) => value.startsWith(c));
    return dialCode || null;
  },
  getConfigByPhone: (value: string): PhoneConfig => {
    const dialCode = phones.parseDialCode(value);

    if (dialCode) {
      return configByDialCode[dialCode];
    }

    return configByCountry[DEFAULT_COUNTRY];
  },
  getIsValidPhone: (value: string): boolean => {
    return phones.getConfigByPhone(value).regExp.test(value);
  },
  getPhoneWithoutDialCode: (value: string): string => {
    const dialCode = phones.parseDialCode(value);

    if (dialCode) {
      return value.slice(dialCode.length);
    }

    return value;
  },
  getSchema: (t: TFunction, optional = false) => {
    return stringSchema.test({
      name: 'is-phone-number-valid',
      test: (value, ctx) => {
        const phone = value ? phones.getPhoneWithoutDialCode(value) : '';

        if (!optional && !phone) {
          return ctx.createError({ message: () => '' });
        }

        if (phone && value && !phones.getIsValidPhone(value)) {
          return ctx.createError({ message: t('invalid_phone_number_please_check_your_input') });
        }

        return true;
      }
    });
  }
};
