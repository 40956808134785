import { LegalEntity } from '@/store/merchants';
import { rootApi } from '@/store/rootApi/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { find, sortBy, uniq } from 'lodash';
import {
  ApplyLoanParams,
  CapitalLoan,
  CapitalLoanWithLegalEntity,
  CapitalLoanWithLegalInfo,
  LegalEntityWithMerchantId,
  UpdateLoanParams
} from '../types';

const loanApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getLoans: build.query<CapitalLoanWithLegalInfo[], void>({
      query: () => ({ url: `/merchant/api/v1/loan` }),
      transformResponse: (response: CapitalLoanWithLegalInfo[]) => response || [],
      providesTags: (result) =>
        result ? [...result.map((l) => ({ type: 'Loans' as const, id: l.id }))] : ['Loans']
    }),
    getLoansWithLegalEntity: build.query<CapitalLoanWithLegalEntity[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const loansResponse = await fetchWithBQ('/merchant/api/v1/loan');

        if (loansResponse.error) return { error: loansResponse.error as FetchBaseQueryError };

        const loansData = loansResponse.data as CapitalLoan[] | undefined;
        const loans = sortBy(loansData, (item) => item.status);

        if (loans) {
          const merchantIds = uniq(loans.map((loan) => loan.merchant_id));

          const legalEntitiesResponse = await Promise.allSettled(
            merchantIds.map(async (merchantId) => {
              const legalEntityResponse = await fetchWithBQ(
                `/merchant/api/v1/merchant/${merchantId}/legal_entity`
              );

              if (legalEntityResponse.error) {
                return { error: legalEntityResponse.error as FetchBaseQueryError };
              }

              const legalEntityData = legalEntityResponse.data as { legal_entity: LegalEntity };

              return { ...legalEntityData.legal_entity, merchant_id: merchantId };
            })
          );

          const legalEntitiesData = legalEntitiesResponse
            .filter(
              (l): l is PromiseFulfilledResult<LegalEntityWithMerchantId> =>
                l.status === 'fulfilled'
            )
            .filter((l) => !!l.value.iban)
            .map((l) => l.value as LegalEntityWithMerchantId);

          const result = loans.map((loan) => {
            const legalEntity = find(legalEntitiesData, (m) => m.merchant_id === loan.merchant_id);

            return { ...loan, legal_entity: legalEntity || {} } as CapitalLoanWithLegalEntity;
          });

          return { data: result };
        }

        return { data: [] };
      },
      providesTags: (result) =>
        result ? [...result.map((l) => ({ type: 'Loans' as const, id: l.id }))] : ['Loans']
    }),
    getLoanWithLegalEntity: build.query<CapitalLoanWithLegalEntity, string>({
      async queryFn(loanId, _queryApi, _extraOptions, fetchWithBQ) {
        const loansResponse = await fetchWithBQ('/merchant/api/v1/loan');

        if (loansResponse.error) return { error: loansResponse.error as FetchBaseQueryError };

        const loans = loansResponse.data as CapitalLoan[] | undefined;
        const loan = loans?.find((l) => l.id === loanId);

        if (loan) {
          const legalEntitysResponse = await fetchWithBQ(
            `/merchant/api/v1/merchant/${loan.merchant_id}/legal_entity`
          );

          if (legalEntitysResponse.error) {
            return { error: legalEntitysResponse.error as FetchBaseQueryError };
          }

          const legalEntityData = legalEntitysResponse.data as { legal_entity: LegalEntity };

          return {
            data: {
              ...loan,
              legal_entity: { ...legalEntityData.legal_entity }
            } as CapitalLoanWithLegalEntity
          };
        }

        return { data: {} as CapitalLoanWithLegalEntity };
      },
      providesTags: (result, error, id) => [{ type: 'Loans', id }]
    }),
    getLoan: build.query<CapitalLoanWithLegalInfo | null, string>({
      query: (loanId) => `/merchant/api/v1/loan/${loanId}`,
      providesTags: (result, error, loanId) => [{ type: 'Loans', id: loanId }]
    }),
    createLoan: build.mutation<{ loan_id: string }, ApplyLoanParams>({
      query: (body) => ({
        url: '/merchant/api/v1/loan/apply',
        method: 'POST',
        body
      }),
      invalidatesTags: (result) =>
        result ? [{ type: 'Loans', id: result.loan_id }, 'Offers'] : ['Loans', 'Offers']
    }),
    updateLoan: build.mutation<void, UpdateLoanParams>({
      query: ({ id, ...params }) => ({
        url: `/merchant/api/v1/loan/${id}`,
        method: 'PATCH',
        body: params
      }),
      invalidatesTags: (result, error, args) => [{ type: 'Loans', id: args.id }]
    }),
    addToWaitlist: build.mutation<void, void>({
      query: () => ({
        url: '/merchant/api/v1/loan/experiment',
        method: 'POST'
      })
    })
  })
});

export const {
  useCreateLoanMutation,
  useGetLoanQuery,
  useGetLoansQuery,
  useLazyGetLoanQuery,
  useAddToWaitlistMutation,
  useGetLoansWithLegalEntityQuery,
  useGetLoanWithLegalEntityQuery,
  useUpdateLoanMutation
} = loanApi;
