import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  alignItems: 'center',

  [theme.breakpoints.down('p768')]: {
    br: {
      display: 'none'
    }
  }
}));

const Text = styled(DeprecatedTypography)(({ theme }) => ({
  color: theme.palette.B2B2023.front.minor,
  width: '268px',
  textAlign: 'center'
}));

export const EmptyState = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <DeprecatedTypography variant="_h2">{t('no_repayments_due')}</DeprecatedTypography>
      <Text variant="_body2Loose500">
        {t('when_we_deduct_repayments_you_ll_find_the_history_here')}
      </Text>
    </Root>
  );
};
