import { useEffect, useState } from 'react';
import {
  TABBY_BUSINESS_APP_STORE_URL,
  TABBY_BUSINESS_GOOGLE_PLAY_URL,
  TABBY_BUSINESS_APP_GALLERY_URL,
  TABBY_BUSINESS_QR_CODE_URL
} from '../constants';

export const useDownloadAppLinks = () => {
  const [oneLinkUrl, setOneLinkUrl] = useState('');

  useEffect(() => {
    const timer = setTimeout(() => {
      // https://dev.appsflyer.com/hc/docs/dl_smart_script_v2#use-google-tag-manager
      const clickURL = window.AF_SMART_SCRIPT_RESULT?.clickURL;

      if (!clickURL) return;

      setOneLinkUrl(clickURL);
      try {
        window.AF_SMART_SCRIPT?.fireImpressionsLink?.();
      } catch {
        //
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return {
    appStoreUrl: oneLinkUrl || TABBY_BUSINESS_APP_STORE_URL,
    googlePlayUrl: oneLinkUrl || TABBY_BUSINESS_GOOGLE_PLAY_URL,
    appGalleryUrl: TABBY_BUSINESS_APP_GALLERY_URL,
    qrCodeUrl: oneLinkUrl || TABBY_BUSINESS_QR_CODE_URL
  };
};
