import { useTranslation } from 'react-i18next';
import InfoIcon from '@/assets/icons/info.svg?icon';
import { useMediaQuery, useTheme } from '@mui/material';
import { LoanSteps } from '../types';
import { Step } from './Step';
import { StepLine } from './StepLine';
import { StepLabel } from './StepLabel';
import { PassedStepIcon } from './PassedStepIcon';

type Props = {
  activeStep: LoanSteps;
};

export const ApplicationStep = ({ activeStep }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  const isChangesRequired = activeStep === 'changes-required';
  const isApplication = activeStep === 'application';
  const inactiveApplicationStepColor = isChangesRequired
    ? theme.palette.B2B2023.accent.negativeA
    : theme.palette.B2B2023.accent.positiveA;

  return (
    <Step>
      <StepLine
        lineColor={
          isApplication ? theme.palette.B2B2023.back.controlMajor : inactiveApplicationStepColor
        }
      />
      {!isMobile && (
        <StepLabel
          labelColor={
            isApplication ? theme.palette.B2B2023.front.major : inactiveApplicationStepColor
          }
        >
          {isChangesRequired ? <InfoIcon /> : !isApplication && <PassedStepIcon />}
          {t('apply')}
        </StepLabel>
      )}
    </Step>
  );
};
