import { Nullable, SortDirection } from '@/types';
import { addMonths, addSeconds, differenceInDays, format, isMatch, parse } from 'date-fns';

import { getLocale } from '../i18n';

type Timestamp = string;
type ISOString = string;

export const SEC_MS = 1000;
export const MIN_MS = 60000;
export const DAY_MS = MIN_MS * 60 * 24;

const padStart = (n: number, max = 2, fill = '0') => `${n}`.padStart(max, fill);

const startDayTime = 'T00:00:00.000';
const endDayTime = 'T23:59:59.999';

export const isValidDateString = (str?: string | null) => {
  return str ? new Date(str).toString() !== 'Invalid Date' : false;
};

export const isValidDate = (date?: Date | null) => {
  if (date instanceof Date) {
    return date.toString() !== 'Invalid Date';
  }

  return false;
};

export const isValidStringDateByFormat = (dateString: string, pattern = 'yyyy-MM-dd') => {
  if (!isMatch(dateString, pattern)) {
    return false;
  }

  const parsedDate = parse(dateString, pattern, new Date());
  const formattedDate = format(parsedDate, pattern);

  return dateString === formattedDate;
};

export const formatYYYYMMDD = (date?: Date | null) => {
  if (!date) return '';

  const yy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return [yy, padStart(mm), padStart(dd)].join('-');
};

export const toUTCtimestamp = (date: Date | null, timeString = '00:00:00') => {
  const dateString = formatYYYYMMDD(date);
  return dateString === '' ? '' : `${dateString}T${timeString}.000Z`;
};

export const getDaysAgoPeriod = (
  days: number,
  dateNow = new Date(),
  formatter = formatYYYYMMDD
) => {
  const msAgo = days * DAY_MS;
  const dateAgo = new Date(dateNow.getTime() - msAgo);

  return { from: formatter(dateAgo), to: formatter(dateNow) };
};

// TODO: remove old date formatters after refactoring
export const formatMonthDDCommaYYYY = (date: Date, lang: string) => {
  return new Intl.DateTimeFormat(getLocale(lang), {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  }).format(date);
};

export const formatMonthDD = (date: Date, lang: string) => {
  return new Intl.DateTimeFormat(getLocale(lang), {
    day: 'numeric',
    month: 'short'
  }).format(date);
};

export const getNumeric12HrTime = (date: Date, lang: string) => {
  return new Intl.DateTimeFormat(getLocale(lang), {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  }).format(date);
};

export const formatWeek = (date: Date, lang: string) => {
  return new Intl.DateTimeFormat(getLocale(lang), {
    weekday: 'short'
  }).format(date);
};

export const addDays = (date: Date, days: number) => {
  return new Date(date.getTime() + days * DAY_MS);
};

export const sortDates = (arr: Date[], direction: SortDirection) => {
  const sorter = (a: Date, b: Date) => a.getTime() - b.getTime();
  return direction === 'asc' ? arr.sort(sorter) : arr.sort(sorter).reverse();
};

export const parseDateString = (date?: string | null): Date | null => {
  return date && isValidDateString(date) ? new Date(date) : null;
};

export const getUTCoffset = (min = new Date().getTimezoneOffset()) => {
  const hrs = Math.abs(min / 60);

  if (min < 0) return `+${hrs}`;
  if (min > 0) return `-${hrs}`;

  return '+0';
};

export const diffMs = (start: Date, end: Date) => {
  return end.getTime() - start.getTime();
};

export const diffSeconds = (start: Date, end: Date) => {
  return diffMs(start, end) / SEC_MS;
};

export const getFormattedDateAndTime = (rawDate: Nullable<string> | void, language: string) => {
  const formattedDate = rawDate ? new Date(rawDate) : null;
  const date = formattedDate ? formatMonthDDCommaYYYY(formattedDate, language) : '';
  const time = formattedDate ? getNumeric12HrTime(formattedDate, language) : '';
  return { date, time };
};

export const createTimestamp = (dateStr: string | null, timeStr: string): Timestamp | null =>
  isValidDateString(dateStr + timeStr) ? dateStr + timeStr : null;

export const getStartDayTimestamp = (dateStr: string | null): Timestamp | null =>
  createTimestamp(dateStr, startDayTime);

export const getEndDayTimestamp = (dateStr: string | null): Timestamp | null =>
  createTimestamp(dateStr, endDayTime);

export const getISOString = (timestamp: string | null): string | null => {
  try {
    return timestamp ? new Date(timestamp).toISOString() : null;
  } catch (error) {
    return null;
  }
};

export const getStartDayISOString = (dateStr?: string | null): ISOString | null =>
  dateStr ? getISOString(getStartDayTimestamp(dateStr)) : null;

export const getEndDayISOString = (dateStr?: string | null) =>
  dateStr ? getISOString(getEndDayTimestamp(dateStr)) : null;

export const formatSecondsDuration = (duration: number) => {
  return format(addSeconds(new Date(0), duration), 'm:ss');
};

export const formatSeconds = (seconds: number) => {
  const s = `${seconds % 60}`.padStart(2, '0');
  const m = `${Math.floor(seconds / 60)}`.padStart(2, '0');
  return `${m}:${s}`;
};

export const isDateAfterMonthsCount = (date: string, monthCount: number) => {
  const targetDate = addMonths(new Date(), monthCount);
  const result = differenceInDays(Date.parse(date), targetDate);
  const dateIsAfterTarget = result < 0;

  return dateIsAfterTarget;
};
