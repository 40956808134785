import { styled } from '@mui/material';
import { BORDER_RADIUS_PX } from '../constants';

export const DeprecatedWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  borderRadius: `${BORDER_RADIUS_PX}px`,
  background: theme.palette.common.white
}));
