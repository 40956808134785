import { Button } from '@/ui-kit/Button';
import { styled } from '@mui/material/styles';
import mime from 'mime-types';
import { useTranslation } from 'react-i18next';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { Events } from '@/store/analytics';
import { FormLabel } from '@/ui-kit/FormLabel';

import useIsMobilePage from '@/hooks/useIsMobilePage';
import Info24 from '@tabby.ai/tabby-ui/dist/icons/core/Info24';
import { Box, Typography } from '@mui/material';
import { Text } from '@/ui-kit/Text';
import { Tooltip } from '@tabby.ai/tabby-ui/dist/core';
import { useState } from 'react';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { FileUploadControlled } from '@/ui-kit/FileUpload';
import { useResetLoanFormFields } from '../hooks/useResetLoanFormFields';
import { useResetLoanFormPropsContext } from '../hooks/useResetLoanFormPropsContext';
import { useResetLoanFormStateContext } from '../hooks/useResetLoanFormStateContext';
import { useResetLoanSyncInitialData } from '../hooks/useResetLoanSyncInitialData';
import { ResetLoanCRNumberFields } from './ResetLoanCRNumberFields';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%'
}));

const FieldGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

const FieldFile = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const Footer = styled('div')(() => ({
  textAlign: 'right',
  gap: '8px',
  display: 'flex',
  justifyContent: 'flex-end'
}));

const HeaderText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px'
}));

const Title = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._h2
}));

const HintContent = styled('div')({
  margin: '8px 4px'
});

const HintList = styled(Typography)({
  marginTop: '8px'
});

const HintListItem = styled('li')({
  listStyleType: 'none',
  marginBottom: '8px',

  '&:before': {
    content: '"•"',
    margin: '0 6px'
  }
});

export const ResetLoanFormContent = () => {
  const isMobile = useIsMobilePage();
  const { t } = useTranslation();
  const { control } = useResetLoanFormPropsContext();
  const { submit, isLoadingSubmit, loan, isLegalEntityCountrySAU } = useResetLoanFormStateContext();

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useResetLoanSyncInitialData();

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  const { showLicenseFileUploader } = useResetLoanFormFields(loan.reset_reason);

  return (
    <Root>
      <HeaderText>
        <Title>{t('business_license')}</Title>

        {showLicenseFileUploader && (
          <Text variant="body1Loose500">
            {t('the_expiration_date_must_be_at_least_2_weeks_from_now')}
          </Text>
        )}
      </HeaderText>
      <FieldGroup>
        {showLicenseFileUploader && (
          <FieldFile>
            <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <FormLabel variant="TextField">{t('business_license')}</FormLabel>
              <Tooltip
                placement={isMobile ? 'bottom' : 'right'}
                title={
                  <HintContent>
                    <Text variant="h3">{t('file_requirements')}</Text>
                    <HintList>
                      <HintListItem>{t('all_data_is_clearly_readable')}</HintListItem>
                      <HintListItem>{t('no_distortion_or_glare')}</HintListItem>
                      <HintListItem>{t('fully_visible_and_no_portions_are_cut_off')}</HintListItem>
                    </HintList>
                  </HintContent>
                }
                ds="B2B2023"
                open={isTooltipOpen}
                onClose={handleTooltipClose}
              >
                <span
                  onMouseEnter={isMobile ? undefined : handleTooltipOpen}
                  onClick={isMobile ? handleTooltipOpen : undefined}
                >
                  <Info24 ds={DS.B2B2023} />
                </span>
              </Tooltip>
            </Box>
            <FileUploadControlled
              control={control}
              name="cr_file"
              allowedMimeTypes={[mime.types.pdf, mime.types.jpeg, mime.types.png]}
              fileName={isLegalEntityCountrySAU ? 'commercial_registration' : 'business_license'}
              maxFileSizeKB={5000}
              analytics={{
                event: Events.CapitalLicenseUploaded
              }}
            />
          </FieldFile>
        )}

        <ResetLoanCRNumberFields />
      </FieldGroup>

      <Footer>
        <Button isLoading={isLoadingSubmit} onClick={submit} variant="contained">
          {t('submit_application')}
        </Button>
      </Footer>
    </Root>
  );
};
