import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { Text } from '@/ui-kit/Text';
import { formatMonthDD } from '@/utils/date';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Sparks24 from '@tabby.ai/tabby-ui/dist/icons/core/Sparks24';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { Trans, useTranslation } from 'react-i18next';
import { useFormStateContext } from '../hooks/useFormStateContext';

const Root = styled('div')(({ theme }) => ({
  color: theme.palette.B2B2023.front.major
}));

const Container = styled('div')(() => ({
  padding: '24px 24px 16px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px'
}));

const Title = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._hxl,
  fontSize: theme.typography.pxToRem(44),
  lineHeight: theme.typography.pxToRem(46),
  marginBottom: '4px'
}));

const IconWrapper = styled('div')(({ theme }) => ({
  '& .TuiIcon-root': {
    color: theme.palette.common.white
  }
}));

const BadgeWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  padding: '8px 12px 8px 8px',
  borderRadius: '12px',
  backdropFilter: 'blur(15px)',
  color: theme.palette.common.white,
  background: `linear-gradient(to right, rgb(123, 58, 242) 1%, rgb(212, 33, 232) 50.02%, rgb(245, 82, 175) 84.38%, rgb(253, 135, 126) 100%)`
}));

export const Header = () => {
  const { t, i18n } = useTranslation();
  const { selectedOffer } = useFormStateContext();

  const formattedDate = selectedOffer
    ? formatMonthDD(new Date(selectedOffer?.expiration_date), i18n.language)
    : '';

  return (
    <Root>
      <Container>
        {formattedDate && (
          <BadgeWrapper>
            <IconWrapper>
              <Sparks24 />
            </IconWrapper>
            <Text variant="body2Tight700">{t('valid_until', { date: formattedDate })}</Text>
          </BadgeWrapper>
        )}
        <Title>
          <Trans i18nKey="youve_qualified_for_an_advance_payout" />
        </Title>
        <DeprecatedTypography variant="_body1Loose500">
          {t('grow_your_business_with_tabby_capital__description')}
        </DeprecatedTypography>
        <DeprecatedTypography variant="_body1Loose500">
          {t('we_will_automatically_deduct_repayments_from_your_weekly_payouts')}
        </DeprecatedTypography>
      </Container>
    </Root>
  );
};
