import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifyChallengePhoneForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeVerifyPhone = () => {
  const { state, goToSignIn } = useAuthPageContext();
  const { step, phonePart } = state;

  if (step === 'challenge_verify_phone' && phonePart) {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <VerifyChallengePhoneForm phone={phonePart} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
