import { ValueOf } from '@/types';

export const Events = {
  AccountActivated: 'Account Activated',
  AccountDeactivated: 'Account Deactivated',
  AccountSettingsOpened: 'Account Settings Opened',
  ActivityHistoryOpened: 'Activity History Opened',
  AddAnotherRoleClicked: 'Add Another Role Clicked',
  AnalyticsOpened: 'Analytics Opened',
  AnnualVolumeFilled: 'Annual Volume Filled',
  AovChartOpened: 'AOV Chart Opened',
  ApplicationFirstNameFilled: 'Application First Name Filled',
  ApplicationFormOpened: 'Application Form Opened',
  ApplicationFirstTimeOpened: 'Application First Time Opened',
  ApplicationLastNameFilled: 'Application Last Name Filled',
  ApplicationSubmitted: 'Application Submitted',
  ApplicationUpdated: 'Application Updated',
  BankAccountCurrencyFilled: 'Bank Account Currency Filled',
  BankAccountNameFilled: 'Bank Account Name Filled',
  BankDetailsOpened: 'Bank Details Opened',
  BankDetailsSubmitted: 'Bank Details Submitted',
  BankNameFilled: 'Bank Name Filled',
  BranchNameFilled: 'Branch Name Filled',
  BrandNameArFilled: 'Brand Name Ar Filled',
  BrandNameFilled: 'Brand Name Filled',
  BusinessContactAdded: 'Business Contact Added',
  BusinessContactRemoved: 'Business Contact Removed',
  BusinessContactUpdate: 'Business Contact Updated',
  BusinessCountriesFilled: 'Business Countries Filled',
  BusinessProfileApplicationOpened: 'Business Profile Application Opened',
  CRNumberFilled: 'CR Number Filled',
  CapitalApplicationOpened: 'Capital Application Opened',
  CapitalApplicationSubmitted: 'Capital Application Submitted',
  CapitalApplyClicked: 'Capital Apply Clicked',
  CapitalEmailFilled: 'Capital Email Filled',
  CapitalFakeDoorOpened: 'Capital Fake Door Opened',
  CapitalFakeDoorSubmitted: 'Capital Fake Door Submitted',
  CapitalLicenseNumberFilled: 'Capital License Number Filled',
  CapitalLicenseUploaded: 'Capital License Uploaded',
  CapitalNameFilled: 'Capital Name Filled',
  CapitalPageOpened: 'Capital Page Opened',
  CapitalPhoneNumberFilled: 'Capital Phone Number Filled',
  CapitalStoreSelected: 'Capital Store Selected',
  CategoryFilled: 'Category Filled',
  CheckStatusClicked: 'Check Status Clicked',
  CheckoutLanguageFilled: 'Checkout Language Filled',
  CommercialTermsAccepted: 'Commercial Terms Accepted',
  CommercialTermsOpened: 'Commercial Terms Opened',
  ContactAdded: 'Contact Added',
  ContactDeleted: 'Contact Deleted',
  ContactUpdated: 'Contact Updated',
  ContactsOpened: 'Contacts Opened',
  ContractFormSubmitted: 'Contract Form Submitted',
  ContractOpened: 'Contract Opened',
  CustomerCareContactAdded: 'Customer Care Contact Added',
  CustomerCareContactEmailFilled: 'Customer Care Contact Email Filled',
  CustomerCareContactUpdated: 'Customer Care Contact Updated',
  CustomerNameFilled: 'Customer Name Filled',
  DashboardOpened: 'Dashboard Opened',
  DateFilterUsed: 'Date Filter Used',
  DeviceSessionTerminated: 'Device Session Terminated',
  DisputesOpened: 'Disputes Opened',
  EcommercePlatformFilled: 'Ecommerce Platform Filled',
  EmailFilled: 'Email Filled',
  EmailVerified: 'Email Verified',
  ForgotPasswordClicked: 'Forgot Password Clicked',
  GmvChartOpened: 'GMV Chart Opened',
  HelpCenterClicked: 'Help Center Clicked',
  IBANFilled: 'IBAN Filled',
  ImageUploadClicked: 'Image Upload Clicked',
  ImageUploaded: 'Image Uploaded',
  IntegrationOpened: 'Integration Opened',
  InviteUserClosed: 'Invite User Closed',
  InviteUserOpened: 'Invite User Opened',
  LegalAddressFilled: 'Legal Address Filled',
  LegalCompanyNameFilled: 'Legal Company Name Filled',
  LegalDetailsOpened: 'Legal Details Opened',
  LegalEntityCountryFilled: 'Legal Entity Country Filled',
  LegalEntityOpened: 'Legal Entity Opened',
  LegalEntitySubmitted: 'Legal Entity Submitted',
  LegalInfoOpened: 'Legal Info Opened',
  LegalInfoSubmitted: 'Legal Info Submitted',
  LicenceTypeFilled: 'Licence Type Filled',
  LicenceUploaded: 'Licence Uploaded',
  LicenseExpireDateFilled: 'License Expire Date Filled',
  Link2FaOpened: 'Link 2FA Opened',
  Link2FaSubmitted: 'Link 2FA Submitted',
  LinkPhoneGetCodeClicked: 'Link Phone Get Code Clicked',
  LinkPhoneOTPEntered: 'Link Phone OTP Entered',
  LinkPhoneOTPFilled: 'Link Phone OTP Filled',
  LinkPhoneOpened: 'Link Phone Opened',
  LinkPhoneResendCodeClicked: 'Link Phone Resend Code Clicked',
  LoggedIn: 'Logged In',
  LoggedOut: 'Logged Out',
  MainInformationOpened: 'Main Information Opened',
  ManageUserClosed: 'Manage User Closed',
  ManageUserOpened: 'Manage User Opened',
  MerchantFilled: 'Merchant Filled',
  MerchantFilterUsed: 'Merchant Filter Used',
  MerchantLoginOpened: 'Merchant Login Opened',
  MerchantSignUpOpened: 'Merchant Sign Up Opened',
  MerchantSignedUp: 'Merchant Signed Up',
  MerchantTypeFilled: 'Merchant Type Filled',
  NationalIDUploaded: 'National ID Uploaded',
  NoDataScreenShown: 'No Data Screen Shown',
  NumberOfInStoreLocationsFilled: 'Number of in-store locations filled',
  NoStores: 'No Stores',
  OTPEntered: 'OTP Entered',
  OTPFilled: 'OTP Filled',
  OTPOpened: 'OTP Opened',
  OnboardingStoresOpened: 'Onboarding Stores Opened',
  OrderNumberFilled: 'Order Number Filled',
  OrdersGoToPayoutsClicked: 'Orders Go To Payouts Clicked',
  OrdersChartOpened: 'Orders Chart Opened',
  OtherBusinessCountriesFilled: 'Other business Countries Filled',
  OtherSessionsTerminated: 'Other Sessions Terminated',
  OtpResendCodeClicked: 'OTP Resend Code Clicked',
  PasswordReset: 'Password Reset',
  PaymentLinkCopied: 'Payment Link Copied',
  PaymentLinkCreatedScreenShown: 'Payment Link Created Screen Shown',
  PaymentLinkFormOpened: 'Payment Link Form Opened',
  PaymentLinkRequested: 'Payment Link Requested',
  PaymentWidgetResultReceived: 'Payment Widget Result Received',
  PayoutCycleApplied: 'Payout Cycle Applied',
  PayoutCycleOpened: 'Payout Cycle Opened',
  PayoutsCSVDownloaded: 'Payout CSV Downloaded',
  PayoutsDateRangeFilterOpened: 'Date Range Filter Opened',
  PayoutsDateRangeFilterSubmitted: 'Date Range Filter Submitted',
  PayoutsDownloadCSVClicked: 'Download Payout CSV Clicked',
  PayoutsOpened: 'Payouts Opened',
  PayoutsStoresFilterOpened: 'Stores Filter Opened',
  PayoutsStoresFilterSubmitted: 'Stores Filter Submitted',
  PayoutFaqPageOpened: 'Payout FAQ Page Opened',
  PhoneNumberDeleted: 'Phone Number Deleted',
  PhoneNumberFilled: 'Phone Number Filled',
  ProductCategoryOpened: 'Product Category Opened',
  ProductsFilled: 'Products Filled',
  PublicKeyCopied: 'Public Key Copied',
  RoleSelected: 'Role Selected',
  RolesListOpened: 'Roles List Opened',
  RoutingNumberFilled: 'Routing Number Filled',
  SWIFTFilled: 'SWIFT Filled',
  SecretKeyCopied: 'Secret Key Copied',
  SectionFilled: 'Section Filled',
  SettlementRecipientsFilled: 'Settlement Recipients Filled',
  SignUpTypeChanged: 'Sign Up Type Changed',
  SignatoryAdditionalRecipientsFilled: 'Signatory Additional Recipients Filled',
  SignatoryBusinessAddressFilled: 'Signatory Business Address Filled',
  SignatoryEmailAddressFilled: 'Signatory Email Address Filled',
  SignatoryFullNameFilled: 'Signatory Full Name Filled',
  SignatoryJobTitleFilled: 'Signatory Job Title Filled',
  SignatoryPhoneNumberFilled: 'Signatory Phone Number Filled',
  StoreTypeOpened: 'Store Type Opened',
  StoresOpened: 'Stores Opened',
  StoresSelected: 'Stores Selected',
  SupportTicketFormOpened: 'Support Ticket Form Opened',
  SupportTicketFormSubmitted: 'Support Ticket Form Submitted',
  TestPublicKeyCopied: 'Test Public Key Copied',
  TestSecretKeyCopied: 'Test Secret Key Copied',
  TicketCsatRequested: 'Ticket CSAT Requested',
  TicketCsatSubmitted: 'Ticket CSAT Submitted',
  UnifiedNumberFilled: 'Unified Number Filled',
  UserInvited: 'User Invited',
  UserUpdated: 'User Updated',
  UsersOpened: 'Users Opened',
  VATCertificateUploaded: 'VAT Certificate Uploaded',
  VATNumberFilled: 'VAT Number Filled',
  VATRecipientsFilled: 'VAT Recipients Filled',
  WalletCreated: 'Wallet created',
  WebsiteURLFilled: 'Website URL Filled',
  WebsiteURLCheckFailed: 'WebsiteURLCheckFailed',
  ZeroDataShown: 'Zero Data Shown',
  FeedsSectionOpened: 'Feeds Section Opened',
  FeedsSellerSelected: 'Feeds Seller Selected',
  UploadItemsManuallyClicked: 'Upload Items Manually Clicked',
  ManualUploadNotifyMeClicked: 'Manual Upload Notify Me Clicked',
  FeedConnectPlatformClicked: 'Feed Connect Platform Clicked',
  FeedConnectionStatusShown: 'Feed Connection Status Shown',
  ScheduledCall: 'Scheduled call'
} as const;

export type EventName = ValueOf<typeof Events>;
