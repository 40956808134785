import { useShowNotification } from '@/components/Notifications';
import { ROUTES } from '@/routes';
import { useAnalyticsTrack } from '@/store/analytics';
import { useCreateLoanMutation } from '@/store/loan';
import { LoanOffer } from '@/store/offers';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useFormPropsContext } from './useFormPropsContext';

export const useFormSubmit = (offer: LoanOffer) => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const showNotification = useShowNotification();

  const { handleSubmit } = useFormPropsContext();
  const [createLoan, { isLoading }] = useCreateLoanMutation();

  const navigate = useNavigate();

  const submit = handleSubmit((values) => {
    const { finance, is_finance, ...rest } = values;
    const { id, merchant_id, currency, amount } = offer;

    const payload = {
      ...rest,
      ...(is_finance ? { finance } : {}),
      offer_id: offer.id
    };

    createLoan(payload)
      .unwrap()
      .then(({ loan_id }) => {
        showNotification({
          variant: 'success',
          message: t('changes_saved')
        });

        track({
          event: 'Capital Application Submitted',
          params: {
            loanId: id,
            merchantId: merchant_id,
            currency,
            capitalAmount: amount,
            signatoryName: payload.sign.name,
            signatoryEmail: payload.sign.email,
            signatoryPhone: payload.sign.phone,
            financeManagerName: payload?.finance?.name,
            financeManagerEmail: payload?.finance?.email,
            financeManagerPhone: payload?.finance?.phone,
            legalCompanyName: payload.company_name_or_legal_name,
            status: 'success'
          }
        });

        navigate(generatePath(ROUTES.financeTabbyCapitalLoansId, { id: loan_id }), {
          replace: true
        });
      })
      .catch(() => {
        track({
          event: 'Capital Application Submitted',
          params: {
            loanId: id,
            merchantId: merchant_id,
            currency,
            capitalAmount: amount,
            signatoryName: payload.sign.name,
            signatoryEmail: payload.sign.email,
            signatoryPhone: payload.sign.phone,
            financeManagerName: payload?.finance?.name,
            financeManagerEmail: payload?.finance?.email,
            financeManagerPhone: payload?.finance?.phone,
            legalCompanyName: payload.company_name_or_legal_name,
            status: 'failure'
          }
        });

        return showNotification({
          variant: 'error',
          message: t('something_went_wrong_please_try_again_later')
        });
      });
  });

  return { submit, isLoading };
};
