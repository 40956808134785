import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useChallengeSetupMutation } from '@/store/auth';
import { Button } from '@/ui-kit/Button';
import { InputPhoneCountryControlled } from '@/ui-kit/InputPhoneCountry';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import phones from '@/lib/phones';
import { useAnalyticsTrack } from '@/store/analytics';
import { Header } from './components/Header';
import { useHandleApiError } from './hooks/useHandleApiError';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

type Props = {
  initialPhone: string | null;
  onSuccess: (phone: string) => void;
};

export const SetupChallengePhoneForm = (props: Props) => {
  const { onSuccess, initialPhone } = props;
  const { t } = useTranslation();
  const [setup, { isLoading }] = useChallengeSetupMutation();
  const handleApiError = useHandleApiError('setupChallenge');
  const { control, handleSubmit, setError } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        phone: phones.getSchema(t)
      })
    ),
    defaultValues: {
      phone: initialPhone || ''
    }
  });
  const track = useAnalyticsTrack();

  const submit = handleSubmit(async ({ phone }) => {
    try {
      await setup({ phone }).unwrap();

      track({ event: 'Link 2FA Submitted', params: { type: 'phone', phone } });

      onSuccess(phone);
    } catch (error) {
      handleApiError(error)((message) => setError('phone', { message }));
    }
  });

  return (
    <Root>
      <Header
        title={t('link_phone_number_to_your_account')}
        subtitle={t('well_send_a_verification_code_to_this_phone_number')}
      />
      <Form onSubmit={submit} noValidate>
        <InputPhoneCountryControlled
          control={control}
          name="phone"
          size="medium"
          disabled={isLoading}
          autoFocus
        />
        <Button type="submit" isLoading={isLoading} variant="contained" size="large" fullWidth>
          {t('continue')}
        </Button>
      </Form>
    </Root>
  );
};
