import { useCallback } from 'react';
import { Events, useAnalyticsTrack } from '@/store/analytics';

import { useFormStateContext } from './useFormStateContext';
import { useFormPropsContext } from './useFormPropsContext';
import { useCRNumberSAUValidation } from './useCRNumberSAUValidation';

export const useHandleCRNumberSAU = () => {
  const track = useAnalyticsTrack();
  const { setValue } = useFormPropsContext();
  const {
    legalInfo: { setIsCRNumberSAUValid }
  } = useFormStateContext();
  const { getValidationResult, isFetching: isFetchingCRSAUValidation } = useCRNumberSAUValidation();

  const handleCRNumberSAU = useCallback(
    async (value: string) => {
      const result = await getValidationResult(value);

      if (!result) {
        setValue('legal_info.legal_address', '');
        setValue('legal_info.legal_company_name', '');
        setValue('legal_info.unified_number', '');
        setValue('legal_info.license_expiry_date', '');
        setValue('legal_info.business_type.id', '');
        setValue('legal_info.company_owner.id', '');
        setIsCRNumberSAUValid(false);
        return;
      }

      setIsCRNumberSAUValid(true);

      const {
        merchant_license_data: {
          legal_address,
          legal_company_name,
          unified_number,
          license_expire_date,
          license_type_id
        },
        company_owner
      } = result;

      if (legal_address) {
        setValue('legal_info.legal_address', legal_address);
        track({
          event: Events.LegalAddressFilled,
          params: { value: legal_address, isPrefilled: true }
        });
      }

      if (legal_company_name) {
        setValue('legal_info.legal_company_name', legal_company_name);
        track({
          event: Events.LegalCompanyNameFilled,
          params: { value: legal_company_name, isPrefilled: true }
        });
      }

      if (unified_number) {
        setValue('legal_info.unified_number', unified_number);
        track({
          event: Events.UnifiedNumberFilled,
          params: { value: unified_number, isPrefilled: true }
        });
      }

      if (license_expire_date) {
        setValue('legal_info.license_expiry_date', license_expire_date);
        track({
          event: Events.LicenseExpireDateFilled,
          params: { value: license_expire_date, isPrefilled: true }
        });
      }

      if (license_type_id) {
        setValue('legal_info.business_type.id', license_type_id);
      }

      if (company_owner.id) {
        setValue('legal_info.company_owner.id', company_owner.id);
      }
    },
    [getValidationResult, setIsCRNumberSAUValid, setValue, track]
  );

  return { handleCRNumberSAU, isFetchingCRSAUValidation };
};
