import {
  Tooltip as TuiTooltip,
  type TooltipProps as TuiTuiTooltipProps
} from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';

export type TooltipProps = TuiTuiTooltipProps;

export const Tooltip = ({ children, ...rest }: TooltipProps) => {
  return (
    <TuiTooltip placement="top" ds={DS.B2B2023} {...rest}>
      <div>{children}</div>
    </TuiTooltip>
  );
};
