import { ValueOf } from '@/types';
import { ReactNode } from 'react';

export const NOTIFICATION_VARIANTS = {
  Error: 'error',
  Info: 'info',
  Success: 'success',
  Warning: 'warning',
  Iris: 'iris'
} as const;

export type NotificationVariant = ValueOf<typeof NOTIFICATION_VARIANTS>;

export type Notification = {
  id: string;
  title?: ReactNode;
  message: ReactNode;
  variant: NotificationVariant;
  autoHide?: boolean;
};
