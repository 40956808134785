import { useTranslation } from 'react-i18next';

import CheckMarkIcon from '@/assets/icons/checkmark.svg?icon';

import { Alert } from './Alert';

export const LoanClosedAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert
      variant="success"
      title={t('youve_fully_repaid_your_capital_advance')}
      icon={<CheckMarkIcon />}
    />
  );
};
