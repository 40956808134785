import { TextFieldControlled } from '@/ui-kit/TextField';
import { useTranslation } from 'react-i18next';
import { SelectControlled } from '@/ui-kit/Select';
import Box from '@mui/material/Box';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { TEST_IDS } from '@/lib/autotests';
import { useEffect } from 'react';
import { InputPhoneCountryControlled } from '@/ui-kit/InputPhoneCountry';
import { useAnnualVolumeLabels } from '@/hooks/i18n/useAnnualVolumeLabels';
import { CountryFields } from './CountryFields';
import { FormSection } from './FormSection';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';

const BasicInfo = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { control } = useFormPropsContext();
  const { isFormDisabled, initialData } = useFormStateContext();
  const { options: annualVolumeOptions } = useAnnualVolumeLabels();

  useEffect(() => {
    track({ event: Events.MainInformationOpened });
  }, [track]);

  return (
    <FormSection
      title={t('tell_us_about_your_business')}
      subtitle={t('we_ll_use_this_information_to_optimise_your_tabby_experience')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <TextFieldControlled
          control={control}
          name="basic_info.first_name"
          placeholder={t('first_name')}
          analytics={{ event: Events.ApplicationFirstNameFilled }}
          testId={TEST_IDS.ApplicationFirstName}
          disabled={isFormDisabled}
          size="medium"
          fullWidth
        />
        <TextFieldControlled
          control={control}
          name="basic_info.last_name"
          placeholder={t('last_name')}
          analytics={{ event: Events.ApplicationLastNameFilled }}
          testId={TEST_IDS.ApplicationLastName}
          disabled={isFormDisabled}
          size="medium"
          fullWidth
        />
        {!initialData.email && (
          <TextFieldControlled
            control={control}
            name="basic_info.email"
            placeholder={t('email')}
            disabled={isFormDisabled}
            fullWidth
            size="medium"
          />
        )}
        {!initialData.phone && (
          <InputPhoneCountryControlled
            control={control}
            name="basic_info.phone"
            disabled={isFormDisabled}
            size="medium"
          />
        )}
        <TextFieldControlled
          control={control}
          name="basic_info.business_name"
          analytics={{ event: Events.BrandNameFilled }}
          placeholder={t('business_name')}
          testId={TEST_IDS.ApplicationBusinessName}
          disabled={isFormDisabled}
          fullWidth
          size="medium"
        />
        <CountryFields />
        <SelectControlled
          control={control}
          name="basic_info.annual_volume"
          placeholder={t('annual_sales_performance')}
          analytics={{ event: Events.AnnualVolumeFilled }}
          testId={TEST_IDS.ApplicationAnnualVolume}
          disabled={isFormDisabled}
          options={annualVolumeOptions}
          size="medium"
        />
      </Box>
    </FormSection>
  );
};

export default BasicInfo;
