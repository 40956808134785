import { generatePath, matchRoutes } from 'react-router-dom';
import { LogoutSource, ROUTES } from './routes';
import { AUTH_REFERRER_URL } from './constants';

const isRestrictedPath = (pathname: string): boolean => {
  return !!matchRoutes(
    [
      { path: ROUTES.auth },
      { path: ROUTES.root },
      { path: ROUTES.logout },
      { path: generatePath(ROUTES.logout, { source: '' }) }
    ],
    pathname
  );
};

export const isValidAuthReferrer = (value: string): boolean => {
  try {
    const url = new URL(value);
    return (
      url.protocol === 'https:' &&
      url.hostname.includes('.tabby.') &&
      !isRestrictedPath(url.pathname)
    );
  } catch {
    return value.startsWith('/') && !isRestrictedPath(value);
  }
};

export const getAuthReferrer = (search: string): string | null => {
  const url = new URLSearchParams(search).get(AUTH_REFERRER_URL);
  return url && isValidAuthReferrer(url) ? url : null;
};

export const getRelativeUrlWithAuthReferrer = (pathname: string, referrer: string | null) => {
  if (referrer && isValidAuthReferrer(referrer)) {
    const search = new URLSearchParams({ [AUTH_REFERRER_URL]: referrer }).toString();
    return `${pathname}?${search}`;
  }

  return pathname;
};

export const getLogoutRoute = (source: LogoutSource) => {
  return generatePath(ROUTES.logout, { source });
};
