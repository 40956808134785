import { useTranslation } from 'react-i18next';
import { Text } from '@/ui-kit/Text';
import React from 'react';
import { styled } from '@mui/material/styles';

const Root = styled('div')(() => ({
  textAlign: 'center'
}));

export const EmptyContent = ({
  extensionsFormatted,
  maxFileSizeKB
}: {
  extensionsFormatted: string;
  maxFileSizeKB: number;
}) => {
  const { t } = useTranslation();

  return (
    <Root>
      <Text variant="body2Tight500" color="B2B2023.front.minor">
        {t('browse_file')}
      </Text>
      <Text variant="body2Tight500" color="B2B2023.front.minor">
        {t('extensions_up_to_max_size_mb', {
          extensionsFormatted,
          maxSizeMB: maxFileSizeKB / 1000
        })}
      </Text>
    </Root>
  );
};
