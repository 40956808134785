import { Text } from '@/ui-kit/Text';
import { checkChildrenEmpty } from '@/utils/jsx';
import { ListSubheader as MuiListSubheader, ListSubheaderProps, styled } from '@mui/material';

type SideMenuBlockProps = {
  label: string;
  children: React.ReactNode;
  isCollapsed?: boolean;
} & ListSubheaderProps;

const SubHeader = styled('div')(() => ({
  position: 'relative'
}));

const ListSubheader = styled(MuiListSubheader, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  '&.MuiListSubheader-root': {
    overflow: 'visible',
    visibility: 'visible',
    padding: isCollapsed ? '8px 4px' : '8px 12px',
    whiteSpace: 'nowrap',
    transition: 'all 0.2s ease'
  }
}));

const TextGradient = styled(Text, {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed?: boolean }>(({ isCollapsed, theme }) => ({
  width: 'maxContent',
  color: isCollapsed ? 'transparent' : theme.palette.B2B2023.front.minor,
  backgroundClip: 'text',
  backgroundImage: isCollapsed
    ? `linear-gradient(to right, ${theme.palette.B2B2023.front.minor}, ${theme.palette.common.white})`
    : 'unset'
}));

export const SideMenuBlock = ({
  children,
  label,
  sx,
  isCollapsed,
  ...rest
}: SideMenuBlockProps) => {
  if (checkChildrenEmpty(children)) return null;

  return (
    <div>
      <SubHeader>
        <ListSubheader isCollapsed={isCollapsed} {...rest}>
          <TextGradient isCollapsed={isCollapsed} variant="badge">
            {label}
          </TextGradient>
        </ListSubheader>
      </SubHeader>
      {children}
    </div>
  );
};
