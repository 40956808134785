import { ROUTES } from '@/routes';
import { useGetLoansQuery } from '@/store/loan';
import { styled } from '@mui/material/styles';
import { find } from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useParams } from 'react-router-dom';
import { PageLayout, Breadcrumbs } from '@/components/PageLayout';
import { LoaderDots } from '@/components/LoaderDots';
import { ErrorPage } from '@/components/ErrorPage';
import { LoanStepper } from '../../components/LoanStepper/LoanStepper';
import { LoanStatusWidget } from './components/LoanStatusWidget';
import { LoanDetails } from './components/LoanDetails';
import { ResetLoanWidget } from './components/ResetLoanWidget';

const PageContent = styled(PageLayout.Content)(({ theme }) => ({
  alignItems: 'center',

  [theme.breakpoints.down('p768')]: {
    padding: '12px 0px',
    overflow: 'hidden'
  }
}));

export const LoanPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();

  const { data: loans = [], isLoading } = useGetLoansQuery();

  const loan = find(loans, (l) => l.id === id);

  const breadcrumbs = useMemo(() => {
    const getBreadcrumbsLabel = () => {
      switch (true) {
        case loan?.early_pay_off && loans.length > 1:
          return t('early_payoff');
        default:
          return loan?.legal_info.legal_name || '';
      }
    };

    return [
      {
        id: 'tabby_capital',
        href: generatePath(ROUTES.financeTabbyCapital),
        label: t('tabby_capital')
      },
      {
        id: 'application',
        href: '',
        label: getBreadcrumbsLabel()
      }
    ];
  }, [loan?.early_pay_off, loan?.legal_info?.legal_name, loans.length, t]);

  const loanContent = useMemo(() => {
    switch (loan?.status) {
      case 'submitted':
      case 'approved':
        return (
          <>
            <LoanStepper status={loan.status} />
            <LoanStatusWidget status={loan.status} email={loan.authority.sign?.email} />
          </>
        );
      case 'rejected':
        return (
          <LoanStatusWidget
            status={loan.status}
            email={loan.authority.sign?.email}
            rejectReason={loan.reject_reason}
          />
        );
      case 'reset':
        return (
          <>
            <LoanStepper status={loan.status} />
            <ResetLoanWidget loanId={id} />
          </>
        );

      default:
        return loan ? <LoanDetails loans={loans} loan={loan} /> : <>dddd</>;
    }
  }, [id, loan, loans]);

  if (isLoading) return <LoaderDots height="100vh" />;

  if (!loan) {
    return <ErrorPage status={404} />;
  }

  return (
    <>
      <PageLayout.Header customBreadcrumbs={<Breadcrumbs crumbs={breadcrumbs} />} />
      <PageContent>{loanContent}</PageContent>
    </>
  );
};
