import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import mime from 'mime-types';
import { FormLabel } from '@/ui-kit/FormLabel';

import { isFileSizeValid, isFileTypeValid } from '@/utils/file';
import { useFileUpload } from '@/ui-kit/FileUpload/useFileUpload';
import { FormControl } from '@/ui-kit/FormControl';
import { FormHelperText } from '@/ui-kit/FormHelperText';
import { FileManagerDropdown } from './components/FileManagerDropdown';
import { LoadingContent } from './components/LoadingContent';
import { ValueContent } from './components/ValueContent';
import { EmptyContent } from './components/EmptyContent';

const FILE_MANAGER_ID = 'FILE_MANAGER_ID';

export type FileUploadProps = {
  onUpload?: (url: string | null) => void;
  disabled?: boolean;
  fileName: string;
  value?: string;
  allowedMimeTypes?: string[];
  maxFileSizeKB?: number;
  helperText?: string;
  error?: boolean;
  label?: string;
  name: string;
  onBlur?: () => void;
};

const Root = styled('div', { shouldForwardProp: (prop) => prop !== 'isError' })<{
  isError: boolean;
}>(({ theme, isError }) => ({
  height: '100px',
  position: 'relative',
  borderRadius: '16px',
  backgroundColor: isError
    ? theme.palette.B2B2023.accent.negativeC
    : theme.palette.B2B2023.back.minor
}));

export const Label = styled('label', {
  shouldForwardProp: (prop) => prop !== 'isDropping'
})<{
  isDropping: boolean;
}>(({ theme, isDropping }) => ({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  zIndex: 1,
  display: 'flex',
  width: '100%',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s',
  border: `2px dashed ${
    isDropping ? theme.palette.B2B2023.front.minor : theme.palette.B2B2023.front.line
  }`,
  borderRadius: '16px',
  cursor: 'pointer'
}));

const Overlay = styled('div')({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  width: '100%',
  height: '100%',
  zIndex: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: '6px',
  cursor: 'pointer'
});

export const FileUpload = (props: FileUploadProps) => {
  const {
    fileName,
    onUpload,
    disabled,
    value,
    allowedMimeTypes = [mime.types.jpeg, mime.types.png, mime.types.pdf],
    maxFileSizeKB = 10000,
    helperText,
    error,
    label,
    name,
    onBlur
  } = props;

  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isDropping, setIsDropping] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const accept = allowedMimeTypes.join(', ');
  const extensions = allowedMimeTypes.map((mt) => mime.extension(mt));
  const extensionsFormatted = extensions.join(', ').toUpperCase();

  const { download, upload, isLoading, abort } = useFileUpload();
  const resetStates = () => {
    setFile(null);
    setProgress(0);
  };

  const handleAbortButtonClick = () => {
    abort();
    resetStates();
  };
  const selectFile = (selectedFile: File) => {
    if (isLoading || !selectedFile) return;

    resetStates();
    setErrorMessage('');
    onUpload?.(null);

    if (!isFileSizeValid(selectedFile, { max: maxFileSizeKB, unit: 'KB' })) {
      setErrorMessage(
        maxFileSizeKB < 1000
          ? t('file_is_larger_than_x_kb', { maxSize: maxFileSizeKB })
          : t('file_is_larger_than_x_mb', { maxSize: maxFileSizeKB / 1000 })
      );
      return;
    }

    if (!isFileTypeValid(selectedFile, allowedMimeTypes)) {
      setErrorMessage(
        t('file_must_have_a_extensions_formatted_extension', { extensionsFormatted })
      );
      return;
    }

    setFile(selectedFile);

    upload(
      selectedFile,
      setProgress,
      (res) => {
        onUpload?.(res);
      },
      () => {
        setErrorMessage(t('failed_to_upload'));
      },
      () => {
        resetStates();
        onBlur?.();
      }
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }
    selectFile(event.target.files[0]);
    event.target.value = '';
  };

  const handleDragLeave = (e: React.DragEvent<HTMLElement>) => {
    if (disabled) return;
    e.preventDefault();
    setIsDropping(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLElement>) => {
    if (disabled) return;
    e.preventDefault();
    setIsDropping(true);
  };

  const handleDrop = (e: React.DragEvent<HTMLElement>) => {
    if (value) return;
    if (disabled) return;
    e.preventDefault();

    const droppedFile = e.dataTransfer.files[0];
    setIsDropping(false);

    selectFile(droppedFile);
  };

  const handleFileDownload = () => {
    if (!value) return;
    setErrorMessage('');

    download(value, fileName, setErrorMessage);
  };

  return (
    <FormControl sx={{ opacity: disabled ? 0.7 : 1 }}>
      {label && <FormLabel variant="TextField">{label}</FormLabel>}
      <Root
        isError={!!errorMessage || !!error}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <FileManagerDropdown
          disabled={disabled}
          value={value}
          onFileDownload={handleFileDownload}
          onFileReplace={() => {
            inputRef.current?.click();
          }}
          onFileDelete={() => {
            resetStates();
            setErrorMessage('');
            onUpload?.(null);
          }}
        />
        <Label htmlFor={FILE_MANAGER_ID + name} isDropping={isDropping}>
          <input
            style={{
              visibility: 'hidden',
              position: 'absolute',
              width: '1px',
              height: '1px',
              margin: '-1px',
              border: 0,
              padding: 0,
              clip: 'rect(0, 0, 0, 0)',
              overflow: 'hidden'
            }}
            id={FILE_MANAGER_ID + name}
            disabled={disabled}
            type="file"
            accept={accept}
            onChange={handleInputChange}
            ref={inputRef}
            name={name}
          />
          {!isLoading && !value && (
            <EmptyContent extensionsFormatted={extensionsFormatted} maxFileSizeKB={maxFileSizeKB} />
          )}
        </Label>
        {value && (
          <Overlay
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            <ValueContent fileName={fileName} />
          </Overlay>
        )}
        {isLoading && file && (
          <Overlay>
            <LoadingContent
              fileName={fileName}
              fileSize={file.size}
              progress={progress}
              onAbortButtonClick={handleAbortButtonClick}
            />
          </Overlay>
        )}
      </Root>
      {(errorMessage || helperText) && (
        <FormHelperText error={(!!errorMessage || !!error) && !disabled}>
          {errorMessage || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};
