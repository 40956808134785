import { PaymentOrderItem } from '@/store/payment';
import { Currency, ListTokenResult, ValueOf } from '@/types';
import { QueryStatus } from '@reduxjs/toolkit/query/react';
import { Merchant } from '../merchants';

export enum DisputeStatuses {
  Pending = 'pending',
  Declined = 'declined',
  Cancelled = 'cancelled',
  Approved = 'approved',
  Arbitration = 'arbitration'
}

export type GetDisputesParamsApi = {
  page_token?: string;
  page_size?: number;
  created_at__gte?: string | null;
  created_at__lte?: string | null;
  statuses?: DisputeStatuses[];
  merchant_ids?: string[];
};

export type GetDisputesParams = {
  nextPageToken?: string;
  limit?: number;
  createdFrom?: string | null;
  createdTo?: string | null;
  statuses?: DisputeStatuses[];
  merchantsIds?: string[];
  query?: string;
};

export type DisputeEventType =
  | 'dispute_amount_changed'
  | 'dispute_approved'
  | 'dispute_canceled'
  | 'dispute_comment_added'
  | 'dispute_created'
  | 'dispute_declined'
  | 'dispute_in_arbitration'
  | 'dispute_partially_refunded';

export type DisputeHistoryItem = {
  id: string;
  created_by: string;
  created_at: string;
  content: string;
  source: 'merchant' | 'tabby' | 'customer';
  description: string;
  attachments: string[];
  event_type: DisputeEventType;
  note?: string;
  link?: string;
};

export type Dispute = {
  id: string;
  merchant_id: string;
  payment_id: string;
  amount: string;
  created_at: string;
  status: DisputeStatuses;
  comment: string;
  reason: DisputeReason;
  days_left: number;
  attachments: string[];
  items?: PaymentOrderItem[];
  history: DisputeHistoryItem[];
  payment?: {
    order_number: string;
    captured_amount: string;
    refunded_amount: string;
    currency: Currency;
    merchant_name: string;
  };
};

export type GetDisputesResponse = {
  disputes: Dispute[] | null;
  next_page_token: string | null;
};

export type GetDisputesResult = ListTokenResult<Dispute>;

export type ExportDisputesCsvParams = Pick<
  GetDisputesParams,
  'createdFrom' | 'createdTo' | 'statuses' | 'merchantsIds' | 'query'
>;

export type ExportDisputesCsvParamsApi = {
  merchant_ids?: string[];
  created_at_gte?: string | null;
  created_at_lte?: string | null;
  statuses?: DisputeStatuses[];
  query?: string;
};

export type ChallengeDisputeParams = {
  id: string;
  amount: string;
  description: string;
  reason: string;
  assets_ids: string[];
};

export const DISPUTE_TUTORIAL_STEPS = {
  first: 'first',
  second: 'second',
  hidden: 'hidden'
} as const;

export type DisputeTutorialStep = ValueOf<typeof DISPUTE_TUTORIAL_STEPS>;

export type DisputesState = {
  disputes: Dispute[];
  filters: GetDisputesParams;
  status: QueryStatus;
  isEndReached: boolean;
  selectedMerchants: Merchant[];
  tutorialStep: DisputeTutorialStep;
};

export const DISPUTE_CHALLENGE_REASONS = {
  OnItsWay: 'merchant_reason_order_on_its_way',
  Delivered: 'merchant_reason_order_has_been_already_delivered',
  DifferentAmount: 'merchant_reason_order_amount_should_be_different',
  DeliveryProblem: 'merchant_reason_problem_with_delivery',
  Other: 'merchant_reason_other'
} as const;

export type DisputeChallengeReason = ValueOf<typeof DISPUTE_CHALLENGE_REASONS>;

export const DISPUTE_REASONS = {
  IdentityTheft: 'identity_theft',
  NotDelivered: 'not_delivered',
  ProductIssue: 'product_issue',
  UnreceivedRefund: 'unreceived_refund'
} as const;

export type DisputeReason = ValueOf<typeof DISPUTE_REASONS>;
