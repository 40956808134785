import { rootApi } from '../rootApi/api';
import { Account } from '../account/types';
import { GetExperimentsParams, GetExperimentsResponse } from '../experiments/types';
import { ComputedPermissionsApi, Me } from './types';
import { createComputedPermissions } from './utils';
import { Application } from '../application/types';
import { RootState } from '../store';

const accountMeApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    me: build.query<Me, void>({
      keepUnusedDataFor: 60 * 5, // 5 min,
      providesTags: ['Me'],
      queryFn: async (args, api, extraOptions, fetchWithBQ) => {
        const state = api.getState() as RootState | void;

        if (state && (!state.auth.isAuthorized || state.auth.is401Error)) {
          return { error: { status: 401, data: { message: 'Unauthorized' } } };
        }

        const [res1, res2, res3] = await Promise.all([
          fetchWithBQ('/merchant/api/v1/account'),
          fetchWithBQ('/merchant/api/v1/auth/permissions'),
          fetchWithBQ('/merchant/api/v2/application')
        ]);

        const account = !res1.error ? (res1.data as Account) : null;
        const permissionsApi = !res2.error ? (res2.data as ComputedPermissionsApi) : null;
        const applicationV2 = !res3.error ? (res3.data as Application) : null;

        const res4 =
          account &&
          (await fetchWithBQ({
            url: '/merchant/api/v1/experiments',
            method: 'GET',
            params: { entity_id: account.id, entity: 'merchant_account' } as GetExperimentsParams
          }));

        const experimentsResponse =
          res4 && !res4.error ? (res4.data as GetExperimentsResponse) : { experiments: [] };
        const experiments = experimentsResponse.experiments
          .filter((e) => e.value === 'enabled')
          .map((e) => e.name);

        const computedPermissions = createComputedPermissions({
          applicationV2,
          experiments,
          permissionsApi
        });

        return {
          data: {
            account,
            computedPermissions,
            experiments
          }
        };
      }
    })
  })
});

export const { useMeQuery } = accountMeApi;
