import {
  Select as TuiSelect,
  type SelectProps as TuiSelectProps
} from '@tabby.ai/tabby-ui/dist/core';
import { FormLabel } from '@/ui-kit/FormLabel';
import { getTestProps, TestId } from '@/lib/autotests';
import { HelperText } from '@/ui-kit/HelperText';
import { styled } from '@mui/material/styles';
import { FormControl as MuiFormControl } from '@mui/material';
import { Option } from '@/types';
import { useTranslation } from 'react-i18next';
import { SelectSkeleton } from './SelectSkeleton';

export { TuiSelectClasses as SelectClasses } from '@tabby.ai/tabby-ui/dist/core';

export const FormControl = styled(MuiFormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export type SelectProps = Omit<TuiSelectProps, 'options'> & {
  helperText?: string;
  testId?: TestId;
  options: Option[];
  isLoading?: boolean;
};

export const Select = ({
  sx,
  label,
  disabled,
  testId,
  inputProps,
  error,
  helperText,
  options,
  placeholder,
  isLoading,
  size,
  ...rest
}: SelectProps) => {
  const { t } = useTranslation();

  if (isLoading) {
    return <SelectSkeleton hasLabel={!!label} size={size} />;
  }

  return (
    <FormControl disabled={disabled}>
      {label && <FormLabel variant="TextField">{label}</FormLabel>}
      <TuiSelect
        ds="B2B2023"
        sx={{ ...sx, display: 'flex', flexDirection: 'column' }}
        error={error}
        inputProps={{ ...inputProps, ...getTestProps(testId) }}
        options={options.map((option) => ({
          value: option.value,
          label: option.label,
          topHelperText: option.topHelperText,
          bottomHelperText: option.bottomHelperText,
          onClick: option.onClick,
          customHtml: option.customHtml
        }))}
        placeholder={placeholder || t('select')}
        size={size}
        disabled={disabled}
        {...rest}
      />
      {helperText && <HelperText error={error && !disabled}>{helperText}</HelperText>}
    </FormControl>
  );
};
