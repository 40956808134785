import BasicModal from '@/components/Modal/Modal';
import { NotReachable } from '@/utils/notReachable';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContentForm } from './ModalContentForm';
import { ModalContentSuccess } from './ModalContentSuccess';

const Root = styled('div')<{ isMobile: boolean }>`
  padding: ${({ isMobile }) => (isMobile ? '24px' : '40px')};
  text-align: center;
  width: ${({ isMobile }) => (isMobile ? '100%' : '392px')};
  height: 100%;
`;

type Props = {
  open: boolean;
  onClose: () => void;
  offerId: string;
};

type Step = { value: 'form' } | { value: 'success'; phone: string; date: string; offerId: string };

export const Modal: FC<Props> = (props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();
  const { open, onClose, offerId } = props;
  const [currentStep, setCurrentStep] = useState<Step>({ value: 'form' });

  const onSuccess = ({ phone, date }: { phone: string; date: string }) =>
    setCurrentStep({ value: 'success', phone, date, offerId });

  useEffect(() => {
    if (open === true) {
      setCurrentStep({ value: 'form' });
    }
  }, [open]);

  const renderStep = (step: Step) => {
    switch (step.value) {
      case 'form': {
        return <ModalContentForm onSuccess={onSuccess} onClose={onClose} isMobile={isMobile} />;
      }
      case 'success': {
        return (
          <ModalContentSuccess
            phone={step.phone}
            date={step.date}
            offerId={step.offerId}
            onClose={onClose}
          />
        );
      }
      default: {
        // eslint-disable-next-line no-new
        new NotReachable(step);
        return null;
      }
    }
  };

  return (
    <BasicModal
      open={open}
      onClose={onClose}
      fullscreen={isMobile}
      showHeader={isMobile}
      showBorder
      borderRadius="32px"
      title={isMobile ? t('schedule_a_call') : undefined}
    >
      <Root isMobile={isMobile}>{renderStep(currentStep)}</Root>
    </BasicModal>
  );
};
