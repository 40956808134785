import { COUNTRIES, Country, Currency } from '@/types';
import { CountryConfig } from './types';
import * as configs from './configs';

export const configByCountry: Record<Country, CountryConfig> = {
  [COUNTRIES.ARE]: configs.ARE,
  [COUNTRIES.BHR]: configs.BHR,
  [COUNTRIES.EGY]: configs.EGY,
  [COUNTRIES.KWT]: configs.KWT,
  [COUNTRIES.QAT]: configs.QAT,
  [COUNTRIES.SAU]: configs.SAU
};

export const currencyToCountry = Object.fromEntries(
  Object.entries(configByCountry).map(([country, cfg]) => [cfg.currency, country])
) as Record<Currency, Country | undefined>;
