import { FormControlLabel } from '@/ui-kit/FormControlLabel';
import { HelperText } from '@/ui-kit/HelperText';
import { styled } from '@mui/material/styles';
import { CheckboxBase, type CheckboxBaseProps } from './CheckboxBase';

export const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export type CheckboxProps = CheckboxBaseProps & {
  label: string;
  error?: boolean;
  helperText?: string;
};

export const Checkbox = ({ label, error, disabled, helperText, ...rest }: CheckboxProps) => {
  return (
    <Root>
      <FormControlLabel
        label={label}
        variant="Checkbox"
        control={<CheckboxBase disabled={disabled} {...rest} />}
      />
      {helperText && <HelperText error={error && !disabled}>{helperText}</HelperText>}
    </Root>
  );
};
