import { useGetQuantityOfActiveDisputesQuery } from '@/store/disputes';
import { truncateNumber } from '@/utils/number';
import { ROUTES } from '@/routes';
import { useTranslation } from 'react-i18next';
import Chat24 from '@tabby.ai/tabby-ui/dist/icons/core/Chat24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import { NavLink, useMatch } from 'react-router-dom';
import useCachedFlag from '@/hooks/useCachedFlag';
import { useEffect, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query/react';

type Props = {
  isCollapsed?: boolean;
  canFetchDisputes?: boolean;
};

export const NavMenuItemDisputes = ({ isCollapsed, canFetchDisputes }: Props) => {
  const { t } = useTranslation();
  const { data } = useGetQuantityOfActiveDisputesQuery(canFetchDisputes ? undefined : skipToken);
  const [visited, setVisited] = useCachedFlag('disputes-page');
  const isDisputesPage = !!useMatch(ROUTES.disputes);

  useEffect(() => {
    if (isDisputesPage) {
      setVisited(true);
    }
  }, [isDisputesPage, setVisited]);

  const notificationBadgeText = useMemo(() => {
    if (canFetchDisputes) {
      return data?.total ? truncateNumber(data.total) : undefined;
    }

    return visited ? undefined : t('new');
  }, [canFetchDisputes, data?.total, t, visited]);

  return (
    <SideMenuItem
      isCollapsed={isCollapsed}
      icon={<Chat24 ds={DS.B2B2023} />}
      label={t('disputes')}
      to={ROUTES.disputes}
      notificationBadgeText={notificationBadgeText}
      component={NavLink}
    />
  );
};
