import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import phones from '@/lib/phones';
import { format } from '../utils';

export const useSchema = (minDate: Date, maxDate: Date) => {
  const { t } = useTranslation();

  return yup.object({
    phone: phones.getSchema(t),
    date: yup
      .date()
      .transform((current, original) => {
        return original === '' ? null : current;
      })
      .min(
        minDate,
        t('the_earliest_date_you_can_choose_is_{{date}}', {
          date: format(minDate, 'en'),
          interpolation: { escapeValue: false }
        })
      )
      .max(
        maxDate,
        t('the_latest_date_you_can_choose_is_{{date}}', {
          date: format(maxDate, 'en'),
          interpolation: { escapeValue: false }
        })
      )
      .required(t('required_field'))
  });
};
