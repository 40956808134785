import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LegendVariant, LEGEND_VARIANTS } from '../types';

export const useLegendLabels = () => {
  const { t } = useTranslation();

  const legendDict = useMemo(
    () => ({
      [LEGEND_VARIANTS.default]: t('to_pay'),
      [LEGEND_VARIANTS.paid]: t('paid'),
      [LEGEND_VARIANTS.overdue]: t('overdue'),
      [LEGEND_VARIANTS.earlyPayOff]: t('early_payoff')
    }),
    [t]
  );

  const getLabel = (legend: LegendVariant) => legendDict[legend];

  return { getLabel };
};
