import { ErrorPage } from '@/components/ErrorPage';
import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { useAuthPageContext } from '../AuthPageProvider';

export const AuthStepError = () => {
  const { goToSignIn } = useAuthPageContext();

  return (
    <AuthStepWidget onGoBack={goToSignIn}>
      <ErrorPage status={500} />
    </AuthStepWidget>
  );
};
