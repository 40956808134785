import { Button } from '@/ui-kit/Button';
import { InputPhoneCountryControlled } from '@/ui-kit/InputPhoneCountry';
import { TextFieldControlled } from '@/ui-kit/TextField';
import Typography from '@mui/material/Typography';
import { Text } from '@/ui-kit/Text';
import { styled, useTheme } from '@mui/material/styles';
import mime from 'mime-types';
import { Trans, useTranslation } from 'react-i18next';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { Events } from '@/store/analytics';
import { FormLabel } from '@/ui-kit/FormLabel';

import SwitchControlled from '@/components/Switch/SwitchControlled';

import Info24 from '@tabby.ai/tabby-ui/dist/icons/core/Info24';
import { Box, useMediaQuery } from '@mui/material';
import { Tooltip } from '@tabby.ai/tabby-ui/dist/core';
import { useState } from 'react';
import { Checkbox } from '@/ui-kit/Checkbox';
import Link from '@/components/Link/Link';
import { ROUTES } from '@/routes';
import { FileUploadControlled } from '@/ui-kit/FileUpload';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { useSyncInitialData } from '../hooks/useSyncInitialData';
import { CRNumberFields } from './CRNumberFields';

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 24px 0',
  gap: '16px'
}));

const FieldGroup = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0 24px'
}));

const FieldTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '24px 0 0 0'
}));

const FieldTitleSwitch = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',

  '.MuiFormControlLabel-root': {
    gap: '0'
  }
}));

const FieldFile = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const Footer = styled('div')(() => ({
  padding: '16px 24px 24px',
  display: 'flex',
  justifyContent: 'flex-end'
}));

const HintContent = styled('div')({
  margin: '8px 4px'
});

const HintList = styled(Typography)({
  marginTop: '8px'
});

const HintListItem = styled('li')({
  listStyleType: 'none',
  marginBottom: '8px',

  '&:before': {
    content: '"•"',
    margin: '0 6px'
  }
});

export const FormContent = () => {
  const { t } = useTranslation();
  const [isRulesAccepted, setIsRulesAccepted] = useState(false);
  const { control, watch } = useFormPropsContext();
  const { submit, isLoadingSubmit, hasAuthority, isLegalEntityCountrySAU, legalEntity } =
    useFormStateContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  useSyncInitialData();

  const is_finance = watch('is_finance');

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };

  return (
    <>
      <Header>
        <Text variant="h2">{t('tabby_capital_application')}</Text>
        <Text variant="body1Loose500">
          {t('well_need_the_following_to_approve_your_application')}
        </Text>
      </Header>
      {hasAuthority && (
        <>
          <FieldGroup>
            <FieldTitle>
              <Text variant="h3">{t('authorised_signatory')}</Text>
            </FieldTitle>
            <TextFieldControlled
              control={control}
              name="sign.name"
              label={t('full_name')}
              placeholder={t('full_name_placeholder')}
              analytics={{
                event: Events.CapitalNameFilled,
                type: 'signatory'
              }}
            />
            <TextFieldControlled
              control={control}
              name="sign.email"
              label={t('email')}
              placeholder="example@mail.com"
              analytics={{
                event: Events.CapitalEmailFilled,
                type: 'signatory'
              }}
            />
            <InputPhoneCountryControlled
              control={control}
              name="sign.phone"
              label={t('phone_number')}
              analytics={{
                event: Events.CapitalPhoneNumberFilled,
                type: 'signatory'
              }}
            />
          </FieldGroup>
          <FieldGroup>
            <FieldTitle>
              <FieldTitleSwitch>
                <Text variant="h3">{t('finance_manager')}</Text>
                <SwitchControlled control={control} name="is_finance" label="" />
              </FieldTitleSwitch>
              <Text variant="body1Loose500">{t('add_your_finance_manager__description')}</Text>
            </FieldTitle>
            {is_finance && (
              <>
                <TextFieldControlled
                  control={control}
                  name="finance.name"
                  label={t('full_name')}
                  placeholder={t('full_name_placeholder')}
                  analytics={{
                    event: Events.CapitalNameFilled,
                    type: 'finance_manager'
                  }}
                />
                <TextFieldControlled
                  control={control}
                  name="finance.email"
                  label={t('email')}
                  placeholder="example@mail.com"
                  analytics={{
                    event: Events.CapitalEmailFilled,
                    type: 'finance_manager'
                  }}
                />
                <InputPhoneCountryControlled
                  control={control}
                  name="finance.phone"
                  analytics={{
                    event: Events.CapitalPhoneNumberFilled,
                    type: 'finance_manager'
                  }}
                />
              </>
            )}
          </FieldGroup>
        </>
      )}
      <FieldGroup>
        <FieldTitle>
          <Text variant="h3">{t('business_license')}</Text>
        </FieldTitle>
        <FieldFile>
          <Text variant="body1Loose500">
            {legalEntity.name
              ? t('the_business_licence_must_be_for_store__description', {
                  store: legalEntity.name
                })
              : t('the_business_licence_must_be_for_company_with_iban__description', {
                  iban: legalEntity.iban
                })}
          </Text>
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <FormLabel variant="TextField">
              {isLegalEntityCountrySAU ? t('commercial_registration') : t('business_license')}
            </FormLabel>
            <Tooltip
              placement={isMobile ? 'bottom' : 'right'}
              title={
                <HintContent>
                  <Text variant="h3">{t('file_requirements')}</Text>
                  <HintList>
                    <HintListItem>{t('all_data_is_clearly_readable')}</HintListItem>
                    <HintListItem>{t('no_distortion_or_glare')}</HintListItem>
                    <HintListItem>{t('fully_visible_and_no_portions_are_cut_off')}</HintListItem>
                  </HintList>
                </HintContent>
              }
              ds="B2B2023"
              open={isTooltipOpen}
              onClose={handleTooltipClose}
            >
              <span
                onMouseEnter={isMobile ? undefined : handleTooltipOpen}
                onClick={isMobile ? handleTooltipOpen : undefined}
              >
                <Info24 ds={DS.B2B2023} />
              </span>
            </Tooltip>
          </Box>
          <FileUploadControlled
            control={control}
            name="cr_file"
            allowedMimeTypes={[mime.types.pdf, mime.types.jpeg, mime.types.png]}
            fileName={isLegalEntityCountrySAU ? 'commercial_registration' : 'business_license'}
            maxFileSizeKB={5000}
            analytics={{
              event: Events.CapitalLicenseUploaded
            }}
          />
        </FieldFile>

        <CRNumberFields />
      </FieldGroup>

      <FieldGroup>
        <FieldTitle>
          <Text variant="h3">{t('payout_cycle')}</Text>
        </FieldTitle>
        <Text variant="body1Loose400">
          <Trans
            i18nKey="tabby_capital_only_works_with_the_fixed_payout_cycle_learn_more_about_payout_cycles"
            components={{
              a: <Link href={ROUTES.financeHowReportsWork} target="_blank" rel="no-refferer" />
            }}
          />
        </Text>
        <Checkbox
          label={t('i_agree_to_the_fixed_payout_cycle')}
          checked={isRulesAccepted}
          onChange={() => setIsRulesAccepted((prev) => !prev)}
        />
      </FieldGroup>

      <Footer>
        <Button
          isLoading={isLoadingSubmit}
          onClick={submit}
          sx={{ textTransform: 'capitalize' }}
          disabled={!isRulesAccepted}
        >
          {t('submit_application')}
        </Button>
      </Footer>
    </>
  );
};
