import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useSelector } from '@/store/store';
import { QueryStatus } from '@reduxjs/toolkit/query/react';
import { DataTable, DataTableItem, DataTableState, DataTableName, PaginationType } from './types';
import { getPaginationByType } from './utils';

export const emptyDataTable: DataTable = {
  items: [],
  pagination: {
    offset: 0,
    limit: 20,
    pageToken: ''
  },
  status: QueryStatus.uninitialized,
  isEndReached: false
};

const initialState = {} as DataTableState;

type LoadPageParams = {
  name: DataTableName;
  isEndReached: boolean;
  items: DataTableItem[];
  nextPageToken?: string;
};

export const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    initList: (state, action: PayloadAction<DataTableName>) => {
      state[action.payload] = emptyDataTable;
    },
    destroyList: (state, action: PayloadAction<DataTableName>) => {
      delete state[action.payload];
    },
    loadFirstPage: (state, action: PayloadAction<LoadPageParams>) => {
      const { name, isEndReached, items, nextPageToken = '' } = action.payload;

      if (!state[name]) return;

      state[name].items = items;
      state[name].pagination.offset = state[name].pagination.limit;
      state[name].pagination.pageToken = nextPageToken;
      state[name].isEndReached = isEndReached;
      state[name].status = QueryStatus.fulfilled;
    },
    loadNextPage: (state, action: PayloadAction<LoadPageParams>) => {
      const { items, name, isEndReached, nextPageToken = '' } = action.payload;

      if (!state[name]) return;

      state[name].items = [...state[name].items, ...items];
      state[name].pagination.offset += state[name].pagination.limit;
      state[name].pagination.pageToken = nextPageToken;
      state[name].isEndReached = isEndReached;
      state[name].status = QueryStatus.fulfilled;
    },
    setStatus: (state, action: PayloadAction<{ name: DataTableName; status: QueryStatus }>) => {
      const { name, status } = action.payload;

      if (!state[name]) return;

      state[name].status = status;
    },
    updateItem: (state, action: PayloadAction<{ name: DataTableName; item: DataTableItem }>) => {
      const {
        name,
        item: { id, ...newItem }
      } = action.payload;

      if (!state[name]) return;

      state[name].items = state[name].items.map((item) =>
        item.id === id ? { ...item, ...newItem } : item
      );
    }
  }
});

export const ACTIONS = {
  ...dataTableSlice.actions
};

export const SELECTORS = {
  selectItems: (state: RootState, name: DataTableName) => {
    return state[dataTableSlice.name][name]?.items || [];
  },
  selectPagination: (state: RootState, name: DataTableName, paginationType: PaginationType) => {
    const pagination = state[dataTableSlice.name][name]?.pagination || emptyDataTable.pagination;
    return getPaginationByType(pagination, paginationType);
  },
  selectInitialPagination: (paginationType: PaginationType) => {
    return getPaginationByType(emptyDataTable.pagination, paginationType);
  },
  selectFlags: (state: RootState, name: DataTableName) => {
    const { status, isEndReached } = state[dataTableSlice.name][name] || emptyDataTable;

    return {
      isEndReached,
      isFetching: status === QueryStatus.pending,
      isError: status === QueryStatus.rejected,
      isUninitialized: status === QueryStatus.uninitialized,
      isListInitialized: Boolean(state[dataTableSlice.name][name])
    };
  }
};

export const HOOKS = {
  useItems: (name: DataTableName) => {
    return useSelector((state: RootState) => SELECTORS.selectItems(state, name));
  },
  usePagination: (name: DataTableName, paginationType: PaginationType) => {
    return useSelector((state: RootState) =>
      SELECTORS.selectPagination(state, name, paginationType)
    );
  },
  useInitialPagination: (paginationType: PaginationType) => {
    return useSelector(() => SELECTORS.selectInitialPagination(paginationType));
  },
  useFlags: (name: DataTableName) => {
    return useSelector((state: RootState) => SELECTORS.selectFlags(state, name));
  }
};
