import CheckmarkIcon from '@/assets/icons/checkmark.svg?icon';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'react-i18next';
import { MailToLink } from '@/components/MailToLink';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  width: '420px',
  margin: '0 auto'
}));

const MessageBody = styled('div')(({ theme }) => ({
  background: theme.palette.B2B2023.back.major,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '48px 24px',
  borderRadius: '24px',
  overflow: 'hidden',
  textAlign: 'center'
}));

const AdditionalMessage = styled('div')(({ theme }) => ({
  background: theme.palette.B2B2023.back.major,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '16px 24px',
  borderRadius: '24px',
  overflow: 'hidden',
  textAlign: 'center'
}));

const Text = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Loose500,
  color: theme.palette.B2B2023.front.minor
}));

const IconWrapper = styled('div')(() => ({
  borderRadius: '50%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',

  '& .MuiSvgIcon-root': {
    fontSize: '48px'
  }
}));

const SuccessIconWrapper = styled(IconWrapper)(({ theme }) => ({
  background: theme.palette.B2B2023.accent.positiveC,
  color: theme.palette.B2B2023.accent.positiveA
}));

export const WaitingListWidget = () => {
  const { t } = useTranslation();

  return (
    <Root>
      <MessageBody>
        <SuccessIconWrapper>
          <CheckmarkIcon />
        </SuccessIconWrapper>

        <DeprecatedTypography variant="_h2">{t('you_are_on_the_waitlist')}</DeprecatedTypography>
        <Text>{t('we_will_let_you_know_once_capital_is_available')}</Text>
      </MessageBody>

      <AdditionalMessage>
        <Text>
          <Trans
            i18nKey="if_you_have_any_questions_about_this_decision_reach_out"
            components={{
              mailToLink: <MailToLink value="partner@tabby.ai" />
            }}
          />
        </Text>
      </AdditionalMessage>
    </Root>
  );
};
