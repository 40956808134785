import { Loader, LoaderProps } from '@tabby.ai/tabby-ui/dist/core';
import Box from '@mui/material/Box';

type Props = {
  height?: '100%' | '100vh';
  type?: LoaderProps['type'];
};

export const LoaderDots = ({ height = '100%', type = '3dots' }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height
      }}
    >
      <Loader type={type} />
    </Box>
  );
};
