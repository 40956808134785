import {
  useResendSignInCodeMutation,
  useAuthenticateVerifyMutation,
  useAuthorize
} from '@/store/auth';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { useAnalyticsTrack } from '@/store/analytics';
import { useState } from 'react';
import { VerifyPhoneForm } from './components/VerifyPhoneForm';
import { useHandleApiError } from './hooks/useHandleApiError';

type Props = {
  phone: string;
  onPhoneChange: VoidFunction;
  onChallenge: (challenge: 'setup_email' | 'verify_email', emailPart: string | null) => void;
};

const trackParams = { type: 'phone', stage: 'login' } as const;

export const VerifySignInPhoneForm = (props: Props) => {
  const { phone, onPhoneChange, onChallenge } = props;
  const lang = useCurrentLanguage();
  const [resend] = useResendSignInCodeMutation();
  const [verify, { isLoading }] = useAuthenticateVerifyMutation();
  const authorize = useAuthorize();
  const handleApiError = useHandleApiError('verify');
  const track = useAnalyticsTrack();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (code: string) => {
    setErrorMessage('');
    try {
      const res = await verify({ otp_code: code }).unwrap();
      track({
        event: 'OTP Entered',
        params: { ...trackParams, result: 'success', phone }
      });

      if (res.status === 'authenticated') {
        track({ event: 'Logged In', params: trackParams });
        authorize();
      } else if (res.status === 'setup_2fa' && res.factor === 'email') {
        onChallenge('setup_email', null);
      } else if (res.status === 'input_2fa' && res.factor === 'email') {
        onChallenge('verify_email', res.email);
      }
    } catch (error) {
      track({
        event: 'OTP Entered',
        params: { ...trackParams, result: 'failure', phone }
      });

      handleApiError(error)(setErrorMessage);
    }
  };

  const handleResend = async ({ resetTimer }: { resetTimer: VoidFunction }) => {
    setErrorMessage('');
    track({ event: 'OTP Resend Code Clicked', params: trackParams });

    try {
      await resend({ lang, phone }).unwrap();
      resetTimer();
    } catch (error) {
      handleApiError(error)(setErrorMessage);
    }
  };

  return (
    <VerifyPhoneForm
      phone={phone}
      onPhoneChange={onPhoneChange}
      onSubmitSuccess={handleSubmit}
      onResend={handleResend}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
};
