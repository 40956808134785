import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { useDispatch } from '@/store';
import { authSlice, useLogoutMutation } from '@/store/auth';
import { useAnalyticsTrack, useAnalytics } from '@/store/analytics';
import { LoaderDots } from '@/components/LoaderDots';
import { LogoutSource } from '@/routes';

export const LogoutPage = () => {
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const { source } = useParams<{ source: LogoutSource }>();
  const track = useAnalyticsTrack({ skipMeQuery: source === 'server' });
  const [logout] = useLogoutMutation();

  useEffect(() => {
    const run = async () => {
      track({ event: 'Logged Out', params: { source } });
      analytics.reset();
      StreamChat.getInstance(process.env.GET_STREAM_KEY).disconnectUser();
      window.localStorage.clear();
      window.sessionStorage.clear();

      if (source !== 'server') await logout();

      dispatch(authSlice.actions.deauthorized());
    };

    run();
  }, [analytics, dispatch, logout, source, track]);

  return <LoaderDots height="100vh" />;
};
