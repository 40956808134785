import { AuthSignInWidget } from '@/components/AuthLayoutWidgets';
import { SignInForm } from '@/components/AuthForms';
import { useAnalyticsTrack } from '@/store/analytics';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepSignIn = () => {
  const { state, setStep, setSignInMethod, setEmail, setPhone } = useAuthPageContext();
  const { step, email: initEmail, phone: initPhone, signInMethod } = state;
  const track = useAnalyticsTrack();

  const handleMethodChange = (method: 'email' | 'phone') => {
    track({ event: 'Sign Up Type Changed', params: { type: method } });
    setSignInMethod(method);
  };

  const handleSuccess = (method: 'email' | 'phone', values: { email: string; phone: string }) => {
    setEmail(values.email);
    setPhone(values.phone);
    setStep(`sign_in_verify_${method}`);
  };

  if (step === 'sign_in') {
    return (
      <AuthSignInWidget>
        <SignInForm
          initialEmail={initEmail}
          initialPhone={initPhone}
          method={signInMethod}
          onMethodChange={handleMethodChange}
          onSuccess={handleSuccess}
        />
      </AuthSignInWidget>
    );
  }

  return <GoToSignIn />;
};
