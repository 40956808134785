import { filterFalsyValues } from '@/utils/object';
import isArray from 'lodash/isArray';

export const convertSearchQueryToObject = (searchQuery: string) => {
  const result = {} as Record<string, string>;

  new URLSearchParams(searchQuery).forEach((value, key) => {
    result[key] = value;
  });

  return result;
};

export const convertObjectToSearchQuery = (obj: Record<string, any>): string => {
  const str = new URLSearchParams(filterFalsyValues(obj)).toString();
  return str ? `?${str}` : '';
};

export const serializeGetParams = (params: Record<string, string | string[]>) => {
  const url = new URLSearchParams();

  Object.entries(params).forEach(([key, value]: [string, string | string[]]) => {
    if (isArray(value)) {
      value.forEach((str) => url.append(key, str));
    } else {
      url.append(key, value);
    }
  });

  return url.toString();
};
