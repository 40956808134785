import { ROUTES } from '@/routes';
import { useEffect } from 'react';

export const RedirectToAds = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      window.location.replace(ROUTES.ads);
    }
  }, []);

  return null;
};
