import { useForm, FormProvider as FormPropsProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { ApplicationV3 } from '@/store/application';
import { mapApplication } from './utils';
import { ApplicationFormValues } from './types';
import { useSchema } from './schemas/useSchema';

type Props = {
  children: ReactNode;
  initialData: Partial<ApplicationV3>;
};

export const FormProvider = ({ children, initialData }: Props) => {
  const schema = useSchema();
  const formProps = useForm<ApplicationFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: mapApplication(initialData)
  });

  return <FormPropsProvider {...formProps}>{children}</FormPropsProvider>;
};
