import { APPLICATION_FORM_STEP_NAME, ApplicationFormStepName } from '@/store/application';
import { ApplicationFormValues } from './types';

export const stepNameToFormKey: Record<ApplicationFormStepName, keyof ApplicationFormValues> = {
  [APPLICATION_FORM_STEP_NAME.BASIC_INFO]: 'basic_info',
  [APPLICATION_FORM_STEP_NAME.PRODUCT_CATEGORY]: 'product_category',
  [APPLICATION_FORM_STEP_NAME.STORE_TYPE]: 'store_type',
  [APPLICATION_FORM_STEP_NAME.LEGAL_DETAILS]: 'legal_info',
  [APPLICATION_FORM_STEP_NAME.BANK_DETAILS]: 'bank_details'
};
