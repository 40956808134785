import { useTranslation } from 'react-i18next';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { SelectControlled } from '@/ui-kit/Select';
import Box from '@mui/material/Box';
import { useCurrencyLabels } from '@/hooks/i18n/useCurrencyLabels';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import { useEffect } from 'react';

import { FormSection } from './FormSection';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { useHandleIbanInput } from '../hooks/useHandleIbanInput';

const BankDetails = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { toOptions } = useCurrencyLabels();
  const { control, watch } = useFormPropsContext();
  const { isFormDisabled } = useFormStateContext();
  const iban = watch('bank_details.iban');
  const disabledBankFields = isFormDisabled || !iban;
  const { handleIbanInput, isFetchingIbanValidation } = useHandleIbanInput();

  useEffect(() => {
    track({ event: Events.BankDetailsOpened });
  }, [track]);

  return (
    <FormSection
      title={t('bank_details')}
      subtitle={t('this_is_the_account_where_we_ll_send_your_payouts')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <TextFieldControlled
          disabled={isFormDisabled}
          control={control}
          name="bank_details.iban"
          placeholder={t('iban')}
          analytics={{ event: Events.IBANFilled }}
          isLoading={isFetchingIbanValidation}
          onBlur={handleIbanInput}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.bank_name"
          placeholder={t('bank_name')}
          disabled
          analytics={{ event: Events.BankNameFilled, isPrefilled: false }}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.swift_code"
          placeholder={t('swift_code')}
          disabled
          analytics={{ event: Events.SWIFTFilled, isPrefilled: false }}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.branch_name"
          placeholder={t('branch_name')}
          disabled
          analytics={{ event: Events.BranchNameFilled, isPrefilled: false }}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.beneficiary_name_en"
          placeholder={t('beneficiary_name_en')}
          helperText={t('this_is_the_name_on_your_bank_account_in_english')}
          disabled={disabledBankFields}
          analytics={{ event: Events.BankAccountNameFilled }}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.beneficiary_name_ar"
          placeholder={t('beneficiary_name_ar')}
          disabled={disabledBankFields}
          analytics={{ event: Events.BankAccountNameFilled }}
          size="medium"
        />
        <SelectControlled
          control={control}
          name="bank_details.currency"
          placeholder={t('currency')}
          options={toOptions(['QAR', 'BHD', 'KWD', 'AED', 'SAR'])}
          disabled={disabledBankFields}
          analytics={{ event: Events.BankAccountCurrencyFilled }}
          size="medium"
        />
        <TextFieldControlled
          control={control}
          name="bank_details.report_recipient"
          placeholder={t('email_address')}
          helperText={t('this_is_where_we_ll_send_your_payout_reports')}
          disabled={disabledBankFields}
          analytics={{ event: Events.SettlementRecipientsFilled }}
          size="medium"
        />
      </Box>
    </FormSection>
  );
};

export default BankDetails;
