import { LoaderDots } from '@/components/LoaderDots';
import { getAuthReferrer, ROUTES } from '@/routes';
import { useIsAuthorized } from '@/store/auth';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

type Props = {
  children?: JSX.Element | null;
};

export const AuthRoute = ({ children = <Outlet /> }: Props): JSX.Element | null => {
  const isAuth = useIsAuthorized();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      const referrer = getAuthReferrer(location.search);

      if (!referrer) {
        navigate(ROUTES.root, { replace: true });
        return;
      }

      if (referrer.startsWith('https:')) {
        window.location.replace(referrer);
        return;
      }

      navigate(referrer, { replace: true });
    }
  }, [isAuth, location.search, navigate]);

  return isAuth ? <LoaderDots height="100vh" /> : children;
};
