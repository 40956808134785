import {
  useAuthorize,
  useChallengeVerifyMutation,
  useResendChallengeOTPMutation
} from '@/store/auth';
import { useAnalyticsTrack } from '@/store/analytics';
import { useState } from 'react';
import { VerifyPhoneForm } from './components/VerifyPhoneForm';
import { useHandleApiError } from './hooks/useHandleApiError';

type Props = {
  phone: string;
  onPhoneChange?: VoidFunction;
};

const trackParams = { type: 'phone', stage: '2fa' } as const;

export const VerifyChallengePhoneForm = (props: Props) => {
  const { phone, onPhoneChange } = props;
  const [verify, { isLoading }] = useChallengeVerifyMutation();
  const [resend] = useResendChallengeOTPMutation();
  const authorize = useAuthorize();
  const handleApiError = useHandleApiError('verify');
  const track = useAnalyticsTrack();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (code: string) => {
    setErrorMessage('');
    try {
      await verify({ otp_code: code }).unwrap();
      track({
        event: 'OTP Entered',
        params: { ...trackParams, result: 'success', phone }
      });
      track({ event: 'Logged In', params: trackParams });
      authorize();
    } catch (error) {
      track({
        event: 'OTP Entered',
        params: { ...trackParams, result: 'failure', phone }
      });

      handleApiError(error)(setErrorMessage);
    }
  };

  const handleResend = async ({ resetTimer }: { resetTimer: VoidFunction }) => {
    setErrorMessage('');
    track({ event: 'OTP Resend Code Clicked', params: trackParams });

    try {
      await resend().unwrap();
      resetTimer();
    } catch (error) {
      handleApiError(error)(setErrorMessage);
    }
  };

  return (
    <VerifyPhoneForm
      phone={phone}
      onPhoneChange={onPhoneChange}
      onSubmitSuccess={handleSubmit}
      onResend={handleResend}
      isLoading={isLoading}
      errorMessage={errorMessage}
    />
  );
};
