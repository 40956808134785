import { Button } from '@tabby.ai/tabby-ui/dist/core/Button';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import FabBase from '@mui/material/Fab';
import MenuItem from '@mui/material/MenuItem';
import MoreButton from '@/components/MoreButton/MoreButton';
import { PageAction } from '@/components/PageLayout/types';
import { styled } from '@mui/material';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  '& > *': {
    width: 'max-content',
    color: theme.palette.common.black
  }
}));

const Fab = styled(FabBase)(({ theme }) => ({
  position: 'fixed',
  width: 'max-content',
  bottom: 30,
  left: 0,
  right: 0,
  margin: 'auto',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  zIndex: 10,
  padding: '12px 24px',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 'bold',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: theme.palette.common.black
  }
}));

const FabInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4
}));

type Props = {
  pageActions?: PageAction[];
};

export const HeaderActions = ({ pageActions = [] }: Props) => {
  const primaryAction = pageActions.find((item) => item?.isPrimary);
  const moreActions = pageActions.filter((item) => item?.id !== primaryAction?.id);
  const isMobile = useIsMobilePage();

  if (isMobile) {
    return (
      <Root>
        {primaryAction && (
          <Fab
            key={primaryAction.id}
            variant="extended"
            size="large"
            onClick={primaryAction.onClick}
            disabled={primaryAction.isLoading || primaryAction.disabled}
          >
            <FabInner>
              {primaryAction.icon}
              {primaryAction.label}
            </FabInner>
          </Fab>
        )}

        {moreActions.length > 0 && (
          <MoreButton>
            {moreActions.map(
              (action) =>
                action && (
                  <MenuItem
                    key={action.id}
                    onClick={action.onClick}
                    disabled={action.isLoading || action.disabled}
                  >
                    {action.icon}
                    {action.label}
                  </MenuItem>
                )
            )}
          </MoreButton>
        )}
      </Root>
    );
  }

  return (
    <Root>
      {pageActions.map(
        (pageAction) =>
          pageAction && (
            <Button
              key={pageAction.id}
              ds="B2B2023"
              size="medium"
              variant={pageAction.variant || 'containedLight'}
              onClick={pageAction.onClick}
              startIcon={pageAction.icon}
              isLoading={pageAction.isLoading}
              disabled={pageAction.isLoading || pageAction.disabled}
            >
              {pageAction.label}
            </Button>
          )
      )}
    </Root>
  );
};
