import { styled } from '@mui/material';

export const Step = styled('div')(({ theme }) => ({
  flex: '1',
  padding: '12px',

  [theme.breakpoints.down('p768')]: {
    padding: '0'
  }
}));
