import { useController, Control, FieldValues, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { DatePicker, DatePickerProps } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';

export type Props<T extends FieldValues> = DatePickerProps & {
  name: Path<T>;
  control: Control<T>;
  transform?: (date: Date | null) => string | Date | null;
  handleChange?: (value: string | Date | null) => void;
};

export const DatePickerControlled = <T extends FieldValues>({
  control,
  name,
  transform = (value) => value,
  handleChange,
  size,
  ds,
  labelInside,
  ...rest
}: Props<T>) => {
  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control
  });
  const { t } = useTranslation();

  const handleDateChange = (date: Date | null) => {
    const transformedDate = transform(date);

    onChange(transformedDate);
    handleChange?.(transformedDate);
  };

  return (
    <DatePicker
      {...rest}
      isHijri={false}
      desktopModeMediaQuery="@media (min-width: 768px)"
      value={value}
      ds={DS.B2B2023}
      size="medium"
      labelInside={t('date')}
      onChange={handleDateChange}
      fullWidth
      slotProps={{
        textField: {
          helperText: error?.message,
          error: !!error
        }
      }}
    />
  );
};
