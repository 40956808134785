import { Text } from '@/ui-kit/Text';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useApplicationFormStepLabels } from '@/hooks/i18n/useApplicationFormStepLabels';
import { ApplicationFormStepName } from '@/store/application';
import { useMediaQuery, useTheme } from '@mui/material';
import { DS } from '@/ui-kit/types';
import React from 'react';
import CheckS24 from '@tabby.ai/tabby-ui/dist/icons/core/CheckS24';

import { generateStepPath } from '../../utils';
import { useFormStateContext } from '../../hooks/useFormStateContext';
import { useHandleValidateCurrentStep } from '../../hooks/useHandleValidateCurrentStep';
import { getIconColorByFlags, getVariantByFlags } from './utils';

export type StepperItemVariant = 'active' | 'valid' | 'default';

const Root = styled('li', {
  shouldForwardProp: (prop) => prop !== 'isValid' && prop !== 'isActive'
})<{ isActive: boolean; isValid: boolean }>(({ theme, isActive, isValid }) => {
  const variantToBorderTopColor: Record<StepperItemVariant, string> = {
    active: theme.palette.B2B2023.front.major,
    valid: theme.palette.B2B2023.accent.positiveA,
    default: theme.palette.B2B2023.front.disabled
  };

  const variant = getVariantByFlags(isActive, isValid);
  const borderTopColor = variantToBorderTopColor[variant];

  return {
    display: 'flex',
    flex: 1,
    paddingTop: '8px',
    gap: '8px',
    alignItems: 'center',
    borderTopWidth: '2px',
    borderTopStyle: 'solid',
    borderTopColor,
    cursor: isValid ? 'pointer' : 'default'
  };
});

type Props = {
  isActive: boolean;
  isValid: boolean;
  stepName: ApplicationFormStepName;
};

export const StepperItem = ({ isActive, stepName, isValid }: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('p768'));
  const { getLabel } = useApplicationFormStepLabels();

  const { currentStepName, isFirstOrSecondTier } = useFormStateContext();
  const { validateCurrentStep } = useHandleValidateCurrentStep();

  if (isMobile && !isActive) {
    return null;
  }

  return (
    <Root
      isActive={isActive}
      isValid={isValid}
      onClick={async () => {
        if (!isValid) {
          return;
        }
        if (isFirstOrSecondTier) {
          return;
        }

        const isStepValid = await validateCurrentStep(currentStepName);
        if (!isStepValid) {
          return;
        }

        navigate(generateStepPath(stepName));
      }}
    >
      {isValid && !isActive && (
        <CheckS24 dir="ltr" ds={DS.B2B2023} color="B2B2023.accent.positiveA" />
      )}
      <Text variant="body1Tight700" color={getIconColorByFlags(isActive, isValid)}>
        {getLabel(stepName)}
      </Text>
    </Root>
  );
};
