import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationFormStepName } from '@/store/application';
import { generateStepPath } from '../utils';

export const useRedirectToLastValidStep = () => {
  const navigate = useNavigate();

  const redirectToLastValidStep = useCallback(
    (stepValidityMap: Record<ApplicationFormStepName, boolean>) => {
      const keys = Object.keys(stepValidityMap) as ApplicationFormStepName[];
      const lastValidStep = keys.reverse().find((stepName) => {
        return stepValidityMap[stepName];
      });

      if (lastValidStep) navigate(generateStepPath(lastValidStep));
    },
    [navigate]
  );

  return { redirectToLastValidStep };
};
