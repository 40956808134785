import { LegalEntity } from '@/store/merchants';
import { LoanOffer } from '@/store/offers';
import { COUNTRIES } from '@/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { FormProvider as FormPropsProvider, useForm } from 'react-hook-form';
import { useFormSchema } from '../hooks/useFormSchema';
import { FormValues } from '../types';
import { FormStateProvider } from './FormStateProvider';

type Props = {
  children: ReactNode;
  initialData: FormValues;
  legalEntity: LegalEntity;
  offer: LoanOffer;
};

export const FormProvider = ({ children, ...rest }: Props) => {
  const { initialData, legalEntity } = rest;
  const isLegalEntityCountrySAU = legalEntity?.legal_entity_country === COUNTRIES.SAU;

  const schema = useFormSchema(isLegalEntityCountrySAU);

  const formProps = useForm<FormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: initialData
  });

  return (
    <FormPropsProvider {...formProps}>
      <FormStateProvider {...rest}>{children}</FormStateProvider>
    </FormPropsProvider>
  );
};
