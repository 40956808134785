import { useTranslation } from 'react-i18next';
import { SelectControlled } from '@/ui-kit/Select';
import Box from '@mui/material/Box';
import { Events, useAnalyticsTrack } from '@/store/analytics';
import useBusinessLicenseTypeLabels from '@/hooks/i18n/useBusinessLicenseLabels';
import { TEST_IDS } from '@/lib/autotests';
import { useEffect } from 'react';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { FileUploadControlled } from '@/ui-kit/FileUpload';

import { FormSection } from './FormSection';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { VATFields } from './VATFields';
import { useHandleCRNumberInput } from '../hooks/useHandleCRNumberInput';

const LegalDetails = () => {
  const track = useAnalyticsTrack();
  const { t } = useTranslation();
  const { toOptions: toBusinessLicenseOptions } = useBusinessLicenseTypeLabels();
  const { control } = useFormPropsContext();
  const {
    isFormDisabled,
    legalInfo: { isLegalEntityCountrySAU, legalEntityCountry }
  } = useFormStateContext();

  const { handleCRNumberInput, isFetchingCRValidation } = useHandleCRNumberInput();

  useEffect(() => {
    track({ event: Events.LegalDetailsOpened });
  }, [track]);

  return (
    <FormSection
      title={t('legal_details')}
      subtitle={t('add_your_business_registration_information_here')}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <TextFieldControlled
            control={control}
            name="legal_info.legal_company_name"
            placeholder={t('legal_company_name')}
            disabled={isFormDisabled || isLegalEntityCountrySAU}
            analytics={{ event: Events.LegalCompanyNameFilled, isPrefilled: false }}
            testId={TEST_IDS.ApplicationLegalCompanyName}
            size="medium"
          />
          <TextFieldControlled
            control={control}
            name="legal_info.legal_address"
            placeholder={t('legal_address')}
            disabled={isFormDisabled || isLegalEntityCountrySAU}
            analytics={{ event: Events.LegalAddressFilled, isPrefilled: false }}
            testId={TEST_IDS.ApplicationLegalAddress}
            size="medium"
          />
          {!isLegalEntityCountrySAU && (
            <SelectControlled
              control={control}
              name="legal_info.business_license_type"
              placeholder={t('type_of_business_license')}
              disabled={isFormDisabled}
              analytics={{ event: Events.LicenceTypeFilled }}
              testId={TEST_IDS.ApplicationLicenceType}
              options={toBusinessLicenseOptions(['commercial_registration', 'trade_license'])}
              size="medium"
            />
          )}
          <TextFieldControlled
            control={control}
            name="legal_info.commercial_registration_number"
            placeholder={t('commercial_registration_number')}
            helperText={
              isLegalEntityCountrySAU
                ? t('please_enter_the_establishment_number_not_the_unified_number')
                : ''
            }
            analytics={{ event: Events.CRNumberFilled }}
            testId={TEST_IDS.ApplicationCRNumber}
            inputProps={{ maxLength: 15 }}
            disabled={isFormDisabled}
            isLoading={isFetchingCRValidation}
            onBlur={handleCRNumberInput}
            size="medium"
          />
          <FileUploadControlled
            control={control}
            name="legal_info.license_url"
            fileName={isLegalEntityCountrySAU ? 'commercial_registration' : 'business_license'}
            label={isLegalEntityCountrySAU ? t('commercial_registration') : t('business_license')}
            disabled={isFormDisabled}
            maxFileSizeKB={10000}
            analytics={{ event: Events.LicenceUploaded }}
          />
          <FileUploadControlled
            control={control}
            name="legal_info.national_id_url"
            fileName="national_id"
            label={
              legalEntityCountry === 'ARE'
                ? t('national_id_of_the_license_holder_are')
                : t('national_id_of_the_license_holder')
            }
            disabled={isFormDisabled}
            maxFileSizeKB={10000}
            analytics={{ event: Events.NationalIDUploaded }}
          />
        </Box>
        <VATFields />
      </Box>
    </FormSection>
  );
};

export default LegalDetails;
