import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import {
  useDispatch as _useDispatch,
  useSelector as _useSelector,
  TypedUseSelectorHook,
  shallowEqual
} from 'react-redux';

import rootReducer from './rootReducer';
import { rootApi } from './rootApi/api';
import { axiosFileUploadApi } from './fileUpload';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Ignore these field paths in all actions
      serializableCheck: {
        ignoredActions: ['api/executeQuery/fulfilled'],
        ignoredActionPaths: [
          'meta.baseQueryMeta.request',
          'meta.baseQueryMeta.response',
          'meta.arg.originalArgs'
        ]
      }
    }).concat([rootApi.middleware, axiosFileUploadApi.middleware]),
  devTools: process.env.NODE_ENV !== 'production'
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;

export const useDispatch = () => _useDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = (selector, equalityFn = shallowEqual) =>
  _useSelector(selector, equalityFn);

export default store;
