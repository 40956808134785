import { ResetReason } from '@/store/loan';
import { useTranslation } from 'react-i18next';

export const useResetStatusLabels = () => {
  const { t } = useTranslation();

  const titles = {
    beneficiary_name_mismatch: t('invalid_details'),
    company_name_mismatch: t('company_name_doesnt_match'),
    irrelevant_license: t('cant_verify_document'),
    business_license_expires_soon: t('document_expires_soon'),
    business_license_expired: t('expired_document')
  };

  const labels = {
    beneficiary_name_mismatch: t(
      'the_details_of_the_document_you_uploaded_dont_match_the_details_you_used_when_you_signed_up_for_tabby'
    ),
    company_name_mismatch: t(
      'the_company_name_on_the_document_you_uploaded_doesnt_match_the_company_name_you_used_to_sign_up_for_tabby'
    ),
    irrelevant_license: t(
      'the_document_you_uploaded_is_either_unreadable_or_its_not_a_valid_business_license_commercial_registration'
    ),
    business_license_expires_soon: t(
      'upload_a_business_license_registration_that_does_not_expire_within_the_next_2_weeks'
    ),
    business_license_expired: t(
      'replace_the_current_document_with_your_valid_business_license_or_commercial_registration'
    )
  };

  const getLabel = (reason: ResetReason) => labels[reason] || reason;
  const getTitle = (reason: ResetReason) => titles[reason] || reason;

  return { getLabel, getTitle };
};
