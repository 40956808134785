import mimeTypes from 'mime-types';

import { DownloadFileResponse, DownloadFileTransformedResponse } from './types';

export const toBase64Asset = (asset: DownloadFileResponse): DownloadFileTransformedResponse => {
  const extension = mimeTypes.extension(asset.mime_type) as string;

  return {
    base64String: `data:${asset.mime_type};base64,${asset.data}`,
    extension
  };
};
