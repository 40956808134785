import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';

import { generateStepPath } from '../utils';
import { useFormStateContext } from './useFormStateContext';

export const useHandlePrevClick = () => {
  const navigate = useNavigate();
  const { isFirstStep, currentIndex, steps } = useFormStateContext();

  const handlePrevClick = useCallback(async () => {
    if (isFirstStep) {
      return;
    }

    const newIndex = Math.max(0, currentIndex - 1);
    navigate(generateStepPath(steps[newIndex].name));
  }, [currentIndex, navigate, isFirstStep, steps]);

  return { handlePrevClick };
};
