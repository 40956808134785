import { ReactNode } from 'react';
import { Button, ButtonProps } from '@tabby.ai/tabby-ui/dist/core/Button';
import FabBase from '@mui/material/Fab';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { styled } from '@mui/material';

const Fab = styled(FabBase)(({ theme }) => ({
  position: 'fixed',
  width: 'max-content',
  bottom: 30,
  left: 0,
  right: 0,
  margin: 'auto',
  backgroundColor: theme.palette.common.black,
  color: theme.palette.common.white,
  zIndex: 10,
  padding: '12px 24px',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: 'bold',
  borderRadius: '8px',

  '&:hover': {
    backgroundColor: theme.palette.common.black
  }
}));

const FabInner = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 4
}));

type Props = ButtonProps & {
  isPrimary?: boolean;
  icon?: ReactNode;
  label: string;
};

export const ActionButton = ({ isPrimary = false, icon, label, color, ...rest }: Props) => {
  const isMobile = useIsMobilePage();

  if (isMobile && isPrimary) {
    return (
      <Fab {...rest} variant="extended" size="large">
        <FabInner>
          {icon}
          {label}
        </FabInner>
      </Fab>
    );
  }

  return (
    <Button
      {...rest}
      ds="B2B2023"
      size="medium"
      startIcon={icon}
      color={color}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {label}
    </Button>
  );
};
