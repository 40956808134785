import { MerchantApiKeys } from '@/store/merchants/types';

export const mapApiKeys = (data?: MerchantApiKeys) => {
  const hasProdApiKeys = Boolean(data?.public_key && data?.secret_key);
  const hasTestApiKeys = Boolean(data?.test_public_key && data?.test_secret_key);

  return {
    publicKey: data?.public_key || '',
    secretKey: data?.secret_key || '',
    testPublicKey: data?.test_public_key || '',
    testSecretKey: data?.test_secret_key || '',
    hasProdApiKeys,
    hasTestApiKeys,
    hasSomeApiKeys: hasProdApiKeys || hasTestApiKeys
  };
};
