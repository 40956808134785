import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Tooltip as BaseTooltip, TooltipProps } from '@tabby.ai/tabby-ui/dist/core';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';

const TooltipContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: '9px 7px',
  minWidth: '330px'
}));

const Label = styled(Typography)(({ theme }) => ({
  color: theme.palette.B2B2023.front.minor
}));

const TooltipListItem = styled(DeprecatedTypography)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gap: '12px',
  padding: '5px 0',
  alignItems: 'center'
}));

type Props = TooltipProps & {
  info: { label: string; money: ReactNode }[];
};

export const WidgetTooltip = ({ title, info, children, ...props }: Props) => {
  return (
    <BaseTooltip
      {...props}
      title={
        <TooltipContent>
          <DeprecatedTypography variant="_h3">{title}</DeprecatedTypography>
          <div>
            {info.map((item) => (
              <TooltipListItem key={item.label} variant="_body2Tight500">
                <Label>{item.label}</Label>
                {item.money}
              </TooltipListItem>
            ))}
          </div>
        </TooltipContent>
      }
    >
      {children}
    </BaseTooltip>
  );
};
