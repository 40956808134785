import { Text } from '@/ui-kit/Text';
import { CheckboxControlled } from '@/ui-kit/Checkbox';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { useTranslation } from 'react-i18next';
import { Events } from '@/store/analytics';
import { styled } from '@mui/material';
import { FileUploadControlled } from '@/ui-kit/FileUpload';

import { COUNTRIES } from '@/types';
import { useEffect } from 'react';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

export const VATFields = () => {
  const { t } = useTranslation();
  const { isFormDisabled } = useFormStateContext();
  const { control, watch, setValue } = useFormPropsContext();
  const hasVAT = watch('legal_info.has_vat');
  const countryOfMainOperation = watch('basic_info.country_of_main_operations');
  const isVatRequire = countryOfMainOperation === COUNTRIES.BHR;

  useEffect(() => {
    if (isVatRequire) {
      setValue('legal_info.has_vat', true);
    }
  }, [isVatRequire, setValue]);

  return (
    <Root>
      <Header>
        <Text variant="h4" color="B2B2023.front.major">
          {t('reclaim_your_vat')}
        </Text>
        <Text variant="body1Loose500" color="B2B2023.front.major">
          {t(
            'easily_reclaim_the_vat_you_pay_on_tabby_charges_by_uploading_your_vat_registration_certificate'
          )}
        </Text>
      </Header>
      {!isVatRequire && (
        <CheckboxControlled
          control={control}
          name="legal_info.has_vat"
          label={t('upload_vat_registration_certificate')}
          onChange={(event, checked) => {
            if (!checked) {
              setValue('legal_info.vat_registration_number', '');
              setValue('legal_info.vat_certificate_url', '');
              setValue('legal_info.address_in_tax_certificate', '');
            }
          }}
          disabled={isFormDisabled}
        />
      )}
      {(hasVAT || isVatRequire) && (
        <>
          <TextFieldControlled
            control={control}
            name="legal_info.vat_registration_number"
            placeholder={t('vat_registration_number')}
            inputProps={{ maxLength: 15 }}
            analytics={{ event: Events.VATNumberFilled }}
            disabled={isFormDisabled}
            size="medium"
          />
          <TextFieldControlled
            control={control}
            name="legal_info.address_in_tax_certificate"
            placeholder={t('address_on_vat_registration_certificate')}
            analytics={{ event: Events.VATRecipientsFilled }}
            disabled={isFormDisabled}
            size="medium"
          />
          <FileUploadControlled
            control={control}
            name="legal_info.vat_certificate_url"
            fileName="vat_certificate"
            label={t('vat_registration_certificate')}
            disabled={isFormDisabled}
            maxFileSizeKB={10000}
            analytics={{ event: Events.VATCertificateUploaded }}
          />
        </>
      )}
    </Root>
  );
};
