import AlertIcon from '@/assets/icons/alert.svg';
import Clock48Icon from '@/assets/icons/clock-48.svg';
import DocSignedIcon from '@/assets/icons/doc-signed.svg';
import Typography from '@mui/material/Typography';
import CrossStroke48 from '@tabby.ai/tabby-ui/dist/icons/core/CrossStroke48';
import { styled } from '@mui/material/styles';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { Trans, useTranslation } from 'react-i18next';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { MailToLink } from '@/components/MailToLink';
import { LoanStatus, RejectionReason } from '@/store/loan';
import { useRejectedLabels } from '@/store/loan/hooks/useRejectedLabels';
import { Box } from '@mui/material';
import { Widget } from './Widget';

const TextEmail = styled(DeprecatedTypography)(({ theme }) => ({
  color: theme.palette.B2B2023.front.minor
}));

const LabelText = styled(Typography)(({ theme }) => ({
  color: theme.palette.B2B2023.front.minor
}));

const CenteredText = styled(Box)({
  textAlign: 'center'
});

export const LoanStatusWidget = ({
  status,
  email,
  rejectReason
}: {
  status: LoanStatus;
  email?: string;
  rejectReason?: RejectionReason;
}) => {
  const { t } = useTranslation();
  const { getLabel } = useRejectedLabels();

  switch (status) {
    case 'submitted':
      return (
        <Widget
          status={status}
          icon={<Clock48Icon />}
          title={
            <CenteredText>
              <Trans i18nKey="your_application_is_being_reviewed" components={{ br: <br /> }} />
            </CenteredText>
          }
        >
          <CenteredText>
            <Trans
              i18nKey="we_process_your_application_within_72_hours__description"
              components={{ b: <TextEmail variant="_body1Loose700" />, br: <br /> }}
              values={{ email }}
            />
          </CenteredText>
        </Widget>
      );
    case 'approved':
      return (
        <Widget
          status={status}
          icon={<DocSignedIcon />}
          title={<CenteredText>{t('sign_the_contract')}</CenteredText>}
          additionalText={
            <CenteredText>
              <Trans
                i18nKey="have_a_question_reach_out_to_capital"
                components={{
                  mailToLink: <MailToLink value="partner@tabby.ai" />
                }}
              />
            </CenteredText>
          }
        >
          <CenteredText>
            <Trans
              i18nKey="sign_the_contract_we_sent_to_mail_from_pandadoc"
              components={{ b: <TextEmail variant="_body1Loose700" /> }}
              values={{ email }}
            />
          </CenteredText>
        </Widget>
      );
    case 'rejected':
      return (
        <Widget
          status={status}
          icon={<CrossStroke48 ds={DS.B2B2023} color="B2B2023.accent.negativeA" />}
          title={t('we_couldnt_approve_your_application')}
          additionalText={
            <Trans
              i18nKey="have_a_question_reach_out_to_maillink"
              components={{
                mailToLink: <MailToLink value="partner@tabby.ai" />
              }}
            />
          }
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '14px',
              justifyContent: 'flex-start'
            }}
          >
            {rejectReason &&
              getLabel(rejectReason).map((label) => <LabelText key={label}>{label}</LabelText>)}
          </Box>
        </Widget>
      );
    case 'reset':
      return (
        <Widget
          status={status}
          icon={<AlertIcon />}
          title={t('tabby_capital_application_declined')}
          additionalText={
            <Trans
              i18nKey="have_a_question_reach_out_to_maillink"
              components={{
                mailToLink: <MailToLink value="partner@tabby.ai" />
              }}
            />
          }
        >
          {t('tabby_capital_application_declined__description')}
        </Widget>
      );
    default:
      return null;
  }
};
