import {
  Button as TuiButton,
  type ButtonProps as TuiButtonProps
} from '@tabby.ai/tabby-ui/dist/core';

type ButtonProps = Omit<TuiButtonProps, 'ds'>;

export const Button = ({ children, ...rest }: ButtonProps) => {
  return (
    <TuiButton ds="B2B2023" {...rest}>
      {children}
    </TuiButton>
  );
};
