import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { FormProvider as FormPropsProvider, useForm } from 'react-hook-form';

import { LegalEntityWithMerchantId } from '@/store/loan';
import { LoanOffer } from '@/store/offers';
import { useDefaultFormValues } from '../hooks/useDefaultFormValues';
import { useFormSchema } from '../hooks/useFormSchema';
import { FormProps, FormValues } from '../types';
import { FormStateProvider } from './FormStateProvider';

type Props = FormProps & {
  children: ReactNode;
  offer: LoanOffer;
  legalEntities: LegalEntityWithMerchantId[];
};

export const FormProvider = ({ children, ...rest }: Props) => {
  const schema = useFormSchema();
  const defaultValues = useDefaultFormValues({ legalEntities: rest.legalEntities });

  const formProps = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
    defaultValues
  });

  return (
    <FormPropsProvider {...formProps}>
      <FormStateProvider {...rest}>{children}</FormStateProvider>
    </FormPropsProvider>
  );
};
