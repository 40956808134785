import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useChallengeSetupMutation } from '@/store/auth';
import { Button } from '@/ui-kit/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import { getEmailSchema } from '@/lib/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useAnalyticsTrack } from '@/store/analytics';
import { Header } from './components/Header';
import { useHandleApiError } from './hooks/useHandleApiError';
import { EmailFieldControlled } from './components/EmailFieldControlled';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

type Props = {
  initialEmail: string | null;
  onSuccess: (email: string) => void;
};

export const SetupChallengeEmailForm = (props: Props) => {
  const { onSuccess, initialEmail } = props;
  const { t } = useTranslation();
  const [setup, { isLoading }] = useChallengeSetupMutation();
  const handleApiError = useHandleApiError('setupChallenge');
  const { control, handleSubmit, setError } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object({
        email: getEmailSchema(t).required(() => '')
      })
    ),
    defaultValues: {
      email: initialEmail || ''
    }
  });
  const track = useAnalyticsTrack();

  const submit = handleSubmit(async ({ email }) => {
    try {
      await setup({ email }).unwrap();

      track({ event: 'Link 2FA Submitted', params: { type: 'email', email } });

      onSuccess(email);
    } catch (error) {
      handleApiError(error)((message) => setError('email', { message }));
    }
  });

  return (
    <Root>
      <Header
        title={t('link_email_address_to_your_account')}
        subtitle={t('you_will_be_sent_a_confirmation_letter_to_email_address_you_enter_below')}
      />
      <Form onSubmit={submit} noValidate>
        <EmailFieldControlled
          control={control}
          name="email"
          placeholder={t('email')}
          InputProps={{ size: 'medium', required: true }}
          disabled={isLoading}
          autoFocus
        />
        <Button type="submit" isLoading={isLoading} variant="contained" size="large" fullWidth>
          {t('continue')}
        </Button>
      </Form>
    </Root>
  );
};
