import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/routes';
import { useGetApplicationV3Query } from '@/store/application';
import Document24 from '@tabby.ai/tabby-ui/dist/icons/core/Document24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import { NavLink } from 'react-router-dom';

type Props = {
  isCollapsed?: boolean;
};

export const NavMenuItemApplicaitonV3 = ({ isCollapsed }: Props) => {
  const { t } = useTranslation();
  const { data: v3 } = useGetApplicationV3Query();
  const isDraft = v3?.status === 'draft';

  if (isDraft) {
    return (
      <SideMenuItem
        icon={<Document24 ds={DS.B2B2023} />}
        isCollapsed={isCollapsed}
        label={t('application')}
        to={ROUTES.applicationForm}
        component={NavLink}
      />
    );
  }

  return null;
};
