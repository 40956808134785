import ArrowChevronDown from '@/assets/icons/arrow-chevron-down.svg?icon';
import { TextCurrency as BaseTextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import TableBase from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import useRepaymentData from '@/store/loan/hooks/useRepaymentData';
import { SortDirection } from '@/types';
import { TableSortLabel } from '@mui/material';
import { orderBy } from 'lodash';
import { useMemo, useState } from 'react';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { REPAYMENT_INFO_STATUSES, RepaymentInfoStatus, RepaymentsSortBy } from '../types';
import { EmptyState } from './EmptyState';
import { useLoanDetailsContext } from '../hooks/useLoanDetailsContext';

const Table = styled(TableBase)(({ theme }) => ({
  marginTop: '16px',

  [theme.breakpoints.down('p768')]: {
    width: '100%'
  }
}));

const TableCellHeader = styled(TableCell)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._badge,
  color: theme.palette.B2B2023.front.minor,
  padding: '12px 16px 8px 8px'
}));

const TableCellBody = styled(TableCell)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  border: 'none',
  paddingLeft: '8px'
}));

const EmptyTableCellBody = styled(TableCellBody)(() => ({
  padding: '40px 16px 16px'
}));

const TextCurrency = styled(BaseTextCurrency)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500
}));

const TextByStatus = styled(DeprecatedTypography, {
  shouldForwardProp: (props) => props !== 'status'
})<{ status?: RepaymentInfoStatus }>(({ theme, status }) => {
  const color =
    status &&
    {
      [REPAYMENT_INFO_STATUSES.earlyPayOff]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.paying]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.paid]: theme.palette.B2B2023.front.minor,
      [REPAYMENT_INFO_STATUSES.overdueCritical]: theme.palette.B2B2023.accent.negativeA,
      [REPAYMENT_INFO_STATUSES.overdueWarning]: theme.palette.B2B2023.accent.warningA
    }[status];

  return {
    color,
    display: 'block'
  };
});

const StylesTableRow = styled(TableRow)({
  verticalAlign: 'baseline'
});

export const TableRepayments = () => {
  const { loan } = useLoanDetailsContext();

  const { t } = useTranslation();
  const { data: repayments } = useRepaymentData(loan);
  const [sorting, setSorting] = useState<{
    sortBy: RepaymentsSortBy | null;
    sortDirection: SortDirection;
  }>({
    sortBy: 'date',
    sortDirection: 'desc'
  });

  const handleChangeSorting = (sortBy: RepaymentsSortBy) => {
    const isAsc = sorting.sortBy === sortBy && sorting.sortDirection === 'asc';

    setSorting({
      sortBy,
      sortDirection: isAsc ? 'desc' : 'asc'
    });
  };

  const sortedRepayments = useMemo(() => {
    const { sortBy, sortDirection } = sorting;

    return sortBy ? orderBy(repayments, sortBy, sortDirection) : repayments;
  }, [repayments, sorting]);

  return (
    <Table sx={{ height: sortedRepayments.length === 0 ? '100%' : 'auto' }}>
      <TableHead>
        <TableRow>
          <TableCellHeader align="left">
            <TableSortLabel
              IconComponent={ArrowChevronDown}
              direction={sorting.sortDirection}
              active={sorting.sortBy === 'date'}
              onClick={() => handleChangeSorting('date')}
            >
              {t('date')}
            </TableSortLabel>
          </TableCellHeader>
          <TableCellHeader align="right">
            <TableSortLabel
              IconComponent={ArrowChevronDown}
              direction={sorting.sortDirection}
              active={sorting.sortBy === 'repaymentAmount'}
              onClick={() => handleChangeSorting('repaymentAmount')}
            >
              {t('repayment')}
            </TableSortLabel>
          </TableCellHeader>
          <TableCellHeader align="right">
            <TableSortLabel
              IconComponent={ArrowChevronDown}
              direction={sorting.sortDirection}
              active={sorting.sortBy === 'resultingPayout'}
              onClick={() => handleChangeSorting('resultingPayout')}
            >
              {t('sales')}
            </TableSortLabel>
          </TableCellHeader>
          <TableCellHeader align="right">
            <TableSortLabel
              IconComponent={ArrowChevronDown}
              direction={sorting.sortDirection}
              active={sorting.sortBy === 'settlementPayout'}
              onClick={() => handleChangeSorting('settlementPayout')}
            >
              {t('final_payout')}
            </TableSortLabel>
          </TableCellHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRepayments.length ? (
          sortedRepayments.map((repayment, idx) => {
            const {
              date,
              deducted,
              status,
              settlementPayout,
              repaymentAmount,
              resultingPayout,
              currency,
              repaymentType
            } = repayment;

            const showDeducted = !!(settlementPayout && repaymentAmount && deducted);
            const isEarlyPayoffPayment = repaymentType === 'early_pay_off';

            return (
              <StylesTableRow key={`${date + idx}`}>
                <TableCellBody>
                  <TextDate format="ddmmyyyy" timestamp={date} />
                </TableCellBody>
                <TableCellBody align="right">
                  <TextCurrency currency={currency} amount={repaymentAmount} />
                  {isEarlyPayoffPayment && (
                    <TextByStatus variant="_caption2Tight500" status={status}>
                      {t('early_payoff')}
                    </TextByStatus>
                  )}
                  {showDeducted && (
                    <TextByStatus variant="_caption2Tight500" status={status}>
                      {t('percent_deducted', { amount: deducted })}
                    </TextByStatus>
                  )}
                </TableCellBody>
                <TableCellBody align="right">
                  {isEarlyPayoffPayment ? (
                    '-'
                  ) : (
                    <TextCurrency currency={currency} amount={settlementPayout} />
                  )}
                </TableCellBody>
                <TableCellBody align="right">
                  {isEarlyPayoffPayment ? (
                    '-'
                  ) : (
                    <TextCurrency currency={currency} amount={resultingPayout} />
                  )}
                </TableCellBody>
              </StylesTableRow>
            );
          })
        ) : (
          <TableRow>
            <EmptyTableCellBody colSpan={4}>
              <EmptyState />
            </EmptyTableCellBody>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};
