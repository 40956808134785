import { ListTokenResponse, ListTokenResult, ValueOf } from '@/types';

export const SUPPORT_TOPICS = {
  TechIssue: 'tech_issue',
  Finances: 'finances',
  TabbyAds: 'tabby_ads',
  Other: 'other'
} as const;

export type SupportTopic = ValueOf<typeof SUPPORT_TOPICS>;

export type SupportTicketsHeading =
  | 'last_activity'
  | 'status'
  | 'store'
  | 'creator'
  | 'topic'
  | 'ticket_id'
  | 'unread_messages';

export const SUPPORT_TICKET_STATUSES = {
  New: 'new',
  Pending: 'pending',
  Opened: 'opened',
  Resolved: 'resolved',
  Closed: 'closed',
  Hold: 'hold',
  Escalated: 'escalated'
} as const;

export type SupportTicketStatus = ValueOf<typeof SUPPORT_TICKET_STATUSES>;

export const CSAT_STATUSES = {
  None: 'none',
  Requested: 'requested',
  Positive: 'positive',
  Negative: 'negative'
} as const;

export type CsatStatus = ValueOf<typeof CSAT_STATUSES>;

export type SupportTicket = {
  id: string;
  created_at: string;
  updated_at: string;
  merchant: { id: string; name: string };
  created_by_user: { id: string; email: string };
  status: SupportTicketStatus;
  topic: SupportTopic;
  chat: { channel_id: string };
  language: string;
  csat_status: CsatStatus;
};

export type GetSupportTicketsResponse = ListTokenResponse<SupportTicket>;
export type GetSupportTicketsResult = ListTokenResult<SupportTicket>;

export type GetSupportTicketsPagination = {
  page_token?: string;
  limit?: number;
};

export type GetSupportTicketsFilters = {
  created_at_gte?: string;
  created_at_lte?: string;
  merchant_ids?: string[];
  statuses?: SupportTicketStatus[];
  topics?: SupportTopic[];
};

export type GetSupportTicketsParams = GetSupportTicketsPagination & GetSupportTicketsFilters;

export type SupportTicketsSearchParams = {
  startDate?: string;
  endDate?: string;
  statuses?: string;
  topics?: string;
};

export type CreateSupportTicketParams = {
  merchant_id: string;
  topic: SupportTopic;
  description: string;
  attachments: string[];
  language: string;
};
