import { Text as TuiText, type TextProps as TuiTextProps } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';

export const Text = ({
  children,
  ds = DS.B2B2022,
  ...rest
}: Omit<TuiTextProps, 'ds'> & { ds?: DS }) => {
  return (
    <TuiText ds={ds} {...rest}>
      {children}
    </TuiText>
  );
};
