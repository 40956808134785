import { styled } from '@mui/material/styles';
import NotVerifiedIcon from '@/assets/icons/not-verified.svg?icon';
import { Text } from '@/ui-kit/Text';
import { useTranslation } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { LoanStatusBadge } from './LoanStatusBadge';

const color = '#D14900';
const background = '#FFF2E8';
const badgeColor = '#FFE8D6';

const OnHoldStatusSection = styled('div')({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '16px',
  background
});

const OnHoldStatusHint = styled(Text)({
  color
});

const IconWrapper = styled('span')({
  color,
  display: 'flex'
});

export const OnHoldStatus = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();

  return (
    <OnHoldStatusSection>
      {isMobile ? (
        <IconWrapper>
          <NotVerifiedIcon />
        </IconWrapper>
      ) : (
        <LoanStatusBadge status="reset" sx={{ background: badgeColor, color }} />
      )}
      <OnHoldStatusHint variant="body2Tight500">{t('cant_verify_document')}</OnHoldStatusHint>
    </OnHoldStatusSection>
  );
};
