import { ElementType, ReactNode, useEffect, useState } from 'react';
import { ListItemProps, ListItemText, ListItem as MuiListItem, Box, styled } from '@mui/material';
import { Tooltip } from '@/ui-kit/Tooltip';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import BaseChip from '@/components/Chip/Chip';

const ListItem = styled(MuiListItem)(({ theme }) => ({
  display: 'flex',
  padding: '2px 0px',
  width: 'unset',
  justifyContent: 'center',
  transition: 'all 0.2s ease 0s',
  borderRadius: '10px',
  color: theme.palette.B2B2023.front.minor,
  whiteSpace: 'nowrap',
  ...deprecatedTypographyStyles[theme.direction]._body2Tight700,

  '&.active': {
    backgroundColor: theme.palette.B2B2023.back.controlMinor,
    color: theme.palette.B2B2023.front.major
  },

  '&.active .TuiIcon-root': {
    color: theme.palette.B2B2023.front.major
  },

  '&:hover': {
    opacity: 0.7
  }
}));

const Content = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed' && prop !== 'hasNotification'
})<{
  isCollapsed?: boolean;
  hasNotification?: boolean;
}>(({ theme, isCollapsed, hasNotification }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: ' 6px 12px',
  justifyContent: isCollapsed ? 'center' : 'unset',
  position: 'relative',

  ...(hasNotification && {
    '&::after': {
      content: '""',
      width: '8px',
      height: '8px',
      backgroundColor: theme.palette.B2B2023.special.notification,
      borderRadius: '50%',
      position: 'absolute',
      top: '2px',
      right: '4px'
    }
  })
}));

const ListItemTextContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed?: boolean }>(({ isCollapsed }) => ({
  marginLeft: isCollapsed ? 0 : '8px',
  overflow: 'hidden'
}));

const Chip = styled(BaseChip)(({ label, theme }) => {
  const dotSize = {
    height: '10px',
    width: '10px'
  };
  const labelSize = {
    height: '20px',
    minWidth: '20px',
    width: 'auto'
  };

  return {
    ...(label === '' ? dotSize : labelSize),
    cursor: 'pointer',
    marginLeft: 'auto',
    fontSize: theme.typography.pxToRem(10),
    borderRadius: '30px',

    '& .MuiChip-label': {
      padding: '0 6px'
    }
  };
});

export type SideMenuItemProps = {
  icon: ReactNode;
  isCollapsed?: boolean;
  label?: ReactNode;
  subLabel?: ReactNode;
  to?: string | { pathname: string };
  tooltipContent?: ReactNode;
  className?: string;
  target?: '_blank';
  notificationBadgeText?: string;
  disableTooltip?: boolean;
  component?: ElementType;
} & Pick<ListItemProps, 'onClick' | 'sx'>;

export const SideMenuItem = (props: SideMenuItemProps) => {
  const {
    label,
    subLabel,
    icon,
    isCollapsed,
    disableTooltip = false,
    notificationBadgeText,
    ...rest
  } = props;
  const hasNotification = typeof notificationBadgeText === 'string';
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isCollapsed) {
      setOpen(false);
    }
  }, [isCollapsed]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <ListItem {...rest}>
      <Content isCollapsed={isCollapsed} hasNotification={isCollapsed && hasNotification}>
        <Box sx={{ alignSelf: 'start' }}>
          <Tooltip
            leaveDelay={0}
            title={
              <>
                {label}
                {subLabel}
              </>
            }
            disableHoverListener={!isCollapsed}
            placement="right"
            onClose={handleClose}
            onOpen={handleOpen}
            open={open && !disableTooltip}
          >
            <Box>{icon}</Box>
          </Tooltip>
        </Box>

        <ListItemTextContainer isCollapsed={isCollapsed}>
          <ListItemText
            disableTypography
            sx={{
              margin: 0,
              display: isCollapsed ? 'none' : 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
          >
            {label}
            {subLabel}
          </ListItemText>
        </ListItemTextContainer>

        {!isCollapsed && hasNotification && <Chip color="red" label={notificationBadgeText} />}
      </Content>
    </ListItem>
  );
};
