import { styled } from '@mui/material/styles';
import { Text } from '@/ui-kit/Text';
import { Trans } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import Bank24 from '@tabby.ai/tabby-ui/dist/icons/core/Bank24';
import { TextDate } from '@/components/TextDate';
import { LoanStatusBadge } from './LoanStatusBadge';

const WaitingdSection = styled('div')({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '16px',
  background: '#EDFAF1'
});

const WaitingHint = styled(Text)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.positiveA
}));

export const WaitingStatus = ({ settlementDate }: { settlementDate: string }) => {
  const isMobile = useIsMobilePage();
  const nextSettlementDate = new Date(settlementDate);
  const isCurrentYear = nextSettlementDate.getUTCFullYear() === new Date().getUTCFullYear();

  return (
    <WaitingdSection>
      {isMobile ? (
        <Bank24 color="B2B2023.accent.positiveA" />
      ) : (
        <LoanStatusBadge status="waiting" />
      )}
      <WaitingHint variant="body2Tight500">
        <Trans
          i18nKey="youll_receive_your_new_advance_with_your_next_payout_on_date"
          components={{
            date: (
              <TextDate
                format={isCurrentYear ? 'ddmm' : 'ddmmyyyy'}
                timestamp={nextSettlementDate.toString()}
              />
            )
          }}
        />
      </WaitingHint>
    </WaitingdSection>
  );
};
