import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { SearchTextField } from '@tabby.ai/tabby-ui/dist/core';
import { Coordinates, Addresses } from './types';
import { GoogleMap } from './GoogleMap';
import { DEFAULT_LOCATION_DUBAI } from './constants';

const Root = styled('div')(() => ({
  position: 'relative',
  width: '100%',
  height: '100%'
}));

const SearchTextFieldWrapper = styled('div')(() => ({
  position: 'absolute',
  zIndex: 1,
  top: '8px',
  left: '8px',
  width: '300px',
  maxWidth: '90%',
  boxShadow: '0px 2px 6px 0px rgba(26, 35, 46, 0.12)',
  borderRadius: '12px'
}));

type LocationMapProps = {
  handleLocationChange: (coordinates: Coordinates) => void;
  handleAddressesChange: (addresses: Addresses) => void;
  lat?: number;
  lng?: number;
  disabled?: boolean;
};

export const GoogleMapSearch = ({
  handleLocationChange,
  handleAddressesChange,
  lat = DEFAULT_LOCATION_DUBAI.lat,
  lng = DEFAULT_LOCATION_DUBAI.lng,
  disabled
}: LocationMapProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [geocoder, setGeocoder] = useState<google.maps.Geocoder | null>(null);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [mapCenter, setMapCenter] = useState<Coordinates>({ lat, lng });

  const updateMarkerPosition = useCallback(
    async (latLng: Coordinates) => {
      if (geocoder && isMapLoaded && !disabled) {
        const [responseEn, responseAr] = await Promise.all([
          geocoder.geocode({ location: latLng, language: 'en' }),
          geocoder.geocode({ location: latLng, language: 'ar' })
        ]);
        if (responseEn.results[0]) {
          handleLocationChange(latLng);
          handleAddressesChange({
            address_en: responseEn.results[0].formatted_address,
            address_ar: responseAr.results[0].formatted_address
          });
        }
      }
    },
    [geocoder, isMapLoaded, disabled, handleLocationChange, handleAddressesChange]
  );

  const setLocationBySearch = useCallback(async () => {
    if (searchValue && geocoder && isMapLoaded) {
      const response = await geocoder.geocode({ address: searchValue, language: 'en' });
      if (response?.results.length) {
        const resultLocation = response.results[0].geometry.location;
        const latLng: Coordinates = { lat: resultLocation.lat(), lng: resultLocation.lng() };
        await updateMarkerPosition(latLng);
        setMapCenter(latLng);
      }
    }
  }, [searchValue, geocoder, isMapLoaded, updateMarkerPosition]);

  useEffect(() => {
    if (isMapLoaded) {
      setGeocoder(new google.maps.Geocoder());
    }
  }, [isMapLoaded]);

  useEffect(() => {
    setLocationBySearch();
  }, [searchValue, setLocationBySearch]);

  return (
    <Root>
      <SearchTextFieldWrapper>
        <SearchTextField
          ds="B2B2023"
          size="small"
          placeholder={t('search')}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          clearButtonCallback={() => setSearchValue('')}
          disabled={disabled}
          sx={{ width: '100%' }}
        />
      </SearchTextFieldWrapper>
      <GoogleMap
        lat={mapCenter.lat}
        lng={mapCenter.lng}
        updateMarkerPosition={updateMarkerPosition}
        setIsMapLoaded={() => setIsMapLoaded(true)}
      />
    </Root>
  );
};
