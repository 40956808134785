import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { rootApi } from '../rootApi/api';
import { ACTIONS as dataTableActions } from '../dataTable/state';
import {
  CreateSupportTicketParams,
  SupportTicket,
  GetSupportTicketsParams,
  GetSupportTicketsResult,
  GetSupportTicketsResponse
} from './types';
import { prepareGetSupportTicketsParams } from './utils';
import { ACTIVE_TICKET_STATUSES, INACTIVE_TICKET_STATUSES } from './constants';

export const supportApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    // NOTE: pagination queries break if they are invalidated via tags
    getSupportTickets: build.query<GetSupportTicketsResult, GetSupportTicketsParams>({
      query: (params) => ({
        url: '/merchant/api/v1/support/tickets',
        method: 'GET',
        params: prepareGetSupportTicketsParams(params)
      }),
      transformResponse: (response: GetSupportTicketsResponse) => ({
        next_page_token: response.next_page_token || '',
        items: response.items || []
      })
    }),
    getSupportTicket: build.query<SupportTicket, string>({
      query: (ticketId) => `/merchant/api/v1/support/tickets/${ticketId}`,
      transformResponse: (response: { ticket: SupportTicket }) => response?.ticket,
      providesTags: (result, error, ticketId) => [{ type: 'SupportTicket', id: ticketId }]
    }),
    createSupportTicket: build.mutation<SupportTicket, CreateSupportTicketParams>({
      query: (body) => ({
        url: '/merchant/api/v1/support/tickets',
        method: 'POST',
        body
      }),
      onCacheEntryAdded: async (args, api) => {
        try {
          await api.cacheDataLoaded;
          // NOTE: pagination queries break if they are invalidated via RTK tags
          api.dispatch(dataTableActions.destroyList('support-tickets'));
        } catch (error) {
          // no error
        }
      },
      transformResponse: (response: { ticket: SupportTicket }) => response?.ticket,
      invalidatesTags: () => ['HasActiveSupportTickets']
    }),
    uploadSupportPrivateAsset: build.mutation<{ id: string; url: string }, File>({
      query: (file) => {
        const formData = new FormData();
        formData.append('asset', file);

        return {
          url: '/merchant/api/v1/support/attachments/upload',
          method: 'POST',
          body: formData
        };
      }
    }),
    csatLike: build.mutation<void, string>({
      query: (ticketId) => ({
        url: `/merchant/api/v1/support/tickets/${ticketId}/csat/like`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, ticketId) => [{ type: 'SupportTicket', id: ticketId }]
    }),
    csatDislike: build.mutation<void, string>({
      query: (ticketId) => ({
        url: `/merchant/api/v1/support/tickets/${ticketId}/csat/dislike`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, ticketId) => [{ type: 'SupportTicket', id: ticketId }]
    }),
    getHasActiveTickets: build.query<boolean, void>({
      queryFn: async (args, api, extraOptions, baseQuery) => {
        try {
          const result = await baseQuery({
            url: '/merchant/api/v1/support/tickets',
            method: 'GET',
            params: { limit: 1, statuses: ACTIVE_TICKET_STATUSES }
          });
          if (result.error) {
            return { error: result.error as FetchBaseQueryError };
          }

          const { items } = result.data as GetSupportTicketsResponse;
          const firstActiveItem = items?.find((t) => !INACTIVE_TICKET_STATUSES.includes(t.status));

          return { data: !!firstActiveItem };
        } catch (error) {
          return { error: { status: 500 } as FetchBaseQueryError };
        }
      },
      providesTags: ['HasActiveSupportTickets']
    })
  })
});

export const {
  useCreateSupportTicketMutation,
  useCsatDislikeMutation,
  useCsatLikeMutation,
  useGetHasActiveTicketsQuery,
  useGetSupportTicketQuery,
  useLazyGetSupportTicketQuery,
  useLazyGetSupportTicketsQuery,
  useUploadSupportPrivateAssetMutation
} = supportApi;
