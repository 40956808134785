import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifySignInEmailForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepSignInVerifyEmail = () => {
  const { state, setStep, goToSignIn, setPhonePart } = useAuthPageContext();
  const { step, email } = state;

  const handleChallenge = (challenge: 'setup_phone' | 'verify_phone', phonePart: string | null) => {
    setPhonePart(phonePart);
    setStep(`challenge_${challenge}`);
  };

  if (step === 'sign_in_verify_email' && email) {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <VerifySignInEmailForm
          email={email}
          onEmailChange={goToSignIn}
          onChallenge={handleChallenge}
        />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
