import { stringSchema } from '@/lib/yup';
import { ResetReason } from '@/store/loan';
import { useMemo } from 'react';
import * as yup from 'yup';
import { useCRNumberSAUValidation } from '@/pages/FinancePages/components/ApplicationCapitalForm/hooks/useCRNumberSAUValidation';
import { useResetLoanFormFields } from './useResetLoanFormFields';

export const useResetLoanFormSchema = (
  isLegalEntityCountrySAU: boolean,
  loanResetReason?: ResetReason
) => {
  const { schema: crNumberSAUSchema } = useCRNumberSAUValidation();
  const {
    showLicenseFileUploader,
    showLegalAddressField,
    showLegalNameField,
    showLicenseNumberField
  } = useResetLoanFormFields(loanResetReason);

  const licenseNumberSchema = showLicenseNumberField
    ? stringSchema.required(() => '')
    : stringSchema;

  const crFileSchema = showLicenseFileUploader ? stringSchema.required(() => '') : stringSchema;
  const legalNameSchema = showLegalNameField ? stringSchema.required(() => '') : stringSchema;
  const legalAddressSchema = showLegalAddressField ? stringSchema.required(() => '') : stringSchema;

  const crNumberScema = isLegalEntityCountrySAU ? crNumberSAUSchema : licenseNumberSchema;

  const schema = useMemo(() => {
    return yup.object().shape({
      cr_file: crFileSchema,
      cr_number: crNumberScema,
      company_name_or_legal_name: legalNameSchema,
      registered_company_address: legalAddressSchema
    });
  }, [crFileSchema, crNumberScema, legalAddressSchema, legalNameSchema]);

  return schema;
};
