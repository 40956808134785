import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo, useCallback } from 'react';
import { IBANValidationResponse, useLazyValidateIBANQuery } from '@/store/validators';
import { useCountryLabels } from '@/hooks/i18n';

const IBAN_MIN_LENGTH = 15;

export const useIbanValidation = () => {
  const { t } = useTranslation();
  const [getValidation, { isFetching }] = useLazyValidateIBANQuery();
  const { getLabel: getCountryLabel } = useCountryLabels();

  const baseSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(() => '')
        .min(IBAN_MIN_LENGTH, t('minimum_length_is_length', { length: IBAN_MIN_LENGTH })),
    [t]
  );

  const getIbanSchema = useCallback(
    (legalEntityCountry?: string) =>
      baseSchema.test({
        name: 'is-valid-iban',
        test: async (value, ctx) => {
          if (!baseSchema.isValidSync(value)) {
            return false;
          }

          try {
            const result = await getValidation(value, true).unwrap();
            const {
              is_valid,
              bank_data: { country }
            } = result;

            if (!is_valid) {
              return ctx.createError({
                message: t('please_enter_valid_iban')
              });
            }

            if (
              legalEntityCountry &&
              legalEntityCountry !== 'other' &&
              country &&
              legalEntityCountry !== country
            ) {
              return ctx.createError({
                message: t(
                  'your_company_is_registered_in_country_bank_account_should_be_in_the_same_country',
                  {
                    country: getCountryLabel(legalEntityCountry)
                  }
                )
              });
            }

            return true;
          } catch (err) {
            return ctx.createError({
              message: t('failed_to_verify_iban')
            });
          }
        }
      }),
    [baseSchema, getCountryLabel, getValidation, t]
  );

  const getValidationResult = useCallback(
    async (value: string, country: string): Promise<IBANValidationResponse | false> => {
      const isValid = await getIbanSchema(country).isValid(value);

      if (!isValid) return false;

      return getValidation(value, true)
        .unwrap()
        .then((res) => res)
        .catch(() => false);
    },
    [getValidation, getIbanSchema]
  );

  return { getIbanSchema, getValidationResult, isFetching };
};
