import { styled } from '@mui/material/styles';
import { Text } from '@/ui-kit/Text';
import { useTranslation } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import Clock24Icon from '@tabby.ai/tabby-ui/dist/icons/core/Clock24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { LoanStatusBadge } from './LoanStatusBadge';

const InReviewSection = styled('div')({
  display: 'flex',
  padding: '16px',
  alignItems: 'center',
  gap: '12px',
  borderRadius: '16px',
  background: '#FFFAE0'
});

const InReviewHint = styled(Text)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.warningA
}));

export const InReviewStatus = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();

  return (
    <InReviewSection>
      {isMobile ? (
        <Clock24Icon ds={DS.B2B2023} color="B2B2023.accent.warningA" />
      ) : (
        <LoanStatusBadge status="submitted" />
      )}
      <InReviewHint variant="body2Tight500">
        {t('we_process_your_application_within_72_hours')}
      </InReviewHint>
    </InReviewSection>
  );
};
