import { styled } from '@mui/material/styles';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';

const Root = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#3eedbf'
}));

const Loader = ({ className, ...props }: CircularProgressProps) => {
  return (
    <Root className={className}>
      <CircularProgress color="inherit" size={30} {...props} />
    </Root>
  );
};

export default Loader;
