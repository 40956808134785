import {
  Checkbox as TuiCheckbox,
  type CheckboxProps as TuiCheckboxProps
} from '@tabby.ai/tabby-ui/dist/core';

export type CheckboxBaseProps = Omit<TuiCheckboxProps, 'ds' | 'label' | 'error' | 'helperText'>;

export const CheckboxBase = ({ ...rest }: CheckboxBaseProps) => {
  return <TuiCheckbox ds="B2B2023" {...rest} />;
};
