import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BusinessSection, BUSINESS_SECTION, BusinessSectionItem } from '@/store/application';
import { Option } from '@/types';

type Props = {
  getLabel: (section: BusinessSection) => string;
  labels: Record<BusinessSection, string>;
  toOptions: (sections: BusinessSectionItem[]) => Option[];
};

const useBusinessSectionLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Record<BusinessSection, string> = useMemo(
    () => ({
      [BUSINESS_SECTION.Accessories]: t('tabby_section_accessories'),
      [BUSINESS_SECTION.BabyAndToys]: t('tabby_section_baby_toys'),
      [BUSINESS_SECTION.Beauty]: t('tabby_section_beauty'),
      [BUSINESS_SECTION.Fashion]: t('tabby_section_fashion'),
      [BUSINESS_SECTION.Gifts]: t('tabby_section_gifts'),
      [BUSINESS_SECTION.Home]: t('tabby_section_home'),
      [BUSINESS_SECTION.Kids]: t('tabby_section_kids'),
      [BUSINESS_SECTION.Other]: t('tabby_section_other'),
      [BUSINESS_SECTION.PopularStores]: t('tabby_section_popular_stores'),
      [BUSINESS_SECTION.SportsAndGaming]: t('tabby_section_sports_gaming'),
      [BUSINESS_SECTION.Sports]: t('tabby_section_sports')
    }),
    [t]
  );

  const getLabel: Props['getLabel'] = useCallback(
    (section: BusinessSection) => {
      return labels[section] || section;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map(({ id, name }) => ({ value: id, label: getLabel(name) })),
    [getLabel]
  );

  return { labels, getLabel, toOptions };
};

export default useBusinessSectionLabels;
