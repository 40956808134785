import { CapitalLoan } from '@/store/loan';
import { find, maxBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { LEGEND_VARIANTS, LegendVariant, RepaymentInfo, RepaymentInfoStatus } from '../types';

const WEEK_COUNT = 20;
const NORMAL_DEDUCTION = 22;
const WARNING_DEDUCTION = 60;
const CRITICAL_DEDUCTION = 100;

const useRepaymentData = (
  loan: CapitalLoan
): {
  data: RepaymentInfo[];
  widgetData: RepaymentInfo[];
  totalCount: number;
  currentCount: number;
  progress: number;
  legends: LegendVariant[];
  overdueData: { date: string; status: RepaymentInfoStatus } | null;
  earlyPayOff: boolean;
  alertStatus: 'payingWarning' | 'overdueWeeklyMin' | 'overdueCritical' | 'paid';
} => {
  const {
    repayments,
    currency,
    status,
    paid_amount,
    amount_vat,
    tenor = WEEK_COUNT,
    repayment_rate = NORMAL_DEDUCTION,
    minimum_repayment_amount = 0
  } = loan;

  const getRepaymentStatus = useCallback(
    (deducted: number, isEarlyPayOff: boolean): RepaymentInfoStatus => {
      if (isEarlyPayOff) {
        return 'earlyPayOff';
      }

      if (deducted <= +repayment_rate) {
        return 'paid';
      }
      if (deducted > +repayment_rate && deducted <= WARNING_DEDUCTION) {
        return 'overdueWarning';
      }
      if (deducted > WARNING_DEDUCTION && deducted <= CRITICAL_DEDUCTION) {
        return 'overdueCritical';
      }

      return 'paid';
    },
    [repayment_rate]
  );

  const getDeducted = (repaymentAmount: number, settlementPayout: number): number => {
    if (repaymentAmount === 0 || settlementPayout === 0) {
      return 100;
    }

    return Math.round((Math.abs(repaymentAmount) / Math.abs(settlementPayout)) * 100);
  };

  const data = useMemo(() => {
    return repayments
      ? repayments.map((repayment) => {
          const { date, id, ...rest } = repayment;
          const repaymentType = rest.repayment_type;
          const repaymentAmount = +rest.amount;
          const settlementPayout = +rest.payment_amount;
          const resultingPayout = settlementPayout - repaymentAmount;
          const deducted = getDeducted(repaymentAmount, settlementPayout);
          const repaymentStatus = getRepaymentStatus(
            deducted,
            Boolean(repaymentType === 'early_pay_off')
          );

          return {
            id,
            date,
            deducted,
            currency,
            settlementPayout,
            repaymentAmount,
            resultingPayout,
            status: repaymentStatus,
            repaymentType
          };
        })
      : [];
  }, [repayments, getRepaymentStatus, currency]);

  const alertStatus = useMemo(() => {
    const lastRepayment = maxBy(data, 'date');

    if (lastRepayment) {
      const deductedPercentage = lastRepayment.deducted;
      const deductedAmount = lastRepayment.repaymentAmount;

      if (status === 'paying') {
        if (deductedPercentage > +repayment_rate && deductedAmount === +minimum_repayment_amount) {
          return 'payingWarning';
        }
      }

      if (status === 'overdue') {
        if (deductedAmount === 0) {
          return 'overdueCritical';
        }
        if (deductedAmount > 0 && deductedAmount < +minimum_repayment_amount) {
          return 'overdueWeeklyMin';
        }
      }
    }

    return 'paid';
  }, [data, status, minimum_repayment_amount, repayment_rate]);

  const widgetData = useMemo(() => {
    const repaymentCount = data.length;
    const cellsCount = Number(tenor) - repaymentCount;

    const hasEarlyPayOffRepaiment = data.find((r) => r.repaymentType === 'early_pay_off');

    if (hasEarlyPayOffRepaiment) {
      const cells = cellsCount < 0 ? [] : Array(cellsCount).fill(hasEarlyPayOffRepaiment);

      return [...data, ...cells].map((repayment) => {
        return repayment;
      });
    }

    const cells = cellsCount < 0 ? [] : Array(cellsCount).fill(undefined);

    return [...data, ...cells].map((repayment) => {
      return repayment;
    });
  }, [data, tenor]);

  const legends: LegendVariant[] = useMemo(() => {
    const hasOverdue = !!data.find((repayment) => repayment?.status === 'overdueCritical');
    const hasEarlyPayOff = !!data.find((repayment) => repayment?.repaymentType === 'early_pay_off');

    return [
      LEGEND_VARIANTS.default,
      LEGEND_VARIANTS.paid,
      ...(hasOverdue ? [LEGEND_VARIANTS.overdue] : []),
      ...(hasEarlyPayOff ? [LEGEND_VARIANTS.earlyPayOff] : [])
    ];
  }, [data]);

  const progress = useMemo(() => {
    return Math.floor((Number(paid_amount) / Number(amount_vat)) * 100);
  }, [amount_vat, paid_amount]);

  const overdueData = useMemo(() => {
    if (status === 'closed') {
      return null;
    }

    const lastRepayment = data[data.length - 1];

    return lastRepayment && ['overdueCritical', 'overdueWarning'].includes(lastRepayment.status)
      ? {
          date: lastRepayment.date,
          status: lastRepayment.status
        }
      : null;
  }, [data, status]);

  const earlyPayOff = useMemo(() => {
    return Boolean(find(data, (r) => r.repaymentType === 'early_pay_off'));
  }, [data]);

  return {
    alertStatus,
    data,
    widgetData,
    legends,
    progress,
    overdueData,
    totalCount: Number(tenor),
    currentCount: data.length,
    earlyPayOff
  };
};

export default useRepaymentData;
