import {
  FetchAxiosBaseQueryError,
  useLazyDownloadFileQuery,
  useUploadFileMutation
} from '@/store/fileUpload';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef } from 'react';
import { downloadByUrl } from '@/utils/file';

export const useFileUpload = () => {
  const { t } = useTranslation();
  const abortRef = useRef<() => void>();

  const [triggerDownload] = useLazyDownloadFileQuery();
  const [triggerUpload, { isLoading }] = useUploadFileMutation();

  const download = (value: string, fileName: string, setErrorMessage: (msg: string) => void) => {
    triggerDownload(value, true)
      .unwrap()
      .then((result) => {
        downloadByUrl(result.base64String, `${fileName}.${result.extension}`);
      })
      .catch(() => {
        setErrorMessage(t('failed_to_download_file_please_try_later'));
      });
  };

  const abort = useCallback(() => {
    abortRef.current?.();
  }, []);

  const upload = (
    file: File,
    setProgress: (val: number) => void,
    onFulfilled: (res: string) => void,
    onRejected: () => void,
    onFinally: () => void
  ) => {
    const controller = new AbortController();
    const { signal } = controller;

    abortRef.current = () => {
      controller.abort();
    };

    triggerUpload({
      file,
      signal,
      onUploadProgress: setProgress
    })
      .unwrap()
      .then(onFulfilled)
      .catch((error: FetchAxiosBaseQueryError) => {
        if (error.code === 'ERR_CANCELED') {
          return;
        }

        onRejected();
      })
      .finally(onFinally);
  };

  useEffect(() => {
    return () => {
      abort();
    };
  }, [abort]);

  return { download, upload, isLoading, abort };
};
