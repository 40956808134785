import { RejectionReason } from '@/store/loan';
import { useTranslation } from 'react-i18next';

export const useRejectedLabels = () => {
  const { t } = useTranslation();

  const labels = {
    general_reject: [
      t(
        'we_urge_you_to_keep_growing_your_sales_with_tabby_as_this_may_help_you_qualify_in_the_future'
      ),
      t(
        'thank_you_again_for_considering_tabby_capital_and_we_wish_you_continued_success_in_your_business'
      )
    ],
    no_valid_license: [
      t('your_business_doesnt_currently_hold_a_valid_license__description'),
      t(
        'thank_you_again_for_considering_tabby_capital_and_we_wish_you_continued_success_in_your_business'
      )
    ],
    high_exposure: [
      t(
        'your_business_or_business_owner_already_has_one_or_multiple_active_loans_with_tabby_capital__description'
      ),
      t(
        'thank_you_again_for_considering_tabby_capital_and_we_wish_you_continued_success_in_your_business'
      )
    ]
  };

  const getLabel = (reason: RejectionReason) => labels[reason] || reason;

  return { getLabel };
};
