import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@/types';
import { APPLICATION_ANNUAL_VOLUME, ApplicationAnnualVolume } from '@/store/application';

type Props = {
  getLabel: (annualVolume: ApplicationAnnualVolume) => string;
  labels: Record<ApplicationAnnualVolume, string>;
  toOptions: (annualVolumes: ApplicationAnnualVolume[]) => Option[];
  options: Option[];
};

export const useAnnualVolumeLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Record<ApplicationAnnualVolume, string> = useMemo(
    () => ({
      above_15m: t('above_15m'),
      from_1m_to_5m: t('from_1m_to_5m'),
      from_5m_to_15m: t('from_5m_to_15m'),
      less_than_1m: t('less_than_1m')
    }),
    [t]
  );

  const getLabel: Props['getLabel'] = useCallback(
    (annualVolume) => {
      return labels[annualVolume] || annualVolume;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const sortedArr = useMemo(
    () => [
      APPLICATION_ANNUAL_VOLUME.LESS_THAN_1M,
      APPLICATION_ANNUAL_VOLUME.FROM_1M_TO_5M,
      APPLICATION_ANNUAL_VOLUME.FROM_5M_TO_15M,
      APPLICATION_ANNUAL_VOLUME.ABOVE_15M
    ],
    []
  );

  const options: Props['options'] = useMemo(() => toOptions(sortedArr), [sortedArr, toOptions]);

  return { labels, getLabel, options, toOptions };
};
