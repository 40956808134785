import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Country, COUNTRIES, Option } from '@/types';

type Props = {
  getLabel: (country: string) => string;
  labels: Record<Country, string>;
  toOptions: (countries: Country[]) => Option[];
  options: Option[];
};

export const useCountryLabels = (): Props => {
  const { t } = useTranslation();

  const labels: Record<Country, string> = useMemo(
    () => ({
      ARE: t('united_arab_emirates'),
      BHR: t('bahrain'),
      EGY: t('egypt'),
      KWT: t('kuwait'),
      QAT: t('qatar'),
      SAU: t('saudi_arabia')
    }),
    [t]
  );

  const getLabel = useCallback(
    (country: string) => {
      return labels[country as Country] || country;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const options: Props['options'] = useMemo(
    () => toOptions(Object.values(COUNTRIES)).sort((a, b) => a.label.localeCompare(b.label)),
    [toOptions]
  );

  return { labels, getLabel, options, toOptions };
};
