import { styled } from '@mui/material/styles';
import { useEffect } from 'react';

const NoScroll = styled('div')(() => ({
  position: 'fixed',
  width: '100%'
}));

// Workaround for iOS Safari and some other mobile browsers —
// the ones, who ignore overflow: hidden on body.
//
// This solution has an inconvinient side effect —
// setting position: fixed on #root, resets window scroll to top.
// But it's better than laggy fullscreen modals on iOS.

const useNoRootScroll = ({ condition = false, restoreScroll = false }) => {
  useEffect(() => {
    const { scrollY } = window;
    const root = document.querySelector('#root');

    if (condition) {
      root?.classList.add(`.${NoScroll}`);
    }

    return () => {
      if (condition) {
        root?.classList.remove(`.${NoScroll}`);

        if (restoreScroll) {
          window.scrollTo(0, scrollY);
        }
      }
    };
  }, [condition, restoreScroll]);
};

export default useNoRootScroll;
