import { useAnalytics } from '@/store/analytics/AnalyticsProvider';
import { useCallback } from 'react';
import { useMeQuery } from '@/store/account-me';
import { useCurrentLanguage } from '@/hooks/useCurrentLanguage';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { getCookies } from '@/utils/dom';
import { EventName } from '../constants';
import { getAnalyticsPageName } from '../utils';

type TrackEvent = {
  event: EventName;
  params?: Record<string, unknown>;
};

export const useAnalyticsTrack = ({ skipMeQuery = false } = {}) => {
  const language = useCurrentLanguage();
  const analytics = useAnalytics();
  const { data: me } = useMeQuery(skipMeQuery ? skipToken : undefined);

  const track = useCallback(
    ({ event, params }: TrackEvent) => {
      analytics.track(event, {
        language,
        email: me?.account?.email || '',
        phone: me?.account?.phone || '',
        pageName: getAnalyticsPageName(),
        ...params,
        ...getCookies()
      });
    },
    [analytics, language, me?.account?.email, me?.account?.phone]
  );

  return track;
};
