import { Skeleton } from '@mui/material';
import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '520px',

  [theme.breakpoints.down('p768')]: {
    width: '100%'
  }
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  background: theme.palette.B2B2023.back.major,
  borderRadius: '24px',
  padding: '24px',
  gap: '24px'
}));

const HeaderTitle = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
}));

const ContentText = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%'
}));

export const ResetLoanWidgetSkeleton = () => {
  return (
    <Root>
      <Column>
        <Content sx={{ flexDirection: 'row', width: '100%', gap: '8px' }}>
          <Skeleton variant="circular" height={48} width={54} />
          <HeaderTitle>
            <Skeleton variant="rounded" height={24} />
            <Skeleton variant="rounded" height={20} />
          </HeaderTitle>
        </Content>
        <Content>
          <ContentText>
            <Skeleton variant="rounded" height={24} width={188} />
            <Skeleton variant="rounded" height={20} />
          </ContentText>
          <Skeleton variant="rounded" height={48} />
        </Content>
      </Column>
    </Root>
  );
};
