import * as yup from 'yup';

export const useFormSchema = () => {
  return yup.object().shape({
    legalEntityId: yup
      .string()
      .trim()
      .required(() => '')
  });
};
