import { useTranslation } from 'react-i18next';
import { SelectControlled } from '@/ui-kit/Select';
import { Events } from '@/store/analytics';
import { TEST_IDS } from '@/lib/autotests';
import { CheckboxControlled } from '@/ui-kit/Checkbox';
import { useMemo } from 'react';
import { useCountryLabels } from '@/hooks/i18n';
import { useHandleCountryOfMainOperationChange } from '../../hooks/useHandleCountryOfMainOperationChange';
import { useFormPropsContext } from '../../hooks/useFormPropsContext';
import { useFormStateContext } from '../../hooks/useFormStateContext';

export const CountryFields = () => {
  const { t } = useTranslation();
  const { control, setValue, watch } = useFormPropsContext();
  const { isFormDisabled } = useFormStateContext();

  const { handleCountryOfMainOperationChange } = useHandleCountryOfMainOperationChange();
  const { options: countryOptions } = useCountryLabels();

  const countryOfMainOperations = watch('basic_info.country_of_main_operations');
  const isOperatingInOtherCountries = watch('basic_info.is_operating_in_other_countries');

  const possibleCountryOptions = countryOptions.filter((option) => option.value !== 'EGY');

  const countryOptionsExcludingMain = useMemo(
    () => possibleCountryOptions.filter((option) => option.value !== countryOfMainOperations),
    [countryOfMainOperations, possibleCountryOptions]
  );

  return (
    <>
      <SelectControlled
        control={control}
        name="basic_info.country_of_main_operations"
        placeholder={t('main_country_of_operations')}
        analytics={{ event: Events.BusinessCountriesFilled }}
        testId={TEST_IDS.ApplicationCountryOfOperation}
        onChange={handleCountryOfMainOperationChange}
        disabled={isFormDisabled}
        size="medium"
        options={possibleCountryOptions}
      />
      <CheckboxControlled
        control={control}
        name="basic_info.is_operating_in_other_countries"
        label={t('my_business_also_operates_in_other_countries')}
        onChange={(e) => {
          if (!e.currentTarget.checked) {
            setValue('basic_info.other_countries', []);
          }
        }}
      />
      {isOperatingInOtherCountries && (
        <SelectControlled
          control={control}
          multiple
          name="basic_info.other_countries"
          placeholder={t('other_countries_where_your_business_is_present')}
          analytics={{ event: Events.OtherBusinessCountriesFilled }}
          testId={TEST_IDS.ApplicationOtherCountriesOfOperation}
          disabled={isFormDisabled}
          size="medium"
          options={countryOptionsExcludingMain}
        />
      )}
    </>
  );
};
