import { ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { AuthPageContextProps, AuthPageState, AuthStep, SignInMethod } from './types';

const initialState: AuthPageState = {
  email: null,
  emailPart: null,
  phone: null,
  phonePart: null,
  step: 'sign_in',
  signInMethod: 'email'
};

const AuthPageContext = createContext<AuthPageContextProps | null>(null);

export const AuthPageProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<AuthPageState>(initialState);
  const update = useCallback((newState: Partial<AuthPageState>) => {
    setState((prev) => ({ ...prev, ...newState }));
  }, []);

  const setEmail = useCallback((email: string) => update({ email }), [update]);
  const setEmailPart = useCallback((emailPart: string | null) => update({ emailPart }), [update]);
  const setPhone = useCallback((phone: string) => update({ phone }), [update]);
  const setPhonePart = useCallback((phonePart: string | null) => update({ phonePart }), [update]);
  const setSignInMethod = useCallback(
    (signInMethod: SignInMethod) => update({ signInMethod }),
    [update]
  );
  const setStep = useCallback((step: AuthStep) => update({ step }), [update]);
  const goToSignIn = useCallback(() => update({ step: 'sign_in' }), [update]);

  const value = useMemo(
    () => ({
      goToSignIn,
      setEmail,
      setEmailPart,
      setPhone,
      setPhonePart,
      setSignInMethod,
      setStep,
      state
    }),
    [goToSignIn, setEmail, setEmailPart, setPhone, setPhonePart, setSignInMethod, setStep, state]
  );

  return <AuthPageContext.Provider value={value}>{children}</AuthPageContext.Provider>;
};

export const useAuthPageContext = () => {
  const context = useContext(AuthPageContext);

  if (!context) {
    throw new Error('useAuthPageContext must be nested into AuthPageProvider');
  }

  return context;
};
