import { useCallback } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { getEmailSchema, stringSchema } from '@/lib/yup';
import { useIbanValidation } from '../hooks/useIbanValidation';

export const useBankDetailsSchema = () => {
  const { t } = useTranslation();
  const { getIbanSchema } = useIbanValidation();

  const getSchema = useCallback(
    (legalEntityCountry: string) => {
      return yup.object({
        iban: getIbanSchema(legalEntityCountry),
        bank_name: stringSchema.required(() => ''),
        swift_code: stringSchema.required(() => ''),
        beneficiary_name_ar: stringSchema,
        beneficiary_name_en: stringSchema.required(() => ''),
        currency: stringSchema.required(() => ''),
        report_recipient: getEmailSchema(t).required(() => ''),
        branch_name: stringSchema
      });
    },
    [t, getIbanSchema]
  );

  return { getSchema };
};
