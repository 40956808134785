import { LoaderDots } from '@/components/LoaderDots';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const PipedriveRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const redirectUrl = `${process.env.PARTNERS_BACKOFFICE_ORIGIN}${location.pathname}`;
    window.location.replace(redirectUrl);
  }, [location.pathname]);

  return <LoaderDots height="100vh" type="Spinning48" />;
};
