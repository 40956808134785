import { CapitalLoan } from '@/store/loan';
import { ReactNode, createContext, useMemo } from 'react';

export const LoanDetailsContext = createContext({
  loan: {} as CapitalLoan,
  loans: [] as CapitalLoan[]
});

type Props = {
  children: ReactNode;
  loan: CapitalLoan;
  loans: CapitalLoan[];
};

export const LoanDetailsProvider = ({ children, loans, loan }: Props) => {
  const state = useMemo(() => ({ loans, loan }), [loans, loan]);

  return <LoanDetailsContext.Provider value={state}>{children}</LoanDetailsContext.Provider>;
};
