import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import MuiModal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import useNoRootScroll from '@/hooks/useNoRootScroll';
import CloseIcon from '@/assets/icons/close.svg?icon';
import Box from '@mui/material/Box';

const Header = styled('div', {
  shouldForwardProp: (prop) => prop !== 'showBorder' && prop !== 'reverseHeader'
})<{ showBorder: boolean; reverseHeader: boolean }>(({ theme, showBorder, reverseHeader }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  justifyContent: 'space-between',
  padding: theme.spacing(6),
  position: 'relative',

  [theme.breakpoints.down('p768')]: {
    padding: '16px 24px'
  },

  ...(showBorder && { borderBottom: `1px solid ${theme.palette.grey[100]}` }),
  ...(reverseHeader && {
    flexDirection: 'row-reverse',
    gap: '12px'
  })
}));

const Root = styled(MuiModal, { shouldForwardProp: (prop) => prop !== 'dialog' })<{
  dialog: boolean;
}>(({ dialog }) => ({
  ...(dialog && {
    margin: '0px 16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })
}));

const Inner = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'dialog' && prop !== 'borderRadius'
})<{ dialog: boolean; borderRadius: string }>(({ theme, dialog, borderRadius }) => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.common.white,
  outline: 'none',
  zIndex: 1,

  ...(dialog && {
    margin: '0px 16px',
    height: 'auto',
    maxHeight: '100vh',
    boxShadow: theme.shadows[5],
    borderRadius,
    overflow: 'hidden'
  })
}));

const Title = styled('div')(({ theme }) => ({
  width: '100%',
  fontSize: theme.typography.pxToRem(18),
  fontWeight: 'bold',
  color: theme.palette.grey[600],
  lineHeight: '24px'
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.grey[600],
  marginLeft: 'auto',
  alignSelf: 'flex-start'
}));

const Content = styled('div', { shouldForwardProp: (prop) => prop !== 'fullscreen' })<{
  fullscreen: boolean;
}>(({ theme, fullscreen }) => ({
  overflow: 'auto',
  [theme.breakpoints.down('p768')]: {
    height: '100%'
  },

  ...(fullscreen && { height: '100%' })
}));

export interface Props {
  open: boolean;
  onClose?: () => void;
  children: ReactNode;
  title?: ReactNode | string;
  fullscreen?: boolean;
  showBorder?: boolean;
  showHeader?: boolean;
  reverseHeader?: boolean;
  borderRadius?: string;
}

const Modal = ({
  open,
  onClose,
  children,
  title,
  fullscreen = false,
  showBorder = true,
  showHeader = true,
  reverseHeader = false,
  borderRadius = '8px'
}: Props) => {
  useNoRootScroll({ condition: fullscreen && open, restoreScroll: true });

  return (
    <Root
      dialog={!fullscreen}
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: fullscreen ? undefined : Backdrop }}
    >
      <Fade in={open}>
        <Inner dialog={!fullscreen} borderRadius={borderRadius}>
          {showHeader && (
            <Header showBorder={showBorder} reverseHeader={reverseHeader}>
              {title && <Title>{title}</Title>}
              <CloseButton onClick={onClose} size="small">
                <CloseIcon />
              </CloseButton>
            </Header>
          )}
          <Content fullscreen={fullscreen}>{children}</Content>
        </Inner>
      </Fade>
    </Root>
  );
};

export default Modal;
