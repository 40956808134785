import { CleanerPatternName, CleanerParseOptions } from './types';
import { patterns } from './constants';

class Cleaner {
  private static createPattern(patternNames: CleanerPatternName[]) {
    return patternNames.reduce((acc, patternName) => acc + patterns[patternName], '');
  }

  private static buildRegex(options: CleanerParseOptions) {
    const { keep, remove } = options;

    if (keep) {
      const keepPattern = this.createPattern(keep);
      return new RegExp(`[^${keepPattern}]`, 'g');
    }

    if (remove) {
      const removePattern = this.createPattern(remove);
      return new RegExp(`[${removePattern}]`, 'g');
    }

    // Regex for all symbols
    return /./g;
  }

  static parse(value: string, options: CleanerParseOptions) {
    const regex = this.buildRegex(options);

    return value.replace(regex, '');
  }
}

export default Cleaner;
