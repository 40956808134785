import { SxProps, Theme } from '@mui/material/styles';
import { MailToLink } from './MailToLink';

type Props = {
  sx?: SxProps<Theme>;
};

const SUPPORT_EMAIL = 'partner@tabby.ai';

export const MailToSupport = (props: Props) => <MailToLink {...props} value={SUPPORT_EMAIL} />;
