import { styled } from '@mui/material/styles';
import {
  Radio as TuiRadio,
  type RadioProps as TuiRadioProps
} from '@tabby.ai/tabby-ui/dist/core/Radio';

const Radio = styled(TuiRadio)(() => ({
  '&:hover': {
    backgroundColor: 'transparent'
  }
}));

const Icon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  border: `1px solid ${theme.palette.grey[300]}`,
  backgroundColor: theme.palette.common.white,
  '$root.Mui-focusVisible &': {
    outline: `2px auto ${theme.palette.grey[300]}`,
    outlineOffset: 2
  },
  'input:hover ~ &': {
    border: `1px solid ${theme.palette.grey[600]}`
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.grey[200]
  },
  'input:hover:disabled ~ &': {
    border: `1px solid ${theme.palette.grey[300]}`
  }
}));

const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.grey[600],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: `1px solid ${theme.palette.grey[600]}`,
  '&:before': {
    borderRadius: '50%',
    display: 'block',
    width: 4,
    height: 4,
    backgroundColor: theme.palette.common.white,
    content: '""'
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.grey[600]
  },
  'input:hover:disabled ~ &': {
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[600]}`
  }
}));

export const RadioBase = (props: TuiRadioProps) => {
  return (
    <Radio ds="B2B2023" disableRipple checkedIcon={<CheckedIcon />} icon={<Icon />} {...props} />
  );
};
