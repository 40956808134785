import { Dropdown } from '@/ui-kit/Dropdown';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@/ui-kit/IconButton';
import { styled } from '@mui/material/styles';
import { DS } from '@/ui-kit/types';
import More24 from '@tabby.ai/tabby-ui/dist/icons/core/More24';
import React from 'react';

const Root = styled('div')(() => ({
  position: 'absolute',
  top: '12px',
  right: '12px',
  zIndex: 3
}));

export const FileManagerDropdown = ({
  onFileReplace,
  onFileDelete,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onFileDownload,
  disabled,
  value
}: {
  onFileReplace: () => void;
  onFileDelete: () => void;
  onFileDownload: () => void;
  disabled?: boolean;
  value?: string;
}) => {
  const { t } = useTranslation();

  const items = [
    // {
    //   name: 'download',
    //   label: t('download'),
    //   onClick: onFileDownload,
    //   disabled: disabled || !value
    // },
    {
      name: 'replace',
      label: t('replace'),
      onClick: onFileReplace,
      disabled: disabled || !value
    },
    {
      name: 'delete',
      label: t('delete'),
      onClick: onFileDelete,
      disabled: disabled || !value
    }
  ];

  return (
    <Root>
      <Dropdown menuItems={items}>
        <IconButton size="extraSmall" variant="text">
          <More24 dir="ltr" ds={DS.B2B2023} color="B2B2023.front.minor" />
        </IconButton>
      </Dropdown>
    </Root>
  );
};
