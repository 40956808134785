import { useCallback } from 'react';

import { useCRNumberSAUValidation } from './useCRNumberSAUValidation';
import { useFormPropsContext } from './useFormPropsContext';
import { useFormStateContext } from './useFormStateContext';

export const useHandleCRNumberSAU = () => {
  const { setValue } = useFormPropsContext();
  const { setIsCRNumberSAUValid } = useFormStateContext();
  const { getValidationResult, isFetching: isFetchingCRSAUValidation } = useCRNumberSAUValidation();

  const handleCRNumberSAU = useCallback(
    async (value: string) => {
      const result = await getValidationResult(value);

      if (!result) {
        setValue('registered_company_address', '');
        setValue('company_name_or_legal_name', '');
        setIsCRNumberSAUValid(false);

        return;
      }

      setIsCRNumberSAUValid(true);

      const {
        merchant_license_data: { legal_address, legal_company_name }
      } = result;

      if (legal_address) {
        setValue('registered_company_address', legal_address);
      }

      if (legal_company_name) {
        setValue('company_name_or_legal_name', legal_company_name);
      }
    },
    [getValidationResult, setIsCRNumberSAUValid, setValue]
  );

  return { handleCRNumberSAU, isFetchingCRSAUValidation };
};
