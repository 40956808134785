import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IconBars from '@/assets/icons/bars.svg?icon';
import IconPercentage from '@/assets/icons/percentage.svg?icon';
import IconRefresh from '@/assets/icons/refreshDouble.svg';

export const useBenefits = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        key: '1',
        icon: <IconBars />,
        title: t('level_up_your_business'),
        text: t('a_capital_injection_for_the_next_phase_of_your_business')
      },
      {
        key: '2',
        icon: <IconPercentage />,
        title: t('no_interest_or_hidden_fees'),
        text: t('just_a_simple_flat_fee_no_surprises')
      },
      {
        key: '3',
        icon: <IconRefresh />,
        title: t('repay_automatically'),
        text: (
          <Trans
            i18nKey="well_deduct_percent_from_your_weekly_sales_payout__description"
            components={{ br: <br /> }}
            values={{ amount: 22 }}
          />
        )
      }
    ],
    [t]
  );
};
