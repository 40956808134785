import { ValueOf } from '@/types';

export type dataTestIdKey = 'data-testid';

export type TestProps = Record<dataTestIdKey, TestId> | Record<string, never>;

export const TEST_IDS = {
  ApplicationAnnualVolume: 'application.annualVolume',
  ApplicationBrandName: 'application.brandName',
  ApplicationBusinessOwnerPhone: 'application.businessOwner.phone',
  ApplicationCountryOfOperation: 'application.countryOfOperation',
  ApplicationEcommercePlatform: 'application.ecommercePlatform',
  ApplicationEcommerceType: 'application.ecommerceType',
  ApplicationSubmit: 'application.submit',
  ApplicationTabbyCategoryId: 'application.tabbyCategoryId',
  ApplicationTabbySectionId: 'application.tabbySectionId',
  ApplicationWebUrl: 'application.webUrl',
  DisputesSearchInput: 'disputes.searchInput',
  LoginCreateAccount: 'login.createAccount',
  LoginEmail: 'login.email',
  LoginForgotPassword: 'login.forgotPassword',
  LoginLoginButton: 'login.loginButton',
  LoginPassword: 'login.password',
  Notification: 'notification',
  OrderCancelConfirm: 'order.cancelConfirm',
  OrderCancelSubmit: 'order.cancelSubmit',
  OrderCaptureAmountInput: 'order.captureAmountInput',
  OrderCaptureConfirm: 'order.captureConfirm',
  OrderCaptureSubmit: 'order.captureSubmit',
  OrderDetalization: 'order.detalization',
  OrderOrderNumber: 'order.orderNumber',
  OrderRefundAmountInput: 'order.refundAmountInput',
  OrderRefundConfirm: 'order.refundConfirm',
  OrderRefundSubmit: 'order.refundSubmit',
  OrderStatus: 'order.status',
  OrdersExportAllButton: 'orders.exportAllButton',
  OrdersSearchInput: 'orders.searchInput',
  RegistrationBrandName: 'registration.brandName',
  RegistrationConfirmPassword: 'registration.confirmPassword',
  RegistrationCreateAccount: 'registration.createAccount',
  RegistrationEmail: 'registration.email',
  RegistrationFirstName: 'registration.firstName',
  RegistrationLastName: 'registration.lastName',
  RegistrationLogin: 'registration.login',
  RegistrationNoWebsite: 'registration.noWebsite',
  RegistrationPassword: 'registration.password',
  RegistrationWebsiteURL: 'registration.websiteURL',
  ResetPasswordBackToLogin: 'resetPassword.backToLogin',
  ResetPasswordEmail: 'resetPassword.email',
  ResetPasswordResetButton: 'resetPassword.resetButton',
  Skeleton: 'skeleton',
  TabbyAdsBannerPreview: 'tabbyAds.bannerPreview',
  TabbyAdsVideoBannerPreview: 'tabbyAds.videoBannerPreview',
  WalletTopUpFormButton: 'walletTopUpForm.button',
  WalletTopUpFormInput: 'walletTopUpForm.input',
  WalletTransactionsRoot: 'walletTransactions.root',
  WalletTransactionsTypeSelector: 'walletTransactions.typeSelector',
  ApplicationFirstName: 'application.firstName',
  ApplicationLastName: 'application.lastName',
  ApplicationBusinessName: 'application.businessName',
  ApplicationCRNumber: 'application.CRNumber',
  ApplicationLegalCompanyName: 'application.applicationLegalCompanyName',
  ApplicationLegalAddress: 'application.applicationLegalAddress',
  ApplicationLicenceType: 'application.applicationLicenceType',
  ApplicationOtherCountriesOfOperation: 'application.otherCountriesOfOperation',
  ApplicationNumberOfInStoreLocations: 'application.numberOfInStoreLocations'
} as const;

export type TestId = ValueOf<typeof TEST_IDS>;
