import { TextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import { ROUTES } from '@/routes';
import { CapitalLoanWithLegalInfo } from '@/store/loan';
import useRepaymentData from '@/store/loan/hooks/useRepaymentData';
import { styled } from '@mui/material/styles';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import CalendarIcon from '@/assets/icons/datepicker-сalendar.svg?icon';
import { Text } from '@/ui-kit/Text';
import { useTranslation } from 'react-i18next';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { TextBadge, Tooltip } from '@tabby.ai/tabby-ui/dist/core';
import { LoanStatusBadge } from './LoanStatusBadge';
import { VisualDataWidget } from './VisualDataWidget';
import { InReviewStatus } from './InReviewStatus';
import { ApprovedStatus } from './ApprovedStatus';
import { OnHoldStatus } from './OnHoldStatus';
import { WaitingStatus } from './WaitingStatus';

const Root = styled(RouterLink, {
  shouldForwardProp: (props) => props !== 'smallPadding'
})<{ smallPadding: boolean }>(({ theme, smallPadding }) => ({
  width: '680px',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  padding: smallPadding ? '20px 8px 8px' : '20px 8px',
  borderRadius: '24px',
  textDecoration: 'none',
  background: theme.palette.B2B2023.back.major,

  '&:hover': {
    cursor: 'pointer'
  },

  [theme.breakpoints.down('p768')]: {
    gap: '8px',
    width: '100%',
    padding: smallPadding ? '8px' : '8px 8px 24px'
  }
}));

const Head = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 16px'
});

const DateSection = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px'
});

const DatePeriod = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  padding: '0 12px',
  color: theme.palette.B2B2023.front.minor
}));

const Merchant = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  background: theme.palette.B2B2023.back.controlMinor
}));

const MerchantName = styled(Text)({
  padding: '0px 16px'
});

const StatusWrapper = styled('div')({
  padding: '0px 16px'
});

const MobileHead = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  padding: '16px'
});

const MobileDatePeriod = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',
  color: theme.palette.B2B2023.front.minor
}));

const MobileLoanInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
});

export const LoanWidget = ({ loan }: { loan: CapitalLoanWithLegalInfo }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();
  const {
    status,
    minimum_repayment_amount = '0',
    paid_amount = 0,
    amount_vat = 0,
    currency,
    applied_at,
    completed_at,
    repaid_percent = '0',
    next_settlement_date,
    legal_info: { legal_name }
  } = loan;
  const { widgetData: repayments } = useRepaymentData(loan);

  const totalRemainingAmount = Number(amount_vat) - Math.abs(Number(paid_amount));
  const hasVisualSlots = ['paying', 'overdue'].includes(status);
  const isSubmitted = status === 'submitted';
  const isApproved = status === 'approved';
  const isWaiting = status === 'waiting' && next_settlement_date;
  const isDeclined = status === 'reset';
  const isStatusVisible = ['closed', 'rejected'].includes(status);
  const headingAmount = status === 'closed' ? amount_vat : totalRemainingAmount;
  const isCurrentYear =
    applied_at && new Date(applied_at).getUTCFullYear() === new Date().getUTCFullYear();
  const dateFormat = isCurrentYear ? 'ddmm' : 'ddmmyyyy';
  const dateOptions: Intl.DateTimeFormatOptions = { month: 'long' };
  const hasRepaidPercent = ['paying'].includes(status);

  return (
    <Root
      to={generatePath(ROUTES.financeTabbyCapitalLoansId, { id: loan.id })}
      smallPadding={!isStatusVisible}
    >
      {isMobile ? (
        <>
          <MobileHead>
            <MobileLoanInfo>
              <MobileDatePeriod>
                {applied_at && (
                  <Text variant="body2Tight500">
                    <TextDate
                      format={dateFormat}
                      timestamp={applied_at}
                      customOptions={dateOptions}
                    />
                  </Text>
                )}
                {completed_at && (
                  <>
                    <span>-</span>
                    <Text variant="body2Tight500">
                      <TextDate
                        format={dateFormat}
                        timestamp={completed_at}
                        customOptions={dateOptions}
                      />
                    </Text>
                  </>
                )}
              </MobileDatePeriod>
              <Text variant="h3" color="B2B2023.front.major">
                <TextCurrency amount={headingAmount} currency={currency} />
              </Text>
            </MobileLoanInfo>
            {hasRepaidPercent ? (
              <TextBadge ds="B2B2023" type="default" size="large" width="fit-content">
                {t('percent_repaid', { percent: repaid_percent })}
              </TextBadge>
            ) : (
              <LoanStatusBadge
                status={status}
                sx={{
                  ...(isDeclined && {
                    background: '#FFE8D6',
                    color: '#D14900'
                  })
                }}
              />
            )}
          </MobileHead>
          {legal_name && (
            <MerchantName variant="body2Tight500" color="B2B2023.front.minor">
              {legal_name}
            </MerchantName>
          )}
        </>
      ) : (
        <>
          <Head>
            <Text variant="h3" color="B2B2023.front.major">
              <TextCurrency amount={headingAmount} currency={currency} />
            </Text>
            <DateSection>
              <DatePeriod>
                {applied_at && (
                  <>
                    <CalendarIcon />
                    <Text variant="body2Tight500">
                      <TextDate
                        format={dateFormat}
                        timestamp={applied_at}
                        customOptions={dateOptions}
                      />
                    </Text>
                  </>
                )}
                {completed_at && (
                  <>
                    <span>-</span>
                    <Text variant="body2Tight500">
                      <TextDate
                        format={dateFormat}
                        timestamp={completed_at}
                        customOptions={dateOptions}
                      />
                    </Text>
                  </>
                )}
              </DatePeriod>
              <Tooltip placement="top" title={legal_name}>
                <Merchant>
                  <Text variant="caption1Tight500" color="B2B2023.front.minor">
                    {legal_name?.slice(0, 2)}
                  </Text>
                </Merchant>
              </Tooltip>
            </DateSection>
          </Head>
          {isStatusVisible && (
            <StatusWrapper>
              <LoanStatusBadge status={status} />
            </StatusWrapper>
          )}
        </>
      )}
      {hasVisualSlots && (
        <VisualDataWidget
          minimumRepayment={minimum_repayment_amount}
          repayments={repayments}
          currency={currency}
          nextSettlementDate={next_settlement_date}
          repaidPercent={repaid_percent}
          loanStatus={status}
        />
      )}
      {isSubmitted && <InReviewStatus />}
      {isWaiting && <WaitingStatus settlementDate={next_settlement_date} />}
      {isApproved && <ApprovedStatus />}
      {isDeclined && <OnHoldStatus />}
    </Root>
  );
};
