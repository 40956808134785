import { useEffect } from 'react';
import { useFormPropsContext } from './useFormPropsContext';
import { useFormStateContext } from './useFormStateContext';
import { useHandleCRNumberSAU } from './useHandleCRNumberSAU';

export const useSyncInitialData = () => {
  const { reset } = useFormPropsContext();
  const { initialData, isLegalEntityCountrySAU } = useFormStateContext();
  const { handleCRNumberSAU } = useHandleCRNumberSAU();

  useEffect(() => {
    if (isLegalEntityCountrySAU) {
      const initialDataSAU = {
        ...initialData,
        company_name_or_legal_name: '',
        registered_company_address: ''
      };
      reset(initialDataSAU);
    } else {
      reset(initialData);
    }
  }, [reset, initialData, isLegalEntityCountrySAU]);

  useEffect(() => {
    if (isLegalEntityCountrySAU && initialData) {
      handleCRNumberSAU(initialData.cr_number);
    }
  }, [handleCRNumberSAU, initialData, isLegalEntityCountrySAU]);
};
