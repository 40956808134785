import {
  ApplicationV3,
  ApplicationFormStepName,
  isFirstOrSecondTierByVolume,
  APPLICATION_FORM_STEP_NAME
} from '@/store/application';
import { IBANValidationResponse } from '@/store/validators';
import { useCallback, useState } from 'react';
import uniq from 'lodash/uniq';
import { COUNTRIES } from '@/types';
import { useFormPropsContext } from './useFormPropsContext';
import { useFormSubmit } from './useFormSubmit';
import { useFormUpdateOnFocusLost } from './useFormUpdateOnFocusLost';

const sortedSteps: ApplicationFormStepName[] = [
  APPLICATION_FORM_STEP_NAME.BASIC_INFO,
  APPLICATION_FORM_STEP_NAME.PRODUCT_CATEGORY,
  APPLICATION_FORM_STEP_NAME.STORE_TYPE,
  APPLICATION_FORM_STEP_NAME.LEGAL_DETAILS,
  APPLICATION_FORM_STEP_NAME.BANK_DETAILS
];

type Params = {
  initialData: Partial<ApplicationV3>;
  currentStepName: ApplicationFormStepName;
};

export const useFormState = ({ initialData, currentStepName }: Params) => {
  const { watch } = useFormPropsContext();
  const { submitApplication, isLoading: isLoadingSubmit } = useFormSubmit();
  const { isLoading: isLoadingUpdate } = useFormUpdateOnFocusLost();

  const currentIndex = Math.max(0, sortedSteps.indexOf(currentStepName));
  const limit = sortedSteps.length - 1;
  const isFirstStep = currentIndex === 0;
  const isLastStep = currentIndex === limit;

  const [validSteps, setValidSteps] = useState<ApplicationFormStepName[]>([]);
  const steps = sortedSteps.map((name) => ({ name, isValid: validSteps.includes(name) }));

  const setStepValidity = (stepName: ApplicationFormStepName, isValid: boolean) => {
    setValidSteps((prev) => {
      return isValid ? uniq([...prev, stepName]) : prev.filter((n) => n !== stepName);
    });
  };

  const setStepsValidity = useCallback(
    (stepValidityMap: Record<ApplicationFormStepName, boolean>) => {
      setValidSteps(sortedSteps.filter((name) => stepValidityMap[name]));
    },
    []
  );

  const [annualVolume, legalEntityCountry] = watch([
    'basic_info.annual_volume',
    'basic_info.country_of_main_operations'
  ]);

  const [isCRNumberSAUValid, setIsCRNumberSAUValid] = useState(false);
  const [ibanValidation, setIbanValidation] = useState<IBANValidationResponse | void>();

  const isFormDisabled = isLoadingSubmit || isLoadingUpdate;
  const isLegalEntityCountrySAU = legalEntityCountry === COUNTRIES.SAU;
  const isFirstOrSecondTier = isFirstOrSecondTierByVolume(annualVolume);
  const shouldSubmitForm = (currentStepName === 'store-type' && isFirstOrSecondTier) || isLastStep;

  return {
    limit,
    steps,
    setStepsValidity,
    setStepValidity,
    currentIndex,
    isFirstStep,
    isLastStep,
    currentStepName,
    submitApplication,
    initialData,
    isFormDisabled,
    isLoadingSubmit,
    shouldSubmitForm,
    isFirstOrSecondTier,
    legalInfo: {
      isCRNumberSAUValid,
      setIsCRNumberSAUValid,
      isLegalEntityCountrySAU,
      legalEntityCountry
    },
    bankDetails: {
      ibanValidation,
      setIbanValidation
    }
  };
};
