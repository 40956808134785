import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Stack from '@/assets/icons/layers.svg?icon';
import { ROUTES } from '@/routes';
import useCachedFlag from '@/hooks/useCachedFlag';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import { Text } from '@/ui-kit/Text';
import { TextBadge } from '@/ui-kit/TextBadge';
import Popper from '@/components/Popper/Popper';
import { Button } from '@/ui-kit/Button';

const Label = styled('div')(() => ({
  display: 'flex'
}));

const LabelText = styled('div')(() => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const PopperContent = styled('div')(() => ({
  width: '320px',
  padding: '16px 20px'
}));

const PopperHeader = styled(Text)(() => ({
  marginBottom: '8px'
}));

const PopperFooter = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '16px'
}));

const POPPER_UPDATE_DELAY = 500;

export const NavMenuItemProductCatalog = ({ isCollapsed }: { isCollapsed?: boolean }) => {
  const { t } = useTranslation();
  const [isShowedPopper, setIsShowedPopper] = useCachedFlag('nav-product-catalog-popper-closed');
  const [visited, setVisited] = useCachedFlag('product-catalog-new-badge');
  const [popperAnchorRef, setPopperAnchorRef] = useState<HTMLDivElement | null>(null);
  const isProductCatalogPage = !!useMatch(ROUTES.marketplaceProductCatalog);

  const handlePopperClose = () => {
    setIsShowedPopper(true);
  };

  useEffect(() => {
    if (isProductCatalogPage) {
      setVisited(true);
    }
  }, [isProductCatalogPage, setVisited]);

  return (
    <>
      <SideMenuItem
        icon={<Stack />}
        isCollapsed={isCollapsed}
        label={
          <Label ref={setPopperAnchorRef}>
            <LabelText>{t('product_catalogue')}</LabelText>
            {!visited && <TextBadge type="positive">{t('new')}</TextBadge>}
          </Label>
        }
        to={ROUTES.marketplaceProductCatalog}
        component={NavLink}
      />
      <Popper
        open={!isShowedPopper}
        anchorEl={popperAnchorRef}
        arrow
        placement="right"
        updateDelay={POPPER_UPDATE_DELAY}
        popperOptions={{ strategy: 'fixed' }}
        disableRootScroll
      >
        <PopperContent>
          <PopperHeader variant="h4">{t('upload_your_products_to_tabby_app')}</PopperHeader>
          <Text variant="body2Loose500">
            {t('connect_your_e_commerce_store_to_show_your__description')}
          </Text>
          <PopperFooter>
            <Button size="small" onClick={handlePopperClose}>
              {t('got_it')}
            </Button>
          </PopperFooter>
        </PopperContent>
      </Popper>
    </>
  );
};
