import { useCallback } from 'react';
import { ApplicationFormStepName } from '@/store/application';
import { stepNameToFormKey } from '../constants';
import { useFormStateContext } from './useFormStateContext';
import { useFormPropsContext } from './useFormPropsContext';

export const useHandleValidateCurrentStep = () => {
  const { setStepValidity } = useFormStateContext();
  const { trigger } = useFormPropsContext();

  const validateCurrentStep = useCallback(
    async (currentStepName: ApplicationFormStepName) => {
      const isValid = await trigger(stepNameToFormKey[currentStepName]);
      if (isValid) {
        setStepValidity(currentStepName, true);
      }
      return isValid;
    },
    [setStepValidity, trigger]
  );

  return { validateCurrentStep };
};
