import { useCallback } from 'react';
import { useNotificationsContext } from '../NotificationsProvider';

export const useCloseNotification = () => {
  const { setNotifications } = useNotificationsContext();

  return useCallback(
    (id: string) => {
      setNotifications((prev) => prev.filter((n) => n.id !== id));
    },
    [setNotifications]
  );
};
