export const TAG_TYPES = [
  'Accounts',
  'Application',
  'ApplicationMerchant',
  'ApplicationMerchants',
  'BusinessCategories',
  'BusinessSections',
  'Candidate',
  'CandidateCommercialTerms',
  'CandidateLegalInfo',
  'CommercialRegistrationValidation',
  'Dispute',
  'Disputes',
  'DisputesActive',
  'Experiments',
  'GroupMerchants',
  'IbanValidation',
  'Invoices',
  'Merchant',
  'MerchantApiKeys',
  'MerchantCommercialTerms',
  'MerchantContract',
  'MerchantPayoutCycle',
  'MerchantProducts',
  'Permissions',
  'Wallet',
  'WalletLegalEntity',
  'CRNumberSAUValidation',
  'Payment',
  'LegalEntity',
  'SupportTicket',
  'CustomerCareContact',
  'BusinessContact',
  'Department',
  'AccountSession',
  'ApplicationV3',
  'Loans',
  'HasActiveSupportTickets',
  'Account',
  'Me',
  'Store',
  'PartnerLegalEntity',
  'CompanyOwner',
  'Offers'
] as const;

export const ERROR_MESSAGES = {
  AccountTemporarilyBlocked: 'account temporarily blocked',
  EmailOrPhoneNumberIsNotConfirmed: 'email or phone number is not confirmed',
  EmailIsNotConfirmed: 'email is not confirmed',
  TooManyAttemptsToSendCode: 'too many attempts to send code',
  NoInstallments: 'no_installments',
  PhoneNumberNotUnique: 'phone number not unique',
  CouldNotValidatePhone: 'could not validate phone',
  InvalidOtpCode: 'invalid otp code',
  OrderAmountTooHigh: 'order_amount_too_high',
  OrderAmountTooLow: 'order_amount_too_low',
  TryInstallments: 'try_installments',
  NotAvailable: 'not_available',
  AccountWithGivenEmailAlreadyExists: 'account with given email already exists',
  AccountWithGivenPhoneAlreadyExists: 'account with given phone already exists',
  InvalidPhone: 'invalid phone',
  InvalidEmail: 'invalid email',
  PhoneNumberIsNotConfirmed: 'phone number is not confirmed',
  TooManyAttemptsToVerifyCode: 'too many attempts to verify code',
  SessionExpired: 'session expired',
  Setup2fa: 'setup_2fa',
  PhoneNumberIsNotUnique: 'phone number is not unique',
  EmailNumberIsNotUnique: 'email is not unique',
  StartWithEmail: 'start with email',
  UserCantInviteOneself: "user can't invite oneself",
  unknown: 'unknown'
} as const;
