import { LoanOffer } from '@/store/offers';
import { Text } from '@/ui-kit/Text';
import { TextBadge } from '@/ui-kit/TextBadge';
import { formatCurrencyAmount } from '@/utils/currency';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Root = styled(Box)(({ theme }) => {
  return {
    position: 'relative',
    cursor: 'pointer',
    minWidth: '128px',
    width: '100%',
    padding: '12px',
    display: 'flex',
    gap: '4px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '16px',
    background: theme.palette.B2B2023.back.major
  };
});

const PopularBadge = styled(TextBadge)(({ theme }) => ({
  position: 'absolute',
  right: '0.333px',
  top: '-9px',
  color: theme.palette.B2B2023.front.invertMajor,
  background: `linear-gradient(to right, rgb(123, 58, 242) 1%, rgb(212, 33, 232) 50.02%, rgb(245, 82, 175) 84.38%, rgb(253, 135, 126) 100%)`
}));

export const OffersItem = ({
  offer,
  isActive,
  isPopular,
  onSelect
}: {
  offer: LoanOffer;
  isActive: boolean;
  isPopular?: boolean;
  onSelect: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const { id, amount, currency, tenor } = offer;
  const offerAmount = formatCurrencyAmount(amount, currency, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });

  return (
    <Root
      onClick={() => onSelect(id)}
      sx={(theme) => ({
        border: isActive
          ? `1.5px solid ${theme.palette.B2B2023.accent.highlightA}`
          : `1px solid ${theme.palette.B2B2023.front.disabled}`
      })}
    >
      {isPopular && <PopularBadge>{t('popular')}</PopularBadge>}
      <Text variant="body1Tight700">{offerAmount}</Text>
      <Text variant="caption2Tight500">{t('up_to_weeks', { weeksCount: tenor })}</Text>
    </Root>
  );
};
