import { LegalEntity } from '@/store/merchants';
import { rootApi } from '@/store/rootApi/api';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { find, uniq } from 'lodash';
import { LoanOffer, LoanOfferWithLegalEntity } from '../types';

const offersApi = rootApi.injectEndpoints({
  endpoints: (build) => ({
    getOffers: build.query<LoanOffer[], void>({
      query: () => ({ url: `/merchant/api/v1/offer` }),
      transformResponse: (response: LoanOffer[]) => response || [],
      providesTags: (result) =>
        result ? [...result.map((l) => ({ type: 'Offers' as const, id: l.id }))] : ['Offers']
    }),
    getOffersLegalEntity: build.query<LoanOfferWithLegalEntity[], void>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        const offersResponse = await fetchWithBQ('/merchant/api/v1/offer');

        if (offersResponse.error) return { error: offersResponse.error as FetchBaseQueryError };

        const offersData = offersResponse.data as LoanOffer[] | undefined;

        if (offersData) {
          const merchantIds = uniq(offersData.map((offer) => offer.merchant_id));

          const legalEntitiesResponse = await Promise.allSettled(
            merchantIds.map(async (merchantId) => {
              const legalEntityResponse = await fetchWithBQ(
                `/merchant/api/v1/merchant/${merchantId}/legal_entity`
              );

              if (legalEntityResponse.error) {
                return { error: legalEntityResponse.error as FetchBaseQueryError };
              }

              const legalEntityData = legalEntityResponse.data as {
                legal_entity: LegalEntity & { merchant_id: string };
              };

              return { ...legalEntityData.legal_entity, merchant_id: merchantId };
            })
          );

          const legalEntitiesData = legalEntitiesResponse
            .filter(
              (l): l is PromiseFulfilledResult<LegalEntity & { merchant_id: string }> =>
                l.status === 'fulfilled'
            )
            .filter((l) => !!l.value.iban)
            .map((l) => l.value as LegalEntity & { merchant_id: string });

          const result = offersData.map((offer) => {
            const legalEntity = find(legalEntitiesData, (m) => m.merchant_id === offer.merchant_id);

            return { ...offer, legal_entity: legalEntity || {} } as LoanOffer & {
              legal_entity: LegalEntity & { merchant_id: string };
            };
          });

          return { data: result };
        }

        return { data: [] };
      },
      providesTags: (result) =>
        result ? [...result.map((o) => ({ type: 'Offers' as const, id: o.id }))] : ['Offers']
    })
  })
});

export const { useGetOffersQuery, useLazyGetOffersQuery, useGetOffersLegalEntityQuery } = offersApi;
