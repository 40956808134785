import { useCallback, useState } from 'react';
import { Currency } from '@/types';
import { useTranslation } from 'react-i18next';
import { NOTIFICATION_VARIANTS, useShowNotification } from '@/components/Notifications';
import { delay } from '@/utils/promise';
import { formatCurrencyAmount } from '@/utils/currency';
import { useRefundPaymentMutation, useLazyGetPaymentQuery } from './api';
import { INVALIDATE_TIMEOUT } from '../constants';

type Params = {
  amount: string;
  currency: Currency;
  onSuccess?: () => void;
  paymentId: string;
};

export const useRefundPayment = () => {
  const { t } = useTranslation();
  const showNotification = useShowNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [getPayment] = useLazyGetPaymentQuery();
  const [refund] = useRefundPaymentMutation();

  const refundPayment = useCallback(
    async ({ amount, currency, onSuccess, paymentId }: Params) => {
      if (isLoading || !paymentId) return;

      setIsLoading(true);

      try {
        await refund({ amount, id: paymentId }).unwrap();
        await delay(INVALIDATE_TIMEOUT);
        getPayment(paymentId);
        showNotification({
          variant: NOTIFICATION_VARIANTS.Success,
          message: t('successfully_initiated_a_refund_of_amount_currency', {
            amount: formatCurrencyAmount(amount, currency)
          })
        });
        onSuccess?.();
      } catch {
        showNotification({
          variant: NOTIFICATION_VARIANTS.Error,
          message: t('failed_to_initiate_a_refund_please_try_again_later')
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getPayment, isLoading, refund, showNotification, t]
  );

  return { refundPayment, isLoading };
};
