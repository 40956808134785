import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { datadogRum } from '@datadog/browser-rum';
import { matchRoutes, useLocation } from 'react-router-dom';
import { ROUTES } from '@/routes';
import { useMeQuery } from '@/store/account-me';
import { initRefiner } from './utils';

const REFINER_KEY = 'c6976060-8780-11ec-8f1f-8165ac1e0071';

const catchAll = (str: string) => `${str}/*`;
const isRestrictedPath = (pathname: string): boolean => {
  return !!matchRoutes(
    [
      { path: ROUTES.root },
      { path: catchAll(ROUTES.applicationForm) },
      { path: catchAll(ROUTES.auth) },
      { path: catchAll(ROUTES.pipedriveApplicationId) },
      { path: catchAll(ROUTES.service) }
    ],
    pathname
  );
};

export const useRefiner = () => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { data: me } = useMeQuery();
  const locale = i18n.language === 'ar' ? 'ar_AR' : 'en_EN';
  const skip = useMemo(() => isRestrictedPath(location.pathname), [location.pathname]);

  useEffect(() => {
    initRefiner({
      onLoad: () => datadogRum.addAction('refiner-js-loaded'),
      onError: () => datadogRum.addAction('refiner-js-load-error'),
      skip
    });

    window._refiner?.('setProject', REFINER_KEY);
    window._refiner?.('onShow', (formId) => {
      datadogRum.addAction('refiner-survey-shown', {
        formId
      });
    });
    window._refiner?.('onComplete', (formId, formData) => {
      datadogRum.addAction('refiner-survey-completed', {
        formId,
        formData
      });
    });
  }, [skip]);

  useEffect(() => {
    window._refiner?.('setLocale', locale);
  }, [locale]);

  useEffect(() => {
    const { account } = me || {};

    if (account && account.id && account.email) {
      window._refiner?.('identifyUser', {
        id: account.id,
        locale,
        email: account.email
      });
    }
  }, [locale, me]);
};
