import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { ReactNode } from 'react';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '540px',
  padding: '16px',

  [theme.breakpoints.down('p768')]: {
    width: '100%',
    padding: '0'
  }
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const MessageBody = styled('div')<{ status?: 'submitted' | 'approved' | 'rejected' | 'reset' }>(({
  theme,
  status
}) => {
  const heightOffset = status === 'rejected' || status === 'approved' ? 220 : 130;
  const isRejectedOrSubmitted =
    status === 'rejected' || status === 'submitted' || status === 'approved';

  return {
    background: theme.palette.B2B2023.back.major,
    borderRadius: '24px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('p768')]: {
      borderRadius: status === 'rejected' ? '0px 0px 24px 24px' : '24px',
      height: isRejectedOrSubmitted ? `calc(100vh - ${heightOffset}px)` : 'auto',
      justifyContent: isRejectedOrSubmitted ? 'center' : 'inherit'
    }
  };
});

const MessageContent = styled('div')<{
  status: 'submitted' | 'approved' | 'rejected' | 'reset';
}>(({ status }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: status === 'rejected' ? 'flex-start' : 'center',
  alignItems: status === 'rejected' ? 'flex-start' : 'center',
  gap: '16px',
  padding: '48px 24px'
}));

const AdditionalMessageContent = styled('div')(() => ({
  padding: '16px 24px'
}));

const Text = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Loose500,
  color: theme.palette.B2B2023.front.minor
}));

const IconWrapper = styled('div')<{ status: 'submitted' | 'approved' | 'rejected' | 'reset' }>(({
  theme,
  status
}) => {
  const color = {
    submitted: theme.palette.B2B2023.accent.warningA,
    reset: theme.palette.B2B2023.accent.warningA,
    approved: theme.palette.B2B2023.accent.highlightA,
    rejected: theme.palette.B2B2023.accent.negativeA
  };

  const backgroundColor = {
    submitted: theme.palette.B2B2023.accent.warningC,
    reset: theme.palette.B2B2023.accent.warningC,
    approved: theme.palette.B2B2023.accent.highlightC,
    rejected: theme.palette.B2B2023.accent.negativeC
  };

  return {
    fill: color[status],
    color: color[status],
    background: backgroundColor[status],
    borderRadius: '50%',
    display: 'flex',
    justifyContent: status === 'rejected' ? 'flex-start' : 'center',
    alignItems: status === 'rejected' ? 'flex-start' : 'center',
    padding: '16px',
    fontSize: '48px',
    width: '80px',
    height: '80px'
  };
});

type Props = {
  icon: ReactNode;
  title: ReactNode;
  children: ReactNode;
  additionalText?: ReactNode;
  status: 'submitted' | 'approved' | 'rejected' | 'reset';
};

export const Widget = ({ status, icon, title, children, additionalText }: Props) => {
  return (
    <Root>
      <Column>
        <MessageBody status={status}>
          <MessageContent status={status}>
            <IconWrapper status={status}>{icon}</IconWrapper>
            <DeprecatedTypography variant="_h2">{title}</DeprecatedTypography>
            <Text>{children}</Text>
          </MessageContent>
        </MessageBody>

        {additionalText && (
          <MessageBody>
            <AdditionalMessageContent>
              <Text>{additionalText}</Text>
            </AdditionalMessageContent>
          </MessageBody>
        )}
      </Column>
    </Root>
  );
};
