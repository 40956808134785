import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

const Root = styled('div')(({ theme }) => ({
  borderRadius: '16px',
  padding: '4px',
  backgroundColor: theme.palette.B2B2023.back.controlMinor,
  overflow: 'hidden',
  display: 'flex',
  height: '56px'
}));

const Button = styled('button', {
  shouldForwardProp: (p) => !['selected'].includes(p as string)
})<{ selected: boolean }>(({ selected, theme }) => {
  const colors = selected
    ? {
        background: theme.palette.B2B2023.back.major,
        color: theme.palette.B2B2023.front.major
      }
    : {
        background: theme.palette.B2B2023.back.controlMinor,
        color: theme.palette.B2B2023.front.minor
      };

  return {
    borderRadius: '12px',
    width: '50%',
    border: 'none',
    cursor: 'pointer',
    ...deprecatedTypographyStyles[theme.direction]._body1Tight700,
    ...colors
  };
});

type Value = 'email' | 'phone';

type Props = {
  value: Value;
  onChange: (value: Value) => void;
};

export const EmailPhoneToggle = ({ value, onChange }: Props) => {
  const { t } = useTranslation();
  const toggle = () => onChange(value === 'email' ? 'phone' : 'email');

  return (
    <Root>
      <Button type="button" selected={value === 'email'} onClick={toggle}>
        {t('by_email')}
      </Button>
      <Button type="button" selected={value === 'phone'} onClick={toggle}>
        {t('by_phone')}
      </Button>
    </Root>
  );
};
