import { useTranslation } from 'react-i18next';
import { SelectControlled } from '@/ui-kit/Select';
import { Events } from '@/store/analytics';
import { TEST_IDS } from '@/lib/autotests';
import { LocationsNumber } from '@/store/application';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { styled } from '@mui/material/styles';
import { useCallback } from 'react';
import { useFormStateContext } from '../../hooks/useFormStateContext';
import { useFormPropsContext } from '../../hooks/useFormPropsContext';
import { Addresses, Coordinates, GoogleMapSearch } from './GoogleMapSearch';

const MapContainer = styled('div')({
  height: '400px'
});

export const LocationFields = () => {
  const { t } = useTranslation();
  const { control, watch, setValue } = useFormPropsContext();
  const { isFormDisabled } = useFormStateContext();
  const isOfflineEcomTypeIncluded = watch('store_type.ecommerce_types')?.includes('offline');
  const isSingleLocation = watch('store_type.locations_number') === '1';
  const lat = watch('store_type.locations.lat');
  const lng = watch('store_type.locations.lng');
  const isAddressEnDisabled = !lat && !lng;

  const handleLocationChange = useCallback(
    ({ lat: newLat, lng: newLng }: Coordinates) => {
      setValue('store_type.locations.lat', newLat.toString(), { shouldValidate: true });
      setValue('store_type.locations.lng', newLng.toString(), { shouldValidate: true });
    },
    [setValue]
  );

  const handleAddressesChange = useCallback(
    (addresses: Addresses) => {
      setValue('store_type.locations.address_en', addresses.address_en, { shouldValidate: true });
    },
    [setValue]
  );

  if (!isOfflineEcomTypeIncluded) {
    return null;
  }

  return (
    <>
      <SelectControlled
        control={control}
        name="store_type.locations_number"
        placeholder={t('number_of_in_store_locations')}
        analytics={{ event: Events.NumberOfInStoreLocationsFilled }}
        testId={TEST_IDS.ApplicationNumberOfInStoreLocations}
        disabled={isFormDisabled}
        options={[
          { label: '1', value: '1' },
          { label: '2', value: '2' },
          { label: '3', value: '3' },
          { label: t('more_than_3'), value: '3+' }
        ]}
        onChange={(e) => {
          const value = e.target.value as LocationsNumber;
          if (value === '1') {
            setValue('store_type.locations', { lat: '', lng: '', address_en: '' });
          } else {
            setValue('store_type.locations', null);
          }
        }}
        size="medium"
      />
      {isSingleLocation && (
        <>
          <MapContainer>
            <GoogleMapSearch
              handleLocationChange={handleLocationChange}
              handleAddressesChange={handleAddressesChange}
              lat={lat ? Number(lat) : undefined}
              lng={lng ? Number(lng) : undefined}
            />
          </MapContainer>
          <TextFieldControlled
            control={control}
            name="store_type.locations.address_en"
            helperText={isAddressEnDisabled && t('search_address_before_editing')}
            placeholder={t('address')}
            disabled={isAddressEnDisabled || isFormDisabled}
          />
        </>
      )}
    </>
  );
};
