import { LANGUAGE, CURRENCY, COUNTRIES } from '@/types';
import { CountryConfig } from './types';

export const ARE: CountryConfig = {
  code: COUNTRIES.ARE,
  currency: CURRENCY.AED,
  language: LANGUAGE.en
};

export const BHR: CountryConfig = {
  code: COUNTRIES.BHR,
  currency: CURRENCY.BHD,
  language: LANGUAGE.en
};

export const KWT: CountryConfig = {
  code: COUNTRIES.KWT,
  currency: CURRENCY.KWD,
  language: LANGUAGE.en
};

export const SAU: CountryConfig = {
  code: COUNTRIES.SAU,
  currency: CURRENCY.SAR,
  language: LANGUAGE.ar
};

export const EGY: CountryConfig = {
  code: COUNTRIES.EGY,
  currency: CURRENCY.EGP,
  language: LANGUAGE.ar
};

export const QAT: CountryConfig = {
  code: COUNTRIES.QAT,
  currency: CURRENCY.QAR,
  language: LANGUAGE.ar
};
