import { CURRENCY, Currency, LANGUAGE } from '@/types';
import { getLocale } from './i18n';

export const getCurrencyDecimalCount = (currency: Currency) => {
  switch (currency) {
    case CURRENCY.BHD:
    case CURRENCY.KWD:
      return 3;
    default:
      return 2;
  }
};

export const formatCurrencyAmount = (
  amount: string | number,
  currency?: Currency,
  options?: Intl.NumberFormatOptions
) => {
  if (!currency) return String(amount);
  return new Intl.NumberFormat(getLocale(LANGUAGE.en), {
    style: 'currency',
    currency,
    currencyDisplay: 'code',
    ...options
  }).format(+amount);
};

export const normalizeCurrency = ((currencies) => {
  return (value: unknown): Currency | null => {
    return currencies.includes(value as Currency) ? (value as Currency) : null;
  };
})(Object.values(CURRENCY));
