import {
  Loader as TuiLoader,
  type LoaderProps as TuiLoaderProps
} from '@tabby.ai/tabby-ui/dist/core';

type LoaderProps = Omit<TuiLoaderProps, 'ds'>;

export const Loader = (props: LoaderProps) => {
  return <TuiLoader ds="B2B2023" {...props} />;
};
