import { useCallback, useMemo } from 'react';
import { LANGUAGE, Language } from '@/types';
import { useTranslation } from 'react-i18next';

export const useToggleLanguage = () => {
  const { t, i18n } = useTranslation();

  const labels: Record<Language, string> = useMemo(
    () => ({
      en: t('language_label__en'),
      ar: t('language_label__ar')
    }),
    [t]
  );

  const nextLang = useMemo(() => {
    return i18n.language === LANGUAGE.ar ? LANGUAGE.en : LANGUAGE.ar;
  }, [i18n.language]);

  const toggleLanguage = useCallback(() => {
    i18n.changeLanguage(nextLang);
  }, [i18n, nextLang]);

  return {
    nextLanguageLabel: labels[nextLang],
    toggleLanguage
  };
};
