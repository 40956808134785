import { useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { stringSchema } from '@/lib/yup';
import { EcomType, LocationsNumber } from '@/store/application';
import { useURLValidation } from '../hooks/useURLValidation';

export const useStoreTypeSchema = () => {
  const { t } = useTranslation();
  const { schema: urlSchema } = useURLValidation();

  return useMemo(() => {
    return yup.object({
      ecommerce_platform: stringSchema.when('ecommerce_types', {
        is: (ecommerceTypes: EcomType[]) => ecommerceTypes.includes('online'),
        then: (s) => s.required(() => ''),
        otherwise: (s) => s
      }),
      locations_number: stringSchema.when('ecommerce_types', {
        is: (ecomTypes: string[]) => ecomTypes.includes('offline'),
        then: (schema) => schema.required(() => t('please_choose_number_of_in_store_locations')),
        otherwise: (schema) => schema.nullable()
      }),
      locations: yup.object().when('locations_number', {
        is: (value: LocationsNumber) => value === '1',
        then: (schema) =>
          schema.shape({
            lat: stringSchema.required(() => ''),
            lng: stringSchema.required(() => ''),
            address_en: stringSchema.required(() => '')
          }),
        otherwise: (schema) => schema.nullable()
      }),

      ecommerce_types: yup
        .array()
        .of(yup.string())
        .min(1, t('please_select_at_least_one_platform')),
      web_url: urlSchema().when('ecommerce_types', {
        is: (ecommerceTypes: EcomType[]) => ecommerceTypes?.includes('online'),
        then: (s) => s.required(() => t('please_enter_a_valid_website_link_starting_with_https')),
        otherwise: (s) => s
      })
    });
  }, [t, urlSchema]);
};
