import { createContext, ReactNode } from 'react';
import { ApplicationV3, ApplicationFormStepName } from '@/store/application';
import { useFormState } from './hooks/useFormState';
import { ApplicationFormState } from './types';

export const FormStateContext = createContext({} as ApplicationFormState);

type Props = {
  children: ReactNode;
  initialData: Partial<ApplicationV3>;
  currentStepName: ApplicationFormStepName;
};

export const FormStateProvider = ({ children, ...rest }: Props) => {
  const state = useFormState(rest);
  return <FormStateContext.Provider value={state}>{children}</FormStateContext.Provider>;
};
