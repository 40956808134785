import BaseTypography, { TypographyProps } from '@mui/material/Typography';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { styled } from '@mui/material/styles';

type DeprecatedVariant =
  keyof (typeof deprecatedTypographyStyles)[keyof typeof deprecatedTypographyStyles];

type Props = Omit<TypographyProps, 'variant'> & { variant: DeprecatedVariant };

const Typography = styled(BaseTypography, {
  shouldForwardProp: (prop) => prop !== 'dVariant'
})<{ dVariant: DeprecatedVariant }>(({ theme, dVariant }) => ({
  ...deprecatedTypographyStyles[theme.direction][dVariant]
}));

export const DeprecatedTypography = (props: Props) => {
  const { variant, ...otherProps } = props;

  return <Typography variant="inherit" dVariant={variant} {...otherProps} />;
};
