import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { CRSAUValidationResponse, useLazyValidateCRNumberSAUQuery } from '@/store/validators';
import { isDateAfterMonthsCount } from '@/utils/date';

const CR_NUMBER_MIN_LENGTH = 10;
const CR_LICENSE_MONTH_EXPIRATION = 2;

export const useCRNumberSAUValidation = () => {
  const { t } = useTranslation();
  const [getValidation, { isFetching }] = useLazyValidateCRNumberSAUQuery();

  const baseSchema = useMemo(
    () =>
      yup
        .string()
        .trim()
        .required(() => '')
        .min(CR_NUMBER_MIN_LENGTH, t('minimum_length_is_length', { length: CR_NUMBER_MIN_LENGTH })),
    [t]
  );

  const schema = useMemo(() => {
    return baseSchema.test({
      name: 'is-valid-sau-cr-number',
      test: async (value, ctx) => {
        if (!baseSchema.isValidSync(value)) {
          return false;
        }

        try {
          const result = await getValidation(value, true).unwrap();
          const {
            is_valid,
            merchant_license_data: { is_active, is_expired, license_expire_date }
          } = result;

          if (!is_valid) {
            return ctx.createError({
              message: t('please_enter_valid_commercial_registration_number')
            });
          }

          if (!is_active) {
            return ctx.createError({ message: t('business_is_inactive') });
          }

          if (is_expired) {
            return ctx.createError({ message: t('business_has_expired') });
          }

          if (isDateAfterMonthsCount(license_expire_date, CR_LICENSE_MONTH_EXPIRATION)) {
            return ctx.createError({ message: t('license_is_almost_expired') });
          }

          return true;
        } catch {
          return ctx.createError({
            message: t('failed_to_verify_commercial_registration_number')
          });
        }
      }
    });
  }, [baseSchema, getValidation, t]);

  const getValidationResult = useCallback(
    async (value: string): Promise<CRSAUValidationResponse | false> => {
      const isValid = await schema.isValid(value);

      if (!isValid) return false;

      return getValidation(value, true)
        .unwrap()
        .then((res) => res)
        .catch(() => false);
    },
    [getValidation, schema]
  );

  return { schema, getValidationResult, isFetching };
};
