import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, useSelector } from '@/store';
import { QueryStatus } from '@reduxjs/toolkit/query/react';

import { GetDisputesParams, DisputesState, Dispute, DisputeTutorialStep } from './types';
import { DISPUTES_TUTORIAL_STEP_KEY } from './constants';

export const disputesSlice = createSlice({
  name: 'disputes',
  initialState: (): DisputesState => ({
    disputes: [],
    status: QueryStatus.uninitialized,
    isEndReached: false,
    selectedMerchants: [],
    filters: { limit: 20 },
    tutorialStep:
      (localStorage.getItem(DISPUTES_TUTORIAL_STEP_KEY) as DisputeTutorialStep | null) || 'first'
  }),
  reducers: {
    setTutorialStep: (state, action: PayloadAction<DisputesState['tutorialStep']>) => {
      if (state.tutorialStep !== 'hidden') {
        state.tutorialStep = action.payload;
        localStorage.setItem(DISPUTES_TUTORIAL_STEP_KEY, action.payload);
      }
    },
    initList: (
      state,
      action: PayloadAction<{
        filters: GetDisputesParams;
        isEndReached: boolean;
        disputes: Dispute[];
      }>
    ) => {
      const { disputes, filters, isEndReached } = action.payload;
      state.disputes = disputes;

      state.filters = filters;
      state.isEndReached = isEndReached;
      state.status = QueryStatus.fulfilled;
    },
    loadNextPage: (
      state,
      action: PayloadAction<{
        isEndReached: boolean;
        disputes: Dispute[];
        nextPageToken: string;
      }>
    ) => {
      const { disputes, isEndReached, nextPageToken } = action.payload;
      state.disputes = [...state.disputes, ...disputes];

      state.filters = { ...state.filters, nextPageToken };
      state.isEndReached = isEndReached;
      state.status = QueryStatus.fulfilled;
    },
    setStatus: (state, action: PayloadAction<QueryStatus>) => {
      state.status = action.payload;
    },
    setFilters: (state, action: PayloadAction<GetDisputesParams>) => {
      state.filters = action.payload;
    },
    setSelectedMerchants: (state, action: PayloadAction<DisputesState['selectedMerchants']>) => {
      state.selectedMerchants = action.payload;
    }
  }
});

export const ACTIONS = {
  ...disputesSlice.actions
};

export const SELECTORS = {
  selectTutorialStep: (state: RootState) => state[disputesSlice.name].tutorialStep,
  selectItems: (state: RootState) => state[disputesSlice.name].disputes,
  selectFilters: (state: RootState) => state[disputesSlice.name].filters,
  selectSelectedMerchants: (state: RootState) => state[disputesSlice.name].selectedMerchants,
  selectPagination: (state: RootState) => {
    const {
      status,
      isEndReached,
      filters: { createdFrom, createdTo, statuses, merchantsIds }
    } = state[disputesSlice.name];

    return {
      isEndReached,
      isFetching: status === QueryStatus.pending,
      isError: status === QueryStatus.rejected,
      isUninitialized: status === QueryStatus.uninitialized,
      filtersCount: [createdFrom, createdTo, statuses, merchantsIds].filter(Boolean).length
    };
  }
};

export const HOOKS = {
  useTutorialStep: () => useSelector(SELECTORS.selectTutorialStep),
  useItems: () => useSelector(SELECTORS.selectItems),
  useFilters: () => useSelector(SELECTORS.selectFilters),
  useSelectedMerchants: () => useSelector(SELECTORS.selectSelectedMerchants),
  usePagination: () => useSelector(SELECTORS.selectPagination)
};
