import { FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { uuid } from '@/utils/string';
import {
  APIError,
  GrAPIError,
  APIErrorWithErrorsList,
  ErrorMessage,
  NormalizedAPIError
} from './types';
import { ERROR_MESSAGES } from './constants';

export function isAPIError(error: unknown): error is APIError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'status' in (error as APIError).data
  );
}

export function isGrAPIError(error: unknown): error is GrAPIError {
  return (
    typeof error === 'object' &&
    error !== null &&
    'errorCode' in (error as GrAPIError) &&
    'errorType' in (error as GrAPIError)
  );
}

export function isAPIErrorWithErrorsList(error: unknown): error is APIErrorWithErrorsList {
  return (
    typeof error === 'object' &&
    error !== null &&
    'data' in error &&
    typeof error.data === 'object' &&
    error.data !== null &&
    'status' in (error as APIErrorWithErrorsList).data &&
    'errors' in (error as APIErrorWithErrorsList).data &&
    Array.isArray((error as APIErrorWithErrorsList).data.errors)
  );
}

export function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
  return typeof error === 'object' && error != null && 'status' in error;
}

export function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    typeof error === 'object' &&
    error != null &&
    'message' in error &&
    typeof (error as any).message === 'string'
  );
}

export function getNormalizedParams(params: Record<string, unknown>) {
  return Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, unknown>
  );
}

const isErrorMessage = (
  (arr: ErrorMessage[]) =>
  (value: string): value is ErrorMessage => {
    return arr.includes(value as ErrorMessage);
  }
)(Object.values(ERROR_MESSAGES));

const normalizeErrorMessage = (value: string): ErrorMessage => {
  return isErrorMessage(value) ? value : 'unknown';
};

export const normalizeAPIError = (error: unknown): NormalizedAPIError => {
  if (isAPIErrorWithErrorsList(error)) {
    const firstKnownError = error.data.errors.find((err) => isErrorMessage(err.message));
    return {
      status: error.status,
      message: normalizeErrorMessage(firstKnownError?.message || '')
    };
  }

  if (isAPIError(error)) {
    return {
      status: error.status,
      message: normalizeErrorMessage(error.data.error)
    };
  }

  if (isFetchBaseQueryError(error) && typeof error.status === 'number') {
    return {
      status: error.status,
      message: 'unknown'
    };
  }

  return {
    status: 500,
    message: 'unknown'
  };
};

const addUrlBuster = (url: string) => {
  const hash = uuid().split('-')[1];
  const buster = `x=${hash}`;
  return url.includes('?') ? `${url}&${buster}` : `${url}?${buster}`;
};

// TODO: Remove on June 1, 2025
export const addCacheBusterToFetchArgs = (args: string | FetchArgs) => {
  if (typeof args === 'string') {
    return addUrlBuster(args);
  }

  if (!args.method || args.method === 'GET') {
    return {
      ...args,
      url: addUrlBuster(args.url)
    };
  }

  return args;
};
