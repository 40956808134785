import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { normalizeLanguage } from '@/utils/i18n';
import { datadogRum } from '@datadog/browser-rum';
import { useMeQuery } from '../../account-me/api';
import { useAnalyticsIdentify } from './useAnalyticsIdentify';

export const useIdentifyUser = () => {
  const { i18n } = useTranslation();
  const { data: me } = useMeQuery();
  const identify = useAnalyticsIdentify();

  useEffect(() => {
    const { account } = me || {};

    if (account) {
      identify({
        id: account.id,
        first_name: account.first_name,
        last_name: account.last_name,
        email: account.email || '',
        phone: account.phone || '',
        language: normalizeLanguage(i18n.language)
      });

      datadogRum.setUser({
        id: account.id,
        email: account.email || '',
        phone: account.phone || ''
      });
    }
  }, [me, i18n.language, identify]);
};
