import * as yup from 'yup';
import {
  emailEndRegex,
  lowerCaseLetterRegexp,
  numberRegExp,
  specialCharactersRegexp,
  upperCaseLetterRegexp
} from '@/utils/regex';
import { TFunction } from 'react-i18next';

export const stringSchema = yup.string().trim();

export const getEmailSchema = (t: TFunction) =>
  stringSchema
    .lowercase()
    .matches(emailEndRegex, { excludeEmptyString: true, message: t('please_enter_valid_email') })
    .email(t('please_enter_valid_email'));

export const getStrongPasswordSchema = (t: TFunction) =>
  stringSchema
    .required(() => '')
    .min(8, t('please_choose_a_stronger_password_use_at_least_8_characters'))
    .matches(specialCharactersRegexp, t('please_use_at_least_one_special_symbol'))
    .matches(numberRegExp, t('please_use_at_least_one_number'))
    .matches(upperCaseLetterRegexp, t('please_use_at_least_one_capital_letter'))
    .matches(lowerCaseLetterRegexp, t('please_use_at_least_one_lowercase_letter'));
