import { PopperProps } from '@mui/material/Popper';
import { useEffect, useRef } from 'react';
import { Props } from './Popper';

const usePopperRef = ({ updateDelay }: Pick<Props, 'updateDelay'>) => {
  const popperRef: PopperProps['popperRef'] = useRef(null);

  useEffect(() => {
    let updateTimeoutId: NodeJS.Timeout;

    if (popperRef.current && updateDelay) {
      updateTimeoutId = setTimeout(() => {
        popperRef.current?.update();
      }, updateDelay);
    }

    return () => {
      clearTimeout(updateTimeoutId);
    };
  });

  return popperRef;
};

export default usePopperRef;
