import { Tooltip, TooltipProps } from '@/ui-kit/Tooltip';
import { HOOKS as disputesHooks, ACTIONS as disputesActions } from '@/store/disputes';
import { useDispatch } from 'react-redux';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/routes';
import { WhoCanManageDisputesContent } from './WhoCanManageDisputesContent';

type Props = {
  children: (hideTooltip: VoidFunction) => ReactElement;
  placement?: TooltipProps['placement'];
};

export const WhoCanManageDisputesTooltip = ({ children, placement }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const disputesTutorialStep = disputesHooks.useTutorialStep();

  const hideTooltip = () => {
    dispatch(disputesActions.setTutorialStep('hidden'));
  };

  const prevTooltip = () => {
    dispatch(disputesActions.setTutorialStep('first'));
    navigate(ROUTES.disputes);
  };

  return (
    <Tooltip
      placement={placement}
      open={disputesTutorialStep === 'second'}
      title={<WhoCanManageDisputesContent onClose={hideTooltip} onBack={prevTooltip} />}
    >
      {children(hideTooltip)}
    </Tooltip>
  );
};
