import { Button } from '@/ui-kit/Button';
import { useToggleLanguage } from '@/hooks/useToggleLanguage';
import { styled } from '@mui/material/styles';
import { TabbyBusinessLogo } from '@/ui-kit/TabbyBusinessLogo';
import { ReactNode } from 'react';

const HEADER_HEIGHT = 64;

const Background = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.B2B2023.back.minor
}));

const Header = styled('div')(({ theme }) => {
  const border = `2px solid ${theme.palette.B2B2023.back.minor}`;

  return {
    height: `${HEADER_HEIGHT}px`,
    backgroundColor: theme.palette.B2B2023.back.major,
    borderRadius: '0px 0px 16px 16px',
    borderRight: border,
    borderBottom: border,
    borderLeft: border,
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    justifyContent: 'space-between',
    padding: '0px 24px 0px 32px'
  };
});

const Content = styled('div')(({ theme }) => ({
  height: `calc(100vh - ${HEADER_HEIGHT}px)`,
  backgroundColor: theme.palette.B2B2023.back.minor,
  overflow: 'auto'
}));

const Centered = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '12px 0px',
  [theme.breakpoints.down('p768')]: {
    margin: '6px 8px'
  }
}));

const ToggleLanguageButton = () => {
  const { nextLanguageLabel, toggleLanguage } = useToggleLanguage();

  return (
    <Button variant="containedLight" onClick={toggleLanguage}>
      {nextLanguageLabel}
    </Button>
  );
};

export const AuthPageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Background>
      <Header>
        <TabbyBusinessLogo />
        <ToggleLanguageButton />
      </Header>
      <Content>
        <Centered>{children}</Centered>
      </Content>
    </Background>
  );
};
