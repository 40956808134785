import { Country, Currency } from '@/types';
import {
  APPLICATION_FORM_STEP_NAME,
  ApplicationFormStepName,
  ApplicationSource,
  ApplicationV3,
  ApplicationAnnualVolume,
  BusinessLicense,
  EcomType,
  LocationsNumber,
  Location,
  EcomPlatform,
  LegalEntityCountry
} from '@/store/application';
import { ROUTES } from '@/routes';
import { generatePath } from 'react-router-dom';
import { DAY_MS } from '@/utils/date';
import { ApplicationFormValues } from './types';

export const mapApplication = (data?: Partial<ApplicationV3>) => ({
  id: data?.id || '',
  account_id: data?.account_id || '',
  status: data?.status || 'draft',
  basic_info: {
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    email: data?.email || '',
    phone: data?.phone || '',
    is_operating_in_other_countries: Boolean(data?.other_countries?.length),
    country_of_main_operations: data?.country_of_main_operations || '',
    other_countries: data?.other_countries || [],
    business_name: data?.business_name || '',
    annual_volume: data?.annual_volume || ''
  },
  product_category: {
    section_id: data?.section_id || '',
    category_id: data?.category_id || ''
  },
  store_type: {
    ecommerce_platform: data?.ecommerce_platform || '',
    ecommerce_types: data?.ecommerce_types || [],
    locations_number: data?.locations_number || null,
    locations: data?.locations?.[0] ? { ...data?.locations[0] } : null,
    web_url: data?.web_url || ''
  },
  legal_info: {
    legal_entity_country:
      data?.legal_info?.legal_entity_country || data?.country_of_main_operations,
    business_license_type: data?.legal_info?.business_license_type || '',
    commercial_registration_number: data?.legal_info?.commercial_registration_number || '',
    license_url: data?.legal_info?.license_url || '',
    national_id_url: data?.legal_info?.national_id_url || '',
    legal_address: data?.legal_info?.legal_address || '',
    legal_company_name: data?.legal_info?.legal_company_name || '',
    unified_number: data?.legal_info?.unified_number || '',
    license_expiry_date: data?.legal_info?.license_expiry_date || '',
    has_vat: Boolean(data?.legal_info?.vat_registration_number),
    vat_registration_number: data?.legal_info?.vat_registration_number || '',
    address_in_tax_certificate: data?.legal_info?.address_in_tax_certificate || '',
    vat_certificate_url: data?.legal_info?.vat_certificate_url || '',
    business_type: { id: data?.legal_info?.business_type?.id || '' },
    company_owner: {
      id: data?.legal_info?.company_owner?.id || '',
      full_name: data?.legal_info?.company_owner?.full_name || '',
      date_of_birth: data?.legal_info?.company_owner?.date_of_birth || ''
    }
  },
  bank_details: {
    iban: data?.bank_details?.iban || '',
    bank_name: data?.bank_details?.bank_name || '',
    swift_code: data?.bank_details?.swift_code || '',
    beneficiary_name_ar: data?.bank_details?.beneficiary_name_ar || '',
    beneficiary_name_en: data?.bank_details?.beneficiary_name_en || '',
    currency: data?.bank_details?.currency || '',
    report_recipient: data?.bank_details?.report_recipient || '',
    branch_name: data?.bank_details?.branch_name || ''
  },
  created_by_account_id: data?.created_by_account_id || '',
  source: data?.source || ''
});

export const toApplication = (data: ApplicationFormValues): ApplicationV3 => {
  return {
    id: data.id,
    account_id: data.account_id,
    status: data.status,
    email: data.basic_info.email,
    first_name: data.basic_info.first_name,
    last_name: data.basic_info.last_name,
    phone: data.basic_info.phone,
    country_of_main_operations: data.basic_info.country_of_main_operations as Country,
    other_countries:
      data.basic_info.other_countries?.length === 0 ? null : data.basic_info.other_countries,
    locations_number: data.store_type.locations_number as LocationsNumber | null,
    locations: data.store_type.locations ? ([data.store_type.locations] as Location[]) : null,
    business_name: data.basic_info.business_name,
    annual_volume: data.basic_info.annual_volume as ApplicationAnnualVolume,
    section_id: data.product_category.section_id,
    category_id: data.product_category.category_id,
    ecommerce_types: data.store_type.ecommerce_types as EcomType[],
    web_url: data.store_type.web_url,
    ecommerce_platform: data.store_type.ecommerce_platform as EcomPlatform,
    legal_info: {
      legal_entity_country: data.legal_info?.legal_entity_country as LegalEntityCountry,
      business_license_type: data.legal_info.business_license_type as BusinessLicense,
      commercial_registration_number: data.legal_info.commercial_registration_number,
      license_url: data.legal_info.license_url,
      national_id_url: data.legal_info.national_id_url,
      legal_address: data.legal_info.legal_address,
      legal_company_name: data.legal_info.legal_company_name,
      unified_number: data.legal_info.unified_number,
      vat_registration_number: data.legal_info.vat_registration_number,
      address_in_tax_certificate: data.legal_info.address_in_tax_certificate,
      vat_certificate_url: data.legal_info.vat_certificate_url,
      license_expiry_date: data.legal_info.license_expiry_date,
      business_type: {
        id: data.legal_info.business_type.id
      },
      company_owner: {
        id: data.legal_info.company_owner.id,
        full_name: data.legal_info.company_owner.full_name,
        date_of_birth: data.legal_info.company_owner.date_of_birth
      }
    },
    bank_details: {
      iban: data.bank_details.iban,
      bank_name: data.bank_details.bank_name,
      swift_code: data.bank_details.swift_code,
      beneficiary_name_ar: data.bank_details.beneficiary_name_ar,
      beneficiary_name_en: data.bank_details.beneficiary_name_en,
      currency: data.bank_details.currency as Currency,
      report_recipient: data.bank_details.report_recipient,
      branch_name: data.bank_details.branch_name
    },
    created_by_account_id: data.created_by_account_id || null,
    source: data.source as ApplicationSource
  };
};

export const isStepName = (value: unknown): value is ApplicationFormStepName => {
  return (
    typeof value === 'string' && Object.values<string>(APPLICATION_FORM_STEP_NAME).includes(value)
  );
};

export const generateStepPath = (stepName: ApplicationFormStepName) =>
  generatePath(ROUTES.applicationFormStep, {
    stepName
  });

const LICENSE_EXPIRATION_WARNING_DAYS_LIMIT = 60;
export const isLicenseExpiringSoon = (timestamp: string): boolean => {
  const expireDate = new Date(timestamp);
  const currentDate = new Date();
  const timeDifference = expireDate.getTime() - currentDate.getTime();
  const dayDifference = timeDifference / DAY_MS;

  return dayDifference <= LICENSE_EXPIRATION_WARNING_DAYS_LIMIT;
};
