import { styled } from '@mui/system';
import Box from '@mui/material/Box';

export const Skeleton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'height' && prop !== 'width'
})<{ width?: string; height?: string }>(({ theme, width, height }) => ({
  height: height || theme.spacing(9),
  width: width || '100%',
  borderRadius: theme.spacing(2),
  backgroundColor: '#DFE5EB',
  overflow: 'hidden',
  position: 'relative',

  '&::after': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translateX(-100%)',
    backgroundImage:
      'linear-gradient(90deg, rgba(255, 255, 255, 0) 0, ' +
      'rgba(255, 255, 255, 0.2) 20%, ' +
      'rgba(255, 255, 255, 0.4) 40%, ' +
      'rgba(255, 255, 255, 0.5) 60%, ' +
      'rgba(255, 255, 255, 0.4) 80%, ' +
      'rgba(255, 255, 255, 0))',
    animation: 'loading 1.5s infinite',
    content: '""'
  },
  '@keyframes loading': {
    '100%': {
      transform: 'translateX(100%)'
    }
  }
}));
