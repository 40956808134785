import { useEffect } from 'react';
import { useHandleCRNumberSAU } from '../../../components/ApplicationCapitalForm/hooks/useHandleCRNumberSAU';
import { useResetLoanFormPropsContext } from './useResetLoanFormPropsContext';
import { useResetLoanFormStateContext } from './useResetLoanFormStateContext';

export const useResetLoanSyncInitialData = () => {
  const { reset } = useResetLoanFormPropsContext();
  const { initialData, isLegalEntityCountrySAU } = useResetLoanFormStateContext();
  const { handleCRNumberSAU } = useHandleCRNumberSAU();

  useEffect(() => {
    if (isLegalEntityCountrySAU) {
      const initialDataSAU = {
        ...initialData,
        company_name_or_legal_name: '',
        registered_company_address: ''
      };
      reset(initialDataSAU);
    } else {
      reset(initialData);
    }
  }, [reset, initialData, isLegalEntityCountrySAU]);

  useEffect(() => {
    if (isLegalEntityCountrySAU && initialData) {
      handleCRNumberSAU(initialData.cr_number);
    }
  }, [handleCRNumberSAU, initialData, isLegalEntityCountrySAU]);
};
