import { styled } from '@mui/material/styles';
import { Text } from '@/ui-kit/Text';
import { ReactNode } from 'react';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

type Props = {
  title: string;
  subtitle: string;
  children: ReactNode;
};
export const FormSection = ({ title, subtitle, children }: Props) => {
  return (
    <Root>
      <Header>
        <Text variant="h2" color="B2B2023.front.major">
          {title}
        </Text>
        <Text variant="body1Loose500" color="B2B2023.front.major">
          {subtitle}
        </Text>
      </Header>
      <div>{children}</div>
    </Root>
  );
};
