import { ReactNode, useState } from 'react';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  '& > *': {
    width: 'max-content',
    color: theme.palette.common.black
  }
}));

const SecondaryMenu = styled(Menu)(() => ({
  marginTop: 35,
  '& .MuiList-root': {
    padding: 0
  }
}));

interface Props {
  children: ReactNode;
}

const MoreButton = ({ children }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleShowActions = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleHideActions = () => {
    setAnchorEl(null);
  };

  return (
    <Root>
      <IconButton onClick={handleShowActions} size="large">
        <MoreVertRoundedIcon />
      </IconButton>

      <SecondaryMenu
        autoFocus={false}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleHideActions}
      >
        {children}
      </SecondaryMenu>
    </Root>
  );
};

export default MoreButton;
