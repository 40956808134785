import { Trans } from 'react-i18next';

import { TextDate } from '@/components/TextDate';
import Bank24 from '@tabby.ai/tabby-ui/dist/icons/core/Bank24';
import { Alert } from './Alert';

export const ContractSignedAlert = ({ settlementDate }: { settlementDate: string }) => {
  const nextSettlementDate = new Date(settlementDate);
  const isCurrentYear = nextSettlementDate.getUTCFullYear() === new Date().getUTCFullYear();

  return (
    <Alert
      variant="success"
      title={
        <Trans
          i18nKey="youll_receive_your_new_advance_with_your_next_payout_on_date"
          components={{
            date: (
              <TextDate
                format={isCurrentYear ? 'ddmm' : 'ddmmyyyy'}
                timestamp={nextSettlementDate.toString()}
              />
            )
          }}
        />
      }
      icon={<Bank24 color="B2B2023.accent.positiveA" />}
    />
  );
};
