import { paymentLinksStateKey } from './constants';
import { PaymentLinksState } from './types';

export const cacheState = (obj: PaymentLinksState) => {
  try {
    localStorage.setItem(paymentLinksStateKey, JSON.stringify(obj));
    return true;
  } catch {
    return false;
  }
};
