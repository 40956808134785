import { combineReducers, PayloadAction } from '@reduxjs/toolkit';
import { authSlice } from './auth/state';
import { disputesSlice } from './disputes/state';
import { dataTableSlice } from './dataTable/state';
import { rootApi } from './rootApi';
import { paymentLinksSlice } from './paymentLinks/state';
import { axiosFileUploadApi } from './fileUpload/api';

export const appReducer = combineReducers({
  auth: authSlice.reducer,
  paymentLinks: paymentLinksSlice.reducer,
  [rootApi.reducerPath]: rootApi.reducer,
  [disputesSlice.name]: disputesSlice.reducer,
  [dataTableSlice.name]: dataTableSlice.reducer,
  [axiosFileUploadApi.reducerPath]: axiosFileUploadApi.reducer
});

const rootReducer = (state: ReturnType<typeof appReducer>, action: PayloadAction<boolean>) => {
  if (action.type === authSlice.actions.deauthorized.type) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer as typeof appReducer;
