import File24 from '@tabby.ai/tabby-ui/dist/icons/core/File24';
import { DS } from '@/ui-kit/types';
import { Text } from '@/ui-kit/Text';
import React from 'react';
import { styled } from '@mui/material/styles';

const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '6px'
}));

export const ValueContent = ({ fileName }: { fileName: string }) => {
  return (
    <Row>
      <File24 dir="ltr" ds={DS.B2B2023} color="B2B2023.front.minor" />
      <Text variant="body2Tight500" color="B2B2023.front.major">
        {fileName}
      </Text>
    </Row>
  );
};
