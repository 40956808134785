import { useEffect } from 'react';

type Props = {
  onFocus?: VoidFunction;
  onFocusLost?: VoidFunction;
  onOffline?: VoidFunction;
  onOnline?: VoidFunction;
  onBeforeunload?: VoidFunction;
};

export const useWindowListeners = (props: Props) => {
  const { onFocus, onFocusLost, onOnline, onOffline, onBeforeunload } = props;

  const handleVisibilityChange = () => {
    if (window.document.visibilityState === 'visible') {
      onFocus?.();
    } else {
      onFocusLost?.();
    }
  };

  useEffect(() => {
    if (onFocus || onFocusLost) {
      window.addEventListener('visibilitychange', handleVisibilityChange, false);
    }

    if (onFocus) window.addEventListener('focus', onFocus, false);
    if (onOnline) window.addEventListener('online', onOnline, false);
    if (onOffline) window.addEventListener('offline', onOffline, false);
    if (onBeforeunload) window.addEventListener('beforeunload', onBeforeunload, false);

    const unsubscribe = () => {
      if (onFocus || onFocusLost) {
        window.removeEventListener('visibilitychange', handleVisibilityChange);
      }

      if (onFocus) window.removeEventListener('focus', onFocus);
      if (onOnline) window.removeEventListener('online', onOnline);
      if (onOffline) window.removeEventListener('offline', onOffline);
      if (onBeforeunload) window.removeEventListener('beforeunload', onBeforeunload);
    };

    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
