import {
  Drawer as MuiDrawer,
  DrawerProps,
  paperClasses as MuiPaper,
  ButtonBase
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '@/routes';
import { SideMenuItem } from '@/ui-kit/SideMenu';
import Logo from '@/components/Logo/Logo';
import { NavMenu } from '@/components/NavMenu/NavMenu';
import { LANGUAGE } from '@/types';
import { useToggleLanguage } from '@/hooks/useToggleLanguage';
import useNoRootScroll from '@/hooks/useNoRootScroll';
import { useGetAccountQuery } from '@/store/account';
import useCachedFlag from '@/hooks/useCachedFlag';
import { IconButton } from '@/ui-kit/IconButton';
import DoubleChevronForward24 from '@tabby.ai/tabby-ui/dist/icons/core/DoubleChevronForward24';
import DoubleChevronBack24 from '@tabby.ai/tabby-ui/dist/icons/core/DoubleChevronBack24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import User24 from '@tabby.ai/tabby-ui/dist/icons/core/User24';
import Question24 from '@tabby.ai/tabby-ui/dist/icons/core/Question24';
import Globe24 from '@tabby.ai/tabby-ui/dist/icons/core/Globe24';
import { useEffect } from 'react';
import { Text } from '@/ui-kit/Text';

export const FULL_DRAWER_WIDTH = 200;
export const COLLAPSED_DRAWER_WIDTH = 64;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'isCollapsed' })<{
  isCollapsed?: boolean;
}>(({ isCollapsed }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  height: '100vh',
  width: isCollapsed ? COLLAPSED_DRAWER_WIDTH : FULL_DRAWER_WIDTH,
  overflowX: 'hidden',
  transition: 'all 0.2s ease',
  border: 'none',
  [`.${MuiPaper.root}`]: {
    transition: 'all 0.3s ease',
    width: isCollapsed ? COLLAPSED_DRAWER_WIDTH : FULL_DRAWER_WIDTH,
    overflow: 'hidden'
  }
}));

const Header = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isCollapsed'
})<{ isCollapsed: boolean }>(({ isCollapsed }) => ({
  height: '100px',
  padding: isCollapsed ? '14px 0 0' : '14px 8px 0 16px',
  display: 'flex',
  flexDirection: isCollapsed ? 'column' : 'row',
  justifyContent: 'space-between',
  alignItems: isCollapsed ? 'center' : 'flex-start',
  gap: isCollapsed ? '8px' : '4px'
}));

const Content = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'hidden',
  overflowY: 'auto',
  margin: '0 8px'
}));

const Footer = styled('div')(({ theme }) => ({
  padding: '6px 0',
  backgroundColor: theme.palette.background.paper,
  zIndex: 1000,
  position: 'relative',
  margin: '0 8px 12px'
}));

type Props = DrawerProps & {
  onNavMenuClick?: VoidFunction;
};

export const NavSidebar = (props: Props) => {
  const { onNavMenuClick, variant = 'permanent', open, onClose } = props;
  const { t, i18n } = useTranslation();
  const [isCollapsed, setIsCollapsed] = useCachedFlag('collapsed');
  const { nextLanguageLabel, toggleLanguage } = useToggleLanguage();
  const { data: account } = useGetAccountQuery();
  const isTemporary = variant === 'temporary';

  useNoRootScroll({ condition: isTemporary && open });

  useEffect(() => {
    if (isTemporary) {
      setIsCollapsed(false);
    }
  }, [setIsCollapsed, isTemporary]);

  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleChangeLanguage = () => {
    toggleLanguage();
    onNavMenuClick?.();
  };

  const credential = account?.email || account?.phone;

  return (
    <Drawer
      onClose={onClose}
      isCollapsed={isCollapsed}
      open={open}
      variant={variant}
      PaperProps={{ sx: { borderRight: 'unset' } }}
    >
      <Header isCollapsed={isCollapsed}>
        <Logo variant={isCollapsed ? 'icon' : 'text'} />

        {variant !== 'temporary' && (
          <IconButton onClick={handleCollapseClick} size="small" variant="text">
            {isCollapsed ? (
              <DoubleChevronForward24 ds={DS.B2B2023} />
            ) : (
              <DoubleChevronBack24 ds={DS.B2B2023} />
            )}
          </IconButton>
        )}
      </Header>
      <Content>
        <NavMenu isCollapsed={isCollapsed} onClick={onNavMenuClick} />
      </Content>
      <Footer>
        <SideMenuItem
          isCollapsed={isCollapsed}
          label={nextLanguageLabel}
          onClick={handleChangeLanguage}
          icon={<Globe24 ds={DS.B2B2023} />}
          component={ButtonBase}
        />
        <SideMenuItem
          icon={<Question24 ds={DS.B2B2023} />}
          isCollapsed={isCollapsed}
          label={t('help_center')}
          to={`https://merchantsupport.tabby.ai/hc/${
            i18n.language === LANGUAGE.ar ? 'ar' : 'en-us'
          }`}
          target="_blank"
          component={NavLink}
        />
        <SideMenuItem
          icon={<User24 ds={DS.B2B2023} />}
          label={t('account')}
          isCollapsed={isCollapsed}
          subLabel={
            credential && (
              <Text
                title={credential}
                variant="caption1Loose500"
                overflow="hidden"
                textOverflow="ellipsis"
                dir="ltr"
              >
                {credential}
              </Text>
            )
          }
          to={ROUTES.account}
          notificationBadgeText={account && account.phone ? undefined : '1'}
          component={NavLink}
        />
      </Footer>
    </Drawer>
  );
};
