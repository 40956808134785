import { useTranslation } from 'react-i18next';
import { Button } from '@/ui-kit/Button';
import { useTimer } from 'react-timer-hook';
import { formatSeconds } from '@/utils/date';

export type Props = {
  resendIntervalMs: number;
  disabled?: boolean;
  onResend: ({ resetTimer }: { resetTimer: VoidFunction }) => void;
};

export const ResendButton = (props: Props) => {
  const { disabled, onResend, resendIntervalMs } = props;
  const { t } = useTranslation();

  const { totalSeconds, isRunning, restart } = useTimer({
    expiryTimestamp: new Date(Date.now() + resendIntervalMs)
  });

  const handleClick = () => {
    const resetTimer = () => {
      restart(new Date(Date.now() + resendIntervalMs));
    };
    onResend({ resetTimer });
  };

  return (
    <Button
      disabled={disabled || isRunning}
      variant="containedLight"
      onClick={handleClick}
      size="large"
    >
      {isRunning
        ? t('resend_code_seconds', { seconds: formatSeconds(totalSeconds) })
        : t('resend_code')}
    </Button>
  );
};
