import { Navigate, Route, Routes } from 'react-router-dom';
import { lazily } from '@/utils/modules';
import { PageLayout } from '@/components/PageLayout';
import { ROUTES } from '@/routes';
import { RedirectByStatus } from '@/pages/FinancePages';
import { AuthPage } from '@/pages/AuthPage';
import { useConnectionStatus } from '@/hooks/useConnectionStatus';
import { useSearchParamsLanguage } from '@/hooks/useSearchParamsLanguage';
import { useTranslateDocument } from '@/hooks/useTranslateDocument';
import { useIdentifyUser, useReportPageView } from '@/store/analytics';
import { useExcludeAccount } from '@/store/account-me';
import { useRefiner } from '@/lib/refiner';
import { generateApplicationFormStepPath } from '@/components/ApplicationForm';

import { AuthRoute } from './components/AuthRoute';
import { LogoutPage } from './components/LogoutPage';
import { LogoutRoute } from './components/LogoutRoute';
import { PrivateRoute } from './components/PrivateRoute';
import { RedirectByPermissions } from './components/RedirectByPermissions';
import { RedirectToAds } from './components/RedirectToAds';
import { PipedriveRedirect } from './components/PipedriveRedirect';
import { useInitGetStream } from '../GetStreamProvider';

const { AccountPage } = lazily(() => import('@/pages/AccountPage/AccountPage'));
const { HowReportsWorkPage } = lazily(() => import('@/pages/HowReportsWorkPage'));
const { PayoutsCompletedPage } = lazily(() => import('@/pages/PayoutsCompletedPage'));
const { PayoutsUpcomingPage } = lazily(() => import('@/pages/PayoutsUpcomingPage'));
const { TabbyCardPage } = lazily(() => import('@/pages/TabbyCardPage'));
const { ProductCatalogPage } = lazily(() => import('@/pages/ProductCatalogPage'));
const { ProductCatalogAuthPage } = lazily(() => import('@/pages/ProductCatalogAuthPage'));
const { DisputesPage } = lazily(() => import('@/pages/DisputesPage/DisputesPage'));
const { OnboardingPage } = lazily(() => import('@/pages/OnboardingPage/OnboardingPage'));
const { PaymentLinkStatusPage } = lazily(() => import('@/pages/PaymentLinkStatusPage'));
const { PaymentsPage } = lazily(() => import('@/pages/PaymentsPage/PaymentsPage'));
const { SupportPage } = lazily(() => import('@/pages/SupportPage/SupportPage'));
const { ApplicationPage } = lazily(() => import('@/pages/ApplicationPage'));
const { AnalyticsPage } = lazily(() => import('@/pages/AnalyticsPage/AnalyticsPage'));
const { StorePage } = lazily(() => import('@/pages/StorePage'));
const { StoresPage } = lazily(() => import('@/pages/StoresPage'));
const { UsersPage } = lazily(() => import('@/pages/UsersPage'));
const { DisputesFaqPage } = lazily(() => import('@/pages/DisputesFaqPage'));
const { WebviewDisputesFaqPage } = lazily(() => import('@/pages/WebviewDisputesFaqPage'));
const { FakeDoorPage, LoanPage, LoansPage, LoanApplicationPage, OfferPage } = lazily(
  () => import('@/pages/FinancePages')
);

export const AppRoutes = () => {
  useConnectionStatus();
  useSearchParamsLanguage();
  useTranslateDocument();
  useReportPageView();
  useExcludeAccount();
  useIdentifyUser();
  useRefiner();
  useInitGetStream();

  return (
    <Routes>
      {/* NO LAYOUT */}
      <Route path={ROUTES.root}>
        <Route index element={<RedirectByPermissions />} />
        <Route element={<AuthRoute />}>
          <Route path={ROUTES.auth} element={<AuthPage />} />
        </Route>
        <Route element={<LogoutRoute />}>
          <Route path={ROUTES.logout} element={<LogoutPage />} />
        </Route>
        <Route element={<PrivateRoute permission="canViewTabbyAds" />}>
          <Route path={ROUTES.ads} element={<RedirectToAds />} />
        </Route>
        <Route path={ROUTES.pipedriveApplicationId} element={<PipedriveRedirect />} />
        <Route path="*" element={<RedirectByPermissions />} />
      </Route>

      {/* APPLICATION FORM LAYOUT */}
      <Route
        path={ROUTES.applicationForm}
        element={<PrivateRoute permission="canViewApplication" />}
      >
        <Route
          index
          element={<Navigate to={generateApplicationFormStepPath('basic-info')} replace />}
        />
        <Route path={ROUTES.applicationFormStep} element={<ApplicationPage />} />
        <Route path="*" element={<Navigate to={ROUTES.applicationForm} replace />} />
      </Route>

      {/* LAYOUT WITHOUT SIDEBAR */}
      <Route path={ROUTES.root} element={<PageLayout />}>
        <Route path={ROUTES.webviewDisputesFaq} element={<WebviewDisputesFaqPage />} />
        <Route element={<PrivateRoute permission="canCreatePaymentLinks" />}>
          <Route path={ROUTES.servicePaymentLinkStatus} element={<PaymentLinkStatusPage />} />
        </Route>
      </Route>

      {/* LAYOUT WITH SIDEBAR (SIDEBAR REQUIRES AUTHORIZATION) */}
      <Route element={<PrivateRoute />}>
        <Route path={ROUTES.root} element={<PageLayout sidebar />}>
          <Route path={ROUTES.account}>
            <Route index element={<AccountPage />} />
            <Route path={ROUTES.accountSessions} element={<AccountPage />} />
            <Route path={ROUTES.accountPhone} element={<AccountPage />} />
            <Route path="*" element={<Navigate to={ROUTES.account} />} />
          </Route>
          <Route element={<PrivateRoute permission="canViewSupport" />}>
            <Route path={ROUTES.support} element={<SupportPage />} />
            <Route path={ROUTES.supportTicketId} element={<SupportPage />} />
          </Route>
          <Route path={ROUTES.analytics} element={<PrivateRoute permission="canViewAnalytics" />}>
            <Route index element={<AnalyticsPage />} />
            <Route path={ROUTES.analyticsCountryMetric} element={<AnalyticsPage />} />
            <Route path="*" element={<Navigate to={ROUTES.analytics} />} />
          </Route>
          <Route element={<PrivateRoute permission="canViewOrders" />}>
            <Route path={ROUTES.payments} element={<PaymentsPage />} />
            <Route path={ROUTES.paymentsId} element={<PaymentsPage />} />
          </Route>
          <Route path={ROUTES.onboarding} element={<PrivateRoute permission="canViewOnboarding" />}>
            <Route index element={<OnboardingPage />} />
            <Route path={ROUTES.onboardingCandidateId} element={<OnboardingPage />} />
            <Route path="*" element={<Navigate to={ROUTES.onboarding} />} />
          </Route>
          <Route element={<PrivateRoute permission="isWorkspaceAdmin" />}>
            <Route path={ROUTES.users} element={<UsersPage />} />
            <Route path={ROUTES.businessProfileStores} element={<StoresPage />} />
            <Route path={ROUTES.businessProfileStore} element={<StorePage />} />
            <Route path={ROUTES.businessProfileStoreTab} element={<StorePage />} />
          </Route>
          <Route element={<PrivateRoute permission="canViewDisputes" />}>
            <Route path={ROUTES.disputes} element={<DisputesPage />} />
            <Route path={ROUTES.disputesId} element={<DisputesPage />} />
            <Route path={ROUTES.disputesFaq} element={<DisputesFaqPage />} />
          </Route>
          <Route
            path={ROUTES.marketplaceProductCatalog}
            element={<PrivateRoute permission="canViewProductCatalog" />}
          >
            <Route index element={<ProductCatalogPage />} />
            <Route
              path={ROUTES.marketplaceProductCatalogAuth}
              element={<ProductCatalogAuthPage />}
            />
            <Route path="*" element={<Navigate to={ROUTES.marketplaceProductCatalog} />} />
          </Route>
          <Route
            path={ROUTES.financeTabbyCapital}
            element={<PrivateRoute permission="canViewCapital" />}
          >
            <Route index element={<RedirectByStatus />} />
            <Route path={ROUTES.financeTabbyCapitalFakeDoor} element={<FakeDoorPage />} />
            <Route path={ROUTES.financeTabbyCapitalLoans} element={<LoansPage />} />
            <Route path={ROUTES.financeTabbyCapitalLoansId} element={<LoanPage />} />
            <Route path={ROUTES.financeTabbyCapitalOffersId} element={<OfferPage />} />
            <Route path={ROUTES.financeTabbyCapitalApplication} element={<LoanApplicationPage />} />
            <Route path="*" element={<Navigate to={ROUTES.financeTabbyCapital} />} />
          </Route>
          <Route
            path={ROUTES.financePayouts}
            element={<PrivateRoute permission="isPayoutsAllowed" />}
          >
            <Route index element={<Navigate to={ROUTES.financeCompletedPayouts} />} />
            <Route path={ROUTES.financeHowReportsWork} element={<HowReportsWorkPage />} />
            <Route path={ROUTES.financeCompletedPayouts} element={<PayoutsCompletedPage />} />
            <Route path={ROUTES.financeUpcomingPayouts} element={<PayoutsUpcomingPage />} />
            <Route path="*" element={<Navigate to={ROUTES.financePayouts} />} />
          </Route>
          <Route element={<PrivateRoute permission="canViewTabbyCard" />}>
            <Route path={ROUTES.financeTabbyCard} element={<TabbyCardPage />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
