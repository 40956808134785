import { TextCurrency } from '@/components/TextCurrency';
import { TextDate } from '@/components/TextDate';
import LinearProgressBase, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { LoanStatus, RepaymentInfo, RepaymentInfoStatus } from '@/store/loan';
import orderBy from 'lodash/orderBy';
import { Currency } from '@/types';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { TextBadge } from '@tabby.ai/tabby-ui/dist/core';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { Text } from '@/ui-kit/Text';
import { WidgetTooltip } from '@/pages/FinancePages/components/WidgetTooltip';
import { LoanStatusBadge } from './LoanStatusBadge';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}));

const LinearProgress = styled(LinearProgressBase)(({ theme }) => ({
  height: 48,
  borderRadius: 10,

  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.B2B2023.back.minor
  },

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 10,
    backgroundColor: theme.palette.B2B2023.accent.positiveB
  }
}));

const VisualSlots = styled('div')(() => ({
  display: 'flex',
  gap: '2px',
  padding: '0px 16px'
}));

const VisualSlot = styled('div', {
  shouldForwardProp: (props) => props !== 'status'
})<{ status?: RepaymentInfoStatus }>(({ theme, status }) => {
  const background =
    status &&
    {
      earlyPayOff: theme.palette.B2B2023.accent.highlightB,
      paying: theme.palette.B2B2023.back.minor,
      paid: theme.palette.B2B2023.accent.positiveB,
      overdueWarning: theme.palette.B2B2023.accent.positiveB,
      overdueCritical: theme.palette.B2B2023.accent.negativeB
    }[status];

  return {
    background,
    flex: '1',
    height: '6px',
    borderRadius: '5px',

    [theme.breakpoints.down('p768')]: {
      height: '24px'
    }
  };
});

const VisualFooter = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '32px',
  alignItems: 'center',
  padding: '16px',
  borderRadius: '16px',
  background: theme.palette.B2B2023.back.minor,

  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column',
    gap: '12px'
  }
}));

const FooterItem = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px',

  [theme.breakpoints.down('p768')]: {
    width: '100%',
    justifyContent: 'space-between'
  }
}));

const Repaid = styled(TextBadge)(({ theme }) => ({
  background: theme.palette.B2B2023.back.major
}));

type Props = {
  repayments: RepaymentInfo[];
  progress?: number;
  minimumRepayment?: string;
  currency?: Currency;
  hasProgress?: boolean;
  hasRepaymentTooltip?: boolean;
  nextSettlementDate?: string;
  repaidPercent?: string;
  loanStatus: LoanStatus;
};

export const VisualDataWidget = ({
  repayments = [],
  progress,
  minimumRepayment,
  currency: loanCurrency,
  hasProgress,
  hasRepaymentTooltip = false,
  nextSettlementDate,
  repaidPercent,
  loanStatus
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useIsMobilePage();
  const sortedByDateRepayments = orderBy(repayments, 'date');
  const hasRepaidPercent = ['closed', 'paying'].includes(loanStatus);

  return (
    <Root>
      {hasProgress && <LinearProgress variant="determinate" value={progress} />}
      <VisualSlots>
        {sortedByDateRepayments.map((repayment, idx) => {
          if (!repayment) {
            // eslint-disable-next-line react/no-array-index-key
            return <VisualSlot status="paying" key={`${idx}`} />;
          }

          const {
            date,
            status,
            settlementPayout,
            repaymentAmount,
            resultingPayout,
            currency,
            repaymentType
          } = repayment;

          if (!hasRepaymentTooltip) {
            return <VisualSlot status={status} key={`${date + idx}`} />;
          }

          const tooltipInfo =
            repaymentType === 'early_pay_off'
              ? [
                  {
                    label: t('early_payoff'),
                    money: <TextCurrency amount={resultingPayout} currency={currency} />
                  },
                  {
                    label: t('repayment'),
                    money: <TextCurrency amount={repaymentAmount} currency={currency} />
                  }
                ]
              : [
                  {
                    label: t('settlement'),
                    money: <TextCurrency amount={settlementPayout} currency={currency} />
                  },
                  {
                    label: t('repayment'),
                    money: <TextCurrency amount={repaymentAmount} currency={currency} />
                  },
                  {
                    label: t('payout'),
                    money: <TextCurrency amount={resultingPayout} currency={currency} />
                  }
                ];

          return (
            <WidgetTooltip
              key={`${date + idx}`}
              title={<TextDate format="ddmmyyyy" timestamp={date} />}
              info={tooltipInfo}
              placement="bottom"
            >
              <VisualSlot status={status} />
            </WidgetTooltip>
          );
        })}
      </VisualSlots>
      <VisualFooter>
        {loanStatus === 'overdue' && !isMobile && <LoanStatusBadge status={loanStatus} />}
        {hasRepaidPercent && !isMobile && (
          <Repaid ds={DS.B2B2023} type="default" size="large">
            {t('percent_repaid', { percent: repaidPercent })}
          </Repaid>
        )}
        {nextSettlementDate && (
          <FooterItem>
            <Text variant="body2Tight500" color="B2B2023.front.minor">
              {t('next_settlement')}
            </Text>
            <Text variant="body2Tight700" color="B2B2023.front.major">
              <TextDate
                format="ddmm"
                timestamp={nextSettlementDate}
                customOptions={isMobile ? { month: 'long' } : undefined}
              />
            </Text>
          </FooterItem>
        )}
        {minimumRepayment && loanCurrency && (
          <FooterItem>
            <Text variant="body2Tight500" color="B2B2023.front.minor">
              {t('weekly_minimum_repayment')}
            </Text>
            <Text variant="body2Tight700" color="B2B2023.front.major">
              <TextCurrency amount={minimumRepayment} currency={loanCurrency} />
            </Text>
          </FooterItem>
        )}
      </VisualFooter>
    </Root>
  );
};
