import { BORDER_RADIUS_PX } from '@/ui-kit/WidgetBase';
import { styled } from '@mui/material/styles';
import { DownloadApp } from './components/DownloadApp';
import { SignIn } from './components/SignIn';

const Root = styled('div')(({ theme }) => ({
  borderRadius: `${BORDER_RADIUS_PX}px`,
  padding: '12px',
  backgroundColor: theme.palette.B2B2023.back.major,
  overflow: 'hidden',
  display: 'flex',
  flexShrink: 0,
  gap: '12px',
  [theme.breakpoints.down('p768')]: {
    flexDirection: 'column-reverse',
    gap: '24px'
  }
}));

type Props = {
  children: JSX.Element;
};

export const AuthSignInWidget = ({ children }: Props) => {
  return (
    <Root>
      <DownloadApp />
      <SignIn>{children}</SignIn>
    </Root>
  );
};
