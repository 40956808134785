import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { TextFieldControlled } from '@/ui-kit/TextField';
import { Button } from '@/ui-kit/Button';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { stringSchema } from '@/lib/yup';
import * as yup from 'yup';
import { useEffect } from 'react';
import cleaner from '@/lib/cleaner';
import { ResendButton, Props as ResendButtonProps } from './ResendButton';

const Form = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Column = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const CodeFieldControlled = styled(TextFieldControlled)(({ theme }) => ({
  '& .MuiInputBase-input': {
    direction: 'ltr /* @noflip */',
    textAlign: theme.direction === 'ltr' ? 'start' : 'end'
  }
})) as typeof TextFieldControlled;

type Props = {
  codeLength: number;
  isLoading: boolean;
  onSubmitSuccess: (value: string) => void;
  errorMessage?: string;
} & Omit<ResendButtonProps, 'disabled'>;

export const CodeForm = (props: Props) => {
  const { isLoading, codeLength, resendIntervalMs, onSubmitSuccess, onResend, errorMessage } =
    props;
  const { t } = useTranslation();

  const { control, handleSubmit, setError } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(
      yup.object().shape({
        value: stringSchema
          .required(() => '')
          .min(codeLength, t('please_enter_x_digits_code', { x: codeLength }))
          .max(codeLength, t('please_enter_x_digits_code', { x: codeLength }))
      })
    ),
    defaultValues: {
      value: ''
    }
  });

  const submit = handleSubmit(({ value }) => {
    onSubmitSuccess(value);
  });

  useEffect(() => {
    if (errorMessage) {
      setError('value', { message: errorMessage });
    }
  }, [errorMessage, setError]);

  return (
    <Form onSubmit={submit}>
      <CodeFieldControlled
        control={control}
        name="value"
        placeholder={t('enter_the_code')}
        InputProps={{ size: 'medium' }}
        inputProps={{ maxLength: codeLength, autoComplete: 'off', 'data-1p-ignore': true }}
        autoFocus
        transform={(value) => cleaner.parse(value, { keep: ['numbers'] })}
      />
      <Column>
        <Button type="submit" isLoading={isLoading} variant="contained" size="large" fullWidth>
          {t('continue')}
        </Button>
        <ResendButton
          disabled={isLoading}
          onResend={onResend}
          resendIntervalMs={resendIntervalMs}
        />
      </Column>
    </Form>
  );
};
