import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { Events } from '@/store/analytics';
import { TextFieldControlled } from '@/ui-kit/TextField';

import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { useHandleCRNumberInput } from '../hooks/useHandleCRNumberInput';

export const CRNumberFields = () => {
  const { t } = useTranslation();
  const { control } = useFormPropsContext();
  const { isLegalEntityCountrySAU } = useFormStateContext();
  const { handleCRNumberInput, isFetchingCRValidation } = useHandleCRNumberInput();
  const isCRNumberRelatedFieldDisabled = isLegalEntityCountrySAU;

  return (
    <>
      <TextFieldControlled
        onBlur={isLegalEntityCountrySAU ? handleCRNumberInput : undefined}
        disabled={isFetchingCRValidation}
        placeholder={t('enter_license_number')}
        control={control}
        name="cr_number"
        label={t('license_number')}
        analytics={{
          event: Events.CapitalLicenseNumberFilled
        }}
        inputProps={{ maxLength: 15 }}
        InputProps={{
          endAdornment: isFetchingCRValidation && <CircularProgress color="inherit" size={20} />
        }}
      />
      <TextFieldControlled
        control={control}
        name="company_name_or_legal_name"
        label={t('legal_company_name')}
        disabled={isCRNumberRelatedFieldDisabled}
      />
      <TextFieldControlled
        control={control}
        name="registered_company_address"
        label={t('registered_company_address')}
        disabled={isCRNumberRelatedFieldDisabled}
      />
    </>
  );
};
