import InfoIcon from '@/assets/icons/info.svg?icon';
import CheckmarkCircleIcon from '@/assets/icons/checkmark-circle.svg?icon';
import WarningIcon from '@/assets/icons/warning.svg?icon';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { NOTIFICATION_VARIANTS, NotificationVariant } from '../types';

type Props = SvgIconProps & {
  variant: NotificationVariant;
};

export const NotificationItemIcon = ({ variant, ...rest }: Props) => {
  switch (variant) {
    case NOTIFICATION_VARIANTS.Success:
      return <CheckmarkCircleIcon {...rest} />;
    case NOTIFICATION_VARIANTS.Error:
    case NOTIFICATION_VARIANTS.Warning:
      return <WarningIcon {...rest} />;
    case NOTIFICATION_VARIANTS.Info:
    case NOTIFICATION_VARIANTS.Iris:
      return <InfoIcon {...rest} />;
    default:
      return null;
  }
};
