import { styled } from '@mui/material/styles';

import { useFormStateContext } from '../../hooks/useFormStateContext';
import { StepperItem } from './StepperItem';

export const Root = styled('nav')(() => ({
  width: '100%'
}));

export const List = styled('ul')(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '32px',
  margin: 0,
  padding: 0,
  listStyle: 'none'
}));

export const Stepper = () => {
  const { steps, currentIndex } = useFormStateContext();

  return (
    <Root>
      <List>
        {steps.map((step, index) => {
          return (
            <StepperItem
              key={step.name}
              stepName={step.name}
              isValid={step.isValid}
              isActive={index === currentIndex}
            />
          );
        })}
      </List>
    </Root>
  );
};
