import { DIAL_CODES, PhoneConfig } from './types';

export const KWT: PhoneConfig = {
  dialCode: DIAL_CODES.KWT,
  format: '+### #### ####',
  inputMask: '0000 0000',
  inputPlaceholder: '0000 0000',
  mask: '_',
  maxLength: 12 - DIAL_CODES.KWT.length,
  regExp: /^(0?0965|\+?9650?|0?)(\d{8})$/,
  separator: ' '
};

export const BHR: PhoneConfig = {
  dialCode: DIAL_CODES.BHR,
  format: '+### ########',
  inputMask: '0000 0000',
  inputPlaceholder: '0000 0000',
  mask: '_',
  maxLength: 12 - DIAL_CODES.BHR.length,
  regExp: /^(0?0973|\+?9730?|0?)(\d{8})$/,
  separator: ' '
};

export const ARE: PhoneConfig = {
  dialCode: DIAL_CODES.ARE,
  format: '+### ## ### ####',
  inputMask: '00 000 0000',
  inputPlaceholder: '00 000 0000',
  mask: '_',
  maxLength: 13 - DIAL_CODES.ARE.length,
  regExp: /^(0?0971|\+?9710?|0?)(5\d{8})$/,
  separator: ' '
};

export const SAU: PhoneConfig = {
  dialCode: DIAL_CODES.SAU,
  format: '+### ## ### ####',
  inputMask: '00 000 0000',
  inputPlaceholder: '00 000 0000',
  mask: '_',
  maxLength: 13 - DIAL_CODES.SAU.length,
  regExp: /^(0?0966|\+?9660?|0?)(5\d{8})$/,
  separator: ' '
};

export const EGY: PhoneConfig = {
  dialCode: DIAL_CODES.EGY,
  format: '+## ## #### ####',
  inputMask: '00 0000 0000',
  inputPlaceholder: '00 0000 0000',
  mask: '_',
  maxLength: 13 - DIAL_CODES.EGY.length,
  regExp: /^\+?201([0-2]|5)\d{8}$/,
  separator: ' '
};

export const QAT: PhoneConfig = {
  dialCode: DIAL_CODES.QAT,
  format: '+### #### ####',
  inputMask: '0000 0000',
  inputPlaceholder: '0000 0000',
  mask: '_',
  maxLength: 12 - DIAL_CODES.QAT.length,
  regExp: /^(0?0974|\+?9740?|0?)(\d{8})$/,
  separator: ' '
};

export const JOR: PhoneConfig = {
  dialCode: DIAL_CODES.JOR,
  format: '+### # #### ####',
  inputMask: '0 0000 0000',
  inputPlaceholder: '0 0000 0000',
  mask: '_',
  maxLength: 13 - DIAL_CODES.JOR.length,
  regExp: /^(?:\+962|0962)7\d{8}$/,
  separator: ' '
};
