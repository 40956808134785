import { rootApi } from '../rootApi/api';
import {
  GetPaymentsFilters,
  GetPaymentsParams,
  GetPaymentsResponse,
  GetPaymentsResult
} from './types';
import { prepareGetPaymentsParams } from './utils';

const paymentsApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getPayments: build.query<GetPaymentsResult, GetPaymentsParams | undefined>({
      query: (params) => ({
        url: '/merchant/api/v1/payments',
        params: prepareGetPaymentsParams(params)
      }),
      transformResponse: ({ payments, ...rest }: GetPaymentsResponse) => {
        return {
          items: payments || [],
          ...rest
        };
      }
    }),
    exportPayments: build.query<{ email: string }, GetPaymentsFilters | undefined>({
      query: (params) => ({
        url: '/merchant/api/v1/payments/export',
        params: {
          ...params,
          limit: 10000
        }
      })
    })
  })
});

export const { useLazyGetPaymentsQuery, useGetPaymentsQuery, useLazyExportPaymentsQuery } =
  paymentsApi;
