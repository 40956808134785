import { TextCurrency } from '@/components/TextCurrency';
import { styled } from '@mui/material/styles';
import Info24 from '@tabby.ai/tabby-ui/dist/icons/core/Info24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { WidgetTooltip } from '@/pages/FinancePages/components/WidgetTooltip';
import { CapitalLoan } from '@/store/loan';
import useRepaymentData from '@/store/loan/hooks/useRepaymentData';
import { Text } from '@/ui-kit/Text';
import { Tooltip } from '@/ui-kit/Tooltip';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { VisualDataWidget } from './VisualDataWidget';
import { WidgetLegend } from './WidgetLegend';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const Head = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  color: theme.palette.B2B2023.front.minor,
  alignItems: 'start',
  marginBottom: '20px',
  position: 'relative'
}));

const HeadInfo = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const HeadInfoBalance = styled(TextCurrency)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._h1,
  color: theme.palette.B2B2023.front.major,
  margin: '8px 0 12px 0'
}));

const IconWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  top: '0',
  right: '-8px'
}));

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.B2B2023.front.minor,
  marginTop: '16px',
  marginBottom: '24px',
  gap: '8px'
}));

const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px'
}));

const RowTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
}));

const Footer = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.B2B2023.front.line}`,
  paddingTop: '24px',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '22px'
}));

export const PaymentsWidget = ({ loan }: { loan: CapitalLoan }) => {
  const { t } = useTranslation();

  const {
    widgetData: repayments,
    totalCount,
    currentCount,
    legends,
    progress
  } = useRepaymentData(loan);

  const {
    currency,
    amount = 0,
    paid_amount = 0,
    fee_amount = 0,
    minimum_repayment_amount = 0,
    amount_vat = 0,
    early_pay_off,
    repayment_rate
  } = loan;

  const totalRepaymentAmount = Number(amount) + Number(fee_amount);
  const totalRemainingAmount = Number(amount_vat) - Math.abs(Number(paid_amount));

  const tooltipContent = useMemo(() => {
    const earlyPayOff = early_pay_off
      ? [
          {
            label: t('early_payoff'),
            money: <TextCurrency amount={early_pay_off.repayment_amount} currency={currency} />
          },
          {
            label: t('capital'),
            money: <TextCurrency amount={early_pay_off.capital_amount} currency={currency} />
          },
          {
            label: t('capital_fee'),
            money: <TextCurrency amount={fee_amount} currency={currency} />
          }
        ]
      : [
          {
            label: t('capital'),
            money: <TextCurrency amount={amount} currency={currency} />
          },
          {
            label: t('capital_fee'),
            money: <TextCurrency amount={fee_amount} currency={currency} />
          }
        ];

    return [
      ...earlyPayOff,
      {
        label: t('total_amount_owed'),
        money: <TextCurrency amount={totalRepaymentAmount} currency={currency} />
      },
      {
        label: t('total_owed'),
        money: <TextCurrency amount={amount_vat} currency={currency} />
      }
    ];
  }, [amount, amount_vat, currency, early_pay_off, fee_amount, t, totalRepaymentAmount]);

  return (
    <Root>
      <Head>
        <HeadInfo>
          <DeprecatedTypography variant="_body1Tight500">
            {t('outstanding_balance')}
          </DeprecatedTypography>
          <HeadInfoBalance amount={totalRemainingAmount} currency={currency} />
          <DeprecatedTypography variant="_body1Tight500">
            <Trans
              i18nKey="amount_repaid"
              components={{
                amount: <TextCurrency amount={Math.abs(+paid_amount)} currency={currency} />
              }}
            />
          </DeprecatedTypography>
        </HeadInfo>
        <WidgetTooltip placement="left" title={t('details')} info={tooltipContent}>
          <IconWrapper>
            <Info24 ds={DS.B2B2023} />
          </IconWrapper>
        </WidgetTooltip>
      </Head>

      <VisualDataWidget
        hasProgress
        progress={progress}
        repayments={repayments}
        totalCount={totalCount}
        currentCount={currentCount}
        hasRepaymentTooltip
        loanStatus={loan.status}
      />

      <Content>
        <Row>
          <RowTitle>
            <Text variant="body1Tight500" color="B2B2023.front.minor">
              {t('repayment_rate')}
            </Text>
            <Tooltip
              ds="B2B2023"
              placement="right"
              title={t('your_base_repayment_rate__description', {
                amount: Number(repayment_rate)
              })}
            >
              <Info24 />
            </Tooltip>
          </RowTitle>
          <Text variant="body1Loose500" color="B2B2023.front.major">
            <Trans i18nKey="percent_of_sales" values={{ percent: Number(repayment_rate) }} />
          </Text>
        </Row>

        <Row>
          <RowTitle>
            <Text variant="body1Tight500" color="B2B2023.front.minor">
              {t('minimum_repayment')}
            </Text>
            <Tooltip
              ds="B2B2023"
              placement="right"
              title={t('we_deduct_22_of_your_weekly_sales__description', {
                amount: Number(repayment_rate)
              })}
            >
              <Info24 />
            </Tooltip>
          </RowTitle>
          <Text variant="body1Loose500" color="B2B2023.front.major">
            <Text variant="body1Loose500" color="B2B2023.front.major">
              <TextCurrency amount={minimum_repayment_amount} currency={currency} />
            </Text>
          </Text>
        </Row>
      </Content>

      <Footer>
        {legends.map((legend) => (
          <WidgetLegend key={legend} variant={legend} />
        ))}
      </Footer>
    </Root>
  );
};
