import { useAuthPageContext } from '../AuthPageProvider';
import { AuthStepSignIn } from './AuthStepSignIn';
import { AuthStepSignInVerifyEmail } from './AuthStepSignInVerifyEmail';
import { AuthStepSignInVerifyPhone } from './AuthStepSignInVerifyPhone';
import { AuthStepChallengeVerifyEmail } from './AuthStepChallengeVerifyEmail';
import { AuthStepChallengeVerifyPhone } from './AuthStepChallengeVerifyPhone';
import { AuthStepChallengeSetupEmail } from './AuthStepChallengeSetupEmail';
import { AuthStepChallengeSetupPhone } from './AuthStepChallengeSetupPhone';
import { AuthStepChallengeSetupVerifyEmail } from './AuthStepChallengeSetupVerifyEmail';
import { AuthStepChallengeSetupVerifyPhone } from './AuthStepChallengeSetupVerifyPhone';
import { AuthStepError } from './AuthStepError';
import { useTrackAuthStepOpened } from '../hooks/useTrackAuthStepOpened';

export const AuthPageContent = () => {
  const {
    state: { step }
  } = useAuthPageContext();

  useTrackAuthStepOpened();

  return (
    {
      sign_in: <AuthStepSignIn />,
      sign_in_verify_email: <AuthStepSignInVerifyEmail />,
      sign_in_verify_phone: <AuthStepSignInVerifyPhone />,
      challenge_verify_email: <AuthStepChallengeVerifyEmail />,
      challenge_verify_phone: <AuthStepChallengeVerifyPhone />,
      challenge_setup_email: <AuthStepChallengeSetupEmail />,
      challenge_setup_phone: <AuthStepChallengeSetupPhone />,
      challenge_setup_verify_email: <AuthStepChallengeSetupVerifyEmail />,
      challenge_setup_verify_phone: <AuthStepChallengeSetupVerifyPhone />
    }[step] || <AuthStepError />
  );
};
