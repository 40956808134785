import { Events } from '@/store/analytics';
import { Button as BaseButton } from '@/ui-kit/Button';
import { SelectControlled } from '@/ui-kit/Select';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { Box } from '@mui/material';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { ArrangeCallback } from '@/pages/FinancePages/components/ArrangeCallback';
import { useFormPropsContext } from '../hooks/useFormPropsContext';
import { useFormStateContext } from '../hooks/useFormStateContext';
import { useFormSubmit } from '../hooks/useFormSubmit';
import { useLegalEntityOptions } from '../hooks/useLegalEntityOptions';
import { Offers } from './Offers';

const Root = styled('div')(() => ({
  padding: '8px 24px 24px'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const Button = styled(BaseButton)(() => ({
  width: '100%'
}));

const MenuItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

const MenuItemLabel = styled('div')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight500,
  color: theme.palette.B2B2023.front.major
}));

const MenuItemInfo = styled(DeprecatedTypography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme.palette.B2B2023.front.minor
}));

const HeaderSubtitle = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body2Tight500,
  color: theme.palette.B2B2023.front.minor,
  paddingLeft: '8px'
}));

export const Offer = () => {
  const { t } = useTranslation();
  const { isLoading, offer } = useFormStateContext();
  const { control } = useFormPropsContext();
  const { submit } = useFormSubmit();
  const options = useLegalEntityOptions();

  return (
    <Root>
      <Header>
        <HeaderSubtitle>{t('store')}</HeaderSubtitle>
        {isLoading ? (
          <Skeleton height={40} />
        ) : (
          <SelectControlled
            control={control}
            name="legalEntityId"
            placeholder={t('select_store')}
            options={
              options
                ? options.map(({ value, label, iban, licenseNumber, address }) => {
                    return {
                      value,
                      label,
                      customHtml: (
                        <MenuItem key={value}>
                          <MenuItemLabel>{label}</MenuItemLabel>
                          <MenuItemInfo variant="_caption2Tight500">
                            {iban && <span>{iban}</span>}
                            {licenseNumber && <span>{licenseNumber}</span>}
                            {address && <span>{address}</span>}
                          </MenuItemInfo>
                        </MenuItem>
                      )
                    };
                  })
                : []
            }
            analytics={{
              event: Events.CapitalStoreSelected
            }}
            required
          />
        )}
      </Header>

      <Offers />

      <Box sx={{ textAlign: 'center', paddingTop: '0px' }}>
        <Button size="large" onClick={submit} disabled={isLoading}>
          {t('apply_for_tabby_capital')}
        </Button>
        <ArrangeCallback offerId={offer.id} />
      </Box>
    </Root>
  );
};
