import { useShowNotification } from '@/components/Notifications';
import { normalizeAPIError } from '@/store/rootApi';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Form, getApiErrorMessage } from '../utils';

type HandleErrorMessage = (errorMessage: string) => void;

export const useHandleApiError = (form: Form) => {
  const { t } = useTranslation();
  const notify = useShowNotification();

  return useCallback(
    (error: unknown) => (cb: HandleErrorMessage) => {
      const normalizedError = normalizeAPIError(error);
      const message = getApiErrorMessage(normalizedError, form, t);

      notify({ variant: 'error', message });

      return cb(message);
    },
    [notify, t, form]
  );
};
