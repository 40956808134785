import { useCallback } from 'react';
import { Events, useAnalyticsTrack } from '@/store/analytics';

import { useFormStateContext } from './useFormStateContext';
import { useFormPropsContext } from './useFormPropsContext';
import { useIbanValidation } from './useIbanValidation';

export const useHandleIbanInput = () => {
  const track = useAnalyticsTrack();
  const { getValues, setValue } = useFormPropsContext();
  const {
    bankDetails: { setIbanValidation }
  } = useFormStateContext();
  const { getValidationResult, isFetching: isFetchingIbanValidation } = useIbanValidation();

  const handleIbanInput = useCallback(async () => {
    const iban = getValues('bank_details.iban');
    const countryOfMainOperations = getValues('basic_info.country_of_main_operations');
    const result = await getValidationResult(iban, countryOfMainOperations);

    if (!result) {
      setValue('bank_details.bank_name', '');
      setValue('bank_details.branch_name', '');
      setValue('bank_details.swift_code', '');
      setIbanValidation();
      return;
    }

    setIbanValidation(result);

    const {
      bank_data: { bank_name, branch_name, swift_code }
    } = result;

    if (bank_name) {
      setValue('bank_details.bank_name', bank_name);
      track({
        event: Events.BankNameFilled,
        params: { value: bank_name, isPrefilled: true }
      });
    }

    if (branch_name) {
      setValue('bank_details.branch_name', branch_name);
      track({
        event: Events.BranchNameFilled,
        params: { value: branch_name, isPrefilled: true }
      });
    }

    if (swift_code) {
      setValue('bank_details.swift_code', swift_code);
      track({
        event: Events.SWIFTFilled,
        params: { value: swift_code, isPrefilled: true }
      });
    }
  }, [getValidationResult, getValues, setIbanValidation, setValue, track]);

  return { handleIbanInput, isFetchingIbanValidation };
};
