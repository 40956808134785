import BaseFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import BaseSwitch, { switchClasses } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const Switch = styled(BaseSwitch)(({ theme }) => ({
  width: 36,
  height: 20,
  padding: 0,
  display: 'flex',

  [`& .${switchClasses.switchBase}`]: {
    padding: 2,
    color: theme.palette.grey[600],

    [`&.${switchClasses.checked}`]: {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,

      [`& + .${switchClasses.track}`]: {
        opacity: 1,
        backgroundColor: theme.palette.grey[600],
        borderColor: theme.palette.grey[600]
      }
    }
  },
  [`& .${switchClasses.thumb}`]: {
    width: 16,
    height: 16,
    boxShadow: 'none'
  },
  [`& .${switchClasses.track}`]: {
    border: `1px solid ${theme.palette.grey[600]}`,
    borderRadius: 30,
    opacity: 1,
    backgroundColor: theme.palette.common.white
  }
}));

const FormControlLabel = styled(BaseFormControlLabel)(() => ({
  margin: 0,
  gap: 16,
  userSelect: 'none'
}));

export type Props = Omit<FormControlLabelProps, 'control' | 'onChange' | 'onClick'> & {
  onChange?: (checked: boolean) => void;
};

const SwitchComponent = ({ onChange, checked, ...rest }: Props) => {
  const handleChange: FormControlLabelProps['onChange'] = (event, newChecked) => {
    onChange?.(newChecked);
  };

  const handleClick: FormControlLabelProps['onClick'] = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <FormControlLabel
      {...rest}
      control={<Switch />}
      onChange={handleChange}
      onClick={handleClick}
      checked={checked}
    />
  );
};

export default SwitchComponent;
