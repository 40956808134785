import { PopperProps } from '@mui/material/Popper';
import { ReactNode, useState } from 'react';
import CloseIcon from '@/assets/icons/close.svg?icon';
import useNoRootScroll from '@/hooks/useNoRootScroll';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { StyledPopper, PopperArrow, PopperContent, popperClassNames, CloseButton } from './styles';
import usePopperRef from './usePopperRef';

export type Props = PopperProps & {
  arrow?: boolean;
  closeable?: boolean;
  updateDelay?: number;
  onClose?: () => void;
  disableRootScroll?: boolean;
  children?: ReactNode;
};

const Popper = ({
  children,
  open,
  anchorEl,
  arrow = false,
  closeable = false,
  updateDelay,
  onClose,
  disableRootScroll,
  ...rest
}: Props) => {
  const isOpen = Boolean(open && anchorEl);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const popperRef = usePopperRef({ updateDelay });

  useNoRootScroll({ condition: isOpen && disableRootScroll });

  return (
    <ClickAwayListener onClickAway={() => onClose?.()}>
      <StyledPopper
        {...rest}
        popperRef={popperRef}
        open={isOpen}
        disablePortal={false}
        anchorEl={anchorEl}
        arrow={arrow}
        onClose={onClose}
        onClick={(e) => e.stopPropagation()}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [-12, 12]
            }
          },
          {
            name: 'flip',
            enabled: true,
            options: {
              altBoundary: false,
              rootBoundary: 'document'
            }
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: false,
              altBoundary: false,
              tether: true,
              rootBoundary: 'document'
            }
          },
          {
            name: 'arrow',
            enabled: arrow,
            options: {
              element: arrowRef
            }
          }
        ]}
      >
        <div className={popperClassNames.inner}>
          {arrow ? <PopperArrow ref={setArrowRef} className={popperClassNames.arrow} /> : null}
          {closeable && (
            <CloseButton size="small" onClick={onClose}>
              <CloseIcon fontSize="inherit" />
            </CloseButton>
          )}
          <PopperContent>{children}</PopperContent>
        </div>
      </StyledPopper>
    </ClickAwayListener>
  );
};

export default Popper;
