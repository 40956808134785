import { useMeQuery } from '@/store/account-me';
import { useAnalytics } from '@/store/analytics/AnalyticsProvider';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookies } from '@/utils/dom';
import { skipToken } from '@reduxjs/toolkit/query';

export const useAnalyticsPage = ({ skipMeQuery = false } = {}) => {
  const { i18n } = useTranslation();
  const analytics = useAnalytics();
  const { data: me } = useMeQuery(skipMeQuery ? skipToken : undefined);

  const page = useCallback(() => {
    analytics.page(undefined, undefined, {
      language: i18n.language,
      email: me?.account?.email,
      ...getCookies()
    });
  }, [analytics, i18n.language, me?.account?.email]);

  return page;
};
