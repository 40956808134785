import { Typography, styled } from '@mui/material';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';

export const StepLabel = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'labelColor'
})<{
  labelColor: string;
}>(({ theme, labelColor }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight700,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
  color: labelColor
}));
