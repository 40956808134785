import { getDaysAgoPeriod } from '@/utils/date';
import { GetPaymentsFilters, PaymentsSortBy } from './types';
import { PaymentStatus } from '../payment/types';

const last30DaysPeriod = getDaysAgoPeriod(30);

export const initialSearchParams: GetPaymentsFilters = {
  created_at__gte: last30DaysPeriod.from,
  created_at__lte: last30DaysPeriod.to,
  sort_by: PaymentsSortBy.createdAt,
  sort_direction: 'desc'
};

export const initialFilters = {
  merchants: []
};

export const allowedStatusesForFilter: PaymentStatus[] = [
  'cancelled',
  'captured',
  'new',
  'refunded'
];
