import { LegalEntity } from '@/store/merchants';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const useDefaultFormValues = (legal_entity: LegalEntity) => {
  const { merchantId } = useParams<{ id: string; merchantId: string }>();

  return useMemo(() => {
    const defaultAuthority = { name: '', phone: '', email: '' };

    return {
      is_finance: false,
      cr_file: legal_entity.license_url || '',
      cr_number: legal_entity.commercial_registration_number || '',
      merchant_id: merchantId || '',
      finance: defaultAuthority,
      sign: defaultAuthority,
      company_name_or_legal_name: legal_entity.legal_company_name || '',
      registered_company_address: legal_entity.legal_address || ''
    };
  }, [legal_entity, merchantId]);
};
