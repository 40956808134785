import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { formatPhoneNumber } from '@/utils/phone';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { CodeForm } from './CodeForm';
import { Header } from './Header';
import { PHONE_OTP_RESEND_INTERVAL_MS, PHONE_OTP_CODE_LENGTH } from '../constants';
import { maskPhonePart } from '../utils';

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}));

const Subtitle = styled('div')(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: '4px'
}));

const PhoneWrap = styled('div')`
  /* @noflip */
  direction: ltr;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type Props = {
  phone: string;
  isLoading: boolean;
  onSubmitSuccess: (value: string) => void;
  onResend: ({ resetTimer }: { resetTimer: VoidFunction }) => void;
  onPhoneChange?: VoidFunction;
  errorMessage?: string;
};

export const VerifyPhoneForm = (props: Props) => {
  const { onSubmitSuccess, phone, onPhoneChange, onResend, isLoading, errorMessage } = props;
  const { t } = useTranslation();

  return (
    <Root>
      <Header
        title={t('enter_the_4_digit_code')}
        subtitle={
          <Subtitle>
            <div>{t('we_have_sent_this_code_to')}</div>
            <PhoneWrap
              sx={(theme) => ({ ...deprecatedTypographyStyles[theme.direction]._body1Loose700 })}
            >
              {maskPhonePart(formatPhoneNumber(phone))}
            </PhoneWrap>
          </Subtitle>
        }
        onChange={onPhoneChange}
        changeButtonText={t('change_number')}
      />
      <CodeForm
        codeLength={PHONE_OTP_CODE_LENGTH}
        isLoading={isLoading}
        onResend={onResend}
        onSubmitSuccess={onSubmitSuccess}
        resendIntervalMs={PHONE_OTP_RESEND_INTERVAL_MS}
        errorMessage={errorMessage}
      />
    </Root>
  );
};
