import { AnalyticsBrowser } from '@segment/analytics-next';
import { createContext, FC, ReactNode, useContext, useMemo } from 'react';

const ctx = createContext<AnalyticsBrowser | null>(null);

export const AnalyticsProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const props = useMemo(() => {
    return AnalyticsBrowser.load(
      { writeKey: process.env.SEGMENT_KEY },
      {
        initialPageview: false,
        integrations: { 'Segment.io': { apiHost: process.env.EVENT_COLLECTOR_HOST } }
      }
    );
  }, []);

  return <ctx.Provider value={props}>{children}</ctx.Provider>;
};

export const useAnalytics = () => {
  const props = useContext(ctx);

  if (!props) {
    throw new Error('useAnalytics must be nested into AnalyticsProvider');
  }

  return props;
};
