import { datadogRum } from '@datadog/browser-rum';
import fileTypeChecker from 'file-type-checker';

export const isFileMimeTypeValid = (file: File, mimeTypes: string[]) => {
  const reader = window.FileReader && new window.FileReader();

  return new Promise<boolean>((resolve) => {
    if (!reader) {
      datadogRum.addAction('Does not support FileReader');
      resolve(mimeTypes.includes(file.type));
      return;
    }

    reader.onload = () => {
      if (reader.result instanceof ArrayBuffer) {
        const detectedFile = fileTypeChecker.detectFile(reader.result);
        resolve(!!detectedFile && mimeTypes.includes(detectedFile.mimeType));
      }

      resolve(false);
    };

    reader.onerror = () => {
      resolve(false);
    };

    reader.readAsArrayBuffer(file);
  });
};
