import { ReactNode } from 'react';

export type ValueOf<T> = T[keyof T];

export const CURRENCY = {
  AED: 'AED',
  BHD: 'BHD',
  CNH: 'CNH',
  EGP: 'EGP',
  EUR: 'EUR',
  GBP: 'GBP',
  INR: 'INR',
  KRW: 'KRW',
  KWD: 'KWD',
  OMR: 'OMR',
  QAR: 'QAR',
  SAR: 'SAR',
  TRY: 'TRY',
  USD: 'USD'
} as const;

export type Currency = ValueOf<typeof CURRENCY>;

export const COUNTRIES = {
  SAU: 'SAU',
  ARE: 'ARE',
  BHR: 'BHR',
  KWT: 'KWT',
  EGY: 'EGY',
  QAT: 'QAT'
} as const;

export type Country = ValueOf<typeof COUNTRIES>;

export const TABBY_PRODUCTS = {
  PayLater: 'pay_later',
  Installments: 'installments',
  MonthlyBilling: 'monthly_billing',
  CreditCardInstallments: 'credit_card_installments',
  VirtualCardInstallments: 'virtual_card_installments',
  PayInFull: 'pay_in_full'
} as const;

export type TabbyProduct = ValueOf<typeof TABBY_PRODUCTS>;

export const LANGUAGE = {
  ar: 'ar',
  en: 'en'
} as const;

export type Language = ValueOf<typeof LANGUAGE>;

export type Falsy = false | 0 | '' | null | undefined;

export const SORT_DIRECTION = {
  asc: 'asc',
  desc: 'desc'
} as const;

export type SortDirection = ValueOf<typeof SORT_DIRECTION>;

export type PartialWithRequired<T, K extends keyof T> = Pick<T, K> & Partial<T>;

export type Option = {
  label: string;
  value: string;
  topHelperText?: string;
  bottomHelperText?: string;
  onClick?: () => void;
  customHtml?: ReactNode;
};

export type Nullable<T> = T | null;

export type WithId = { id: string };

export type ListTokenResponse<T extends Record<string, unknown>> = {
  next_page_token: string | null;
  items: T[] | null;
};

export type ListTokenResult<T extends Record<string, unknown>> = {
  next_page_token: string;
  items: T[];
};

export type ListOffsetResponse<T extends Record<string, unknown>, P extends string = 'items'> = {
  [key in P]: T[] | null;
} & { limit?: number; offset?: number; total_count?: number };

export type ListOffsetResult<T extends Record<string, unknown>, P extends string = 'items'> = {
  [key in P]: T[];
} & { limit: number; offset: number; total_count: number };
