import { useCallback } from 'react';
import { COUNTRIES } from '@/types';

import { useFormPropsContext } from './useFormPropsContext';
import { useHandleCRNumberSAU } from './useHandleCRNumberSAU';

export const useHandleCRNumberInput = () => {
  const { getValues } = useFormPropsContext();
  const { handleCRNumberSAU, isFetchingCRSAUValidation } = useHandleCRNumberSAU();

  const isFetchingCRValidation = isFetchingCRSAUValidation;

  const handleCRNumberInput = useCallback(async () => {
    const crNumber = getValues('legal_info.commercial_registration_number');
    const countryOfMainOperations = getValues('basic_info.country_of_main_operations');

    if (countryOfMainOperations === COUNTRIES.SAU) {
      handleCRNumberSAU(crNumber || '');
    }
  }, [getValues, handleCRNumberSAU]);

  return { handleCRNumberInput, isFetchingCRValidation };
};
