import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import NotVerifiedIcon from '@/assets/icons/not-verified.svg?icon';

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  background: theme.palette.B2B2023.back.major,
  borderRadius: '16px'
}));

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  padding: '19px 24px 19px 0px'
}));

const IconWrapper = styled('div')(({ theme }) => ({
  color: theme.palette.B2B2023.accent.warningA,
  background: theme.palette.B2B2023.accent.warningC,
  borderRadius: '24px',
  display: 'inline-flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px',
  margin: '16px',
  fontSize: '48px'
}));

const Title = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight700
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._caption1Loose500,
  color: theme.palette.B2B2023.front.minor
}));

export const ResetLoanHeader = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Root>
      <IconWrapper>
        <NotVerifiedIcon />
      </IconWrapper>
      <Content>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </Content>
    </Root>
  );
};
