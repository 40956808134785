import { Text } from '@/ui-kit/Text';
import React from 'react';
import { IconButton } from '@/ui-kit/IconButton';
import { Loader } from '@/ui-kit/Loader';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Close16 from '@tabby.ai/tabby-ui/dist/icons/core/Close16';
import { DS } from '@/ui-kit/types';

const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: '6px'
}));

export const LoadingContent = ({
  fileName,
  fileSize,
  progress,
  onAbortButtonClick
}: {
  fileName: string;
  fileSize: number;
  progress: number;
  onAbortButtonClick: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Text variant="body2Tight500" color="B2B2023.front.major">
          {fileName}
        </Text>
        <IconButton variant="text" size="extraSmall" onClick={onAbortButtonClick}>
          <Close16 dir="ltr" ds={DS.B2B2023} color="B2B2023.front.minor" />
        </IconButton>
      </Row>
      <Row>
        <Text variant="caption2Tight500" color="B2B2023.front.minor">
          {fileSize < 1000000
            ? t('file_size_kb', { fileSize: Math.round(fileSize / 1000) })
            : t('file_size_mb', { fileSize: Math.round(fileSize / 1000000) })}
        </Text>
        <Loader type="Spinning24" color="B2B2023.front.minor" />
        <Text variant="caption2Tight500" color="B2B2023.front.minor">
          {progress}%
        </Text>
      </Row>
    </>
  );
};
