import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApplicationFormStepName } from '@/store/application';

import { generateStepPath } from '../utils';

export const useRedirectToFirstInvalidStep = () => {
  const navigate = useNavigate();

  const redirectToFirstInvalidStep = useCallback(
    (stepValidityMap: Record<ApplicationFormStepName, boolean>) => {
      const keys = Object.keys(stepValidityMap) as ApplicationFormStepName[];
      const firstInvalidStep = keys.find((stepName) => {
        return !stepValidityMap[stepName];
      });

      if (firstInvalidStep) navigate(generateStepPath(firstInvalidStep));
    },
    [navigate]
  );

  return { redirectToFirstInvalidStep };
};
