import { Events } from '@/store/analytics';
import { TextFieldControlled } from '@/ui-kit/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';

import { Box, Typography, styled } from '@mui/material';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { useHandleCRNumberInput } from '../../../components/ApplicationCapitalForm/hooks/useHandleCRNumberInput';
import { useResetLoanFormPropsContext } from '../hooks/useResetLoanFormPropsContext';
import { useResetLoanFormStateContext } from '../hooks/useResetLoanFormStateContext';
import { useResetLoanFormFields } from '../hooks/useResetLoanFormFields';

const Subtitle = styled(Typography)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._caption1Loose500,
  color: theme.palette.B2B2023.front.minor
}));

export const ResetLoanCRNumberFields = () => {
  const { t } = useTranslation();
  const { control } = useResetLoanFormPropsContext();
  const { isLegalEntityCountrySAU, loan } = useResetLoanFormStateContext();
  const { handleCRNumberInput, isFetchingCRValidation } = useHandleCRNumberInput();
  const isCRNumberRelatedFieldDisabled = isLegalEntityCountrySAU;

  const { showLicenseNumberField, showLegalNameField, showLegalAddressField } =
    useResetLoanFormFields(loan.reset_reason);

  return (
    <>
      {showLicenseNumberField && (
        <TextFieldControlled
          onBlur={isLegalEntityCountrySAU ? handleCRNumberInput : undefined}
          disabled={isFetchingCRValidation}
          placeholder={t('enter_license_number')}
          control={control}
          name="cr_number"
          label={t('license_number')}
          analytics={{
            event: Events.CapitalLicenseNumberFilled
          }}
          inputProps={{ maxLength: 15 }}
          InputProps={{
            endAdornment: isFetchingCRValidation && <CircularProgress color="inherit" size={20} />
          }}
        />
      )}
      {showLegalNameField && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <TextFieldControlled
            control={control}
            placeholder={t('legal_company_name')}
            name="company_name_or_legal_name"
            label={t('legal_company_name')}
            disabled={isCRNumberRelatedFieldDisabled}
          />
          <Subtitle>{t('must_match_the_business_license')}</Subtitle>
        </Box>
      )}
      {showLegalAddressField && (
        <TextFieldControlled
          control={control}
          name="registered_company_address"
          placeholder={t('enter_company_address')}
          label={t('registered_company_address')}
          disabled={isCRNumberRelatedFieldDisabled}
        />
      )}
    </>
  );
};
