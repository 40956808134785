import * as yup from 'yup';
import { stringSchema } from '@/lib/yup';
import { useMemo } from 'react';

export const useProductCategorySchema = () => {
  return useMemo(
    () =>
      yup.object({
        section_id: stringSchema.required(() => ''),
        category_id: stringSchema.required(() => '')
      }),
    []
  );
};
