import { ROUTES } from '@/routes';
import { styled } from '@mui/material';
import { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as BaseLink, generatePath } from 'react-router-dom';
import { PayoutCycleInfo } from '@/pages/FinancePages/components/PayoutCycleInfo';
import { Diagram } from '@/pages/FinancePages/components/Diagram';

const Link = styled(BaseLink)(({ theme }) => ({
  color: theme.palette.B2B2023.accent.highlightA
}));

export const useFaqItems = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      {
        key: 1,
        title: t('how_do_i_make_repayments'),
        content: (
          <>
            <Trans
              i18nKey="you_pay_back_your_capital_with_your_regular_tabby_sales__description"
              components={{
                a: <Link to={generatePath(ROUTES.financeCompletedPayouts, { payoutId: null })} />
              }}
            />
            <Diagram />
          </>
        )
      },
      {
        key: 2,
        title: t('how_long_do_i_have_to_pay_back_capital'),
        content: t('youll_have_to_repay_the_capital_in_the_amount_of_time__description')
      },
      {
        key: 3,
        title: t('is_there_a_minimum_weekly_repayment'),
        content: t('yes_your_minimum_weekly_repayment__description')
      },
      {
        key: 4,
        title: t('what_if_i_dont_meet_the_minimum_weekly_repayment'),
        content: t('if_you_cant_meet_the_minimum_weekly_repayment__description')
      },
      {
        key: 5,
        title: t('how_can_i_get_a_capital_offer_for_a_higher_amount'),
        content: t(
          'your_current_capital_offer_is_based_on_your_tabby_sales_perfomance__description'
        )
      },
      {
        key: 6,
        title: t('how_long_does_it_take_to_receive_the_funds'),
        content: t(
          'the_full_process_from_application_to_capital_disbursement_disbursement_takes_around_a_week__description'
        )
      },
      {
        key: 7,
        title: t('what_are_payout_cycles'),
        content: <PayoutCycleInfo />
      }
    ],
    [t]
  );
};
