import {
  DataTablePagination,
  DataTablePaginationOffsetBased,
  DataTablePaginationTokenBased,
  PaginationType
} from './types';

export const getPaginationByType = (
  pagination: DataTablePagination,
  paginationType: PaginationType
): DataTablePaginationTokenBased | DataTablePaginationOffsetBased => {
  const { offset, limit, pageToken } = pagination;

  switch (paginationType) {
    case 'offsetBased':
      return { offset, limit };
    case 'tokenBased':
    default:
      return { pageToken };
  }
};
