import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { VerifyChallengeEmailForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeVerifyEmail = () => {
  const { state, goToSignIn } = useAuthPageContext();
  const { step, emailPart } = state;

  if (step === 'challenge_verify_email' && emailPart) {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <VerifyChallengeEmailForm email={emailPart} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
