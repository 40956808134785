import { Link } from 'react-router-dom';
import { ROUTES } from '@/routes';
import TabbyLogoSvg from '@/assets/icons/tabby-logo.svg';
import TabbyIconSvg from '@/assets/icons/tabby-icon.svg';
import { styled } from '@mui/material/styles';

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'dark'
})<{ dark: boolean }>(({ theme, dark }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: dark ? theme.palette._text.default : theme.palette.common.white
}));

const RootLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'dark'
})<{ dark: boolean }>(({ theme, dark }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: dark ? theme.palette._text.default : theme.palette.common.white
}));

const TextLogo = styled(TabbyLogoSvg, {
  shouldForwardProp: (prop) => prop !== 'size'
})<{ size: number }>(({ size }) => ({
  width: 'auto',
  height: `${size}px`
}));

const IconLogo = styled(TabbyIconSvg, {
  shouldForwardProp: (prop) => prop !== 'size'
})<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`
}));

type Props = {
  variant?: 'icon' | 'text';
  theme?: 'light' | 'dark';
  size?: number;
  disableRootLink?: boolean;
};

const Logo = ({ variant = 'text', theme = 'dark', size = 32, disableRootLink }: Props) => {
  const icon = variant === 'icon' ? <IconLogo size={size} /> : <TextLogo size={size} />;

  if (disableRootLink) {
    return <Root dark={theme === 'dark'}>{icon}</Root>;
  }

  return (
    <RootLink dark={theme === 'dark'} to={ROUTES.root}>
      {icon}
    </RootLink>
  );
};

export default Logo;
