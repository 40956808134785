import { MouseEvent, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TestId, getTestProps } from '@/lib/autotests';

const commonStyles = {
  textDecoration: 'none',
  fontWeight: 'bold',
  cursor: 'pointer',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center'
} as const;

const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.iris[300]
}));

const StyledLink = styled('a')(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.iris[300]
}));

const StyledSpan = styled('span')(({ theme }) => ({
  ...commonStyles,
  color: theme.palette.iris[300]
}));

export type Props = {
  children?: ReactNode;
  className?: string;
  href?: string;
  onClick?: (e?: MouseEvent) => void;
  target?: string;
  title?: string;
  to?: string;
  testId?: TestId;
  rel?: string;
};

const Link = ({ className, children, href, to, testId, ...rest }: Props) => {
  const testProps = getTestProps(testId);

  if (to) {
    return (
      <StyledRouterLink className={className} to={to} {...rest} {...testProps}>
        {children}
      </StyledRouterLink>
    );
  }

  if (href) {
    return (
      <StyledLink className={className} href={href} {...rest} {...testProps}>
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledSpan className={className} {...rest} {...testProps}>
      {children}
    </StyledSpan>
  );
};

export default Link;
