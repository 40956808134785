import MuiButton, { ButtonProps } from '@mui/material/Button';
import Loader from '@/components/Loader/Loader';
import { TestId, getTestProps } from '@/lib/autotests';
import { styled } from '@mui/material/styles';

const Root = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'fullWidth'
})<{
  color?: 'green' | 'red' | 'tabbyGreen' | 'white';
  fullWidth?: boolean;
}>(({ theme, color, fullWidth }) => ({
  position: 'relative',
  color: theme.palette.common.black,
  ...(color === 'green' && {
    color: theme.palette.pine[200]
  }),
  ...(color === 'red' && {
    color: theme.palette.coral[300]
  }),
  ...(color === 'tabbyGreen' && {
    color: theme.palette.common.white
  }),
  ...(fullWidth && {
    width: '100%'
  })
}));

const StyledButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'btnColor'
})<{
  btnColor?: 'green' | 'red' | 'tabbyGreen' | 'white';
}>(({ theme, btnColor }) => ({
  '&.MuiButtonBase-root': {
    padding: '8px 24px',
    lineHeight: 1.25,
    textTransform: 'none',
    fontWeight: 'bold'
  },
  '&.MuiButton-sizeLarge': {
    ...theme.typography.h5,
    borderRadius: '8px',
    padding: '16px 36px'
  },
  '&.MuiButton-outlined': {
    backgroundColor: theme.palette.common.white,
    border: '2px solid',
    padding: '6px 24px',
    '&:hover': {
      backgroundColor: theme.palette.common.white
    },
    '&.MuiButton-outlinedSizeLarge': {
      padding: '14px 36px'
    }
  },
  ...(btnColor === 'white' && {
    color: theme.palette.common.white
  }),
  '&.MuiButton-contained': {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    ...(btnColor === 'green' && {
      backgroundColor: theme.palette.pine[200]
    }),
    ...(btnColor === 'tabbyGreen' && {
      backgroundColor: theme.palette.tabbyGreen.main,
      color: theme.palette._text.default
    }),
    ...(btnColor === 'red' && {
      backgroundColor: theme.palette.coral[300]
    }),
    '&:hover': {
      opacity: 0.8
    },
    '&.Mui-disabled': {
      opacity: 0.4
    }
  }
}));

const StyledLoader = styled(Loader)(() => ({
  position: 'absolute',
  top: 0
}));

export type Props = Omit<ButtonProps, 'color'> & {
  className?: string;
  color?: 'green' | 'red' | 'tabbyGreen' | 'white';
  isLoading?: boolean;
  testId?: TestId;
};

const Button = ({
  className,
  children,
  color,
  isLoading,
  disabled,
  fullWidth,
  testId,
  ...rest
}: Props) => {
  return (
    <Root className={className} color={color} fullWidth={fullWidth}>
      <StyledButton
        btnColor={color}
        color="inherit"
        disableElevation
        fullWidth
        disabled={isLoading || disabled}
        // ripple breaks button in edge with auto AR translation
        disableFocusRipple
        disableTouchRipple
        disableRipple
        {...rest}
        {...getTestProps(testId)}
      >
        {children}
      </StyledButton>
      {isLoading && <StyledLoader />}
    </Root>
  );
};

export default Button;
