import { AuthStepWidget } from '@/components/AuthLayoutWidgets';
import { SetupChallengePhoneForm } from '@/components/AuthForms';
import { useAuthPageContext } from '../AuthPageProvider';
import { GoToSignIn } from './GoToSignIn';

export const AuthStepChallengeSetupPhone = () => {
  const { state, goToSignIn, setStep, setPhone } = useAuthPageContext();
  const { step, phone: initialPhone } = state;

  const handleSuccess = (phone: string) => {
    setPhone(phone);
    setStep('challenge_setup_verify_phone');
  };

  if (step === 'challenge_setup_phone') {
    return (
      <AuthStepWidget onGoBack={goToSignIn}>
        <SetupChallengePhoneForm initialPhone={initialPhone} onSuccess={handleSuccess} />
      </AuthStepWidget>
    );
  }

  return <GoToSignIn />;
};
