import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState
} from 'react';
import { Notification } from './types';

type ContextProps = {
  notifications: Notification[];
  setNotifications: Dispatch<SetStateAction<Notification[]>>;
};

const NotificationsContext = createContext<ContextProps | null>(null);

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const props = useMemo(
    () => ({
      notifications,
      setNotifications
    }),
    [notifications]
  );

  return <NotificationsContext.Provider value={props}>{children}</NotificationsContext.Provider>;
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error('useNotificationsContext call must be nested into NotificationsProvider');
  }

  return context;
};
