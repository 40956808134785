import { useCallback, useMemo } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { COUNTRIES } from '@/types';
import { stringSchema } from '@/lib/yup';
import { useCRNumberSAUValidation } from '../hooks/useCRNumberSAUValidation';

const regExpVatNumber = /^[0-9]{15}$/;

export const useLegalInfoSchema = () => {
  const { t } = useTranslation();
  const { schema: crNumberSAUSchema } = useCRNumberSAUValidation();

  const vatNumberSchema = useMemo(
    () =>
      stringSchema.matches(regExpVatNumber, {
        message: t('please_enter_valid_vat'),
        excludeEmptyString: true
      }),
    [t]
  );

  const getSchema = useCallback(
    (legalEntityCountry: string) => {
      return yup.object({
        business_license_type: stringSchema.required(() => ''),
        commercial_registration_number:
          legalEntityCountry === COUNTRIES.SAU
            ? stringSchema.concat(crNumberSAUSchema)
            : stringSchema.required(() => ''),
        license_url: stringSchema.required(() => ''),
        national_id_url: stringSchema.required(() => ''),
        legal_address: stringSchema.required(() => ''),
        legal_company_name: stringSchema.required(() => ''),
        unified_number: stringSchema,
        license_expire_date: stringSchema,
        has_vat: yup.boolean(),
        vat_registration_number: vatNumberSchema.when('has_vat', {
          is: true,
          then: (s) => s.required(() => '')
        }),
        address_in_tax_certificate: stringSchema.when('has_vat', {
          is: true,
          then: (s) => s.required(() => '')
        }),
        vat_certificate_url: stringSchema
      });
    },
    [crNumberSAUSchema, vatNumberSchema]
  );

  return { getSchema };
};
