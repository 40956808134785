import { CapitalLoanWithLegalInfo } from '@/store/loan';
import { yupResolver } from '@hookform/resolvers/yup';
import { ReactNode } from 'react';
import { FormProvider as FormPropsProvider, useForm } from 'react-hook-form';
import countries from '@/lib/countries';
import { useResetLoanFormSchema } from '../hooks/useResetLoanFormSchema';
import { ResetLoanFormStateProvider } from './ResetLoanFormStateProvider';
import { ResetLoanFormValues } from '../types';

type Props = {
  children: ReactNode;
  initialData: ResetLoanFormValues;
  loan: CapitalLoanWithLegalInfo;
};

export const ResetLoanFormProvider = ({ children, ...rest }: Props) => {
  const { loan, initialData } = rest;
  const isLegalEntityCountrySAU = countries.getCountryByCurrency(loan.currency) === 'SAU';

  const schema = useResetLoanFormSchema(isLegalEntityCountrySAU, loan?.reset_reason);

  const formProps = useForm<ResetLoanFormValues>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: initialData
  });

  return (
    <FormPropsProvider {...formProps}>
      <ResetLoanFormStateProvider {...rest}>{children}</ResetLoanFormStateProvider>
    </FormPropsProvider>
  );
};
