import { DeprecatedTypography } from '@/components/DeprecatedTypography';
import { TextCurrency as BaseTextCurrency } from '@/components/TextCurrency';
import useIsMobilePage from '@/hooks/useIsMobilePage';
import { LoanOffer } from '@/store/offers';
import { Text } from '@/ui-kit/Text';
import { formatCurrencyAmount } from '@/utils/currency';
import Skeleton from '@mui/material/Skeleton';
import { styled, useTheme } from '@mui/material/styles';
import { Tooltip } from '@tabby.ai/tabby-ui/dist/core';
import Info24 from '@tabby.ai/tabby-ui/dist/icons/core/Info24';
import { deprecatedTypographyStyles } from '@tabby.ai/tabby-ui/dist/deprecated/theme';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useFormStateContext } from '../hooks/useFormStateContext';

const Root = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '16px',
  borderRadius: '24px'
}));

const Widget = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  border: `1px solid ${theme.palette.B2B2023.front.line}`,
  borderRadius: '24px',
  padding: '24px 24px 28px 24px',
  overflow: 'hidden'
}));

const Header = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px'
}));

const WrappedText = styled(Text)(() => ({
  textWrap: 'balance'
}));

const Balance = styled('span')(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._hxl,
  fontSize: theme.typography.pxToRem(48),
  lineHeight: '1',
  color: theme.palette.B2B2023.front.invertMajor,

  [theme.breakpoints.down('p768')]: {
    fontSize: theme.typography.pxToRem(44)
  }
}));

const Info = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px'
}));

const RepaymentProcess = styled(Info)(({ theme }) => ({
  padding: '24px',
  background: theme.palette.B2B2023.back.major,
  borderRadius: '24px'
}));

const Row = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '8px'
}));

const AmountText = styled(BaseTextCurrency)(({ theme }) => ({
  ...deprecatedTypographyStyles[theme.direction]._body1Tight700,
  color: theme.palette.B2B2023.front.invertMajor
}));

const Content = styled('div')(({ theme }) => ({
  background: theme.palette.B2B2023.back.minor,
  borderRadius: '24px'
}));

const RowTitle = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
}));

export const OfferCard = ({ offer }: { offer: LoanOffer }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useIsMobilePage();
  const { isLoading } = useFormStateContext();
  const { amount, fee_and_vat_amount, currency, amount_vat, minimum_repayment_amount } = offer;

  const [isRepaymentTooltipOpen, setIsRepaymentTooltipOpen] = useState(false);
  const [isWeeklyRepaymentTooltipOpen, setIsWeeklyRepaymentTooltipOpen] = useState(false);
  const [isPayoutCycleTooltipOpen, setIsPayoutCycleTooltipOpen] = useState(false);

  const loanAmount = formatCurrencyAmount(amount, currency, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  });
  const tooltipPlacement = isMobile ? 'top' : 'right';

  const handleRepaymentRateClick = () => {
    setIsRepaymentTooltipOpen(true);
  };

  const handleRepaymentRateTooltipClose = () => {
    setIsRepaymentTooltipOpen(false);
  };

  const handleWeeklyRepaymentRateClick = () => {
    setIsWeeklyRepaymentTooltipOpen(true);
  };

  const handleWeeklyRepaymentRateTooltipClose = () => {
    setIsWeeklyRepaymentTooltipOpen(false);
  };

  const handlePayoutCycleClick = () => {
    setIsPayoutCycleTooltipOpen(true);
  };

  const handlePayoutCycleTooltipClose = () => {
    setIsPayoutCycleTooltipOpen(false);
  };

  return (
    <Root>
      <Widget
        style={{
          background: `linear-gradient(to ${
            theme.direction === 'rtl' ? 'left' : 'right'
          }, #7B3AF2 1%, #D421E8 50.02%, #F552AF 84.38%, #FD877E 100%)`
        }}
      >
        <Header>
          {isLoading ? (
            <>
              <Skeleton width={100} />
              <Skeleton width={200} height={64} />
            </>
          ) : (
            <>
              <Text variant="body1Tight700" color="B2B2023.front.invertMajor">
                {t('tabby_capital_offer')}
              </Text>
              <Balance>{loanAmount}</Balance>
            </>
          )}
        </Header>
        <Info>
          <Row>
            {isLoading ? (
              <>
                <Skeleton width={200} />
                <Skeleton width={100} />
              </>
            ) : (
              <>
                <Text variant="body1Tight500" color="B2B2023.front.invertMajor">
                  {t('capital_percent_fee_plus_vat')}
                </Text>
                <AmountText amount={fee_and_vat_amount || 0} currency={currency} />
              </>
            )}
          </Row>
          <Row>
            {isLoading ? (
              <>
                <Skeleton width={200} />
                <Skeleton width={100} />
              </>
            ) : (
              <>
                <Text variant="body1Tight500" color="B2B2023.front.invertMajor">
                  {t('total_owed')}
                </Text>
                <AmountText amount={amount_vat} currency={currency} />
              </>
            )}
          </Row>
        </Info>
      </Widget>
      <Content sx={{}}>
        <RepaymentProcess>
          <Info>
            <Text variant="body2Tight700" color="B2B2023.front.minor">
              {t('repayment_process')}
            </Text>
            <Row>
              {isLoading ? (
                <>
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </>
              ) : (
                <>
                  <RowTitle>
                    <Text variant="body1Tight500" color="B2B2023.front.minor">
                      {t('repayment_rate')}
                    </Text>
                    <Tooltip
                      ds="B2B2023"
                      open={isRepaymentTooltipOpen}
                      onClose={handleRepaymentRateTooltipClose}
                      placement={tooltipPlacement}
                      title={t('your_repayment_rate_is_22_of_your_weekly_sales__description', {
                        amount: Number(offer.repayment_rate)
                      })}
                    >
                      <span
                        onMouseEnter={isMobile ? undefined : handleRepaymentRateClick}
                        onClick={isMobile ? handleRepaymentRateClick : undefined}
                      >
                        <Info24 />
                      </span>
                    </Tooltip>
                  </RowTitle>
                  <DeprecatedTypography variant="_body1Tight700">
                    <Trans
                      i18nKey="percent_of_sales"
                      values={{ percent: Number(offer.repayment_rate) }}
                    />
                  </DeprecatedTypography>
                </>
              )}
            </Row>
            <Row>
              {isLoading ? (
                <>
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </>
              ) : (
                <>
                  <Text variant="body1Tight500" color="B2B2023.front.minor">
                    {t('time_to_repay')}
                  </Text>
                  <Text variant="body1Tight700">
                    {t('weeks_with_count', {
                      count: Number(offer.tenor)
                    })}
                  </Text>
                </>
              )}
            </Row>
            <Row>
              {isLoading ? (
                <>
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </>
              ) : (
                <>
                  <div>
                    <WrappedText variant="body1Tight500" color="B2B2023.front.minor">
                      {t('weekly_minimum_repayment')}

                      <Tooltip
                        ds="B2B2023"
                        open={isWeeklyRepaymentTooltipOpen}
                        onClose={handleWeeklyRepaymentRateTooltipClose}
                        placement={tooltipPlacement}
                        title={t('we_deduct_22_of_your_weekly_sales__description', {
                          amount: Number(offer.repayment_rate)
                        })}
                      >
                        <span
                          onMouseEnter={isMobile ? undefined : handleWeeklyRepaymentRateClick}
                          onClick={isMobile ? handleWeeklyRepaymentRateClick : undefined}
                        >
                          <Info24
                            sx={{
                              '&.TuiIcon-root': {
                                display: 'inline-block',
                                verticalAlign: 'middle',
                                marginLeft: '4px'
                              }
                            }}
                          />
                        </span>
                      </Tooltip>
                    </WrappedText>
                  </div>
                  <AmountText
                    amount={minimum_repayment_amount || 0}
                    currency={currency}
                    sx={{ color: theme.palette.B2B2023.front.major }}
                  />
                </>
              )}
            </Row>
            <Row>
              {isLoading ? (
                <>
                  <Skeleton width={200} />
                  <Skeleton width={100} />
                </>
              ) : (
                <>
                  <RowTitle>
                    <Text variant="body1Tight500" color="B2B2023.front.minor">
                      {t('payout_cycle')}
                    </Text>
                    <Tooltip
                      ds="B2B2023"
                      open={isPayoutCycleTooltipOpen}
                      onClose={handlePayoutCycleTooltipClose}
                      placement={tooltipPlacement}
                      title={t(
                        'if_you_are_on_the_flexible_payout_cycle_we_ll_change_it__description'
                      )}
                    >
                      <span
                        onMouseEnter={isMobile ? undefined : handlePayoutCycleClick}
                        onClick={isMobile ? handlePayoutCycleClick : undefined}
                      >
                        <Info24 />
                      </span>
                    </Tooltip>
                  </RowTitle>
                  <Text variant="body1Tight700">{t('fixed_weekly')}</Text>
                </>
              )}
            </Row>
          </Info>
        </RepaymentProcess>
      </Content>
    </Root>
  );
};
