import { useEffect } from 'react';
import { useMatch, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCachedFlag from '@/hooks/useCachedFlag';
import { ROUTES } from '@/routes';
import Bank24 from '@tabby.ai/tabby-ui/dist/icons/core/Bank24';
import { DS } from '@tabby.ai/tabby-ui/dist/types/commonTUI';
import { SideMenuItem } from '@/ui-kit/SideMenu';

type Props = {
  isCollapsed?: boolean;
};

const NavMenuItemCapital = ({ isCollapsed }: Props) => {
  const { t } = useTranslation();
  const [visited, setVisited] = useCachedFlag('capital-new-badge');
  const isCapitalPage = !!useMatch(ROUTES.financeTabbyCapital);

  useEffect(() => {
    if (isCapitalPage) {
      setVisited(true);
    }
  }, [isCapitalPage, setVisited]);

  return (
    <SideMenuItem
      isCollapsed={isCollapsed}
      icon={<Bank24 ds={DS.B2B2023} />}
      label={t('capital')}
      to={ROUTES.financeTabbyCapital}
      notificationBadgeText={visited ? undefined : t('new')}
      component={NavLink}
    />
  );
};

export default NavMenuItemCapital;
