import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useTranslateDocument = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  });
};
