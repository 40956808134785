import { styled } from '@mui/material';

export const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'auto',
  padding: '0 12px 12px',

  [theme.breakpoints.down('p768')]: {
    padding: '0px'
  }
}));
