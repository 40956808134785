import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UPDATE_LOAN_RESPONSE_ERRORS, UpdateLoanResponseErrors } from '../types';

export const useLoanUpdateErrorsLabels = () => {
  const { t } = useTranslation();

  const reasonDict = useMemo(
    () => ({
      [UPDATE_LOAN_RESPONSE_ERRORS.wrong_status]: t('wrong_status_of_edited_loan'),
      [UPDATE_LOAN_RESPONSE_ERRORS.params_for_update_not_diff]: t(
        'change_information_in_some_fields_look_for_hints_next_to_them'
      ),
      [UPDATE_LOAN_RESPONSE_ERRORS.params_for_update_not_sent]: t(
        'change_information_in_some_fields_look_for_hints_next_to_them'
      )
    }),
    [t]
  );

  const getLabel = (reason: UpdateLoanResponseErrors) => reasonDict[reason] || '';

  return { getLabel };
};
