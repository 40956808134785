import { LoaderDots } from '@/components/LoaderDots';
import { getLogoutRoute, getRelativeUrlWithAuthReferrer, ROUTES } from '@/routes';
import { ComputedPermissions, useMeQuery } from '@/store/account-me';
import { useIsAuthorized, useIs401Error } from '@/store/auth';
import { skipToken } from '@reduxjs/toolkit/query';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

type Props = {
  children?: JSX.Element | null;
  permission?: keyof ComputedPermissions;
};

export const PrivateRoute = ({ children = <Outlet />, permission }: Props): JSX.Element | null => {
  const isAuth = useIsAuthorized();
  const is401Error = useIs401Error();
  const { data: me } = useMeQuery(permission ? undefined : skipToken);
  const location = useLocation();

  if (!isAuth) {
    return <Navigate to={ROUTES.auth} replace />;
  }

  if (is401Error) {
    const redirectUrl = getRelativeUrlWithAuthReferrer(getLogoutRoute('server'), location.pathname);
    return <Navigate to={redirectUrl} replace />;
  }

  if (!permission) {
    return children;
  }

  if (!me) {
    return <LoaderDots height="100vh" />;
  }

  return me.computedPermissions[permission] ? children : <Navigate to={ROUTES.root} replace />;
};
