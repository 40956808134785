import { StreamChat } from 'stream-chat';
import { CHANNEL_TYPE } from '../support/constants';

export const getUnreadChannels = async (
  client: ReturnType<typeof StreamChat.getInstance>,
  user_id: string
): Promise<{ count: number; ids: string[] }> => {
  try {
    const ids: string[] = [];
    const count =
      client.user &&
      'unread_channels' in client.user &&
      typeof client.user.unread_channels === 'number'
        ? client.user.unread_channels
        : 0;

    if (count > 0) {
      const channels = await client.queryChannels(
        { type: CHANNEL_TYPE, members: { $in: [user_id] } },
        { unread_count: -1 },
        { limit: count }
      );

      channels.forEach((c) => {
        if (c.id && c.countUnread() > 0) ids.push(c.id);
      });
    }

    return { count, ids };
  } catch (error) {
    return { count: 0, ids: [] };
  }
};
