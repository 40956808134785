import { LoanStatus } from '@/store/loan';
import { useTranslation } from 'react-i18next';

export const useStatusLabels = () => {
  const { t } = useTranslation();

  const labels = {
    submitted: t('in_review'),
    approved: t('approved'),
    closed: t('completed'),
    paying: t('paying'),
    overdue: t('missed'),
    rejected: t('rejected'),
    waiting: t('waiting'),
    reset: t('on_hold')
  };

  const getLabel = (status: LoanStatus) => labels[status] || status;

  return { getLabel };
};
