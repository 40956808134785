import { Currency, ValueOf } from '@/types';
import { CapitalLoanWithLegalEntity, LegalEntityWithMerchantId, LoanAuthority } from '@/store/loan';
import { useDefaultFormValues } from './hooks/useDefaultFormValues';
import { useResetLoanDefaultFormValues } from './hooks/useResetLoanDefaultFormValues';
import { useResetLoanFormState } from './hooks/useResetLoanFormState';

export type FormProps = {
  legalEntities?: LegalEntityWithMerchantId[];
  loan: CapitalLoanWithLegalEntity;
  isLoading?: boolean;
};

export type FormValues = ReturnType<typeof useDefaultFormValues>;

export const REPAYMENT_INFO_STATUSES = {
  paying: 'paying',
  paid: 'paid',
  overdueCritical: 'overdueCritical',
  overdueWarning: 'overdueWarning',
  earlyPayOff: 'earlyPayOff'
} as const;

export type RepaymentInfoStatus = ValueOf<typeof REPAYMENT_INFO_STATUSES>;

export type RepaymentInfo = {
  id: string;
  date: string;
  deducted: number;
  settlementPayout: number;
  repaymentAmount: number;
  resultingPayout: number;
  currency: Currency;
  status: RepaymentInfoStatus;
  repaymentType: 'early_pay_off' | 'general';
};

export const LEGEND_VARIANTS = {
  default: 'default',
  paid: 'paid',
  overdue: 'overdue',
  earlyPayOff: 'earlyPayOff'
} as const;

export type LegendVariant = ValueOf<typeof LEGEND_VARIANTS>;

export const ALERT_VARIANTS = {
  error: 'error',
  warning: 'warning',
  success: 'success'
};

export type AlertVariant = ValueOf<typeof ALERT_VARIANTS>;

export const REPAYMENTS_SORT_BY = {
  date: 'date',
  settlementPayout: 'settlementPayout',
  repaymentAmount: 'repaymentAmount',
  resultingPayout: 'resultingPayout'
} as const;

export type RepaymentsSortBy = ValueOf<typeof REPAYMENTS_SORT_BY>;

export type ResetLoanFormProps = {
  initialData: {
    isFinance?: boolean;
    merchantId?: string;
    finance?: LoanAuthority;
    sign?: LoanAuthority;
    companyNameOrLegalName?: string;
    registeredCompanyAddress?: string;
    crFile?: string;
    crNumber?: string;
  };
  loan: CapitalLoanWithLegalEntity;
};

export type ResetLoanFormValues = ReturnType<typeof useResetLoanDefaultFormValues>;
export type ResetLoanFormState = ReturnType<typeof useResetLoanFormState>;

export const UPDATE_LOAN_RESPONSE_ERRORS = {
  wrong_status: 'wrong_status',
  params_for_update_not_diff: 'params_for_update_not_diff',
  params_for_update_not_sent: 'params_for_update_not_sent'
} as const;

export type UpdateLoanResponseErrors = ValueOf<typeof UPDATE_LOAN_RESPONSE_ERRORS>;
