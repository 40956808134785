import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Option } from '@/types';
import { BUSINESS_LICENSE, BusinessLicense } from '@/store/application';

type Props = {
  labels: Record<BusinessLicense, string>;
  options: Option[];
  toOptions: (licenses: BusinessLicense[]) => Option[];
  getLabel: (license: BusinessLicense) => string;
};

const useBusinessLicenseTypeLabels = (): Props => {
  const { t } = useTranslation();

  const labels = useMemo(
    () => ({
      [BUSINESS_LICENSE.Commercial]: t('commercial_registration'),
      [BUSINESS_LICENSE.Trade]: t('trade_license'),
      [BUSINESS_LICENSE.Freelance]: t('freelance_license')
    }),
    [t]
  );

  const getLabel = useCallback(
    (license: BusinessLicense) => {
      return labels[license] || license;
    },
    [labels]
  );

  const toOptions: Props['toOptions'] = useCallback(
    (arr) => arr.map((value) => ({ value, label: getLabel(value) })),
    [getLabel]
  );

  const options: Props['options'] = useMemo(
    () => toOptions(Object.values(BUSINESS_LICENSE)).sort((a, b) => a.label.localeCompare(b.label)),
    [toOptions]
  );

  return { labels, options, toOptions, getLabel };
};

export default useBusinessLicenseTypeLabels;
