import { NormalizedAPIError } from '@/store/rootApi';
import { TFunction } from 'i18next';

export type Form = 'setupChallenge' | 'verify' | 'signin';

export const getApiErrorMessage = (error: NormalizedAPIError, form: Form, t: TFunction): string => {
  const { message } = error;

  if (message === 'invalid phone') {
    return t('invalid_phone_number');
  }

  if (message === 'invalid email') {
    return t('invalid_email_address');
  }

  if (
    message === 'too many attempts to send code' ||
    message === 'too many attempts to verify code'
  ) {
    return t('too_many_incorrect_login_attempts_please_wait_for_30_minutes_and_try_again');
  }

  if (message === 'invalid otp code') {
    return t('this_code_is_incorrect_or_has_expired');
  }

  if (form === 'setupChallenge' && message === 'phone number is not unique') {
    return t('phone_number_is_invalid_or_already_taken');
  }

  if (form === 'setupChallenge' && message === 'email is not unique') {
    return t('email_is_invalid_or_already_taken');
  }

  if (message === 'phone number is not unique' || message === 'email is not unique') {
    return t('invalid_credentials');
  }

  if (message === 'session expired') {
    return t('session_expired');
  }

  if (message === 'start with email') {
    return t('please_sign_in_with_email_first');
  }

  return t('falied_to_login_please_try_again_later');
};

export const maskPhonePart = (phonePart: string) => phonePart.replace(/X/g, '*');
