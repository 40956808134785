import { rootApi } from '../rootApi/api';
import { AccountSession, Account } from './types';

const accountApi = rootApi.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getSessions: build.query<AccountSession[], void>({
      query: () => '/merchant/api/v1/auth/sessions',
      transformResponse: (response: AccountSession[] | null) => response || [],
      providesTags: [{ type: 'AccountSession', id: 'LIST' }]
    }),
    deleteSessions: build.mutation<void, void>({
      query: () => ({
        url: '/merchant/api/v1/auth/sessions',
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'AccountSession', id: 'LIST' }]
    }),
    deleteSession: build.mutation<void, string>({
      query: (sessionId) => ({
        url: `/merchant/api/v1/auth/sessions/${sessionId}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: 'AccountSession', id: 'LIST' }]
    }),
    getAccount: build.query<Account, void>({
      query: () => '/merchant/api/v1/account',
      providesTags: ['Account']
    }),
    requestAddPhone: build.mutation<void, { phone: string; lang: string }>({
      query: (body) => ({
        url: '/merchant/api/v1/phone/send-code',
        method: 'POST',
        body
      })
    }),
    confirmAddPhone: build.mutation<void, { code: string }>({
      query: (body) => ({
        url: '/merchant/api/v1/phone/set',
        method: 'POST',
        body
      }),
      invalidatesTags: ['Account', 'Me']
    }),
    deletePhone: build.mutation<void, void>({
      query: () => ({
        url: '/merchant/api/v1/auth/phone',
        method: 'DELETE'
      }),
      invalidatesTags: ['Account', 'Me']
    })
  })
});

export const {
  useConfirmAddPhoneMutation,
  useDeletePhoneMutation,
  useDeleteSessionMutation,
  useDeleteSessionsMutation,
  useGetAccountQuery,
  useGetSessionsQuery,
  useRequestAddPhoneMutation
} = accountApi;
