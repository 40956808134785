import { ResetReason } from '@/store/loan';

export const useResetLoanFormFields = (reason?: ResetReason) => {
  const fieldsAccess = {
    showLicenseFileUploader: false,
    showLicenseNumberField: false,
    showLegalNameField: false,
    showLegalAddressField: false
  };

  switch (reason) {
    case 'business_license_expired':
    case 'business_license_expires_soon':
      fieldsAccess.showLicenseFileUploader = true;
      break;
    case 'irrelevant_license':
    case 'beneficiary_name_mismatch':
      fieldsAccess.showLicenseFileUploader = true;
      fieldsAccess.showLicenseNumberField = true;
      fieldsAccess.showLegalNameField = true;
      fieldsAccess.showLegalAddressField = true;
      break;
    case 'company_name_mismatch':
      fieldsAccess.showLegalNameField = true;
      break;
    default:
      break;
  }

  return fieldsAccess;
};
