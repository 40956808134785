import {
  TextField as TuiTextField,
  type TextFieldProps as TuiTextFieldProps
} from '@tabby.ai/tabby-ui/dist/core';
import { getTestProps, TestId } from '@/lib/autotests';
import { FormLabel } from '@/ui-kit/FormLabel';
import { HelperText } from '@/ui-kit/HelperText';
import { styled } from '@mui/material/styles';
import { FormControl as MuiFormControl } from '@mui/material';
import { Loader } from '@/ui-kit/Loader';
import Alert24 from '@tabby.ai/tabby-ui/dist/icons/core/Alert24';
import { DS } from '@/ui-kit/types';

export type TextFieldProps = Omit<TuiTextFieldProps, 'required'> & {
  testId?: TestId;
  isLoading?: boolean;
};

export const FormControl = styled(MuiFormControl)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px'
}));

const getEndAdornment = (isError: boolean, disabled: boolean, isLoading: boolean) => {
  if (isError && !disabled) {
    return <Alert24 dir="ltr" ds={DS.B2B2023} color="B2B2023.special.notification" />;
  }
  if (isLoading) {
    return <Loader type="Spinning24" color="B2B2023.front.major" />;
  }

  return null;
};

export const TextField = ({
  testId,
  label,
  error,
  helperText,
  inputProps,
  disabled,
  size,
  isLoading,
  InputProps,
  ...rest
}: TextFieldProps) => {
  const endAdornment =
    getEndAdornment(!!error, !!disabled, !!isLoading) || InputProps?.endAdornment;

  return (
    <FormControl disabled={disabled}>
      {label && <FormLabel variant="TextField">{label}</FormLabel>}
      <TuiTextField
        ds="B2B2023"
        size={size}
        error={error}
        disabled={disabled}
        inputProps={{ ...inputProps, ...getTestProps(testId) }}
        InputProps={{
          ...InputProps,
          endAdornment
        }}
        {...rest}
      />
      {helperText && <HelperText error={error && !disabled}>{helperText}</HelperText>}
    </FormControl>
  );
};
